import moment from 'moment';
import React, { useContext } from 'react';
import { getChatUpdateRowData, GlobalContext } from '../Utils';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, MENU, USER_PLACEHOLDER } from '../_constants';
import { CHAT_UPDATE_TYPE } from '../_constants/chat.constants';

const ChatUserRow = ({ active, update, onClick }) => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  // const test = false;
  const { title, image, gender = 1 } = getChatUpdateRowData(update);
  const isGroup = update.update_type === CHAT_UPDATE_TYPE.GROUP;
  const lastMessage = update.last_message;
  const count = update.unread_count;
  return (
    <div  style={{ marginBottom: 5 }} onClick={() => onClick({ ...update, title })}>
      <div
        className={`d-flex chat-user-list chat-user-update-padding user-select-none cursor-pointer ${
          active() ? 'user-list-active' : ''
        }`}
      >
        {/* Avatar */}
        <CircularAvatar
          size={46}
          source={apiConstants.imgUrlS3 + image}
          className='fz-none employee-img-with-low-border'
          isMale={gender === 1}
          placeholderSrc={isGroup ? USER_PLACEHOLDER.GROUP : null}
        />

        <div className=' px-2' style={{ flex: 1 }}>
          {/* Name and time */}
          {/* icon for identify the group type  */}
          {update.project_id && <div className='group-type-label '>{LABEL_OF_PROJECT}</div>}

          <div
            className='d-flex align-items-center user-details-container'
            style={{ gap: 3, whiteSpace: 'nowrap' }}
          >
            <div className='chat-user-list-name fz-13px-rem fw-600'>{title}</div>
          </div>

          {/* Recent Msg */}
          {lastMessage ? (
            <div className='chat-user-list-last-msg fz-13px-rem fw-400'>{lastMessage}</div>
          ) : (
            <div className='chat-user-list-last-msg fz-13px-rem' style={{ fontStyle: 'italic' }}>
              No messages yet
            </div>
          )}
        </div>
        {/* Message Count */}
        <div className='msg-count-container'>
          {update?.last_message_time && (
            <div className='chat-user-list-time fz-10px-rem '>
              {moment(parseInt(update.last_message_time)).format('hh:mm a')}
            </div>
          )}

          {Boolean(count) && <div className='chat-user-list-msg-count m-auto'>{count}</div>}
        </div>
      </div>
    </div>
  );
};

export default ChatUserRow;
