/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import { Check, CircleDashed, Ellipsis, LayoutDashboard, Pen, Plus, Trash, X } from 'lucide-react';
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useNavigate } from 'react-router-dom';
import { ContextMenu, Modal } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import { NOTIFICATION_TYPE, RT } from '../_constants';
import { miscService } from '../_services';
import { chatService } from '../_services/chat.service';
import useNotification from '../Hook/useNotification';
import { useUser } from '../Hook/useUser';
import { useUserValidate } from '../Hook/useUserValidate';
import { CustomEmptyContainer } from '../Task/TaskComponents/CustomInputs';
import { TASK_LIST_EMPTY_MESSAGE } from '../Task/taskConstants';
import { DashboardContext, GlobalContext, jsonToUrlEncoded } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { widgetConstant } from './_utils';
import './dashboard.css';
import './dashboardcustom.css';
import AddWidgetModal from './Widget/AddWidget';
import { layoutDropdownStyle } from './Widget/widgetconstants';

const ResponsiveGridLayout = WidthProvider(Responsive);

// Lazy load components
const DashboardBarGraph = React.lazy(() => import('./DashboardBarGraph'));
const DashboardChat = React.lazy(() => import('./DashboardChat'));
const DashboardStatusCounts = React.lazy(() => import('./DashboardStatusCounts'));
const DashboardTaskStatistics = React.lazy(() => import('./Widget/DashboardTaskStatistics'));
const DashboardUpdates = React.lazy(() => import('./DashboardUpdates'));
const DashboardDeadlines = React.lazy(() => import('./Widget/DashboardDeadlines'));
const DashboardWorkLoadStatics = React.lazy(() => import('./Widget/DashboardWorkLoadStatics'));
const TimeBoxCalendar = React.lazy(() => import('../Calander/TimeBoxCalendar'));
const DashboardProjects = React.lazy(() => import('./Widget/DashboardMyProjects'));

// used from home page
const DashBoardCustom = () => {
  const user = useUser();
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const userValidate = useUserValidate();
  const updateToken = useNotification({ disabled: false });
  const nav = useNavigate();

  const [queryPayload, setQueryPayload] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isWidgetModal, setIsWidgetModal] = useState(false);
  const [isWidgetDropDown, setIsWidgetDropDown] = useState({ modal: false, key: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isDelete: false, id: null });
  const [editWidgetModal, setEditWidgetModal] = useState({ isEdit: false, editData: null });

  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const isMutating = useIsMutating();

  const [savedLayouts, setSavedLayouts] = useState(() => {
    const saved = localStorage.getItem('dashboard-layouts');
    return saved ? JSON.parse(saved) : { lg: [], md: [], sm: [], xs: [], xxs: [] };
  });
  const [layout, setLayout] = useState(savedLayouts[currentBreakpoint] || []);

  const {
    data: widgetList,
    isLoading: widgetLoading,
    refetch,
  } = useQuery({
    queryKey: ['widgets'],
    queryFn: () => miscService.getWidget('widget'),
    select: (resp) => resp?.data,
    enabled: !!user?.id,
  });
  const [selectedWidget, setSelectedWidget] = useState({});

  const handleOpenModal = () => setIsWidgetModal(true);
  const handleCloseModal = () => setIsWidgetModal(false);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['event-add'],
    mutationFn: (payload) => miscService.addWidget(payload),
    onSuccess: () => {
      setTimeout(() => {
        refetch();
      }, 1000);
      handleCloseModal();
      return;
    },
    onError: (err) => {
      console.error(err);
      makeAlert(err?.message);
    },
  });
  // Use an effect to set the selected widget when the widget list is loaded
  useEffect(() => {
    if (widgetList && widgetList?.length > 0) {
      const isActiveWidget = widgetList.filter((item) => item.is_active == true);
      const isDefaultWidget = widgetList.filter((item) => item.is_default == true);
      if (isActiveWidget?.length > 0) {
        setSelectedWidget(isActiveWidget[0]); // Set the first widget as the selected widget
      } else setSelectedWidget(isDefaultWidget[0]); // Set the first widget as the selected widget
    }
  }, [widgetList]);
  // const customLayout = selectedWidget?.custom_data?.map((item) => item?.position);

  const adjustWidgetLayout = (widgets, gridCols = 3) => {
    // Sort widgets by their `order` property
    const sortedWidgets = [...widgets].sort((a, b) => a.order - b.order);

    // Initialize position tracking
    let x = 0;
    let y = 0;

    // Adjust positions based on sorted order
    const adjustedWidgets = sortedWidgets.map((widget, index) => {
      const newWidget = {
        ...widget,
        position: {
          ...widget.position,
          x: x, // Assign current x
          y: y, // Assign current y
        },
      };

      // Move to the next column
      x += widget.position.w || 1;

      // If the current row is full, reset `x` and move to the next row
      if (x >= gridCols) {
        x = 0;
        y += widget.position.h || 1;
      }

      return newWidget;
    });

    return adjustedWidgets;
  };
  useEffect(() => {
    if (selectedWidget?.custom_data) {
      const sortedAndAdjustedWidgets = adjustWidgetLayout(selectedWidget.custom_data, 3); // Assuming 3 columns

      const position = sortedAndAdjustedWidgets.map((widget) => {
        const pos = widget.position;
        const identifier = pos.i;
        pos.name = identifier;
        pos.i = `${identifier}?${widget?.project_id ? 'project_id=' + widget?.project_id : ''}${
          widget?.template_id ? 'template_id=' + widget?.template_id : ''
        }${widget?.userType ? 'user_type=' + widget?.userType : ''}`;
        return pos;
      });

      setLayout(position);
    }
  }, [selectedWidget?.custom_data]);

  const updateQuery = {
    select: [
      'id',
      'task_id',
      'last_message',
      'last_message_time',
      'message',
      'message_count_info',
      'update_type',
      'updated_time',
      'last_message_creator_id',
    ],
    update_type: 'task',
    unread: true,
    task_select: ['id', 'name', 'note', 'priority', 'order_seq'],
  };

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setQueryPayload(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const { data: updatesList, isLoading } = useQuery({
    queryKey: ['dashboard-update', user?.id],
    queryFn: () => chatService.getChatUpdateList({ payload: getPayLoad(updateQuery) }),
    select: (resp) => resp.data,
    enabled: !!user?.id,
  });

  // for change in layout
  const handleLayoutChange = useCallback(
    (newLayout, allLayouts) => {
      // if (isEditMode) {
      setSavedLayouts(allLayouts);
      localStorage.setItem('dashboard-layouts', JSON.stringify(allLayouts));
      setLayout(newLayout);
      // }
    },
    [isEditMode],
  );
  const handleBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setLayout(savedLayouts[breakpoint] || []);
  };

  const dashboardContextValue = useMemo(
    () => ({
      updatesList,
      isLoading,
    }),
    [updatesList, isLoading],
  );
  // Adjust the delay as needed (300ms in this example)

  const Loader = () => {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon color={'var(--Base-Color)'} size={70} />
      </div>
    );
  };

  const dashboardWidgets = useMemo(
    () => ({
      [widgetConstant.task_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardTaskStatistics {...props} />
        </Suspense>
      ),
      [widgetConstant.calendar]: (props) => (
        <Suspense fallback={<Loader />}>
          <TimeBoxCalendar isNeedProject={false} isNeedTemplate={false} {...props} />
        </Suspense>
      ),
      [widgetConstant.chats]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardChat {...props} />
        </Suspense>
      ),
      [widgetConstant.updates]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardUpdates {...props} queryPayload={queryPayload} />
        </Suspense>
      ),
      [widgetConstant.graphical_report]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardBarGraph {...props} />
        </Suspense>
      ),
      [widgetConstant.status_counts]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardStatusCounts user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.upcoming_deadlines]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardDeadlines user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.workload_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardWorkLoadStatics {...props} />
        </Suspense>
      ),
      [widgetConstant.my_projects]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardProjects {...props} />
        </Suspense>
      ),
    }),
    [queryPayload],
  );

  const getDashBoardWidget = (widget) => {
    let selectedProps = {};
    const WidgetComponent = dashboardWidgets[widget?.name] || null;

    selectedProps = {
      userType: widget.userType,
      project_id: widget.project_id,
      viewType: widget.viewType,
      template_id: widget.template_id,
    };

    // If the widget component is not null, call it with the additional props
    return WidgetComponent ? WidgetComponent(selectedProps) : null;
  };

  // to change layout and set as active
  const handleChangeLayout = async (data) => {
    data.is_active = true;
    data.isChangeLayout = true;
    // setSelectedWidget(data);
    await mutateAsync(data);
  };

  // to handle the drag and drop feature
  const handleDragAndResizeStop = async (layout, oldItem, newItem, placeholder, e, node) => {
    const currentWidget = structuredClone(selectedWidget); // Avoid direct mutation
    let hasChanged = false;

    // Extract the dragged/resized widget details
    const { i: newI, x: newX, y: newY, w: newW, h: newH } = newItem;

    // Find the index of the dragged widget
    const draggedWidgetIndex = currentWidget.custom_data.findIndex(
      (widget, index) => `${widget.name}-${index}` === newI,
    );

    if (draggedWidgetIndex === -1) {
      console.error(`Widget with identifier ${newI} not found.`);
      return;
    }

    // Update the dragged widget's position and size
    const draggedWidget = {
      ...currentWidget.custom_data[draggedWidgetIndex],
      position: {
        ...currentWidget.custom_data[draggedWidgetIndex].position,
        x: newX,
        y: newY,
        w: newW,
        h: newH,
      },
    };

    currentWidget.custom_data[draggedWidgetIndex] = draggedWidget;

    // Efficient conflict resolution
    const resolveConflicts = (widgets, draggedWidget) => {
      const { position: draggedPos } = draggedWidget;

      widgets.forEach((widget) => {
        if (widget === draggedWidget) return;

        const { position: otherPos } = widget;

        // Check for overlap using bounding box
        const isOverlapping =
          draggedPos.x < otherPos.x + otherPos.w &&
          draggedPos.x + draggedPos.w > otherPos.x &&
          draggedPos.y < otherPos.y + otherPos.h &&
          draggedPos.y + draggedPos.h > otherPos.y;

        if (isOverlapping) {
          // Push the overlapping widget below the dragged widget
          const deltaY = draggedPos.y + draggedPos.h - otherPos.y;
          widget.position.y += deltaY;

          // Avoid cascading overlaps by resolving conflicts for the moved widget
          resolveConflicts(widgets, widget);
        }
      });
    };

    resolveConflicts(currentWidget.custom_data, draggedWidget);

    // Finalize layout without re-sorting
    const finalizeLayout = (widgets) => {
      widgets.forEach((widget) => {
        const { x, y } = widget.position;
        // Ensure positions are within bounds and align properly
        widget.position.x = Math.max(0, x);
        widget.position.y = Math.max(0, y);
      });
    };

    finalizeLayout(currentWidget.custom_data);

    // Check for layout changes
    hasChanged =
      JSON.stringify(currentWidget.custom_data) !== JSON.stringify(selectedWidget.custom_data);

    if (hasChanged) {
      if (currentWidget?.is_default) {
        currentWidget.is_default = false;
        delete currentWidget.id;
        delete currentWidget.user_id;
        delete currentWidget.title;
        currentWidget.user_id = user.id;
        currentWidget.title = 'Custom Layout - ' + widgetList.length;
      }

      // Update state with the new layout
      setSelectedWidget((prev) => ({
        ...prev,
        custom_data: currentWidget.custom_data,
      }));

      // Optionally save changes to the backend
      await mutateAsync(currentWidget);
    } else {
      console.log('No changes detected.');
    }
  };

  // Check if a widget overlaps any occupied grid cell
  const isOverlapping = (startY, x, w, h, occupied) => {
    for (let col = x; col < x + w; col++) {
      for (let row = startY; row < startY + h; row++) {
        if (occupied.has(`${col}-${row}`)) {
          return true;
        }
      }
    }
    return false;
  };

  // Mark the grid cells occupied by a widget
  const markOccupied = (x, y, w, h, occupied) => {
    for (let col = x; col < x + w; col++) {
      for (let row = y; row < y + h; row++) {
        occupied.add(`${col}-${row}`);
      }
    }
  };

  // Function to get the next id
  const getNextId = (newWidget, widgets) => {
    // Check if the ID already exists in the array
    const idExists = widgets.some((widget) => widget.id === newWidget.id);

    if (idExists) {
      // If the ID exists, return the largest id from the array
      const largestId = widgets.reduce((max, widget) => (widget.id > max ? widget.id : max), 0);
      return largestId + 1;
    } else {
      // If the ID doesn't exist, return the ID of the new widget
      return newWidget.id;
    }
  };

  // submit the form data edit and change widget position
  const handleSubmit = async (formData) => {
    try {
      if (editWidgetModal.isEdit) {
        const updatedData = selectedWidget?.custom_data?.map((widget) => {
          if (widget?.id === formData?.id) {
            return {
              ...widget,
              ...formData,
              position: formData.position,
            };
          }
          return widget;
        });
        selectedWidget.custom_data = updatedData;
        setEditWidgetModal({ editData: null, isEdit: false });
      } else {
        const customData = selectedWidget?.custom_data;
        delete formData.label, formData.value;
        formData.order = selectedWidget?.custom_data?.length + 1;
        formData.id = getNextId(formData, customData);
        customData?.push(formData);
        selectedWidget.custom_data = customData;
        selectedWidget.status = 1;
      }
      // If the selected widget is the default layout, create a new custom layout
      if (selectedWidget?.is_default) {
        selectedWidget.is_default = false;
        delete selectedWidget.id, selectedWidget.user_id, selectedWidget.title;
        selectedWidget.user_id = user.id;
        selectedWidget.title = 'Custom Layout - ' + widgetList.length;
      }
      await mutateAsync(selectedWidget); // Pass the form data to the mutation
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  // edit widget by id
  const handleEditWidget = (widgetId) => {
    setEditWidgetModal({ isEdit: true, editData: widgetId });
  };

  // handler for delete widget by id
  const handleDeleteWidget = (widgetId) => {
    setDeleteConfirmation({ isDelete: true, id: widgetId });
  };

  // handler for delete confirmation
  const handleRemove = async (id) => {
    setDeleteConfirmation({ id: null, isDelete: false });
    const currentWidget = structuredClone(selectedWidget);

    const removingItem = currentWidget.custom_data?.filter((item) => item?.id == id)?.[0];

    const updatedLayout = currentWidget.custom_data?.filter((item, index) => {
      if (item?.id != id) {
        if (index + 1 == removingItem?.order) item.order = removingItem?.order;
        return item;
      }
    });
    currentWidget.custom_data = updatedLayout;
    await mutateAsync(currentWidget); // Pass the form data to the mutation
  };

  // navigate to the respective page based on widget name
  const navigateTo = (widgetName) => {
    if (widgetName === 'chats') {
      nav(`/${RT.CHAT}?tab=all`);
    } else if (widgetName === 'updates') {
      nav(`/${RT.NOTIFICATION}/${NOTIFICATION_TYPE.TASK_CHAT}`);
    } else if (widgetName === 'my_projects') {
      nav(`${RT.PROJECT_LIST}`);
    }
  };

  if (isLoading || widgetLoading) {
    return null;
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <section className='dashboard-layout'>
        <div className='notification-nav-contain'>
          <div className={'dashboard_menu_active cursor-pointer'}>
            <CircleDashed size={18} className='me-1' /> Overview
          </div>

          <section className='d-flex gap-1 mb-1 align-item-center'>
            {isEditMode && (
              <SearchableQuerySelect
                queryFn={miscService.getWidget}
                initialValue={widgetList}
                isCustomFunction
                value={selectedWidget || {}}
                isLoading={widgetLoading}
                queryKey='widgets'
                getOptionLabel={(option) => option?.title}
                getOptionValue={(option) => option?.id}
                classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal mr-1' }}
                styles={layoutDropdownStyle}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                placeholder={`Choose Layout`}
                onChange={(val) => {
                  handleChangeLayout(val);
                }}
              />
            )}
            {/* <ToolTip tooltipText={'Dashboard'} isModern>
              <div
                className={`menu-item-outline-widget${!isEditMode ? ' active' : ''}`}
                onClick={() => setIsEditMode(false)}
              >
                <DashBoardDrawer height={15} width={15} size={18} color={'#87909E'} />
              </div>
            </ToolTip> */}
            {!isEditMode && (
              <ToolTip tooltipText={'Edit'} isModern>
                <div
                  className={`menu-item-outline-widget${isEditMode ? ' active' : ''}`}
                  onClick={() => setIsEditMode(true)}
                >
                  <Pen
                    height={15}
                    width={15}
                    color={isEditMode ? '#3454d1' : '#87909E'}
                    size={18}
                  />
                </div>
              </ToolTip>
            )}
            {isEditMode && (
              <ToolTip tooltipText={'Save'} isModern>
                <div
                  className={`menu-item-outline-widget${isEditMode ? ' active' : ''}`}
                  onClick={() => setIsEditMode(false)}
                >
                  <Check
                    height={15}
                    width={15}
                    color={isEditMode ? '#3454d1' : '#87909E'}
                    size={18}
                  />
                </div>
              </ToolTip>
            )}
          </section>
        </div>
      </section>
      <ResponsiveGridLayout
        className='layout'
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
        onDragStop={handleDragAndResizeStop}
        onResizeStop={handleDragAndResizeStop} // Add the resize handler
        cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        draggableHandle='.dashboard-header-container'
        resizeHandles={['se']}
        allowOverlap={false} // Allow overlap only in edit mode
        compactType={'vertical'} // Allow free dragging in edit mode
        width={'100%'}
        margin={{ lg: [20, 20], md: [20, 20], sm: [20, 20], xs: [20, 20], xxs: [20, 20] }}
        preventCollision={!isEditMode} // Prevent collision only when not in edit mode
        isDraggable={isEditMode}
        isResizable={isEditMode}
        rowHeight={10}
      >
        {selectedWidget?.custom_data?.map((item, index) => {
          const uniqueKey = `${item.name}-${index}`; // Generate a unique identifier for each widget
          let gridHeight = item?.position?.h;
          const actualHeight = gridHeight * 10 + 20 * (gridHeight - 1);

          return (
            <div
              key={uniqueKey}
              data-grid={{
                ...item?.position,
                i: uniqueKey, // Use uniqueKey for the draggable item
                h: gridHeight,
                isDraggable: isEditMode,
                isResizable: isEditMode,
              }}
              style={{
                '--grid-height': actualHeight + 'px',
                padding: item.i === widgetConstant.status_counts ? '0px' : '10px',
                background:
                  item.i == widgetConstant.status_counts ? 'transparent' : 'var(--bg-primary)',
                height: 'auto',
                border: item.name === widgetConstant.status_counts ? 'none' : '',
              }}
            >
              <div className='dashboard-header-container' style={{ paddingBottom: '5px' }}>
                <div className='dashboard-widget-header'>{item?.title}</div>
                <div className='d-flex gap-2'>
                  <div>
                    {isEditMode && !selectedWidget.is_default && (
                      <section className='d-flex align-items-center gap-2'>
                        {deleteConfirmation.id !== item?.id && (
                          <div
                            className='menu-item-outline-widget'
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsWidgetDropDown({ modal: true, key: item?.id }); // Use uniqueKey
                            }}
                            style={{ zIndex: 9999 }}
                          >
                            <Ellipsis size={18} color='var(--icon-light)' />
                          </div>
                        )}
                        {deleteConfirmation.isDelete && deleteConfirmation.id === item?.id && (
                          <div className='d-flex gap-1'>
                            <div className='menu-item-outline-widget'>
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmation({ id: null, isDelete: false });
                                }}
                                size={16}
                              />
                            </div>
                            <div className='menu-item-outline-widget'>
                              <Check
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemove(deleteConfirmation?.id);
                                }}
                                size={16}
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    )}
                  </div>
                  {isWidgetDropDown.modal && isWidgetDropDown.key === item?.id && (
                    <ContextMenu
                      positionClass='drop p-0 right-30px'
                      onRequestClose={() => {
                        setIsWidgetDropDown({ modal: false, key: null });
                      }}
                      isContextVisible={true}
                    >
                      <div className='dashboard-widget-dropdown'>
                        <section
                          className='dashboard-widget-dropdown-item'
                          onClick={() => handleEditWidget(item)}
                        >
                          <Pen size={12} /> Edit
                        </section>
                        <section
                          className='dashboard-widget-dropdown-item'
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsWidgetDropDown({ modal: false, key: null });
                            handleDeleteWidget(item?.id); // Use uniqueKey for delete
                          }}
                        >
                          <Trash size={12} /> Delete
                        </section>
                      </div>
                    </ContextMenu>
                  )}
                </div>
              </div>
              <section className='widget'>{getDashBoardWidget(item)}</section>
            </div>
          );
        })}
      </ResponsiveGridLayout>
      {layout.length == 0 && (
        <CustomEmptyContainer
          Icon={() => {
            return <LayoutDashboard />;
          }}
          text={TASK_LIST_EMPTY_MESSAGE.dashboard_empty}
          handleAdd={() => {
            setIsEditMode(true);
            handleOpenModal();
          }}
        />
      )}
      {isEditMode && (
        <>
          {layout?.length > 0 && (
            <button
              style={{ background: 'var(--Base-Color)' }}
              className='taskAdd-FAB'
              onClick={handleOpenModal}
            >
              <Plus color='#fff' />
            </button>
          )}
          {isWidgetModal && (
            <Modal.Container handleClose={handleCloseModal}>
              <Modal.View
                className={'widget-form-container'}
                containerClass={'d-flex justify-content-end modal_right_side'}
              >
                <AddWidgetModal
                  user={user}
                  isOpen={isWidgetModal}
                  onClose={handleCloseModal}
                  onSubmit={handleSubmit}
                  isSaving={isPending}
                  currentLayoutId={selectedWidget.id}
                />
              </Modal.View>
            </Modal.Container>
          )}
        </>
      )}
      {editWidgetModal.isEdit && (
        <Modal.Container handleClose={handleCloseModal}>
          <Modal.View
            className={'widget-form-container'}
            containerClass={'d-flex justify-content-end modal_right_side'}
          >
            <AddWidgetModal
              user={user}
              isOpen={editWidgetModal.isEdit}
              onClose={() => {
                setEditWidgetModal({ editData: null, isEdit: false });
              }}
              onSubmit={handleSubmit}
              isSaving={isPending}
              currentLayoutId={selectedWidget.id}
              editWidgetData={editWidgetModal.editData}
              isEdit={true}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </DashboardContext.Provider>
  );
};

export default DashBoardCustom;
