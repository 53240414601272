/* eslint-disable no-unused-vars */
import { useIsMutating } from '@tanstack/react-query';
import { X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Button } from '../../_components';
import InputWithIcons from '../../_components/Inputs/InputWithIcons';
import { MENU_ICONS } from '../../_constants/template.constants';
import { Cancel, Tick } from '../../Utils/SvgIcons';

export function StatusAdd({ selectedData, modalClose, handleMutate, setSelectedData, length }) {
  const isMutating = useIsMutating();
  const [submitted, setSubmitted] = useState(false);

  //-- state for form data
  const [formData, setFormData] = useState({
    name: '',
    color: '',
    is_counted: false,
    is_failed_status: false,
    id: null,
    icon: '',
  });

  //-- set initial form data if selectedData changes
  useEffect(() => {
    if (selectedData) {
      setFormData({
        name: selectedData.name || '',
        color: selectedData.color || '',
        is_counted: selectedData.is_counted || false,
        is_failed_status: selectedData.is_failed_status || false,
        id: selectedData.id || null,
        icon: selectedData.icon || null,
      });
    }
  }, [selectedData]);

  //-- handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  //-- handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedData({});
    setSubmitted(true);

    if (formData.name) {
      formData.order = selectedData.order || length + 1;

      handleMutate(formData);
      setSubmitted(false);
    }
  };

  return (
    <section className='status-container'>
      <HeadAndClose head={'Add Status'} handleClose={modalClose} />
      <form onSubmit={handleSubmit} noValidate>
        {/* status */}
        <div className='mb-2'>
          <div className='status-head-txt'>Status</div>
          <InputWithIcons
            onIconChange={(icon) => setFormData((prev) => ({ ...prev, icon: icon.name }))}
            getIconUrl={(icon) => icon.path}
            iconList={MENU_ICONS}
            findIcon={(icon) => formData.icon === icon.name}
            name='name'
            inputValue={formData.name}
            onInputChange={handleChange}
            className='status-inp'
            type='text'
            required
          />

          {submitted && !formData.name && <p className='status-error'>Status required</p>}
        </div>
        {/* color */}
        <div>
          <div className='status-head-txt'>Color</div>
          <div className='status-color'>
            <input
              name='color'
              value={formData.color}
              onChange={handleChange}
              type='color'
              className='color_picker'
            />
          </div>
        </div>
        <div className='status-check-wrap'>
          <input
            className='menu_check_box'
            type='checkbox'
            name='is_counted'
            checked={formData.is_counted}
            onChange={handleChange}
            disabled
          />
          <div className='check-bx-txt '>Show Count in home page</div>
        </div>
        <div className='status-check-wrap'>
          <input
            disabled
            className='menu_check_box'
            type='checkbox'
            name='is_failed_status'
            checked={formData.is_failed_status}
            onChange={handleChange}
          />
          <div className='check-bx-txt '>Is failed status</div>
        </div>
        <SaveAndCancel onCancel={modalClose} isSubmitting={isMutating} />
      </form>
    </section>
  );
}

//--save and cancel button
const SaveAndCancel = ({ onCancel, isSubmitting }) => {
  return (
    <div className='status-btn-wrapper'>
      <Button.Container type='button' handleOk={onCancel} className={'cancel-btn'}>
        <Cancel />
        <Button.Title title='Cancel' />
      </Button.Container>
      <Button.Container isDisabled={isSubmitting} type={'submit'}>
        <Tick />
        <Button.Title title='Save' />
        <Button.Loading isLoading={isSubmitting} />
      </Button.Container>
    </div>
  );
};

// --modal head and close button
const HeadAndClose = ({ head, handleClose }) => {
  return (
    <div className='status-head'>
      <div>{head}</div>
      <div onClick={handleClose}>
        <X />
      </div>
    </div>
  );
};
