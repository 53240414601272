export const TimeSheetTypes = {
    Task: 1,
    Designation: 2,
    Template: 3,
    Project: 4, // added
    Role: 5, // added
    TimeLog: 6,
    User: 7,
};


export const TimeSheetTypeSelectOptions = [{
    value: TimeSheetTypes.Task,
    label: 'Task',
},
{
    value: TimeSheetTypes.Designation,
    label: 'Designation',
},
{
    value: TimeSheetTypes.Template,
    label: 'Template'
},
{
    value: TimeSheetTypes.Project,
    label: 'Project'
},
{
    value: TimeSheetTypes.Role,
    label: 'Role'
},
{
    value: TimeSheetTypes.TimeLog,
    label: 'TimeLog'
},
{
    value: TimeSheetTypes.User,
    label: 'User'
}
]


export const timesheetDataDummy = [
    {
        "task_id": "16",
        "task_name": "simple task without project",
        "from_date": "1725363470488",
        "to_date": "1725363470488",
        "total_hours": "604819194",
        "billing_hours": "604819194",
        "time_logs": [
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            },
            {
                "id": 1,
                "end_time": 1728055800914,
                "task_name": "simple task without project",
                "start_time": 1728027000000,
                "total_hours": 28800914,
                "billing_hours": 28800914
            }
        ]
    },
    {
        "task_id": "108",
        "task_name": "errors fix - https://docs.google.com/spreadsheets/d/1027ccR9SCX8J-YbD5EZXx6kMd_mYs0AA8GWpcLn2XtM/edit?gid=0#gid=0\n",
        "from_date": "1728113260010",
        "to_date": "1728152999999",
        "total_hours": "4162491",
        "billing_hours": "4162491",
        "time_logs": [
            {
                "id": 3,
                "end_time": 1728116683584,
                "task_name": "errors fix - https://docs.google.com/spreadsheets/d/1027ccR9SCX8J-YbD5EZXx6kMd_mYs0AA8GWpcLn2XtM/edit?gid=0#gid=0\n",
                "start_time": 1728116434517,
                "total_hours": 249067,
                "billing_hours": 249067
            },
            {
                "id": 4,
                "end_time": 1728120600064,
                "task_name": "errors fix - https://docs.google.com/spreadsheets/d/1027ccR9SCX8J-YbD5EZXx6kMd_mYs0AA8GWpcLn2XtM/edit?gid=0#gid=0\n",
                "start_time": 1728116686640,
                "total_hours": 3913424,
                "billing_hours": 3913424
            }
        ]
    },
    {
        "task_id": "109",
        "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
        "from_date": "1728113604744",
        "to_date": "1728152999999",
        "total_hours": "8125635",
        "billing_hours": "8125635",
        "time_logs": [
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            },
            {
                "id": 2,
                "end_time": 1728116430980,
                "task_name": "in line related add with slice issue fix for 2 lines of task name add, check the issue with mobile view alse",
                "start_time": 1728116044045,
                "total_hours": 386935,
                "billing_hours": 386935
            }
        ]
    }
]
