import { UsersRound } from 'lucide-react';

export const TASK_VISIBLE_TYPE = {
  ALL: 1,
  SELECTED_USERS: 2,
  OWNERS: 0,
  1: 'All',
  2: 'Selected Users',
  0: 'Owners',
};

export const TASK_TYPES = {
  TIME_BOUND_TASK: 1,
  NO_VERIFY_TASK: 2,
  NON_TIME_BOUND_TASK: 3,
  1: 'Time bound',
  2: 'Time bound',
  3: 'Non Time bound',
};

export const SHARE_UPDATES_OPTIONS = {
  SHARE: 1,
  DO_NOT_SHARE: 0,
};

export const START_MODE = {
  IMMEDIATE: 1,
  CUSTOM_START_TIME: 2,
  AFTER_ANOTHER_TASK: 3,
  0: 'Immediate',
  1: 'Immediate',
  2: 'After',
  3: 'After',
};

export const ALLOW_ASSIGN_TO_MAIN = {
  DENY: 0,
  ALLOW: 1,
};

export const VALIDATIONS_OPTIONS = [
  // { label: 'Task Name', field: 'name', id: 1 },
  { label: 'Description', field: 'note', id: 2 },
  { label: 'Start Date', field: 'task_start_date', id: 3 },
  { label: 'End Date', field: 'task_end_date', id: 4 },
  // { label: 'Start Time', field: 'start_time', id: 5 },
  // { label: 'End Time', field: 'end_time', id: 6 },
  { label: 'Requested Hours', field: 'expected_hours', id: 7 },
  { label: 'Priority', field: 'priority', id: 8 },
  { label: 'Assigned User', field: 'assignee_id', id: 9 },
  { label: 'Verify User', field: 'verifying_users', id: 10 },
  // { label: LABEL_OF_CLIENT, field: 'client_id', id: 11 },

  // { label: 'Sub Tasks', field: 'subtasks', id: 11 },
];

export const VALIDATION_VALUES = {
  HIDE: 0,
  REQUIRED: 1,
  NOT_REQUIRED: 2,
};

export const EDITOR_TOOLBAR_OPTIONS = {
  options: ['inline', 'list'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
    bold: {
      className: 'EditorIconOption',
      // icon: '/icons/toolbar/bold.svg',
    },
    italic: {
      className: 'EditorIconOption',
      // icon: '/icons/toolbar/italic.svg',
    },
    strikethrough: {
      className: 'EditorIconOption',
      // icon: '/icons/toolbar/strikethrough.svg',
    },
  },
  list: {
    options: ['unordered', 'ordered'],
    unordered: {
      className: 'EditorIconOption',
      // icon: '/icons/toolbar/list-ordered.svg',
    },
    ordered: {
      className: 'EditorIconOption',
    },
  },
};

export const START_MODES = {
  IMMEDIATE: 1,
  CUSTOM_START_TIME: 2,
  PRECEDING_OTHER_TASK: 3,
  0: 'Immediate',
  1: 'Immediate',
  2: 'Custom',
  3: 'Preceding other task',
};

export const ERROR_BLOCKS = {
  TEMPLATE_NAME: 'TEMPLATE_NAME',
  EXPECTED_HOURS: 'EXPECTED_HOURS',
  START_TIME: 'START_TIME',
  TASK_VISIBLE_USER: 'TASK_VISIBLE_USER',
  STATUS_GROUP: 'STATUS_GROUP',
  INVOICE_BILL: 'INVOICE_BILL',
};

export const TEMPLATE_ERROR_MSGS = {
  EXPECTED_HOURS_REQUIRED: 'Expected hours are required',
  TEMPLATE_NAME_REQUIRED: 'Template name is required',
  END_TIME_GREATER_THAN_START_TIME: 'End time should be greater than start time',
  CIRCULAR_DEPENDENCY_FOUND: 'Option unavailable due to circular dependency.',
  TASK_VISIBLE_USER_REQUIRED: 'Please select at least one user',
  START_END_TIME_REQUIRED: 'Start time and end time are required',
  START_TIME_REQUIRED: 'Start time is required',
  END_TIME_REQUIRED: 'End time is required',
  TASK_DEPENDS_ON_OTHER_TASK: "You can't delete this task, since it depends on some other task",
};

export const TEMPLATE_VISIBLE_ALL_OPTION = {
  first_name: 'All',
  id: 0,
  gender: 3,
  icon: UsersRound,
  hideAvatar: true,
  description: 'Visible to all users',
};

export const TEMPLATE_VERIFY_CHANGE = {
  ALLOWED: 1,
  NOT_ALLOWED: 0,
};

export const TEMPLATE_TYPE = {
  NORMAL: 1,
  REQUEST: 2,
  TODO: 3,
};
export const TEMPLATE_ASSIGN_USER_SCOPE = {
  USER_AND_CHILD_USERS: 0,
  EVERYONE: 1,
  REPORTED_USERS: 2,
  ONLY_USER: 3,
};

export const TEMPLATE_FIELD_VALIDATIONS = {
  is_unlisted: {
    [TEMPLATE_TYPE.REQUEST]: { value: true, isDisabled: true },
    [TEMPLATE_TYPE.TODO]: { value: true, isDisabled: true },
    [TEMPLATE_TYPE.NORMAL]: { value: false, isDisabled: false },
  },
  include_in_menu: {
    [TEMPLATE_TYPE.REQUEST]: { value: true, isDisabled: true },
    [TEMPLATE_TYPE.TODO]: { value: true, isDisabled: true },
    [TEMPLATE_TYPE.NORMAL]: { value: false, isDisabled: false },
  },
  task_type: {
    [TEMPLATE_TYPE.REQUEST]: { value: TASK_TYPES.NON_TIME_BOUND_TASK, isDisabled: true },
    [TEMPLATE_TYPE.TODO]: { value: TASK_TYPES.NON_TIME_BOUND_TASK, isDisabled: true },
    [TEMPLATE_TYPE.NORMAL]: { value: TASK_TYPES.TIME_BOUND_TASK, isDisabled: false },
  },
};

export const MENU_ICONS = [
  { name: 'Chat', path: '/icons/chat.svg', id: 1, height: 15, width: 14 },
  { name: 'File', path: '/icons/file.svg', id: 2, height: 15, width: 15 },
  { name: 'Project', path: '/icons/project.svg', id: 3, height: 15, width: 14 },
  { name: 'Report', path: '/icons/report.svg', id: 4, height: 14, width: 13 },
  { name: 'Team', path: '/icons/team.svg', id: 5, height: 16, width: 17 },
  { name: 'File2', path: '/icons/file.svg', id: 6 },
  { name: 'Project2', path: '/icons/project.svg', id: 7, height: 15, width: 14 },
  { name: 'Report2', path: '/icons/report.svg', id: 8, height: 14, width: 13 },

  { name: 'done', path: '/status_icons/done.svg', id: 9 },
  { name: 'in-progress', path: '/status_icons/Group 134698.svg', id: 10 },
  { name: 'todo', path: '/status_icons/list-todo.svg', id: 11, height: 16, width: 17 },
  { name: 'under-verification', path: '/status_icons/underVerification.svg', id: 12 },
  { name: 'to-verify', path: '/status_icons/toVerify.svg', id: 13 },
  { name: 'pending', path: '/status_icons/pending.svg', id: 13 },
  { name: 'default-icon', path: '/status_icons/defaultStatusIcon.svg', id: 13 },
];
