export const RT = {
  EDITOR: 'editor',
  PROFILE: 'profile',
  QUERY_DEMO: 'demo',
  QUERY_DEMO_LIST: 'demo-list',
  QUERY_DEMO_ADD: 'demo-add',

  DEMO: 'demo',
  DEMO_LIST: 'demo-list',
  DEMO_ADD: 'demo-add',

  REMINDER_LIST: 'reminder-list',

  USER_LIST: 'user-list',
  USER: 'user',
  USER_ADD: 'user-add',

  COMPANY_LIST: 'company-list',
  COMPANY: 'company',
  COMPANY_ADD: 'company-add',

  TASK_LIST: 'task-list',
  TASK: 'task',
  TASK_ADD: 'task-add',

  TEMPLATE_LIST: 'template-list',
  TEMPLATE: 'template',
  TEMPLATE_ADD: 'template-add',

  TODO_LIST: 'todo-list',
  TODO: 'todo',
  TODO_ADD: 'todo-add',

  MY_TEAM_LIST: 'myteam-list',
  MY_TEAM: 'myteam',
  MY_TEAM_ADD: 'myteam-add',
  MY_TEAM_LIST_MAIN: 'my-team-list-main',
  MY_TEAM_CALENDAR: 'my-team-list-calendar',

  OKR_LIST: 'okr-list',
  OKR_VIEW: 'okr',

  ORGANIZATION_LIST: 'organization-list',
  ORGANIZATION: 'organization',
  ORGANIZATION_ADD: 'organization-add',
  WORK_SPACE: 'work-space',

  WORK_FLOW_LIST: 'wf-list',
  WORK_PROCESS_LIST: 'wf-process-list',

  PROJECT_LIST: 'project-list',
  PROJECT: 'project',
  PROJECT_ADD: 'project-add',
  MILESTONE: 'milestone',
  PROJECT_TASK_LIST: 'project-task-list',
  PROJECT_BOARD: 'project-board',
  PROJECT_CALENDAR: 'project-calendar',

  CHAT_LIST: 'chat-list',
  CHAT: 'chat',
  CHAT_ADD: 'chat-add',

  CALENDAR: 'calender',

  NOTIFICATION: 'notification',
  REQUEST: 'request',
  //   settings--sub routes
  SETTINGS: 'settings',
  GENERAL: 'general',
  MENU: 'menu',
  PROJECT_SETTINGS: 'project',
  TASK_SETTINGS: 'task',
  EMPLOYEE_SETTINGS: 'employee',
  PROFILE_SETTINGS: 'profile',
  CHAT_SETTINGS: 'chat',
  NOTIFICATION_SETTINGS: 'notification',
  TIME_SETTINGS: 'time',
  DEPARTMENT: 'department',
  DESIGNATION: 'designation',
  LABEL: 'label',
  PERMISSION: 'permission',
  THEME: 'theme',
  BRANCH: 'branch',
  TEAM: 'team',
  CLIENT: 'client',
  CLIENT_SINGLE_PAGE: 'client-single-view',
  CLIENT_TASK_LIST: 'client-task-list',
  ADVANCED_PERMISSION: 'advanced_permission',
  ROLE: 'role',

  //   end settings--sub routes
  TIME_HISTORY: 'time-history',
  TIME_SHEET: 'time-sheet',
  GRAPH_REPORT: 'graph-report',
  PIE_CHART: 'pie-chart-report',
  BAR_CHART: 'bar-chart-report',

  FILE: 'file',
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot_password',
  REPORT: 'Report',
  PINNED: 'Pinned',
  // ARCHIVE: 'Archive',
  DIGITAL_DOCUMENT: 'digital-document',
  PHYSICAL_DOCUMENT: 'physical-document',
  ALL_FILE: 'all',
  FILE_LIST: 'file-list',

  ALL_FOLDER: 'all-folder',
  WORK_HISTORY: 'work-history',
  TASK_REPORT: 'task-report',
  WORK_ACTIVITY: 'work-activity',
  TEAM_REPORT: 'team-report',

  FILESYSTEM: 'filesystem',
  NEW_FILE_LIST: 'new-file-list',
  NEW_FOLDER_LIST: 'new-folder-list',

  FILELIST: 'file_list',
  FOLDERLIST: 'folder_list',
  BOARD_VIEW: 'board-view',
  GAME: 'game',

  AI: 'ai',

  ARCHIVE: 'archive-list',

  INVITE_ACCEPT: 'invite-accept',
  // INVITE_WELCOME: 'invite-home',
  QUICK_SET: 'Quick Set',

  USER_MANAGEMENT: 'user-management',

  CONFIG: 'config',
  GMAIL: 'gmail',
  WHATSAPP: 'whatsapp',

  TIMESHEET: 'time-sheet-list',
  TIMESHEET_GENERATE: 'time-sheet-generate',

  INVOICE: 'invoice',
  INVOICE_LIST: 'invoice-list',
  ESTIMATE_LIST: 'estimate-list',
  ITEM_LIST: 'item-list',
  TAX_LIST: 'tax-list',

  BILL_LIST: 'bill-list',

  PAYMENT_RECEIVED: 'payment-received',
  INVOICE_CLIENT_LIST: 'invoice-client-list',
  LEDGER: 'ledger',
};
