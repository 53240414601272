/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ArrowDownUp,
  BriefcaseBusiness,
  Eye,
  File,
  Home,
  ListFilter,
  Plus,
  SquareUserRound,
  User,
  Users,
} from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { TaskAdd } from '../Task';
import { fullNameUser, GlobalContext, Images, MyTeamIcon } from '../Utils';
import { ChatIcon, LoadingIcon, MyTeamIconSvg } from '../Utils/SvgIcons';
import { Modal, PaginationComponent, TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { MyTeamFilter } from './MyTeamFilter';

export function MyTeamList() {
  // hook section
  const tableContainerRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // useState section
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [addTaskTo, setAddTaskTo] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSortVisible, setIsSortVisible] = useState(false);

  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);

  const { globalSettings } = useContext(GlobalContext);
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'menu-settings')?.labels;

  const hasExist = (name) => {
    const setting = menuSettings?.find((label) => label.name === name);
    return setting ? setting.value : undefined;
  };

  const [query, setQuery] = useState({
    select: [
      'id',
      'first_name',
      'last_name',
      'employee_code',
      'address',
      'reported_users',
      'image_url',
      'gender',
      'status',
      'is_sub_admin',
      'compressed_image_url',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  const department_id = searchParams.get('department_id');
  const reported_users_ids = searchParams.get('reported_users_ids');
  const designation_id = searchParams.get('designation_id');
  const sortBy = searchParams.get('sortBy');
  const direction = searchParams.get('direction');
  // Update query state with sorting parameters
  useEffect(() => {
    if (sortBy && direction) {
      setQuery((prevQuery) => ({
        ...prevQuery,
        pageVo: {
          ...prevQuery.pageVo,
          sort: [{ sortBy, direction }],
        },
      }));
    } else {
      setQuery((prevQuery) => {
        const { ...restPageVo } = prevQuery.pageVo;
        return {
          ...prevQuery,
          pageVo: restPageVo,
        };
      });
    }
  }, [sortBy, direction]);
  // code to redirect page 1 after adding filter

  useEffect(() => {
    if (searchParams.get('pageNo')) {
      const page = parseInt(searchParams.get('pageNo'));
      if (query.pageVo.pageNo !== page) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          pageVo: { ...prevQuery.pageVo, pageNo: page },
        }));
      }
    }
  }, [searchParams, query.pageVo.pageNo]);

  // store filter details present in URL into a single variable
  const filterToUrl = {};
  if (department_id) filterToUrl.department_id = department_id;
  if (designation_id) filterToUrl.designation_id = designation_id;
  if (reported_users_ids) filterToUrl.reported_users_ids = reported_users_ids;
  const filterCount = Object.keys(filterToUrl).length;

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: userData,
    error,
    isLoading,
    isSuccess,
    isPaused,
    refetch,
  } = useQuery({
    queryKey: [
      'userList',
      query?.pageVo?.pageNo,
      debouncedSearch,
      filterToUrl,
      query?.pageVo?.sort,
    ],
    queryFn: () => userService.getDirectReportingUserList({ ...query, ...filterToUrl }),
    placeholderData: keepPreviousData,
  });

  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });
  // section trigger when delete option selected
  useEffect(() => {
    if (isPaused) {
      makeAlert('No Internet');
    }
  }, [isPaused]);

  // get the count of organization
  const listItemCount = userData?.data?.rows.length;

  const tableData = userData?.data?.rows?.map((user, index) => {
    const slNo = (userData?.data?.page - 1) * userData?.data?.limit + index + 1;
    const fullName = fullNameUser(user.first_name, user.last_name);
    const url = user.compressed_image_url ? user.compressed_image_url : user.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return (
      <Tr key={user?.id}>
        <Td>{slNo}</Td>
        <Td>
          <div className='d-flex gap-2 align-items-center'>
            <CircularAvatar
              size={25}
              source={finalUrl}
              className='fz-none zoom-in employee-img-with-low-border ms-0 me-0'
              isMale={user.gender === 1}
              isNeutral={user.gender === 3}
            />
            {fullName}
          </div>
        </Td>
        <Td className='align-items-center'>
          {user?.employee_code ? user?.employee_code : ' - - '}
        </Td>
        <Td>{user?.role_name ? user?.role_name : ' - - '}</Td>
        <Td>{user['designation.name'] ? user['designation.name'] : '-'}</Td>
        <Td>
          <StackedAvatars
            getSourceByKey={(user) =>
              user.compressed_image_url ? user.compressed_image_url : user.image_url
            }
            getNameByKey={(user) => user.first_name}
            getGenderByKey={(user) => user.gender === 1}
            imageList={user?.reported_users_details}
            showInitials={false}
            size={25}
            tooltipPopOver
            scrollRefForTooltip={tableContainerRef}
            justifyPosition={'justify-content-start'}
          />
        </Td>
        <Td className='cursor-pointer'>
          {user?.is_profile_completed && (
            <div className='d-flex gap-4 justify-content-start icon-div invisible'>
              <div className='d-flex gap-2 '>
                <ToolTip
                  tooltipText={'View Profile'}
                  isModern
                  popOver
                  scrollRef={tableContainerRef}
                >
                  <div
                    className='icon-bg'
                    onClick={() => {
                      navigate(`/${RT.MY_TEAM}/${user?.id}`);
                    }}
                  >
                    <Eye size={15} />
                  </div>
                </ToolTip>
                <ToolTip tooltipText={'View Task'} isModern popOver scrollRef={tableContainerRef}>
                  <div
                    className='icon-bg'
                    onClick={() => {
                      navigate(
                        `/${RT.TASK_LIST}?status=in-progress&user_id=${
                          user.id
                        }&type=individual&user_status=${
                          user?.status === 1 ? 'active' : 'removed'
                        }&list_type=team-task`,
                      );
                    }}
                  >
                    <File size={15} />
                  </div>
                </ToolTip>
                {roleBasedPermission?.ACTION_TASK_ADD ? (
                  <ToolTip tooltipText={'Add Task'} isModern popOver scrollRef={tableContainerRef}>
                    <div
                      className='icon-bg'
                      onClick={() => {
                        const { first_name, image_url, gender, id, last_name } = user;
                        setAddTaskTo({ id, last_name, first_name, image_url, gender });
                        setAddTaskModal(true);
                      }}
                    >
                      <Plus size={15} />
                    </div>
                  </ToolTip>
                ) : (
                  ''
                )}

                {/* chat navigation 
              {hasExist('Chat') && (
                <ToolTip tooltipText={'Chat'} isModern popOver scrollRef={tableContainerRef}>
                  <div
                    className='icon-bg'
                    onClick={() => {
                      navigate(`/${RT.CHAT}?tab=all&chat_user_id=${user.id}&from=my-team`);
                    }}
                  >
                    <ChatIcon />
                  </div>
                </ToolTip>
              )}
            </div>
            
                */}

                {/* chat navigation  */}
                {hasExist('Chat') && (
                  <ToolTip tooltipText={'Chat'} isModern popOver scrollRef={tableContainerRef}>
                    <div
                      className='icon-bg'
                      onClick={() => {
                        navigate(`/${RT.CHAT}?tab=all&chat_user_id=${user.id}&from=my-team`);
                      }}
                    >
                      <ChatIcon />
                    </div>
                  </ToolTip>
                )}
              </div>
            </div>
          )}
        </Td>
        <Td></Td>
      </Tr>
    );
  });

  const handleSortClick = (value) => {
    const currentSortBy = searchParams.get('sortBy');
    let newDirection = 'ASC';

    if (currentSortBy === value) {
      newDirection = searchParams.get('direction') === 'ASC' ? 'DESC' : 'ASC';
    }

    const newSearchParams = new URLSearchParams(searchParams.toString()); // Clone the current search params
    newSearchParams.set('sortBy', value);
    newSearchParams.set('direction', newDirection);
    setSearchParams(newSearchParams);
  };

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  return (
    <div className='fade-entry'>
      <div className='url-container'>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'My Team'}
            color={'var(--icon-light)'}
            fontWeight={500}
            icon={<Users size={13} />}
            onClick={() => navigate(`/${RT.MY_TEAM_LIST}`)}
          />
        </div>
      </div>
      <div className='my-team-list '>
        {/* component will active when error occurs   */}
        {error && <ErrorComponent error={error?.message} />}

        <section>
          {/* <div className='my-task-tabs'>{userData?.data?.isFilterVisible && <MyTeamTabs />}</div> */}
          <div>
            <section className=' myteam-table-container py-3'>
              <section>
                <div className='tab_contain'>
                  <SearchBar handleSearch={handleSearch} searchKey={query.searchKey} />
                  <div className='d-flex align-items-center justify-content-end gap-3'>
                    <ToolTip tooltipText={'User management'} isModern>
                      <div
                        className='menu-item-outline'
                        onClick={() => {
                          navigate(
                            `/${RT.SETTINGS}/${RT.TEAM}/${RT.USER_MANAGEMENT}?id=${RT.USER_MANAGEMENT}&&status=active-users&pageNo=1`,
                          );
                        }}
                      >
                        <MyTeamIconSvg />
                      </div>
                    </ToolTip>

                    {
                      <div
                        className='ellipsis_icon'
                        ref={filterRef}
                        onClick={() => {
                          setIsFilterVisible(true);
                        }}
                      >
                        <>
                          <ToolTip tooltipText={'Filter'} isModern>
                            <div
                              className='menu-item-outline'
                              style={{
                                background:
                                  isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                              }}
                            >
                              <ListFilter
                                size={16}
                                color={
                                  isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'
                                }
                              />
                            </div>
                          </ToolTip>
                        </>

                        <MyTeamFilter
                          isVisible={isFilterVisible}
                          setSearchParams={setSearchParams}
                          handleClose={(event) => {
                            event.stopPropagation();
                            setIsFilterVisible(false);
                          }}
                          searchParams={searchParams}
                        />
                        {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                      </div>
                    }
                    <ToolTip tooltipText={'Sort'} isModern>
                      <div
                        className='menu-item-outline'
                        style={{
                          background: isSortVisible ? '#e9f2ff' : '',
                        }}
                        onClick={() => {
                          handleSortClick('first_name');
                          setIsSortVisible((prev) => !prev);
                        }}
                      >
                        <ArrowDownUp size={16} color={isSortVisible ? '#3454d1' : '#87909E'} />
                      </div>
                    </ToolTip>
                  </div>
                </div>
                <div className=' mt-3'>
                  <div className='myTeam-table-container ' ref={tableContainerRef}>
                    <Table className='table table-hover tbl mb-0'>
                      <Thead className='table-head '>
                        <Tr className='table-head'>
                          <Th>
                            <img src={Images.SerialNo} height={14} width={14} />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Employee'}
                              icon={
                                <User
                                  size={16}
                                  strokeWidth={'3px'}
                                  color={'var(--second-text-color)'}
                                />
                              }
                              gap={3}
                              textClassName='table-head-color'
                            />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Employee ID'}
                              icon={<img className='img-icon-size' src={MyTeamIcon.EmployeeId} />}
                              gap={3}
                              textClassName='table-head-color'
                            />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Type'}
                              icon={
                                <BriefcaseBusiness size={17} stroke='var(--second-text-color)' />
                              }
                              gap={3}
                              textClassName='table-head-color'
                            />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Role'}
                              icon={<SquareUserRound size={18} stroke='var(--second-text-color)' />}
                              gap={3}
                              textClassName='table-head-color'
                            />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Reported to'}
                              icon={
                                <img
                                  className='img-icon-size'
                                  width={14}
                                  height={14}
                                  src={MyTeamIcon.ReportTo}
                                />
                              }
                              className='w-fit'
                              gap={3}
                              textClassName='table-head-color'
                            />
                          </Th>
                          <Th style={{ width: 100 }}></Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <div></div>
                      <Tbody style={{ verticalAlign: 'middle' }}>
                        {isLoading && (
                          <TableRow noOfCols={10}>
                            <LoadingIcon size={60} />
                          </TableRow>
                        )}
                        {!listItemCount && isSuccess && !isPaused && (
                          <TableRow noOfCols={10}>No data found</TableRow>
                        )}
                        {isPaused && <TableRow noOfCols={10}>No Internet</TableRow>}
                        {listItemCount > 0 && isSuccess && !isPaused && tableData}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </section>
              {listItemCount > 0 && (
                <section className='mt-3'>
                  <PaginationComponent
                    pageOnClick={(data) => {
                      setQuery((prev) => ({
                        ...prev,
                        pageVo: {
                          ...prev.pageVo,
                          pageNo: data,
                        },
                      }));
                      searchParams.set('pageNo', data);
                      setSearchParams(searchParams);
                    }}
                    demoData={userData?.data}
                  />
                </section>
              )}
            </section>{' '}
          </div>

          <section>
            {addTaskModal && (
              <Modal.Container>
                <Modal.Head containerClass={'task-add-head'}></Modal.Head>
                <Modal.View className={'task-add-container'}>
                  <TaskAdd
                    id={0}
                    handleClose={() => {
                      setAddTaskModal(false);
                    }}
                    selectDefaultTemplateIfPresent
                    assignTo={addTaskTo?.id}
                    defaultAssignee={addTaskTo}
                    y
                  />
                </Modal.View>
              </Modal.Container>
            )}
          </section>
        </section>
      </div>
    </div>
  );
}
