import { SearchIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Modal } from '../_components';
import { GlobalSearch } from './GlobalSearch';
import './GlobalSearch.css';
import ToolTip from '../_components/Tooltip/ToolTip';

export default function GlobalSearchContainer() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'f') {
        event.preventDefault(); // Prevent browser default find action
        setIsOpen(true);
      }
      if (event.key === 'Escape') {
        setIsOpen(false); // Close modal on Escape
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <ToolTip isModern tooltipText={'Search  (Ctrl+F)'}>
        <div className='d-flex   btn' role='button' onClick={() => setIsOpen(true)}>
          <SearchIcon size={19} />
        </div>
      </ToolTip>

      {isOpen && (
        <Modal.Container handleClose={() => setIsOpen(false)}>
          <Modal.View className='global-search-modal'>
            <GlobalSearch setIsOpen={setIsOpen} />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
