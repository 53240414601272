import { useMutation, useQuery } from '@tanstack/react-query';
import { Pen } from 'lucide-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalContext, Images } from '../../Utils';
import { Button, Modal } from '../../_components';
import { statusService } from '../../_services/status.service';
import { AddNewView, HeadWithArrow, StatusCard } from '../UtilsInSettings';
import '../settings.css';
import { StatusGroupAdd } from './StatusGroupAdd';
import { CustomTrashIcon } from '../../Utils/SvgIcons';

//-TODO:- improve this component lot of states, remove
export function StatusGroupComponent({ StatusData }) {
  const { makeAlert } = useContext(GlobalContext);

  const [addModal, setAddModal] = useState(false);
  const [arrowDown, setArrowDown] = useState(false);
  const [statusGroupData, setStatusGroupData] = useState({});
  const [groupName, setGroupName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editGroupId, setEditGroupId] = useState();
  const [groupData, setGroupData] = useState(null);

  const modalClose = () => {
    setAddModal(false);
  };

  //-- filter default status
  const defaultStatus = useMemo(
    () => StatusData?.filter((status) => status.is_default == 1),
    [StatusData],
  );
  //-- filter non default status
  const nonDefaultStatus = useMemo(
    () => StatusData?.filter((status) => status.is_default == 0),
    [StatusData],
  );

  //-- fetch all status groups
  const getAllStatusGroupList = async () => {
    const statusGroupData = await statusService.statusGroupList({ status: 1 });
    setStatusGroupData(statusGroupData.data);
    return statusGroupData;
  };

  const { error, refetch } = useQuery({
    queryKey: ['group-status-list'],
    queryFn: () => getAllStatusGroupList(),
  });

  const defaultGroupStatusData = statusGroupData?.defaultStatus;
  const nonDefaultGroupStatusData = statusGroupData?.nonDefaultStatus;

  useEffect(() => {
    if (error?.message) {
      makeAlert(error?.message);
    }
  }, [error?.message]);

  return (
    <>
      <section className='mt-4 task_tabs_container pb-2'>
        <HeadWithArrow
          head={'Status Groups'}
          isDown={arrowDown}
          handler={() => setArrowDown(!arrowDown)}
        />
        {!arrowDown && (
          <>
            <AddNewView
              head={'Create New Group'}
              handleOK={() => {
                setEditData(), setAddModal(true);
                setEditGroupId(), setGroupName();
                setIsDefault(false);
              }}
            />
            {/* default */}
            {!!defaultGroupStatusData && !!defaultGroupStatusData[0] && (
              <>
                <div className='group-sub-head general_head'>Default Group</div>
                <CardComponent
                  refetch={refetch}
                  setGroupData={setGroupData}
                  is_default={true}
                  setEditData={setEditData}
                  setAddModal={setAddModal}
                  setGroupName={setGroupName}
                  cardStyle={'card'}
                  data={defaultGroupStatusData && defaultGroupStatusData[0]}
                  setEditGroupId={setEditGroupId}
                  setIsDefault={setIsDefault}
                />
              </>
            )}
            {/* end default */}
            {/* other */}
            {!!nonDefaultGroupStatusData && !!nonDefaultGroupStatusData.length && (
              <>
                <div className='group-sub-head general_head'>Custom Groups</div>
                {nonDefaultGroupStatusData &&
                  nonDefaultGroupStatusData.map((nonDefault, index) => {
                    return (
                      <CardComponent
                        refetch={refetch}
                        setGroupData={setGroupData}
                        key={index}
                        setEditGroupId={setEditGroupId}
                        setEditData={setEditData}
                        setAddModal={setAddModal}
                        setGroupName={setGroupName}
                        cardStyle={'card-other'}
                        data={nonDefault}
                        is_default={false}
                        setIsDefault={setIsDefault}
                      />
                    );
                  })}
              </>
            )}
            {/* end other */}
          </>
        )}
      </section>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className={'add-status-group'}>
            <StatusGroupAdd
              groupData={groupData}
              setGroupData={setGroupData}
              editGroupId={editGroupId}
              editData={editData}
              refetch={refetch}
              initialData={defaultStatus}
              nonDefaultStatus={nonDefaultStatus}
              name={groupName}
              modalClose={modalClose}
              isDefault={isDefault}
              StatusData={StatusData}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}

const CardComponent = ({
  setEditGroupId,
  data,
  cardStyle,
  setGroupName,
  setAddModal,
  setEditData,
  is_default,
  setIsDefault,
  setGroupData,
  refetch,
}) => {
  const cardClass = cardStyle || 'card';
  // const queryClient = useQueryClient();
  const { makeAlert } = useContext(GlobalContext);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectId, setSelectedId] = useState(null);

  const modalClose = () => {
    setDeleteModal(false);
    setSelectedId(null);
  };

  const handleDeleteFunction = async ({ id }) => {
    if (id) {
      await statusService.statusGroupDelete({ id: data?.id });
      makeAlert('Status group deleted successfully');
      refetch();
      modalClose();
    }
  };

  //-- react query fot mutation function
  const {
    mutate: handleDelete,
    // isError,
    error: deleteError,
  } = useMutation({
    mutationFn: handleDeleteFunction,
  });

  useEffect(() => {
    if (deleteError?.message) {
      makeAlert(deleteError?.message);
    }
  }, [deleteError?.message]);

  return (
    <div className={cardClass}>
      <div className='card-head-contain'>
        <div className='head-txt'>{data?.name}</div>
        <div className='d-flex gap-2 align-items-center'>
          <section className='settings-more-menu'>
            <div
              role='button'
              onClick={() => {
                setGroupData(data);
                setEditGroupId(data?.id);
                setEditData(data?.items);
                setAddModal(true);
                setGroupName(data?.name);
                setIsDefault(is_default);
              }}
            >
              <Pen size={14} color='#87909E' />
            </div>
          </section>
          {!is_default && (
            <div
              onClick={() => {
                setSelectedId(data?.id);
                setDeleteModal(true);
              }}
            >
              <CustomTrashIcon size={16} />
            </div>
          )}
        </div>
      </div>
      {/* labels */}
      <div className='card-contain'>
        {data?.items?.map((LABEL) => {
          return (
            <>
              <StatusCard LABEL={LABEL?.name} color={LABEL?.color} />
            </>
          );
        })}
      </div>
      {/* labels */}
      <div className='line'></div>
      {/* <div className='used-txt'>Used in : Temp1, Temp2, Temp3</div> */}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' />
              <div className='del-description'>Are you sure you want to delete.</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDelete({ id: selectId })}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};
