/* eslint-disable no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import { CircleCheck } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Modal } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CommonDropDownStyle, MENU } from '../_constants';
import { MODAL_NOTES } from '../_constants/deletenote.constants';
import { clientService } from '../_services';
import { invoiceService } from '../_services/invoice.service';
import { SelectedFiles } from '../FileSystem/SelectedFiles';
import { currencyType } from '../Settings/InvoiceSettings';
import { INVOICE_IDS } from '../Settings/SettingsLabels';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  FileSystemImage,
  getFileType,
  GlobalContext,
  InvoiceItemState,
  InvoiceState,
  sanitizeFileName,
} from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { AddItemModal } from './AddItemModal';
import './invoice.css';
import { InvoiceAddItemTable } from './InvoiceAddItemTable';
import { TAX_CONST, TAX_TYPE_CONST, TRANSACTION_TYPE } from './InvoiceConstant';

export const AddInvoiceEstimate = ({
  handleClose = () => {},
  afterAdd = () => {},
  transactionType = TRANSACTION_TYPE.INVOICE,
}) => {
  const { getMenuLabelName, globalSettings, makeAlert, globalSettingsRefetch } =
    useContext(GlobalContext);
  const inputRefs = useRef([]);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const hasExist = (labelID) => {
    const setting = invoiceSettings?.labels?.requiredFields?.find((label) => label.id == labelID);
    return setting ? setting.value : undefined;
  };
  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  // useState section
  const [itemAddModal, setItemAddModal] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [invoiceItemData, setInvoiceItemData] = useState([{ ...InvoiceItemState }]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [typeOfTax, setTypeOfTax] = useState(null);

  const invoiceNumberOfDigits = parseInt(invoiceSettings?.labels?.numberOfDigits) || 1; // Default to 0 if invoiceNumberOfDigits is not available
  const paddedInvoiceNumber = String(invoiceSettings?.labels?.invoiceNumber).padStart(
    invoiceNumberOfDigits,
    '0',
  );

  const [invoiceData, setInvoiceData] = useState({
    ...InvoiceState,
    transaction_type: transactionType,
    tax: invoiceSettings?.labels?.defaultTax,
    tax_type: invoiceSettings?.labels?.taxType,
    terms_and_conditions: invoiceSettings?.labels?.termsCondition,
    invoice_number:
      (transactionType === TRANSACTION_TYPE.ESTIMATE
        ? invoiceSettings?.labels?.estimatePrefix?.toUpperCase()
        : invoiceSettings?.labels?.invoicePrefix?.toUpperCase()) + paddedInvoiceNumber,
  });

  // function to calculate total amount of invoice
  const totalAmounts = useMemo(() => {
    const totalSubAmount = invoiceItemData?.reduce((acc, item) => acc + (item?.amount || 0), 0);
    const totalTaxAmount = invoiceItemData?.reduce(
      (acc, item) => acc + (item?.item_tax_amount || 0),
      0,
    );
    const adjustment = parseFloat(invoiceData?.adjustment) || 0;
    const shippingCharge = parseFloat(invoiceData?.shipping_charges) || 0;
    const totalBillAmount = totalSubAmount + shippingCharge + adjustment;
    const round_off = totalBillAmount % 1; // Extract decimal part
    const total = totalBillAmount - round_off; // Remove decimal part

    // tax info

    let tax_info = null;

    if (typeOfTax === 'in-state') {
      const cgst = totalTaxAmount / 2;
      const sgst = totalTaxAmount / 2;
      tax_info = { cgst: cgst.toFixed(2), sgst: sgst.toFixed(2) };
    } else if (typeOfTax === 'out-state') {
      const igst = totalTaxAmount;
      tax_info = { igst: igst.toFixed(2) };
    }

    return {
      sub_total: totalSubAmount.toFixed(2),
      tax_amount: totalTaxAmount.toFixed(2),
      total: total.toFixed(2), // Keep two decimal places
      round_off: round_off.toFixed(2), // Keep two decimal places
      tax_info: tax_info,
    };
  }, [
    invoiceItemData,
    invoiceData?.shipping_charges,
    invoiceData?.adjustment,
    invoiceData?.client_id,
  ]);

  useEffect(() => {
    setInvoiceData((prev) => ({
      ...prev,
      ...totalAmounts,
    }));
  }, [totalAmounts, setInvoiceData]);

  // Handle item details change (Quantity, Rate, Discount)
  // const handleItemChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const updatedItems = [...invoiceItemData];

  //   updatedItems[index] = {
  //     ...updatedItems[index],
  //     [name]: value,
  //   };

  //   // Auto-calculate amount if quantity & rate are filled
  //   if (name === 'quantity' || name === 'rate' || name === 'discount' || name === 'default_tax') {
  //     const quantity = updatedItems[index].quantity || 1;
  //     const rate = updatedItems[index].rate || 0;
  //     const discountAmount = updatedItems[index].discount
  //       ? ((quantity * rate) / 100) * updatedItems[index].discount
  //       : 0;
  //     const taxAmount = updatedItems[index].default_tax?.rate
  //       ? ((quantity * rate) / 100) * updatedItems[index].default_tax?.rate
  //       : 0;
  //     // updatedItems[index].amount = quantity * rate + taxAmount - discountAmount;

  //     updatedItems[index].amount = parseFloat(
  //       (quantity * rate + taxAmount - discountAmount).toFixed(2),
  //     );
  //   }

  //   // Auto-calculate tax - amount if rate are filled
  //   if (name === 'quantity' || name === 'rate' || name === 'default_tax') {
  //     const quantity = updatedItems[index].quantity || 1;
  //     const rate = updatedItems[index].rate || 0;
  //     const totalAmount = quantity * rate;
  //     let taxAmount = 0;

  //     if (invoiceData?.tax === TAX_CONST.EXCLUSIVE) {
  //       const taxRate = updatedItems[index].default_tax?.rate || 0;
  //       taxAmount = (totalAmount * taxRate) / 100;
  //       updatedItems[index].item_tax_amount = parseFloat(taxAmount.toFixed(2));
  //     } else if (invoiceData?.tax === TAX_CONST.INCLUSIVE) {
  //       const taxRate = updatedItems[index].default_tax?.rate || 0;
  //       taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
  //       updatedItems[index].item_tax_amount = parseFloat(taxAmount.toFixed(2));
  //     }

  //     setInvoiceItemData(updatedItems);
  //   }

  //   setInvoiceItemData(updatedItems);
  // };

  const calculateTaxAndAmount = (item, taxType, isTaxRequired = true) => {
    if (!item?.default_tax?.id && isTaxRequired) return item;

    const quantity = parseFloat(item.quantity) || 1;
    const rate = parseFloat(item.rate) || 0;
    const taxRate = parseFloat(item.default_tax?.rate) || 0;
    const totalAmount = quantity * rate;

    let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
    let taxAmount = 0;
    let finalAmount = totalAmount - discountAmount;

    if (taxType === TAX_CONST.EXCLUSIVE) {
      taxAmount = (totalAmount * taxRate) / 100;
      finalAmount += taxAmount;
    } else if (taxType === TAX_CONST.INCLUSIVE) {
      taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
    }

    return {
      ...item,
      amount: parseFloat(finalAmount.toFixed(2)),
      item_tax_amount: parseFloat(taxAmount.toFixed(2)),
    };
  };

  const handleItemChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = [...invoiceItemData];

    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };


    // Auto-calculate amount if quantity & rate are filled
    // if (name === 'quantity' || name === 'rate' || name === 'discount' || name === 'default_tax') {
    //   const quantity = parseFloat(updatedItems[index].quantity) || 1;
    //   const rate = parseFloat(updatedItems[index].rate) || 0;
    //   const taxRate = parseFloat(updatedItems[index].default_tax?.rate) || 0;
    //   const totalAmount = quantity * rate;

    //   let discountAmount = updatedItems[index].discount
    //     ? (totalAmount * updatedItems[index].discount) / 100
    //     : 0;
    //   let taxAmount = 0;
    //   let finalAmount = totalAmount - discountAmount;

    //   if (invoiceData?.tax === TAX_CONST.EXCLUSIVE) {
    //     taxAmount = (totalAmount * taxRate) / 100;
    //     finalAmount += taxAmount;
    //   } else if (invoiceData?.tax === TAX_CONST.INCLUSIVE) {
    //     taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
    //     // finalAmount -= taxAmount;
    //   }

    //   updatedItems[index].amount = parseFloat(finalAmount.toFixed(2));
    //   updatedItems[index].item_tax_amount = parseFloat(taxAmount.toFixed(2));
    // }
    if (['quantity', 'rate', 'discount', 'default_tax'].includes(name)) {
      updatedItems[index] = calculateTaxAndAmount(updatedItems[index], invoiceData?.tax, false);
    }

    setInvoiceItemData(updatedItems);
  };

  /**
   * calculateIndiaTaxes
   * - Example function to handle Indian GST logic:
   *   - If company.state === customer.state => CGST & SGST
   *   - Else => IGST
   */
  function calculateIndiaTaxes(company, client) {
    const clientStateCode = client.tax_number.substring(0, 2);
    const companyStateCode = company.companyTaxNumber.substring(0, 2);

    if (clientStateCode && companyStateCode && clientStateCode !== companyStateCode) {
      setTypeOfTax('out-state');
    } else {
      setTypeOfTax('in-state');
    }
  }

  // to manage amount input
  const isValidAdjustment = (value) => {
    return /^-?\d*\.?\d*$/.test(value); // Allows numbers, optional negative sign, and decimals
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'adjustment') {
      // Allow valid numbers including decimals and negative numbers
      if (value === '-' || value === '.' || value === '-.' || isValidAdjustment(value)) {
        setInvoiceData({
          ...invoiceData,
          [name]: value,
        });
      }
      return;
    }
    if (name === 'shipping_charges') {
      // Allow valid numbers including decimals and negative numbers
      if (value === '.' || isValidAdjustment(value)) {
        setInvoiceData({
          ...invoiceData,
          [name]: value,
        });
      }
      return;
    }
    // if (name === 'tax' && value === TAX_CONST.EXCLUSIVE) {
    //   setInvoiceItemData((prevItems) =>
    //     prevItems.map((item) => {
    //       if (item?.default_tax?.id) {
    //         const quantity = parseFloat(item.quantity) || 1;
    //         const rate = parseFloat(item.rate) || 0;
    //         const taxRate = parseFloat(item.default_tax?.rate) || 0;
    //         const totalAmount = quantity * rate;

    //         let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
    //         let taxAmount = 0;
    //         let finalAmount = totalAmount - discountAmount;

    //         taxAmount = (totalAmount * taxRate) / 100;
    //         finalAmount += taxAmount;

    //         const amount = parseFloat(finalAmount.toFixed(2));
    //         const item_tax_amount = parseFloat(taxAmount.toFixed(2));
    //         return {
    //           ...item,
    //           amount: amount,
    //           item_tax_amount: item_tax_amount,
    //         };
    //       } else {
    //         return item;
    //       }
    //     }),
    //   );
    // }

    // if (name === 'tax' && value === TAX_CONST.INCLUSIVE) {
    //   setInvoiceItemData((prevItems) =>
    //     prevItems.map((item) => {
    //       if (item?.default_tax?.id) {
    //         const quantity = parseFloat(item.quantity) || 1;
    //         const rate = parseFloat(item.rate) || 0;
    //         const taxRate = parseFloat(item.default_tax?.rate) || 0;
    //         const totalAmount = quantity * rate;

    //         let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
    //         let taxAmount = 0;
    //         let finalAmount = totalAmount - discountAmount;
    //         taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);

    //         const amount = parseFloat(finalAmount.toFixed(2));
    //         const item_tax_amount = parseFloat(taxAmount.toFixed(2));
    //         return {
    //           ...item,
    //           amount: amount,
    //           item_tax_amount: item_tax_amount,
    //         };
    //       } else {
    //         return item;
    //       }
    //     }),
    //   );
    // }
    if (name === 'tax' && (value === TAX_CONST.EXCLUSIVE || value === TAX_CONST.INCLUSIVE)) {
      setInvoiceItemData((prevItems) =>
        prevItems.map((item) => calculateTaxAndAmount(item, value)),
      );
    }

    if (name === 'tax_type' && value === TAX_TYPE_CONST.NOT_SELECTED) {
      setInvoiceItemData((prevItems) =>
        prevItems.map((item) => {
          if (item?.default_tax?.id) {
            console.log('here');
            if (invoiceData?.tax === TAX_CONST.EXCLUSIVE) {
              const quantity = parseFloat(item.quantity) || 1;
              const rate = parseFloat(item.rate) || 0;
              const taxRate = parseFloat(item.default_tax?.rate) || 0;
              const totalAmount = quantity * rate;

              let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
              let finalAmount = totalAmount - discountAmount;

              return {
                ...item,
                default_tax: null,
                amount: finalAmount,
                item_tax_amount: 0,
              };
            } else {
              return {
                ...item,
                default_tax: null,
                item_tax_amount: 0,
              };
            }
          } else {
            return item;
          }
        }),
      );
    }
    if (name === 'client_id') {
      //labz-todo - not sure condition
      if (
        value?.country === 'IN' &&
        !invoiceSettings?.labels?.companyTaxNumber &&
        value?.tax_treatment?.is_tax_number_required
      ) {
        makeAlert(
          `Please submit company GST number before creating invoice for ${value?.name.toUpperCase()} ${LABEL_OF_CLIENT}`,
        );
        return;
      } else if (
        value?.country === 'IN' &&
        invoiceSettings?.labels?.companyTaxNumber &&
        value?.tax_treatment?.is_tax_number_required
      ) {
        calculateIndiaTaxes({ companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber }, value);
      } else {
        setTypeOfTax(null);
        setInvoiceData({
          ...invoiceData,
          [name]: value,
          tax_info: null,
        });
        return;
      }
    }
    setInvoiceData({
      ...invoiceData,
      [name]: value,
    });
  };

  const handleAdjustmentBlur = (event) => {
    const { name, value } = event.target;

    if (
      !isValidAdjustment(value) ||
      value === '-' ||
      value === '.' ||
      value === '-.' ||
      value === ''
    ) {
      setInvoiceData({
        ...invoiceData,
        [name]: 0,
      });
    }
  };

  // to delete selected item
  const handleItemDelete = (deleteIndex) => {
    setInvoiceItemData(invoiceItemData?.filter((_, index) => index !== deleteIndex));
  };

  // to add new item
  const handleNewItem = () => {
    setInvoiceItemData([...invoiceItemData, InvoiceItemState]);
  };

  // save api call
  const handleSave = async () => {
    const updatedInvoiceItemData = invoiceItemData?.map((item) => ({
      ...item,
      default_tax: item?.default_tax?.id,
    }));

    await invoiceService
      .invoiceAdd({
        ...invoiceData,
        client_id: invoiceData?.client_id?.id,
        bill_date: invoiceData?.bill_date
          ? new Date(invoiceData?.bill_date).getTime()
          : moment().valueOf(),
        due_date: invoiceData?.due_date
          ? new Date(invoiceData?.due_date).getTime()
          : moment().valueOf(),
        invoiceItems: [...updatedInvoiceItemData],
        files: selectedFiles,
      })
      .then(() => {
        setSubmitted(false);
        globalSettingsRefetch();
        afterAdd();
      });
    handleClose();
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['invoice-add'],
    onSuccess: (data) => {
      setSubmitted(false);
      globalSettingsRefetch();
      afterAdd();
      handleClose();
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (
      invoiceData?.invoice_number?.toString().trim() === '' ||
      !invoiceData?.client_id?.id ||
      !new Date(invoiceData?.bill_date).getTime() ||
      !new Date(invoiceData?.due_date).getTime() ||
      new Date(invoiceData?.bill_date).getTime() > new Date(invoiceData?.due_date).getTime()
    ) {
      setIsLoading(false);
      return null;
    }

    if (invoiceItemData?.length === 0) {
      setIsLoading(false);
      makeAlert('Please enter at least one item with a valid amount');
      return null;
    }

    if (
      invoiceItemData?.length > 0 &&
      (invoiceItemData.some((item) => !item?.amount) ||
        invoiceItemData.some((item) => !item?.item_name))
    ) {
      setIsLoading(false);
      makeAlert('Please enter item with valid item details');
      return null;
    }
    // const updatedInvoiceItemData = invoiceItemData?.map((item) => ({
    //   ...item,
    //   default_tax: item?.default_tax?.id,
    // }));

    try {
      // invoice number validation
      const existingInvoice = await invoiceService.invoiceList({
        invoice_number: invoiceData?.invoice_number,
      });

      if (existingInvoice?.data?.total) {
        setIsConfirmationVisible(true);
      } else {
        handleMutate();
      }
    } catch (error) {
      // setError({
      //   common: error?.message || alertConstants.SERVER_ERROR,
      // });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  // file upload section ,
  const onFileSelect = (files) => {
    let filesArray = [];

    for (let file of files) {
      filesArray.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        isUploading: false,
        isFailed: false,
        isSuccess: false,
      });
    }
    selectedFiles?.length > 0
      ? setSelectedFiles([...selectedFiles, ...filesArray])
      : setSelectedFiles([...filesArray]);
  };
  // remove selected file
  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };

  return (
    <>
      <div className='modal_view_content'>
        <div>
          {/* Customer Info Section */}
          <div className='invoice-form-section'>
            <div className='grid-3-col'>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>{LABEL_OF_CLIENT} Name</label>
                <SearchableInfiniteQuerySelect
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryFn={(params) =>
                    clientService.clientList({
                      ...params,
                      select: ['name', 'id', 'tax_number', 'country', 'tax_treatment'],
                    })
                  }
                  queryKey={['client-list-task']}
                  enableCondition={true}
                  isCustomFunction
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name', 'tax_number'],
                    };
                  }}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption, name: 'client_id' },
                    });
                  }}
                  placeholder={`Select ${LABEL_OF_CLIENT}`}
                  styles={CommonDropDownStyle}
                  menuPortalTarget={document.getElementById('MODAL')}
                  value={invoiceData?.client_id}
                />
                <ErrorBlock
                  errorMsg={`Please select ${LABEL_OF_CLIENT}`}
                  hasError={submitted && !invoiceData?.client_id?.id}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'}
                </label>
                <input
                  type='text'
                  className='input-box'
                  placeholder='Invoice number'
                  name='invoice_number'
                  value={invoiceData.invoice_number}
                  onChange={handleChange}
                />
                <ErrorBlock
                  errorMsg={`Invoice number required`}
                  hasError={submitted && invoiceData?.invoice_number?.toString().trim() === ''}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Reference' : 'Order'} Number
                </label>
                <input
                  type='text'
                  className='input-box'
                  placeholder={`Enter ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'reference' : 'order'
                  } number`}
                  name='order_number'
                  value={invoiceData.order_number}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Terms & Dates Section */}
          <div className='invoice-form-section'>
            <div className='grid-3-col'>
              {transactionType === TRANSACTION_TYPE.INVOICE && (
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Terms</label>
                  <input
                    type='text'
                    className='input-box'
                    name='terms'
                    value={invoiceData.terms}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'} Date
                </label>
                <input
                  type='date'
                  className='input-box'
                  name='bill_date'
                  value={invoiceData.bill_date}
                  onChange={handleChange}
                />
                <ErrorBlock
                  errorMsg={` ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'
                  } date required`}
                  hasError={submitted && !new Date(invoiceData?.bill_date).getTime()}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Due Date</label>
                <input
                  type='date'
                  className='input-box'
                  name='due_date'
                  value={invoiceData.due_date}
                  onChange={handleChange}
                  min={invoiceData.bill_date}
                />
                <ErrorBlock
                  errorMsg={`Due date must be greater than ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'
                  } date`}
                  hasError={
                    submitted &&
                    new Date(invoiceData?.bill_date).getTime() >
                      new Date(invoiceData?.due_date).getTime()
                  }
                />
                <ErrorBlock
                  errorMsg={` 
                  Due date required`}
                  hasError={submitted && !new Date(invoiceData?.due_date).getTime()}
                />
              </div>
            </div>
          </div>

          <InvoiceAddItemTable
            invoiceData={invoiceData}
            invoiceItemData={invoiceItemData}
            handleItemChange={handleItemChange}
            handleItemDelete={handleItemDelete}
            handleNewItem={handleNewItem}
            setItemAddModal={setItemAddModal}
            inputRefs={inputRefs}
            handleChange={handleChange}
            setInvoiceItemData={setInvoiceItemData}
            calculateTaxAndAmount={calculateTaxAndAmount}
          />

          {/* Notes & Total Section */}
          <div className='invoice-form-section'>
            <div className='grid-2-col'>
              <div className='total-section '>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Notes</label>
                  <textarea
                    className='notes-textarea box-focus '
                    placeholder='Add notes here'
                    name='customer_notes'
                    value={invoiceData.customer_notes}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Terms & Conditions</label>
                  <textarea
                    className='notes-textarea box-focus'
                    placeholder='Add t&c here'
                    name='terms_and_conditions'
                    value={invoiceData.terms_and_conditions}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className='total-section'>
                <div className='total-row'>
                  <span>Sub total</span>
                  <span>
                    {currencyTextIcon}
                    {invoiceData?.sub_total}
                  </span>
                </div>
                {typeOfTax === 'out-state' && (
                  <div className='total-row'>
                    <span>IGST</span>
                    <span>
                      {currencyTextIcon}
                      {invoiceData?.tax_info?.igst ?? 0}
                    </span>
                  </div>
                )}
                {typeOfTax === 'in-state' && (
                  <div className='total-row'>
                    <span>CGST</span>
                    <span>
                      {currencyTextIcon}
                      {invoiceData?.tax_info?.cgst ?? 0}
                    </span>
                  </div>
                )}
                {typeOfTax === 'in-state' && (
                  <div className='total-row'>
                    <span>SGST</span>
                    <span>
                      {currencyTextIcon}
                      {invoiceData?.tax_info?.sgst ?? 0}
                    </span>
                  </div>
                )}
                {/* {invoiceData?.tax_type != TAX_TYPE_CONST.NOT_SELECTED && ( */}
                <div className='total-row'>
                  <span>Total tax amount</span>
                  <span>
                    {currencyTextIcon}
                    {invoiceData?.tax_amount}
                  </span>
                </div>
                {/* )} */}
                {hasExist(INVOICE_IDS.SHIPPING_CHARGE) && (
                  <div className='total-row'>
                    <span>Shipping Charges</span>
                    <span style={{ width: '35%' }}>
                      <input
                        type='text'
                        className='input-box'
                        name='shipping_charges'
                        value={invoiceData.shipping_charges}
                        onChange={handleChange}
                        onBlur={handleAdjustmentBlur}
                      />
                    </span>
                  </div>
                )}
                {hasExist(INVOICE_IDS.ADJUSTMENT) && (
                  <div className='total-row'>
                    <span>Adjustment</span>
                    <span style={{ width: '35%' }}>
                      <input
                        type='text'
                        className='input-box'
                        name='adjustment'
                        value={invoiceData?.adjustment}
                        onChange={handleChange}
                        onBlur={handleAdjustmentBlur}
                      />
                    </span>
                  </div>
                )}
                <div className='total-row mb-4'>
                  <span>Round off</span>

                  <span>
                    {currencyTextIcon}
                    {invoiceData?.round_off}
                  </span>
                </div>
                <div className='total-row grand-total'>
                  <span>Total</span>
                  <span>
                    {currencyTextIcon}
                    {invoiceData?.total}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* File Upload */}
          <div className='invoice-form-section'>
            {/* <div className="file-upload-box">
          <p>Drag and drop or click to upload</p>
        </div> */}
            <div>
              <input
                accept={'*/*'}
                multiple
                onChange={(e) => onFileSelect(e.target.files)}
                id='upload'
                type='file'
                style={{ display: 'none' }}
              />
              <div
                className='file-upload-contain'
                style={{
                  padding: '1rem 5px',
                  color: 'var(--second-text-color)',
                  backgroundColor: 'var(--bg-light)',
                }}
              >
                <label className='upload-text text-decoration-none' htmlFor='upload'>
                  <div className='icon-outer-file'>
                    <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
                  </div>
                  {/* Drag and Drop file here or */}
                  Choose file
                </label>
              </div>

              <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>

      {itemAddModal && (
        <Modal.Container
          handleClose={() => {
            setItemAddModal(false);
          }}
        >
          <Modal.View className={'w-25'}>
            <Modal.Head
              Heading={'New Item'}
              handleClose={() => {
                setItemAddModal(false);
              }}
            />
            <AddItemModal
              handleClose={() => {
                setItemAddModal(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {isConfirmationVisible && (
        <Modal.Container>
          <Modal.View className={'status-delete w-50'}>
            <Modal.Head
              handleClose={() => setIsConfirmationVisible(false)}
              Heading={'Invoice Confirmation'}
            />
            <Modal.Description>
              <CircleCheck size={90} color='#fff' fill='#17c666' />
              <div className='del-description'>{MODAL_NOTES.INVOICE_CONFIRMATION}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setIsConfirmationVisible(false)}
                className={'outline-cancel-button'}
              >
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={handleMutate} className={'blue-btn'}>
                <Tick />
                <Button.Title title={'Save'} />
                <Button.Loading isLoading={isLoading} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};
