/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  CircleCheckBig,
  DollarSign,
  Euro,
  IndianRupee,
  PoundSterling,
  SwissFranc,
} from 'lucide-react';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, CustomFileUpload } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { apiConstants, CommonDropDownStyle } from '../_constants';
import { clientService, settingsService } from '../_services';
import { organizationService } from '../_services/organization.service';
import { taxService } from '../_services/tax.service';
import { useUser } from '../Hook/useUser';
import { TAX_CONST, TAX_LIST, TAX_TYPE_LIST } from '../Invoice/InvoiceConstant';
import { GlobalContext, Images } from '../Utils';
import { Tick } from '../Utils/SvgIcons';
import { INVOICE_IDS, INVOICE_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

export const currencyType = [
  { id: 1, name: 'USD', textIcon: '$', icon: <DollarSign size={16} /> }, // US Dollar
  { id: 2, name: 'EUR', textIcon: '€', icon: <Euro size={16} /> }, // Euro
  { id: 3, name: 'GBP', textIcon: '£', icon: <PoundSterling size={16} /> }, // British Pound
  { id: 4, name: 'INR', textIcon: '₹ ', icon: <IndianRupee size={16} /> }, // Indian Rupee
  { id: 8, name: 'CHF', textIcon: '₣', icon: <SwissFranc size={16} /> }, // Swiss Franc
];

export function InvoiceSettings() {
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });
  const [invoiceSettings, setInvoiceSettings] = useState(INVOICE_LABELS);
  const company_id = useUser()?.company_id;
  const [query] = useState({ select: ['name', 'labels'], name: 'invoice-settings' });
  const [gstLoading, setGstLoading] = useState(false);
  const [gstVerification, setGstVerification] = useState({
    isVerified: false,
    data: '',
    isUpdated: false,
  });
  const getInvoiceData = async () => {
    const data = await settingsService.settingsGet(query);
    setInvoiceSettings(data?.data[0].labels);
    return data?.data[0];
  };

  const { refetch, isLoading } = useQuery({
    queryKey: ['invoice-settings'],
    queryFn: () => getInvoiceData(),
  });

  const paperType = [
    { id: 1, name: 'A4' },
    { id: 2, name: 'A3' },
  ];

  const fontColors = ['#000000', '#2366E5', '#089369', '#DF2526'];
  const bkColors = ['#fffff', '#f1f1f1', '#EFF6FE', '#EEF1FF'];

  // fetch document type details
  const { data: companyDetails } = useQuery({
    queryKey: ['cmp-data'],
    queryFn: () =>
      organizationService.getOrganizationDetails({ id: company_id, select: ['country', 'id'] }),
    select: (data) => data?.data,
    enabled: Boolean(company_id),
  });

  // Mutate function to update settings
  const { mutate: updateInvoiceSettings, isPending } = useMutation({
    mutationFn: (updatedSettings) => settingsService.settingsAdd(updatedSettings),
    onSuccess: () => {
      makeAlert('Settings updated successfully!');
      refetch(); // Refetch data after update
      globalSettingsRefetch();
    },
    onError: (error) => {
      makeAlert('Failed to update settings. Try again.');
      console.error(error);
    },
  });

  const handleInputChange = (e) => {
    setInvoiceSettings({ ...invoiceSettings, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (field, value) => {
    if (field === 'backgroundColor' || field === 'fontColor' || field === 'taxGroup') {
      setInvoiceSettings({ ...invoiceSettings, [field]: value });
    } else {
      if (field === 'defaultTax' && value?.id === TAX_CONST.EXCLUSIVE) {
        setInvoiceSettings({
          ...invoiceSettings,
          [field]: value.id,
          // taxType: null,
          // taxGroup: null,
        });
      } else {
        setInvoiceSettings({ ...invoiceSettings, [field]: value.id });
      }
    }
  };

  const handleGstVerification = async () => {
    setGstLoading(true);
    if (
      companyDetails?.country === 'IN' &&
      invoiceSettings?.companyTaxNumber?.toString().trim() !== ''
    ) {
      try {
        const res = await clientService.verifyGst({ gstin: invoiceSettings?.companyTaxNumber });
        if (res?.data?.status_cd === '0') {
          setGstVerification({ isVerified: false, data: { message: res?.data?.error?.message } });
          setGstLoading(false);
        } else {
          setGstVerification({ isVerified: true, data: res?.data });
          setGstLoading(false);
        }
      } catch (error) {
        console.log('error on');
        setGstVerification({ isVerified: false, data: { message: 'Verification Failed' } });
        setGstLoading(false);
      }
    } else {
      console.log('error on');

      return;
    }
  };

  const handleTemplateChange = (template) => {
    setInvoiceSettings({ ...invoiceSettings, selectedTemplate: template });
  };

  const handleChange = (e) => {
    console.log(e);
  };

  const handleSubmit = () => {
    if (companyDetails?.country !== 'IN' || gstVerification?.isVerified) {
      updateInvoiceSettings({ labels: invoiceSettings, name: 'invoice-settings' });
    } else {
      if (
        // client?.tax_treatment?.is_tax_number_required &&
        gstVerification?.isUpdated &&
        !gstVerification?.isVerified &&
        companyDetails?.country === 'IN'
      ) {
        makeAlert('Please verify GST before submission');
        return null;
      }
      updateInvoiceSettings({ labels: invoiceSettings, name: 'invoice-settings' });
    }
  };

  const handleUpload = (data) => {
    setInvoiceSettings({ ...invoiceSettings, organizationIcon: data[0] });
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  const handleToggle = (id, value) => {
    setInvoiceSettings((prevLabels) => ({
      ...prevLabels,
      requiredFields: prevLabels.requiredFields?.map((fields) =>
        fields.id === id ? { ...fields, value: !value } : fields,
      ),
    }));
  };

  const organizationIcon = invoiceSettings?.organizationIcon
    ? apiConstants.imgUrlS3 + invoiceSettings?.organizationIcon
    : Images.hidesk;

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <div style={{ display: 'flex', position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            // flexGrow: 1,
            flexDirection: 'column',
            padding: '0px 3px 2px 15px',
            height: '80vh',
            width: '55%',
          }}
        >
          <div
            onScroll={handleScroll}
            style={{
              display: 'flex',
              flexGrow: 1,
              overflow: 'auto',
              flexDirection: 'column',
            }}
          >
            <InvoiceHead name={'Choose Template'} />

            <div style={{ display: 'flex', gap: 50 }}>
              {['basic', 'modern'].map((template) => (
                <div key={template} style={{ position: 'relative' }}>
                  <img style={{ height: 120, width: 100 }} src={Images.template} />
                  <div style={{ textAlign: 'center', marginTop: 7 }}>
                    {template.charAt(0).toUpperCase() + template.slice(1)}
                  </div>
                  <input
                    style={{ position: 'absolute', top: 5, right: 5 }}
                    className='menu_check_box'
                    type='checkbox'
                    checked={invoiceSettings.selectedTemplate === template}
                    onChange={() => handleTemplateChange(template)}
                  />
                </div>
              ))}
            </div>

            {/* hide and show section  */}
            {/* hide and show section */}
            <InvoiceHead name={'Required Fields'} />

            {invoiceSettings?.requiredFields?.map((invoice) => {
              if (invoice.type === 'toggle') {
                return (
                  <ToggleWithHeading
                    className='invoice-sub'
                    key={invoice?.id}
                    id={invoice?.id}
                    handleToggle={() => handleToggle(invoice?.id, invoice.value)}
                    isChecked={invoice.value}
                    heading={invoice.label}
                    // description={invoice?.description}
                  />
                );
              }

              // If you want to render something else for other types, you can add more conditions here
              return null; // Return null for invoices that don't match the condition
            })}

            {/* end of hide and show   */}

            <InvoiceHead name={'Design options'} />

            {/* organization */}
            <section style={{ display: 'flex', gap: 50 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Organization Logo'} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img
                    src={organizationIcon}
                    style={{ height: 40, width: 40, objectFit: 'contain' }}
                  />

                  <CustomFileUpload
                    name={'organizationLogo'}
                    handleOK={handleUpload}
                    CustomView={
                      <label htmlFor='fileInput' style={{ cursor: 'pointer', fontSize: 13 }}>
                        <input
                          id='fileInput'
                          type='file'
                          style={{ display: 'none' }}
                          onChange={(e) => console.log(e.target.files)} // Handle file selection here
                        />
                        <span style={{ color: 'blue', marginLeft: 25 }}>Change</span>
                      </label>
                    }
                  />
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Organization name'} />
                <input
                  name='organizationName'
                  value={invoiceSettings.organizationName}
                  onChange={handleInputChange}
                  // style={{ height: 30, width: 150 }}
                  className='input-box'
                />
              </div>
            </section>
            {/* organization */}
            {/* section */}
            <section style={{ display: 'flex', gap: 50, marginTop: 15 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Invoice prefix'} />
                <input
                  name='invoicePrefix'
                  value={invoiceSettings.invoicePrefix}
                  onChange={handleInputChange}
                  className='input-box'
                />
              </div>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Estimate prefix'} />
                <input
                  name='estimatePrefix'
                  value={invoiceSettings.estimatePrefix}
                  onChange={handleInputChange}
                  className='input-box'
                />
              </div>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Number of digit'} />
                <input
                  name='numberOfDigits'
                  value={invoiceSettings.numberOfDigits}
                  onChange={handleInputChange}
                  className='input-box'
                />
              </div>
            </section>
            {/* section */}
            {/* section */}
            <section style={{ display: 'flex', gap: 50, marginTop: 15 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Current Invoice number'} />
                <input
                  name='invoiceNumber'
                  value={invoiceSettings.invoiceNumber}
                  onChange={handleInputChange}
                  className='input-box'
                />
              </div>
              <div style={{ flex: 1 }}>
                {/* todo-labz- verification */}
                <InvoiceSubHead name={'Company Tax number'} />
                <input
                  name='companyTaxNumber'
                  value={invoiceSettings.companyTaxNumber}
                  onChange={(event) => {
                    if (!gstVerification?.isUpdated) {
                      setGstVerification({
                        isVerified: false,
                        data: '',
                        isUpdated: true,
                      });
                    }
                    handleInputChange(event);
                  }}
                  className='input-box'
                />
                {companyDetails?.country === 'IN' && !gstVerification?.isVerified && (
                  <span
                    style={{ color: 'var(--Base-Color)', fontWeight: 600, fontSize: 11 }}
                    className='d-flex cursor-pointer'
                    onClick={() => {
                      if (!gstLoading) {
                        handleGstVerification();
                      }
                    }}
                  >
                    <Tick color='var(--Base-Color)' />
                    {gstLoading ? 'Verifying...' : 'Verify'}
                  </span>
                )}
                {companyDetails?.country === 'IN' && gstVerification?.isVerified && (
                  <span
                    style={{ color: 'green', fontWeight: 600, fontSize: 11 }}
                    className='d-flex cursor-pointer align-items-center '
                  >
                    Verified
                    <CircleCheckBig color='green' size={12} />
                  </span>
                )}
              </div>
            </section>
            {/* section */}

            <section style={{ display: 'flex', gap: 50, marginTop: 15 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Default tax'} />

                <ReactSelect
                  id='trigger-type-select'
                  styles={CommonDropDownStyle}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  options={TAX_LIST}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  placeholder='Select a condition'
                  value={
                    TAX_LIST.find((option) => option.id === invoiceSettings.defaultTax) || null
                  }
                  onChange={(selected) => handleSelectChange('defaultTax', selected)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <>
                  <InvoiceSubHead name={'Tax Type'} />

                  <ReactSelect
                    id='trigger-type-select'
                    styles={CommonDropDownStyle}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    options={TAX_TYPE_LIST}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    placeholder='Select a condition'
                    value={
                      TAX_TYPE_LIST.find((option) => option.id === invoiceSettings.taxType) || null
                    }
                    onChange={(selected) => handleSelectChange('taxType', selected)}
                  />
                </>
              </div>
              <div style={{ flex: 1 }}>
                <>
                  <InvoiceSubHead name={'Tax Group'} />

                  <SearchableInfiniteQuerySelect
                    name='default_tax'
                    getOptionLabel={(option) => {
                      return `${option?.name} - ${option?.rate}%`;
                    }}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    queryFn={taxService.taxList}
                    queryKey={['tax-list-settings']}
                    enableCondition={true}
                    isCustomFunction
                    getFunctionParams={(searchKey) => {
                      return {
                        searchKey: searchKey,
                        select: ['id', 'name', 'rate'],
                      };
                    }}
                    // onChange={(selectedOption) => {
                    //   handleItemChange(
                    //     {
                    //       target: { value: selectedOption, name: 'default_tax' },
                    //     },
                    //     index,
                    //   );
                    // }}
                    onChange={(selected) => handleSelectChange('taxGroup', selected)}
                    placeholder={`Select `}
                    styles={CommonDropDownStyle}
                    // menuPortalTarget={document.getElementById('MODAL')}
                    value={invoiceSettings?.taxGroup}
                  />
                </>
              </div>
            </section>

            <section style={{ display: 'flex', gap: 50, marginTop: 15 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Currency'} />
                <ReactSelect
                  id='trigger-type-select'
                  styles={CommonDropDownStyle}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  options={currencyType}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  placeholder='Select a condition'
                  value={
                    currencyType.find((option) => option.id === invoiceSettings.currency) || null
                  }
                  onChange={(selected) => handleSelectChange('currency', selected)}
                />
              </div>
              <div style={{ flex: 1 }}></div>
            </section>

            <section style={{ marginTop: 15, marginBottom: 5 }}>
              <InvoiceHead name={'Customization Style'} />
              <InvoiceSubHead name={'Font Color'} />
              <div style={{ display: 'flex', gap: 10, marginBottom: 18 }}>
                {fontColors.map((color) => (
                  <div
                    key={color}
                    style={{
                      height: 40,
                      width: 40,
                      background: color,
                      borderRadius: 200,
                      cursor: 'pointer',
                      border: invoiceSettings.fontColor === color ? '2px solid black' : 'none',
                    }}
                    onClick={() => handleSelectChange('fontColor', color)}
                  ></div>
                ))}
              </div>

              <InvoiceSubHead name={'Background'} />
              <div style={{ display: 'flex', gap: 10 }}>
                {bkColors.map((color) => (
                  <div
                    key={color}
                    style={{
                      height: 35,
                      width: 70,
                      background: color,
                      borderRadius: 5,
                      cursor: 'pointer',
                      border:
                        invoiceSettings.backgroundColor === color ? '2px solid black' : 'none',
                    }}
                    onClick={() => handleSelectChange('backgroundColor', color)}
                  ></div>
                ))}
              </div>
            </section>
            {/* section */}

            <InvoiceHead name={'Format & Layout'} />

            <section style={{ display: 'flex', gap: 50 }}>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Paper Type'} />

                <ReactSelect
                  id='trigger-type-select'
                  styles={CommonDropDownStyle}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  options={paperType}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  placeholder='Select a paper type'
                  onChange={(selected) => handleSelectChange('paperType', selected)}
                  value={
                    paperType.find((option) => option.id === invoiceSettings.paperType) || null
                  }
                />
              </div>
              <div style={{ flex: 1 }}>
                <InvoiceSubHead name={'Margin'} />
                <input
                  name='invoiceNumber'
                  value={invoiceSettings.invoiceNumber}
                  onChange={handleInputChange}
                  className='input-box'
                />
              </div>
            </section>

            {/* section */}

            <InvoiceHead name={'Terms & Conditions'} />

            {/* {(!id || milestone.id) && (
            <Editor
              // key={milestone?.goal}
              toolbar={EDITOR_TOOLBAR_OPTIONS}
              stripPastedStyles={true}
              onChange={(contentState) =>
                handleChange({
                  target: {
                    name: 'term',
                    value: contentState,
                  },
                })
              }
              // defaultContentState={milestone?.goal}
              wrapperClassName='milestone-wrapper-style wrapper-width'
              editorClassName='milestone-editor-style'
              toolbarClassName='editor-toolbar'
              placeholder='Type Your Goal'
            /> */}
            {/* )} */}
            <div className='mb-3'>
              <textarea
                className='notes-textarea box-focus'
                placeholder='Add terms and conditions here'
                name='termsCondition'
                value={invoiceSettings?.termsCondition}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>

          <div
            className='inv-btn-container'
            style={{
              boxShadow: scrollFlag.isTopReached
                ? 'none'
                : '0px -4px 9px -2px var(--shadow, #091e4229)',
              // marginBottom: 5,
            }}
          >
            <Button.Container className={'invoice-btn'} handleOk={() => handleSubmit()}>
              <Tick />
              <Button.Title title={'Save & Proceed'} />
              <Button.Loading isLoading={isPending} />
            </Button.Container>
          </div>
        </div>
        <div style={{ width: 1, background: '#f8f8f8', marginRight: 15 }}></div>
        <div style={{ display: 'flex', flexGrow: 18 }}>
          {invoiceSettings.selectedTemplate === 'basic' ? (
            <BasicInvoice invoiceSettings={invoiceSettings} />
          ) : (
            <ModernInvoice invoiceSettings={invoiceSettings} />
          )}
        </div>
      </div>
    </>
  );
}

export const InvoiceHead = ({ name, rest }) => {
  return (
    <>
      <div className='invoice-head' {...rest}>
        {name}
      </div>
    </>
  );
};

export const InvoiceSubHead = ({ name, rest }) => {
  return (
    <>
      <div className='invoice-sub' {...rest}>
        {name}
      </div>
    </>
  );
};

export const BasicInvoice = ({ invoiceSettings }) => {
  const getRequiredFieldValueById = (invoiceSetting, id) => {
    return invoiceSetting?.requiredFields.find((field) => field.id === id)?.value;
  };

  const shippingField = getRequiredFieldValueById(invoiceSettings, INVOICE_IDS.SHIPPING_CHARGE);
  const adjustmentField = getRequiredFieldValueById(invoiceSettings, INVOICE_IDS.ADJUSTMENT);

  const organizationIcon = invoiceSettings?.organizationIcon
    ? apiConstants.imgUrlS3 + invoiceSettings?.organizationIcon
    : Images.hidesk;

  return (
    <div style={{ backgroundColor: '#f9f9f9', width: '100%' }}>
      <div className='invoice-container'>
        {/* head */}
        <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <div style={{ fontWeight: '600', fontSize: 15 }}>
            INVOICE <span>#EBUK2000830</span>
          </div>
          <div>
            <img src={organizationIcon} style={{ objectFit: 'contain', height: 25, width: 30 }} />
          </div>
        </div>
        {/* head */}
        {/* line */}
        <div style={{ marginTop: 15, height: 1, width: '100%', background: '#d2d2d2' }}></div>
        {/* line */}

        {/* address part */}
        <div style={{ marginTop: '4%' }}>
          <div>
            {/* <div style={{ display: 'flex', gap: 4 }}>
              <img src={Images.hidesk} style={{ height: 20, width: 20 }} />
              <div style={{ fontWeight: '600', fontSize: 15 }}>Company Name</div>
            </div> */}

            {/* address */}
            <div style={{ display: 'flex', gap: 20, marginTop: 15 }}>
              <div>
                <div className='head-bold'>Bill from,</div>
                <div>Hilite Business Park,</div>
                <div>Calicut,485545 </div>
                <div>Email: hidesk@gmail.com</div>
                <div>Phone: +9745212187</div>

                <div className='head-bold  mt-3'>Issued On,</div>
                <div>11 March, 2025 </div>
              </div>
              <div>
                <div className='head-bold'>Bill from,</div>
                <div>Hilite Business Park,</div>
                <div>Calicut,485545 </div>
                <div>Email: hidesk@gmail.com</div>
                <div>Phone: +9745212187</div>

                <div className='head-bold  mt-3'>Due On,</div>
                <div>11 March, 2025 </div>
              </div>
            </div>
            {/* address */}

            {/* invoice details */}
            <div style={{ marginTop: 20, fontSize: 13, fontWeight: '600' }}>Invoice details</div>

            <div style={{ marginTop: 15 }}>
              <div className='inv-table-container'>
                <div className='inv-table-header'>
                  <div className='inv-table-column'>Tasks</div>
                  <div className='inv-table-column'>Hour</div>
                  <div className='inv-table-column'>Price</div>
                </div>
                <div className='inv-table-row'>
                  <div className='inv-table-column'>GST billing - March </div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div>
                {/* <div className='inv-table-row'>
                  <div className='inv-table-column'>task_name_dummy_01</div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div>
                <div className='inv-table-row'>
                  <div className='inv-table-column'>task_name_dummy_01</div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div> */}
                <div className='inv-table-footer' style={{ marginTop: 10 }}>
                  <div className='inv-footer-label'>Sub Total</div>
                  <div className='inv-footer-value'>$75</div>
                </div>
                <div className='inv-table-footer'>
                  <div className='inv-footer-label'>Tax (VAT)</div>
                  <div className='inv-footer-value'>$5</div>
                </div>

                {shippingField && (
                  <div className='inv-table-footer'>
                    <div className='inv-footer-label'>Shipping Charges</div>
                    <div className='inv-footer-value'>$5</div>
                  </div>
                )}

                {adjustmentField && (
                  <div className='inv-table-footer'>
                    <div className='inv-footer-label'>Adjustments</div>
                    <div className='inv-footer-value'>$5</div>
                  </div>
                )}
                <div className='inv-table-footer'>
                  <div className='inv-footer-label'>Round Off</div>
                  <div className='inv-footer-value'>$5</div>
                </div>

                <div style={{ height: 1, background: '#ddd', marginBottom: 5 }}></div>

                <div className='inv-table-footer total'>
                  <div className='inv-footer-label'>Total</div>
                  <div className='inv-footer-value'>$80</div>
                </div>
                <div style={{ height: 1, background: '#ddd', marginBottom: 5 }}></div>
              </div>
            </div>
            {/* invoice details */}
          </div>
        </div>
        {/* address part */}
      </div>
    </div>
  );
};

export const ModernInvoice = ({ invoiceSettings }) => {
  const getRequiredFieldValueById = (invoiceSetting, id) => {
    return invoiceSetting?.requiredFields.find((field) => field.id === id)?.value;
  };

  const shippingField = getRequiredFieldValueById(invoiceSettings, INVOICE_IDS.SHIPPING_CHARGE);
  const adjustmentField = getRequiredFieldValueById(invoiceSettings, INVOICE_IDS.ADJUSTMENT);

  const organizationIcon = invoiceSettings?.organizationIcon
    ? apiConstants.imgUrlS3 + invoiceSettings?.organizationIcon
    : Images.hidesk;

  return (
    <div style={{ backgroundColor: '#f9f9f9', width: '100%' }}>
      <div className='invoice-container'>
        {/* head */}
        <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <div style={{ fontWeight: '600', fontSize: 15 }}>
            INVOICE <span>#EBUK2000830</span>
          </div>
          <div>
            <img src={organizationIcon} style={{ objectFit: 'contain', height: 25, width: 30 }} />
          </div>
        </div>
        {/* head */}
        {/* line */}
        <div style={{ marginTop: 15, height: 1, width: '100%', background: '#d2d2d2' }}></div>
        {/* line */}

        {/* address part */}
        <div style={{ marginTop: '4%' }}>
          <div>
            {/* <div style={{ display: 'flex', gap: 4 }}>
              <img src={Images.hidesk} style={{ height: 20, width: 20 }} />
              <div style={{ fontWeight: '600', fontSize: 15 }}>Company Name</div>
            </div> */}

            {/* address */}
            <div
              style={{ display: 'flex', gap: 20, marginTop: 15, justifyContent: 'space-between' }}
            >
              <div>
                <div className='head-bold'>Bill from,</div>
                <div>Hilite Business Park,</div>
                <div>Calicut,485545 </div>
                <div>Email: hidesk@gmail.com</div>
                <div>Phone: +9745212187</div>

                <div className='head-bold  mt-3'>Issued On,</div>
                <div>11 March, 2025 </div>
              </div>
              <div>
                <div className='head-bold'>Bill from,</div>
                <div>Hilite Business Park,</div>
                <div>Calicut,485545 </div>
                <div>Email: hidesk@gmail.com</div>
                <div>Phone: +9745212187</div>

                <div className='head-bold  mt-3'>Due On,</div>
                <div>11 March, 2025 </div>
              </div>
            </div>
            {/* address */}

            {/* invoice details */}
            <div style={{ marginTop: 20, fontSize: 13, fontWeight: '600' }}>Invoice details</div>

            <div style={{ marginTop: 15 }}>
              <div className='inv-table-container'>
                <div className='inv-table-header'>
                  <div className='inv-table-column'>Tasks</div>
                  <div className='inv-table-column'>Hour</div>
                  <div className='inv-table-column'>Price</div>
                </div>
                <div className='inv-table-row'>
                  <div className='inv-table-column'>GST billing - March </div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div>
                {/* <div className='inv-table-row'>
                  <div className='inv-table-column'>task_name_dummy_01</div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div>
                <div className='inv-table-row'>
                  <div className='inv-table-column'>task_name_dummy_01</div>
                  <div className='inv-table-column'>30</div>
                  <div className='inv-table-column'>$25</div>
                </div> */}
                <div className='inv-table-footer' style={{ marginTop: 10 }}>
                  <div className='inv-footer-label'>Sub Total</div>
                  <div className='inv-footer-value'>$75</div>
                </div>
                {shippingField && (
                  <div className='inv-table-footer'>
                    <div className='inv-footer-label'>Shipping Charges</div>
                    <div className='inv-footer-value'>$5</div>
                  </div>
                )}

                {adjustmentField && (
                  <div className='inv-table-footer'>
                    <div className='inv-footer-label'>Adjustments</div>
                    <div className='inv-footer-value'>$5</div>
                  </div>
                )}
                <div className='inv-table-footer'>
                  <div className='inv-footer-label'>Round Off</div>
                  <div className='inv-footer-value'>$5</div>
                </div>

                <div style={{ height: 1, background: '#ddd', marginBottom: 5 }}></div>

                <div className='inv-table-footer total'>
                  <div className='inv-footer-label'>Total</div>
                  <div className='inv-footer-value'>$80</div>
                </div>
                <div style={{ height: 1, background: '#ddd', marginBottom: 5 }}></div>
              </div>
            </div>
            {/* invoice details */}
          </div>
        </div>
        {/* address part */}
      </div>
    </div>
  );
};
