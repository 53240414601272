import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { alertConstants, CommonDropDownStyle } from '../_constants';
import { itemService } from '../_services/item.service';
import { taxService } from '../_services/tax.service';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { ItemState } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import './invoice.css';

export const AddItemModal = ({ handleClose = () => {}, id = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [itemData, setItemData] = useState(ItemState);
  // // eslint-disable-next-line no-unused-vars
  // const [query, setQuery] = useState({
  //   select: ['id', 'name', 'price','description'],
  // });

  const fetchItem = async () => {
    const data = await itemService.itemGet({
      id: id,
      select: ['id', 'name', 'price', 'description'],
    });

    setItemData({
      ...data?.data,
      tax_id: data?.data?.Tax,
    });
    return data;
  };

  // get all user list
  const {
    // eslint-disable-next-line no-unused-vars
    data: item,
    // isLoading: isUserLoading,
  } = useQuery({
    queryKey: ['item-list'],
    queryFn: () => fetchItem(),
    enabled: Boolean(id),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItemData({
      ...itemData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (itemData?.name.toString().trim() === '' || !itemData?.price) {
      setIsLoading(false);
      return null;
    }
    try {
      await itemService.itemAdd({ ...itemData, tax_id: itemData?.tax_id?.id }).then(() => {
        setSubmitted(false);
        // afterAdd(data?.data);
      });
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };
  return (
    <>
      <div className='modal_view_content'>
        <ErrorComponent error={error?.message} />

        <div className='invoice-form-section'>
          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Item Name</label>
            <input
              type='text'
              className='input-box'
              value={itemData?.name}
              name='name'
              onChange={handleChange}
            />
            <ErrorBlock
              errorMsg={'Name required'}
              hasError={submitted && itemData?.name.toString().trim() === ''}
            />
          </div>

          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Description</label>
            <textarea
              className='notes-textarea box-focus fz-13px'
              placeholder='Add description here'
              value={itemData?.description}
              name='description'
              onChange={handleChange}
            ></textarea>
          </div>

          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Price</label>
            <input
              type='text'
              className='input-box'
              value={itemData?.price}
              name='price'
              onChange={handleChange}
            />
            <ErrorBlock errorMsg={'Price required'} hasError={submitted && !itemData?.price} />
          </div>

          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Tax type</label>
            <SearchableInfiniteQuerySelect
              name='tax_id'
              getOptionLabel={(option) => {
                return `${option?.name} - ${option?.rate}%`;
              }}
              getOptionValue={(option) => {
                return option?.id;
              }}
              queryFn={taxService.taxList}
              queryKey={['tax-list']}
              enableCondition={true}
              isCustomFunction
              getFunctionParams={(searchKey) => {
                return {
                  searchKey: searchKey,
                  select: ['id', 'name'],
                };
              }}
              onChange={(selectedOption) => {
                handleChange({
                  target: { value: selectedOption, name: 'tax_id' },
                });
              }}
              placeholder={`Select `}
              styles={{
                ...CommonDropDownStyle,
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
              }}
              menuPortalTarget={document.getElementById('MODAL')}
              value={itemData?.tax_id}
            />
          </div>
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
};
