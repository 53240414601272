import { MENU } from '../_constants';

export const INVOICE_IDS = {
  SHIPPING_CHARGE: 1,
  ADJUSTMENT: 2,
};

export const INVOICE_LABELS = {
  selectedTemplate: 'basic',
  organizationIcon: '',
  organizationName: '',
  invoicePrefix: '',
  estimatePrefix: '',
  invoiceNumber: '',
  numberOfDigits: '',
  defaultTax: null,
  taxGroup: null,
  taxType: null,
  currency: null,
  fontColor: '#000000',
  backgroundColor: '#ffffff',
  paperType: 'A4',
  margin: '15',
  termsCondition: '',
  requiredFields: [
    {
      id: INVOICE_IDS.SHIPPING_CHARGE,
      label: 'Shipping charge',
      value: true,
      type: 'toggle',
    },
    {
      id: INVOICE_IDS.ADJUSTMENT,
      label: 'Adjustment',
      value: true,
      type: 'toggle',
    },
  ],
};
export const MENU_LABELS = [
  {
    id: 1,
    label: MENU.project.name,
    value: true,
    type: 'other',
    default_name: MENU.project.default_name,
  },
  // {
  //   id: 2,
  //   label: 'To do',
  //   value: false,
  //   type: 'check-box',
  //   default_name: 'todo',
  // },
  {
    id: 3,
    label: MENU.okr.name,
    value: true,
    type: 'check-box',
    default_name: MENU.okr.default_name,
  },
  // {
  //   id: 4,
  //   label: 'My Team',
  //   value: true,
  //   type: 'check-box',
  //   default_name: 'myteam',
  // },
  {
    id: 5,
    label: MENU.chat.name,
    value: true,
    type: 'check-box',
    default_name: MENU.chat.default_name,
  },
  // {
  //   id: 6,
  //   label: 'Calender',
  //   value: true,
  //   type: 'check-box',
  //   default_name: 'calender',
  // },
  // {
  //   id: 7,
  //   label: 'Requests',
  //   value: true,
  //   type: 'check-box',
  //   default_name: 'requests',
  // },
  {
    id: 8,
    label: 'Reports',
    value: true,
    type: 'check-box',
    default_name: 'reports',
  },
  {
    id: 9,
    label: MENU.files.name,
    value: true,
    type: 'check-box',
    default_name: MENU.files.default_name,
  },
  {
    id: 10,
    label: MENU.myResponsibility.name,
    value: true,
    type: 'other',
    default_name: MENU.myResponsibility.default_name,
  },
  {
    id: 11,
    label: MENU.teamResponsibility.name,
    value: true,
    type: 'other',
    default_name: MENU.teamResponsibility.default_name,
  },
  {
    id: 12,
    label: MENU.contact.name,
    value: true,
    type: 'other',
    default_name: MENU.contact.default_name,
  },
  // {
  //   id: 12,
  //   label: MENU.contact.name,
  //   value: false,
  //   type: 'other',
  //   default_name: MENU.contact.default_name,
  // },
];

export const PROJECT_IDS = {
  STATUS: 1,
  COST: 2,
  ESTIMATED_COST: 3,
  START_DATE: 4,
  END_DATE: 5,
  DESCRIPTION: 9,
  IMAGE: 10,
};

export const PROJECT_LABELS = [
  { id: PROJECT_IDS.STATUS, label: 'Status', value: true, type: 'select' },
  { id: PROJECT_IDS.COST, label: 'Cost', value: true, type: 'toggle' },
  { id: PROJECT_IDS.ESTIMATED_COST, label: 'Estimated Cost', value: true, type: 'toggle' },
  { id: PROJECT_IDS.START_DATE, label: 'Start Date', value: true, type: 'toggle' },
  { id: PROJECT_IDS.END_DATE, label: 'End Date', value: true, type: 'toggle' },
  // { id: 6, label: 'Project Owner', value: false, type: 'toggle' },
  // { id: 7, label: 'Client', value: false, type: 'toggle' },
  // { id: 8, label: 'Allocated User', value: false, type: 'toggle' },
  { id: PROJECT_IDS.DESCRIPTION, label: 'Description', value: true, type: 'toggle' },
  { id: PROJECT_IDS.IMAGE, label: 'Image', value: true, type: 'toggle' },
];

export const EMPLOYEE_LABELS = [
  {
    id: 1,
    label: 'Employee Add',
    value: true,
    type: 'select',
    description:
      'Please note that in the event the employee add has been disabled, it will not be viewable',
  },
  {
    id: 2,
    label: 'Address',
    value: true,
    type: 'toggle',
    description: `Show or hide the field for entering or displaying the employee's address.`,
  },
  {
    id: 3,
    label: 'Date of Birth',
    value: true,
    type: 'toggle',
    description: `Enable or disable the field for storing the employee's birth date.`,
  },
  {
    id: 4,
    label: 'Gender',
    value: true,
    type: 'toggle',
    description: 'Toggle the display of the gender selection field.',
  },
  // { id: 5, label: 'Organization', value: true, type: 'toggle' },
  // { id: 6, label: 'Email ID', value: true, type: 'toggle' },
  { id: 7, label: 'Contact Number', value: true, type: 'toggle' },
  // { id: 8, label: 'Report To', value: true, type: 'toggle' },
  // { id: 9, label: 'Office Mail', value: true, type: 'toggle' },
  // { id: 10, label: 'Office Number', value: true, type: 'toggle' },
  { id: 11, label: 'Employee Picture', value: true, type: 'toggle' },
];

export const CHAT_LABELS = [
  // { id: 1, label: 'Audio', value: false, type: 'toggle' },
  // { id: 2, label: 'Video', value: false, type: 'toggle' },
  {
    id: 3,
    label: 'Before Stop Chat Message required ',
    value: false,
    type: 'toggle',
    description:
      'Enable a custom message to notify participants before stopping the chat session. This ensures clear communication and avoids abrupt disconnections.',
  },
  {
    id: 4,
    label: 'View Updates',
    value: false,
    type: 'toggle',
    description:
      'Allow users to view real-time updates within the chat interface, keeping everyone informed of changes or important announcements.',
  },
  {
    id: 5,
    label: 'View Tick',
    value: false,
    type: 'toggle',
    description:
      'Display read receipts or message ticks to indicate when messages have been delivered or read by the recipient.',
  },
  {
    id: 6,
    label: 'The status change needs to be displayed in the chat.',
    value: false,
    type: 'toggle',
    description:
      'Automatically show status changes (e.g., online, offline, typing) within the chat, providing better visibility of user activity',
  },
  {
    id: 7,
    label: 'All chat messages together.',
    value: false,
    type: 'toggle',
    description:
      'Organize and display all chat messages from a single conversation in one thread for easy reference and continuity.',
  },
  {
    id: 8,
    label: 'Start and stop message needs to be displayed in the chat',
    value: false,
    type: 'toggle',
    description:
      'Show system-generated messages when a chat session starts or ends, providing a clear timeline of the interaction.',
  },
];

export const NOTIFICATION_LABELS = [
  { id: 1, label: 'Desktop Notification', value: false, type: 'toggle' },
  { id: 2, label: 'Notification Checkbox', value: false, type: 'toggle' },
  { id: 3, label: 'Push Notification', value: false, type: 'toggle' },
  { id: 4, label: 'Reminder', value: false, type: 'toggle' },
  { id: 5, label: 'Requests', value: false, type: 'toggle' },
];

export const TIME_LABELS = [
  { id: 1, label: 'Time Box', value: false, type: 'toggle' },
  { id: 2, label: 'Time Box Team', value: false, type: 'toggle' },
  { id: 3, label: 'Time History', value: false, type: 'toggle' },
  { id: 4, label: 'Time Zone', value: false, type: 'toggle' },
];

export const GENERAL_SETTINGS = [
  // { id: 1, label: 'Theme Color', value: '#d2d2d2', type: 'color-picker' },
  // { id: 2, label: 'Panel Color', value: '', type: 'color-picker' },
  { id: 3, label: 'Text Color', value: '', type: 'color-picker' },
  { id: 4, label: 'Logo', value: '', type: 'file-upload' },
  { id: 5, label: 'SideBar Bottom Image', value: '', type: 'file-upload' },
  // { id: 6, label: 'Fav Icon', value: '', type: 'file-upload' },
  { id: 7, label: 'Home Image', value: '', type: 'file-upload' },
  { id: 8, label: 'Slogan', value: 'hai', type: 'text-input' },
  { id: 9, label: 'Label', value: true, type: 'toggle' },
  { id: 10, label: 'Reminder', value: true, type: 'toggle' },
  { id: 11, label: 'Recurring Task', value: true, type: 'toggle' },
  { id: 12, label: 'Sub Task', value: true, type: 'toggle' },
  { id: 13, label: 'Draggable task', value: true, type: 'toggle', default_name: 'draggable_task' },
  { id: 14, label: 'Ai Chat ', value: false, type: 'toggle', default_name: 'ai_chat' },
  { id: 15, label: 'Ai Audio ', value: false, type: 'toggle', default_name: 'ai_audio' },
  { id: 16, label: 'Note', value: true, type: 'toggle', default_name: 'note' },
  {
    id: 17,
    label: 'Global Search',
    value: true,
    type: 'toggle',
    default_name: 'global_search',
  },
];

export const THEME_SETTINGS = [
  { id: 1, label: 'Css', value: '#d2d2d2', type: 'text-area' },
  { id: 2, label: 'Js', value: '#d2d2d2', type: 'text-area' },
];

export const CUSTOM_FIELD = {
  fieldType: 'text',
  label: '',
  value: false,
  type: 'toggle',
  isCustom: true,
};
export const CUSTOM_FIELD_EDITED = { label: '', value: false, type: 'toggle', isCustom: true };

export const STATUS_OPTIONS = [
  { value: 'enable', label: 'Enable' },
  { value: 'disable', label: 'Disable' },
];

export const PROJECT_DESCRIPTION =
  'Please note that in the event the project has been disabled, it will not be viewable';
export const EMPLOYEE_DESCRIPTION =
  'Please note that in the event the employee add has been disabled, it will not be viewable';
export const TASK_EDIT_OWNER = 'Set task edit selected field permission for Task Owner.';

export const REPORTING_USER = 'Set task edit selected field permission for Task Owner .';

export const TASK_OWNER_OPTIONS = [
  { value: 1, label: 'All Fields' },
  { value: 2, label: 'No Permission' },
  { value: 3, label: 'Selected Fields' },
];

export const ADD_TASK_SETTINGS = {
  admin: {
    fields: 2,
    selectedFields: [],
  },
  taskOwner: {
    fields: 2,
    selectedFields: [],
  },
  reportingUser: {
    fields: 2,
    selectedFields: [],
  },
};

export const ADDITIONAL_SETTINGS = {
  under_verification: {
    is_needed: false,
    //name: 'Under verification',
    name: 'Not verified',
    heading: 'Need additional tab for under-verification',
  },
  to_verify: {
    is_needed: false,
    name: 'To verify',
    heading: 'Need additional tab for to-verify',
  },
  hide_verification: {
    name: 'hide_verification',
    is_needed: false,
    heading: 'Hide verification tasks from the completed list',
  },
  // verification_combined: {
  //   is_needed: false,
  //   name: 'Combined verification',
  //   heading: 'Combined to-verify and under-verification',
  // },
};

export const EDIT_TASK_SETTINGS = {
  taskOwner: { fields: 2, selectedFields: [] },
  admin: { fields: 1, selectedFields: [] },
  reportingUser: { fields: 1, selectedFields: [] },
};

// const { getMenuLabelName } = useContext(GlobalContext);
// const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

export const EMPLOYEE_FIELDS = [
  'Description',
  'Label',
  'Date',
  'Time',
  'Priority',
  // 'Project',
  'Parent Okr',
];

export const REPORTING_FIELDS = [
  'OKR Name',
  'Description',
  'Allocated To',
  // 'Project',
  'Label',
  'Date',
  'Time',
  'Priority',
  'Parent Okr',
];
export const userTypeList = [
  { value: 3, label: 'Admin', name: 'admin' },
  { value: 2, label: 'Reporting User', name: 'reportingUser' },
  { value: 1, label: 'Task Owner', name: 'taskOwner' },
];

export const taskTabs = [
  { name: 'additional_tabs', label: 'Additional tabs' },
  { name: 'default_status', label: 'Default Status' },
  { name: 'priority', label: 'Priority' },
];
