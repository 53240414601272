import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';
export const permissionService = {
  permissionAdd,
  permissionList,
  permissionDelete,
  permissionGet,
  getAllPermissions,
  getAllPermissionList,
  updatePermission,
  getRoleBasedPermission,
};

function permissionGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_permission?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function permissionAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/permission_add', requestOptions).then(handleResponse);
}
function permissionList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/permission_list', requestOptions).then(handleResponse);
}
function permissionDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/permission_delete', requestOptions).then(handleResponse);
}
function getAllPermissions(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/all-permissions', requestOptions).then(handleResponse);
}
function getAllPermissionList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_all_advanced_permission_list', requestOptions).then(
    handleResponse,
  );
}

function updatePermission(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/update_permission', requestOptions).then(handleResponse);
}

function getRoleBasedPermission(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_role_based_permissions ', requestOptions).then(
    handleResponse,
  );
}
