import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pen, Play, Plus, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { GlobalContext, Images } from '../Utils';
import { Calendar, Clock, FileIcon, LoadingIcon, Milestone, SINoIcon, StatusIcon } from '../Utils/SvgIcons';
import { Button, Modal, PaginationComponent, TableNoData, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { MILESTONE_STATUS, RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { projectService } from '../_services';
import { milestoneService } from '../_services/milestone.service';
import MilestoneAdd from './MilestoneAdd';

const MilestoneList = () => {
  const queryClient = useQueryClient();
  const projectId = useParams();
  const nav = useNavigate();
  const { makeAlert } = useContext(GlobalContext);
  const [isMilestoneAdd, setMilestoneAdd] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editMilestoneID, setEditMilestoneID] = useState(null);
  const [deleteMilestoneID, setDeleteMilestoneID] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ modal: false, message: '' });
  const [statusLoadingId, setStatusLoadingId] = useState(null);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'duration', 'start_date', 'end_date', 'goal', 'progress_status'],
    searchKey: '',
    status: 1,
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    project_id: projectId.id,
  });
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  //  ref section
  const tableContainerRef = useRef(null);

  const modalClose = () => {
    setDeleteModal({ modal: false, message: '' });
    setEditMilestoneID(null);
  };
  const handleDelete = async () => {
    try {
      await milestoneService.milestoneDelete({ id: deleteMilestoneID });
      let isNeedToBack = milestoneData?.data?.rows.length === 1 && milestoneData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('milestoneList');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error.message);
      console.error('Error deleting branch:', error);
    }
  };

  const handleStatusUpdate = async (event, id, value) => {
    event.stopPropagation();
    setStatusLoadingId(id);
    const payload = {
      progress_status: value,
      id: parseInt(id),
      project_id: parseInt(projectId.id),
    };
    try {
      await milestoneService.milestoneStatusUpdate(payload);
    } catch (error) {
      makeAlert(error.message);
    } finally {
      setStatusLoadingId(null);
    }
  };

  const { data: projectData } = useQuery({
    queryKey: ['project', debouncedSearch],
    queryFn: () =>
      projectService.projectGet({ id: projectId.id, select: ['name', 'project_type'] }),
    placeholderData: keepPreviousData,
  });
  const {
    data: milestoneData,
    error,
    refetch,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['milestoneList', query?.pageVo?.pageNo, statusLoadingId, debouncedSearch],
    queryFn: () => milestoneService.milestoneList(query),
    placeholderData: keepPreviousData,
  });

  const milestoneCount = milestoneData?.data?.rows.length;
  const project_task_navigation_id = milestoneData?.data?.task_list_navigation_id;
  const milestoneRows = milestoneData?.data?.rows.map((milestone, index) => {
    const isActionVisible = milestoneData?.data?.isActionVisible;
    const slNo = (milestoneData?.data?.page - 1) * milestoneData?.data?.limit + index + 1;
    const actionButton =
      milestone?.progress_status == 0
        ? { backgroundColor: 'var(--bg-primary)', label: 'Start', value: MILESTONE_STATUS.START }
        : milestone?.progress_status == 1
        ? {
            backgroundColor: '#3454D1',
            color: '#fff',
            label: 'Complete',
            value: MILESTONE_STATUS.COMPLETE,
          }
        : {};

    let progress = parseFloat(milestone?.completion_percentage);
    // Ensure childProgress is a number before formatting
    if (typeof progress === 'number' && progress > 0) {
      // Check if the progress is a whole number
      if (progress % 1 === 0) {
        progress = progress.toFixed(0); // No decimal points
      } else {
        progress = progress.toFixed(1); // One decimal point
      }
    }

    const getColorBasedOnPercent = () => {
      if (progress < 30) {
        return 'rgba(234, 77, 77, 1)';
      } else if (progress < 70) {
        return 'rgba(237, 166, 0, 1)';
      } else {
        return 'rgba(28, 169, 119, 1)';
      }
    };

    const progressColors = getColorBasedOnPercent();
    return (
      <Tr
        key={index}
        onClick={() => {
          nav(
            `/${RT.PROJECT}/${projectId.id}/${RT.PROJECT_TASK_LIST}?status=${
              project_task_navigation_id ?? 'in-progress'
            }&milestone_id=${milestone?.id}`,
          );
          // nav(
          //   `/${RT.PROJECT}/${projectId.id}/${RT.PROJECT_TASK_LIST}?status=${
          //     milestone.navigation_id ?? 'in-progress'
          //   }&milestone_id=${milestone?.id}`,
          // );
        }}
        className='cursor-pointer'
      >
        <td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</td>
        <Td
          className='milestone-name'
          style={{
            lineBreak: milestone?.name.split(' ').some((word) => word.length > 30)
              ? 'anywhere'
              : 'normal',
          }}
        >
          {milestone?.name}
        </Td>

        <Td>
          <span style={{ color: progressColors }}>{progress}%</span>
        </Td>
        <Td>{moment(parseInt(milestone.start_date)).format('MMM DD, YYYY')} </Td>
        <Td>
          <span style={{ color: milestone?.is_overdue ? '#EA4D4D' : '' }}>
            {moment(parseInt(milestone.end_date)).format('MMM DD, YYYY')}
          </span>
        </Td>
        <Td>
          {milestone?.completed_task_count} of {milestone?.total_task_count} completed
        </Td>
        <Td>
          {milestone?.progress_status === MILESTONE_STATUS.COMPLETE ? (
            <div className='milestone-status-container'>
              <div className='dot-icon' style={{ backgroundColor: '#17C666' }} /> Completed
            </div>
          ) : milestone?.progress_status === MILESTONE_STATUS.START ? (
            <div className='milestone-status-container'>
              <div className='dot-icon' style={{ backgroundColor: '#FA8847' }} /> In Progress
            </div>
          ) : (
            <div className='milestone-status-container'>
              <div className='dot-icon' style={{ backgroundColor: '#3454D1' }} /> Todo
            </div>
          )}
        </Td>
        <Td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip
              tooltipText={`Edit  ${
                projectData?.data?.project_type == 1 ? 'sprint' : 'milestones'
              } `}
              isModern
              popOver
              scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditMilestoneID(milestone?.id);
                  setMilestoneAdd(true);
                }}
              >
                <Pen size={16} />
              </div>
            </ToolTip>
            {isActionVisible && (
              <ToolTip
                tooltipText={`Delete  ${
                  projectData?.data?.project_type == 1 ? 'sprint' : 'milestones'
                } `}
                isModern
                popOver
                scrollRef={tableContainerRef}
              >
                <div
                  className='icon-bg'
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleteMilestoneID(milestone?.id);
                    setDeleteModal({
                      modal: true,
                      message:
                        projectData?.data?.project_type == 1
                          ? DELETE_NOTE.SPRINT
                          : DELETE_NOTE.MILESTONE,
                    });
                  }}
                >
                  <Trash size={16} />
                </div>
              </ToolTip>
            )}
            {isActionVisible && (
              <div>
                {milestone.progress_status !== MILESTONE_STATUS.COMPLETE && (
                  <Button.Container
                    type='submit'
                    className={'action-button-complete'}
                    style={{
                      backgroundColor: actionButton.backgroundColor,
                      color: actionButton.color,
                    }}
                    handleOk={(event) =>
                      handleStatusUpdate(event, milestone.id, actionButton.value)
                    }
                  >
                    {actionButton?.label === 'Start' ? (
                      <Play fill size={10} color='var(--icon-light)' />
                    ) : (
                      ''
                    )}

                    <Button.Title title={actionButton.label} />
                    <Button.Loading isLoading={milestone?.id === statusLoadingId} />
                  </Button.Container>
                )}
              </div>
            )}
          </div>
        </Td>
      </Tr>
    );
  });

  const TABLE_HEADING = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Milestone height={16} width={16} color='#87909E' />
          {projectData?.data?.project_type == 1 ? 'Sprint' : 'Milestones'}
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '10vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Clock /> Progress
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Start Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Due Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <FileIcon color='#87909E' /> Tasks Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <StatusIcon /> Status
        </div>
      ),
      minWidth: 50,
    },
    {},
  ];

  return (
    <div className='project-milestone-header-container'>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message} />
      <div className='row '>
        {/* header input section */}
        <section>
          <div className='tab_contain' style={{ fontSize: '13px' }}>
            <div className='d-flex gap-2'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
              />

              {/* <div className={'search_contain ' + (query.searchKey ? 'active' : '')}>
                <input
                  onChange={(event) => {
                    searchParams.set('pageNo', 1);
                    setSearchParams(searchParams);
                    setQuery({
                      ...query,
                      searchKey: event.target.value,
                      pageVo: { ...query.pageVo, pageNo: 1 },
                    });
                  }}
                  placeholder='Search...'
                  type='text'
                  className='bodr_none'
                />
                <Search size={18} color='#87909E' />
              </div> */}
            </div>
            {projectData?.data?.status === 1 && (
              <div className='d-flex gap-1 align-items-center justify-content-end '>
                <ToolTip
                  tooltipText={`Add ${
                    projectData?.data?.project_type == 1 ? 'Sprint' : 'Milestones'
                  }`}
                  hideTooltip={isMilestoneAdd}
                  isModern
                >
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isMilestoneAdd ? '#e9f2ff' : '',
                    }}
                    onClick={() => {
                      setMilestoneAdd(true);
                    }}
                  >
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
              </div>
            )}
          </div>
        </section>
      </div>
      <section className='mt-2'>
        <div className='table_outer' ref={tableContainerRef}>
          <Table className='table table-hover tbl mb-0'>
            <Thead>
              <Tr className='table-head'>
                {TABLE_HEADING.map((heading, index) => {
                  return (
                    <Th
                      key={index}
                      scope='col'
                      style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                    >
                      {heading.name}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <TableRow noOfCols={8}>
                  <LoadingIcon size={60} />
                </TableRow>
              )}
              {!milestoneCount && isSuccess && <TableNoData noOfCols={8}>No Data</TableNoData>}
              {milestoneCount > 0 && isSuccess && milestoneRows}
            </Tbody>
          </Table>
        </div>
      </section>
      {milestoneCount > 0 && (
        <section className='mt-2'>
          <PaginationComponent
            pageOnClick={(data) => {
              setQuery((prev) => ({
                ...prev,
                pageVo: {
                  ...prev.pageVo,
                  pageNo: data,
                },
              }));
              searchParams.set('pageNo', data);
              setSearchParams(searchParams);
            }}
            demoData={milestoneData?.data}
          />
        </section>
      )}

      <section>
        {isMilestoneAdd && (
          <Modal.Container>
            {/* <Modal.Head containerClass={'task-add-head'}></Modal.Head> */}
            <Modal.View className={'milestone-add-container'}>
              <MilestoneAdd
                type={projectData?.data?.project_type == 1 ? 'sprint' : 'milestone'}
                id={editMilestoneID}
                project_id={projectId.id}
                handleClose={(event) => {
                  event.stopPropagation();
                  setEditMilestoneID(null);
                  setMilestoneAdd(false);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </section>

      {deleteModal.modal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{deleteModal?.message}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDelete(deleteMilestoneID)}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};

export default MilestoneList;
