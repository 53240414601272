/* eslint-disable no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { workflowService } from '../_services';
import { emailTemplateService } from '../_services/emailTemplate.service';

import { Button } from '../_components';
import { statusService } from '../_services/status.service';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { DelayComponent } from './DelayComponent';
import './node_add.css';
import { ValueSelectionComponent } from './Steps/ValueSelectionComponent';
import {
  ACTION_TYPE,
  actionOptions,
  customStyles,
  FELIDS,
  TRIGGER_TYPE,
  WF_TYPE,
  wfTypeOptions,
} from './workflow.constants';
import {
  extractConditionsFromArray,
  getIconVisibility,
  getTriggerByName,
  getWorkFlowLabel,
  getWorkFlowType,
  useTriggerNodeData,
} from './workflowHelper';

export const NodeAddComponent = ({ setAddModal, data, rest }) => {
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  console.log('NodeAddComponent', data);
  let triggerNodeData = useTriggerNodeData();
  let TRIGGER_TYPE = triggerNodeData?.node_details?.data?.id;

  const queryClient = useQueryClient();
  const { wf_id } = useParams();
  const [nodeData, setNodeData] = useState({
    type: '', // For "Condition" or "Action"
    operation: '', // For operations like email, SMS, etc.
    operation_details: null, // For operation-specific details [] || {}
  });

  const handleChangeConditions = (key, dataObj, index) => {
    setNodeData((prevState) => {
      const updatedOperationDetails = [...(prevState.operation_details || [])];
      updatedOperationDetails[index] = {
        ...updatedOperationDetails[index],
        [key]: dataObj,
      };

      return {
        ...prevState,
        operation_details: updatedOperationDetails,
      };
    });
  };

  const handleChange = (key, value) => {
    setNodeData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOperationDetailsChange = (key, value) => {
    console.log('handleOperationDetailsChange', key, value);
    setNodeData((prevState) => ({
      ...prevState,
      operation_details: {
        ...prevState.operation_details,
        [key]: value,
      },
    }));
  };

  const generateValueData = (name, selectedData) => {
    console.log('generateValueData', name, selectedData);
    let data = {
      condition: {
        label: 'Labels ',
        id: 'label',
        type: 'number',
        tableType: 'TASK',
        operation: '=',
      },
      condition_value: {
        label: 'Learning',
        value: '8',
      },
    };

    if (name === FELIDS.STATUS_GROUP) {
      data.condition.label = 'Status group';
      data.condition.id = 'status_group_id';
      data.condition_value.label = selectedData.name;
      data.condition_value.value = selectedData.id;
    }
    if (name === FELIDS.PREVIOUS_STATUS) {
      data.condition.label = 'Status';
      data.condition.id = 'status_group_item_id';
      data.condition_value.label = selectedData.name;
      data.condition_value.value = selectedData.id;
    }
    if (name === FELIDS.NEW_STATUS) {
      data.condition.label = 'Status';
      data.condition.id = 'status_group_item_id';
      data.condition_value.label = selectedData.name;
      data.condition_value.value = selectedData.id;
    }

    return data;
  };

  //-- manage status change case
  const handleConditionOperationDetailsChange = (name, value) => {
    console.log('handleConditionOperationDetailsChange', name, value);

    const generatedValueData = generateValueData(name, value);

    setNodeData((prevState) => {
      // Handle case where operation_details might be null or undefined
      const existingOperationDetails = prevState?.operation_details || [];

      // Check if the item already exists based on the FELIDS field
      const existingIndex = existingOperationDetails.findIndex((item) => item.FELIDS === name);

      let updatedOperationDetails;

      if (existingIndex > -1) {
        // Update existing item
        updatedOperationDetails = existingOperationDetails.map((item, index) => {
          if (index === existingIndex) {
            return {
              ...item,
              ...generatedValueData, // Update the value
            };
          }
          return item; // Return unchanged item
        });
      } else {
        // Add new item if it doesn't exist
        updatedOperationDetails = [
          ...existingOperationDetails,
          { FELIDS: name, ...generatedValueData },
        ];
      }

      return {
        ...prevState,
        operation_details: updatedOperationDetails,
      };
    });
  };

  // generate node data
  const generateNodeAndEdgeData = (nodeDataObj, parentNodeDetails) => {
    const nodeUniqueId = new Date().getTime().toString();

    let returnData = {
      TRIGGER_TYPE: TRIGGER_TYPE,
      workflow_id: wf_id,
      type: nodeDataObj.type,
      operation: nodeDataObj.operation,
      operation_details: nodeDataObj.operation_details,
      node_details: {
        id: nodeUniqueId,
        type: getWorkFlowType(nodeDataObj.type),
        data: {
          TRIGGER_TYPE: TRIGGER_TYPE,
          label: getWorkFlowLabel(nodeDataObj.type),
          addIconNeed: getIconVisibility(nodeDataObj.type),
          operation: nodeDataObj.operation,
          operation_details: nodeDataObj.operation_details,
        },
        position: { x: rest.xPos, y: rest.yPos + 200 },
      },
      parent_id: parentNodeDetails?.step_id,

      parent_edge_details: {
        id: nodeUniqueId,
        source: rest?.id,
        target: nodeUniqueId,
        type: 'smoothstep',
        style: {
          stroke: '#e1e1e1',
          strokeWidth: 1,
        },
      },
    };

    return returnData;
  };

  // menu settings update
  const addWorkflowSteps = async (data) => {
    let response = await workflowService.addWorkflowSteps(data);
    return response.data;
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isPending } = useMutation({
    mutationFn: addWorkflowSteps,
    onSuccess: () => {
      queryClient.invalidateQueries(['workflow-steps-list']);
      setAddModal(false);
      setNodeData({ type: '', operation: '', operation_details: {} });
    },
  });

  const handleSubmit = () => {
    let generatedData = generateNodeAndEdgeData(nodeData, data);
    handleMutate(generatedData);
  };

  // console.log('NODE DATA', nodeData);
  return (
    <>
      <div className='trigger-modal-container-2'>
        {/* Header */}
        <div className='trigger-modal-header'>
          <h2 className='trigger-modal-title'>Create node </h2>
          <div style={{ fontSize: 13, marginTop: 5 }}> Trigger type : {`${TRIGGER_TYPE}`}</div>
          <p className='trigger-modal-subtitle'>Create condition | action node</p>
        </div>

        {/* Tab Headers */}
        <div className='trigger-content'>
          {/* Dropdown selection */}
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <label htmlFor='trigger-type-select' className='trigger-type-select'>
              Select type
            </label>

            <ReactSelect
              id='trigger-type-select'
              styles={customStyles}
              options={wfTypeOptions}
              getOptionLabel={(option) => option?.label}
              getOptionValue={(option) => option?.value}
              onChange={(selectedOption) => {
                handleChange('type', selectedOption?.value);
              }}
              value={wfTypeOptions.find((option) => option?.value === nodeData.type) || null}
            />
          </div>

          {/* Action-specific dropdown */}
          {nodeData?.type === WF_TYPE.ACTION && (
            <>
              <ActionSelection
                handleChange={handleChange}
                nodeData={nodeData}
                handleOperationDetailsChange={handleOperationDetailsChange}
              />
            </>
          )}
          {/* Action-specific dropdown */}

          {nodeData?.type === WF_TYPE.CONDITION && (
            <>
              <ConditionSelection
                handleChange={handleChange}
                nodeData={nodeData}
                handleOperationDetailsChange={handleOperationDetailsChange}
                TRIGGER_TYPE={TRIGGER_TYPE}
                handleConditionOperationDetailsChange={handleConditionOperationDetailsChange}
                handleChangeConditions={handleChangeConditions}
              />
            </>
          )}
          {/* delay */}
          {nodeData?.type === WF_TYPE.DELAY && (
            <>
              <DelayComponent
                handleChange={handleChange}
                nodeData={nodeData}
                handleOperationDetailsChange={handleOperationDetailsChange}
              />
            </>
          )}
        </div>

        {/* Footer */}
        <div
          style={{
            boxShadow: scrollFlag.isBottomReached ? '' : '0 -6px 5px -5px var(--shadow, #091e4229)',
          }}
          className='client-add-button-container modal_footer'
        >
          <Button.Container
            className={'outline-cancel-button'}
            type='reset'
            handleOk={() => {
              setAddModal(false);
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container
            handleOk={(e) => handleSubmit()}
            type='submit'
            className={'blue-btn'}
            isDisabled={isPending}
          >
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isPending} />
          </Button.Container>
        </div>

        {/* button */}
        {/* Footer */}
      </div>
    </>
  );
};

export const ActionSelection = ({ handleChange, nodeData, handleOperationDetailsChange }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 10 }}>
        <label htmlFor='trigger-operation-select' className='trigger-type-select'>
          Select action
        </label>
        <ReactSelect
          id='trigger-type-select'
          styles={customStyles}
          options={actionOptions}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          onChange={(selectedOption) => {
            handleChange('operation', selectedOption?.value);
          }}
          value={actionOptions.find((option) => option.value === nodeData.operation) || null}
        />
      </div>

      {/* Email-specific details */}
      {nodeData.operation === ACTION_TYPE.MAIL && (
        <>
          <EmailTemplateSelection
            operation={nodeData?.operation}
            onChange={(data) => handleOperationDetailsChange('template_details', data)}
          />
        </>
      )}

      {(nodeData.operation === ACTION_TYPE.SMS ||
        nodeData.operation === ACTION_TYPE.WHATS_APP_SMS) && (
        <>
          <div style={{ fontSize: 15, marginTop: 10, textAlign: 'center' }}>Coming soon!</div>
        </>
      )}
    </>
  );
};

export const EmailTemplateSelection = ({ onChange = () => {} }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  // organization list API call section
  const {
    data: emailTemplateData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['email-template-list', query?.pageVo?.pageNo],
    queryFn: () => emailTemplateService.emailTemplateList(query),
  });

  // Transform API data into a format suitable for ReactSelect
  const emailTemplateOptions =
    emailTemplateData?.data?.rows?.map((template) => ({
      label: template.name,
      value: template.id,
    })) || [];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 10,
      }}
    >
      <label htmlFor='email-template-select' className='trigger-type-select'>
        Select email template
      </label>

      <ReactSelect
        id='trigger-type-select'
        styles={customStyles}
        options={emailTemplateOptions}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onChange={(selectedOption) => onChange(selectedOption)}
        isLoading={isLoading} // Show a loading indicator
        placeholder={isLoading ? 'Loading templates...' : 'Select an email template'}

        // value={actionOptions.find((option) => option.value === nodeData.operation) || null}
      />
    </div>
  );
};

export const ConditionSelection = ({
  handleConditionOperationDetailsChange,
  handleChange,
  nodeData,
  handleOperationDetailsChange,
  handleChangeConditions,
}) => {
  const [selectedCondition, setSelectedCondition] = useState();
  const [selectStatusGroup, setSelectStatusGroup] = useState();

  let triggerNodeData = useTriggerNodeData();

  let TRIGGER = triggerNodeData?.node_details?.data?.id;

  // Get trigger object based on TRIGGER_TYPE
  const selectedTrigger = getTriggerByName(TRIGGER);

  const options = extractConditionsFromArray(selectedTrigger?.conditions);

  const handleConditionChange = (selectedOption) => {
    console.log('handleConditionChange', selectedOption);
    selectedOption.operation = '=';
    setSelectedCondition(selectedOption);
    handleChangeConditions('condition', selectedOption, 0);
  };

  const handleStatusGroupChange = (name, selectedOption) => {
    if (name === FELIDS.STATUS_GROUP) {
      setSelectStatusGroup(selectedOption);
    }

    // console.log('STATUS_GROUP', selectedOption);

    handleConditionOperationDetailsChange(name, selectedOption);
  };

  // Status group list
  const getAllStatusGroupList = async () => {
    const statusGroupData = await statusService.statusGroupList({ status: 1 });
    console.log('getAllStatusGroupList', statusGroupData);
    let fullData = [
      ...statusGroupData.data.defaultStatus,
      ...statusGroupData.data.nonDefaultStatus,
    ];
    return fullData;
  };

  const { data: statusGroupList, refetch } = useQuery({
    queryKey: ['status-group-lit=st', selectedCondition?.id],
    queryFn: () => getAllStatusGroupList(),
    enabled: TRIGGER === FELIDS.STATUS_GROUP ? true : false,
  });

  const getAllStatusItem = async () => {
    const statusGroupData = await statusService.statusGroupItemList({
      status_group_id: selectStatusGroup?.id,
    });

    return statusGroupData.data;
  };

  const { data: statusList } = useQuery({
    queryKey: ['status-group-list', selectStatusGroup?.id],
    queryFn: () => getAllStatusItem(),
    enabled: selectStatusGroup?.id ? true : false,
  });

  return (
    <>
      {TRIGGER === TRIGGER_TYPE.TASK_STATUS_CHANGE && (
        <>
          <div style={{ marginTop: 10 }}>
            <label htmlFor='trigger-type-select' className='condition-field-select'>
              Select status group
            </label>
            <ReactSelect
              id='trigger-type-select'
              styles={customStyles} // Apply custom styles
              options={statusGroupList} // Pass the options generated from conditions
              getOptionLabel={(option) => option?.name} // Use label for display
              getOptionValue={(option) => option?.id} // Use id as value
              onChange={(e) =>
                handleStatusGroupChange(FELIDS.STATUS_GROUP, {
                  id: e.id,
                  name: e.name,
                  FELID: FELIDS.STATUS_GROUP,
                  ...e,
                })
              } // Update state on change
              placeholder='Select a condition'
            />
          </div>

          {selectStatusGroup?.id && (
            <>
              <div style={{ marginTop: 10 }}>
                <label htmlFor='trigger-type-select' className='condition-field-select'>
                  Select previous status
                </label>
                <ReactSelect
                  id='trigger-type-select'
                  styles={customStyles} // Apply custom styles
                  options={statusList} // Pass the options generated from conditions
                  getOptionLabel={(option) => option?.name} // Use label for display
                  getOptionValue={(option) => option?.id}
                  onChange={(e) =>
                    handleStatusGroupChange(FELIDS.PREVIOUS_STATUS, {
                      id: e.id,
                      name: e.name,
                      FELID: FELIDS.PREVIOUS_STATUS,
                      ...e,
                    })
                  } // Update state on change
                  placeholder='Select a condition'
                />
              </div>
              <div style={{ marginTop: 10 }}>
                <label htmlFor='trigger-type-select' className='condition-field-select'>
                  Select new status
                </label>
                <ReactSelect
                  id='trigger-type-select'
                  styles={customStyles} // Apply custom styles
                  options={statusList} // Pass the options generated from conditions
                  getOptionLabel={(option) => option?.name} // Use label for display
                  getOptionValue={(option) => option?.id} // Use id as value
                  placeholder='Select a condition'
                  onChange={(e) =>
                    handleStatusGroupChange(FELIDS.NEW_STATUS, {
                      id: e.id,
                      name: e.name,
                      FELID: FELIDS.NEW_STATUS,
                      ...e,
                    })
                  } // Update state on change
                />
              </div>
            </>
          )}
        </>
      )}

      {TRIGGER != TRIGGER_TYPE.TASK_STATUS_CHANGE && (
        <>
          <div style={{ marginTop: 10 }}>
            <label htmlFor='trigger-type-select' className='condition-field-select'>
              Select filed
            </label>
            <ReactSelect
              id='trigger-type-select'
              styles={customStyles} // Apply custom styles
              options={options} // Pass the options generated from conditions
              getOptionLabel={(option) => option?.label} // Use label for display
              getOptionValue={(option) => option?.id} // Use id as value
              onChange={handleConditionChange} // Update state on change
              placeholder='Select a condition'
              // onChange={
              //   (e) => console.log('NOAA-->', e)
              //   // handleStatusGroupChange(FELIDS.STATUS_GROUP, {
              //   //   id: e.id,
              //   //   name: e.name,
              //   //   FELID: FELIDS.STATUS_GROUP,
              //   //   ...e,
              //   // })
              // }
            />
          </div>

          <ValueSelectionComponent
            selectedCondition={selectedCondition}
            handleOperationDetailsChange={(data) =>
              handleChangeConditions('condition_value', data, 0)
            }
          />
        </>
      )}
    </>
  );
};
