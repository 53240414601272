/* eslint-disable no-unused-vars */
import { CircleGauge, Plus, Sailboat } from 'lucide-react';
import React from 'react';
import { Handle } from 'reactflow';
import { Modal } from '../_components';
import { NodeAddComponent } from './NodeAddComponent';
import './workflow.css';

export function TriggerNode(props) {
  const { data, ...rest } = props;

  const labelText = data.label || 'Trigger node';
  const addIconNeed = data?.addIconNeed;
  const [addModal, setAddModal] = React.useState(false);

  return (
    <>
      <div className='nd-container'>
        <div className='nd-header'>
          <CircleGauge className='nd-header-icon' size={14} />
          <span className='nd-header-text'>Trigger</span>
        </div>

        <div>
          <div className='nd-content'>
            <Sailboat className='nd-content-icon' size={16} />
            <span className='nd-content-text'>
              When <strong>{labelText}</strong>
            </span>
          </div>
          <div className='nd-divider'></div>
          <div className='nd-description'>Trigger when a {labelText}</div>
        </div>

        <Handle type='source' position='bottom' id='trigger-output' className='nd-handle' />

        {addIconNeed && (
          <div onClick={() => setAddModal(true)} className='nd-add-icon'>
            <Plus size={13} />
          </div>
        )}
      </div>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <NodeAddComponent
              rest={rest}
              data={data}
              addModal={addModal}
              setAddModal={setAddModal}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
