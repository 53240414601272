/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */

import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactFlow, { Background, MarkerType, useEdgesState, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { workflowService } from '../_services';
import { ActionNode } from './ActionNode';
import { ConditionNode } from './ConditionNode';
import { DelayNode } from './DelayNode';
import { TriggerCreationNode } from './TriggerCreationNode';
import { TriggerNode } from './TriggerNode';
import { WorkflowHead } from './WorkflowHead';

const nodeTypes = {
  TriggerCreationNode: TriggerCreationNode,
  TriggerNode: TriggerNode,
  ConditionNode: ConditionNode,
  ActionNode: ActionNode,
  DelayNode: DelayNode,
};

function WorkFlow() {
  const initialNodes = [
    // {
    //   id: '1',
    //   type: 'TriggerCreationNode',
    //   position: { x: 400, y: 150 },
    // },
    // {
    //   id: '1',
    //   type: 'TriggerNode',
    //   data: { label: 'TASK_CREATION', addIconNeed: true },
    //   position: { x: 400, y: 150 },
    // },
    // {
    //   id: '2',
    //   type: 'ConditionNode',
    //   data: { label: 'condition node', icon: 'zap', addIconNeed: true },
    //   position: { x: 400, y: 300 },
    // },
    // {
    //   id: '3',
    //   type: 'ConditionNode',
    //   data: { label: 'condition node', icon: 'zap', addIconNeed: true },
    //   position: { x: 400, y: 500 },
    // },
    // {
    //   id: '1',
    //   type: 'ConditionNode',
    //   data: { label: 'condition node', icon: 'zap', addIconNeed: true },
    //   position: { x: 400, y: 500 },
    // },
    // {
    //   id: '2',
    //   type: 'ActionNode',
    //   data: { label: 'action node', icon: 'zap', addIconNeed: true },
    //   position: { x: 400, y: 680 },
    // },
  ];

  const initialEdges = [
    // "source":"1735018029909","target":"1735018498270"
    // { id: 'e2-3', source: '2', target: '3', type: 'smoothstep' },
    // { id: 'e2-3', source: '3', target: '4', type: 'smoothstep' },
    // { id: 'e2-3', source: '4', target: '5', type: 'smoothstep' },
  ];

  const { wf_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [query, setQuery] = useState({
    select: [
      'parent_id',
      'id',
      'name',
      'createdAt',
      'updatedAt',
      'workflow_id',
      'type',
      'operation',
      'operation_details',
      'parent_id',
      'node_details',
      'edge_details',
      'sequence_number',
    ],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 100,
    },
    // status: 1,
  });

  // organization list API call section
  const {
    data: workflowData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['workflow-steps-list', wf_id],
    queryFn: () => getWorkflowStepList(),
  });

  const getWorkflowStepList = async () => {
    let newQuery = { ...query, workflow_id: wf_id };
    const response = await workflowService.getWorkflowStepList(newQuery);

    const result = response.data.map((item) => {
      if (item.node_details && item.node_details.data) {
        item.node_details.data.step_id = item.id;
      }
      return item.node_details || {};
    });
    const resultEdges = response.data
      .filter((item) => item.edge_details) // Filter out items without edge_details
      .map((item) => item.edge_details); // Map only items with edge_details

    if (result.length) {
      setNodes((prev) => [...result]);
    } else {
      setNodes((prev) => [
        {
          id: '1',
          type: 'TriggerCreationNode',
          position: { x: 400, y: 150 },
        },
      ]);
    }

    setEdges((prev) => [...resultEdges]);

    return response;
  };

  const _initialEdges = [
    {
      id: 'edge-TriggerNode-ConditionNode',
      source: 'TriggerNode',
      target: 'ConditionNode',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      style: { stroke: '#000', strokeWidth: 1 },
    },
    {
      id: 'edge-ConditionNode-ConditionNode2',
      source: 'ConditionNode',
      target: 'ConditionNode2',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      style: { stroke: '#000', strokeWidth: 1 },
    },
    {
      id: 'edge-ConditionNode2-action',
      source: 'ConditionNode2',
      target: 'action',
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      style: { stroke: '#000', strokeWidth: 1 },
    },
  ];

  // menu settings update
  const updateNodePosition = async (data) => {
    let response = await workflowService.updateWorkflowStepPosition(data);
    return response.data;
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: updateNodePosition,
  });

  const onConnect = useCallback((params) => setEdges((eds) => [...eds, params]), [setEdges]);

  const handleNodeDragStop = (node) => {
    // Perform any operation when a node drag is completed
    console.log('Node drag stopped. Final position:', node.position);

    // Example: Save the updated position to the backend
    // saveNodePositionToBackend(node.id, node.position);

    handleMutate({ id: node.id, position: node.position });
  };

  if (isLoading) {
    <div>Loading...</div>;
  }
  return (
    <>
      <WorkflowHead />
      <div style={{ width: '100%', height: '100%', background: '#fafafa' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          onNodeDragStop={(event, node) => {
            console.log('Drag completed:', node);
            // Perform any additional action on drag stop
            handleNodeDragStop(node);
          }}
        >
          <Background color='pink' variant='dots' gap={12} size={1} />
        </ReactFlow>
      </div>
    </>
  );
}

export default WorkFlow;
