import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants, USER_LIST_ACTIONS } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { sharedUserSelect } from '../_constants/filesystem.constant';
import { folderService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { folderState, GlobalContext } from '../Utils';
import { SharedWith } from './SharedWith';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const FolderAdd = ({
  handleClose = () => {},
  id = null,
  position = POSITION.LEFT,
  shareWithListPlacement = 'bottom',
}) => {
  const popUpPosition = position === POSITION.RIGHT ? 'right' : 'left';

  const queryClient = useQueryClient();
  const { makeAlert } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    select: ['id', 'name', 'shared_with_team', 'shared_with_user'],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [folderData, setFolderData] = useState(folderState);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ message: '' });

  const fetchFolder = async (query) => {
    query.id = id;
    const folder = await folderService.folderGet(query);
    if (folder?.data?.length) {
      const result = folder.data[0];
      let sharedTeam =
        result.shared_with_team.length > 0 && result.shared_with_team[0] !== 0
          ? result.teams
          : result.shared_with_team.length > 0
          ? [SHARED_WITH_ALL_OPTION.TEAM]
          : [];
      let sharedUser =
        result.shared_with_user.length > 0 && result.shared_with_user[0] !== 0
          ? result.members
          : result.shared_with_user.length > 0
          ? [SHARED_WITH_ALL_OPTION.USER]
          : [];

      setFolderData({ ...result, shared_with_team: sharedTeam, shared_with_user: sharedUser });

      return { ...result, shared_with_team: sharedTeam, shared_with_user: sharedUser };
    }
    throw new Error('Folder data is empty');
  };

  const {
    // eslint-disable-next-line no-unused-vars
    data: folder,
    // isLoading: queryLoading,
    // error: queryError,
  } = useQuery({
    queryKey: ['folder-data', query],
    queryFn: () => fetchFolder(query),

    enabled: !!id, // Only run the query if the id is set

    onError: (error) => {
      setError({
        message: error.message || alertConstants.SERVER_ERROR,
      });
    },
  });
  // team list
  const { data: teamData } = useQuery({
    queryKey: ['shared-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
  });

  const { data: usersForShare } = useQuery({
    queryKey: ['user-list-file'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: sharedUserSelect,
        action: USER_LIST_ACTIONS.ACTION_FILE_ALLOCATION,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);
    if (folderData?.name.toString().trim() === '') {
      setIsLoading(false);
      return null;
    }
    const shared_with_user = folderData?.shared_with_user?.map((user) => parseInt(user.id));
    const shared_with_team = folderData?.shared_with_team?.map((user) => parseInt(user.id));

    const payload = {
      ...folderData,
      shared_with_team: shared_with_team,
      shared_with_user: shared_with_user,
    };
    try {
      const res = await folderService.folderAdd(payload);
      queryClient.invalidateQueries(['folderList']);

      if (res.error_code === 0) {
        setSubmitted(false);
        handleClose(event);
        if (id) {
          makeAlert('Folder updated Successfully');
        } else {
          makeAlert('Folder added Successfully');
        }
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'shared_with') {
      const lastItem = value.length > 0 ? value[value.length - 1] : [];
      const isSelectedAll = lastItem && lastItem.id === 0;

      let filteredValue;
      if (isSelectedAll) {
        // If 'All' is selected last, show only 'All'
        filteredValue = [lastItem];
      } else {
        // Filter out 'All' if other items are selected
        filteredValue = value.filter((item) => item.id !== 0);
      }

      setFolderData((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));

      return;
    }
    setFolderData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div
      className='file-pop-up '
      style={{
        [popUpPosition]: 0,
        top: '5px',
      }}
    >
      <div
        className={` filter-container  `}
        style={{
          padding: 20,
          width: 330,
          boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
          background: 'var(--bg-primary)',
        }}
      >
        <ErrorComponent error={error?.message} />
        <form onSubmit={handleSubmit}>
          <div className='filter-head'>
            <h6 className='menu-item-head'> {id ? 'Update' : 'Create'} Folder</h6>
          </div>
          <div className='filter-body'>
            <div className='row'>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  Folder Name<span className='field-required'> *</span>
                </label>
                <input
                  type='text'
                  className='input-box'
                  style={{ height: '34px' }}
                  name='name'
                  value={folderData?.name}
                  placeholder='Enter Folder name'
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                <ErrorBlock
                  errorMsg={'Folder name required'}
                  hasError={submitted && folderData?.name.toString().trim() === ''}
                />
              </div>
            </div>

            <SharedWith
              menuPlacement={shareWithListPlacement}
              onAdd={(userOrTeam, type) => {
                const name = type === 'user' ? 'shared_with_user' : 'shared_with_team';
                handleChange({
                  target: {
                    value: [...(folderData[name] ?? []), ...userOrTeam],
                    name: name,
                    type: 'shared_with',
                  },
                });
              }}
              onAvatarRemove={(userOrTeam, name) => {
                setFolderData((prev) => ({
                  ...prev,
                  [name]: prev[name].filter(
                    (item) => parseInt(item.id) !== parseInt(userOrTeam.id),
                  ),
                }));
              }}
              userImageList={folderData?.shared_with_user ? folderData?.shared_with_user : []}
              sharedUserSelect={sharedUserSelect}
              usersForShare={usersForShare}
              teamForShare={teamData}
              teamImageList={folderData?.shared_with_team ? folderData?.shared_with_team : []}
            />
          </div>

          <div className='row mt-3'>
            <div className='d-flex justify-content-end gap-2'>
              <Button.Container
                className={'cancel-btn'}
                type='button'
                handleOk={(event) => {
                  handleClose(event);
                }}
              >
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>

              <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
                <Tick />
                <Button.Title title={id ? 'Update' : 'Create'} />
                <Button.Loading isLoading={isLoading} />
              </Button.Container>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
