import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Pencil } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelectCreatable from 'react-select/creatable';
import { GlobalContext } from '../Utils';
import useDebounce from '../_helpers/useDebounce';
import { documentTypeService } from '../_services';
import { CommonDropDownStyle } from '../_constants';

export const CreatableEditableSelect = ({ documentTypeId, setDocumentTypeId }) => {
  const { makeAlert } = useContext(GlobalContext);
  const [newValue, setNewValue] = useState('');
  const [editFlag, setEditFlag] = useState(null);
  const [documentTypeSearch, setDocumentTypeSearch] = useState(''); // state for user list search
  const debouncedDocumentType = useDebounce(documentTypeSearch, 500);

  // document type list
  const {
    data: documentTypes,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['documentTypeList', debouncedDocumentType, documentTypeId],
    queryFn: () =>
      documentTypeService.documentTypeList({
        searchKey: documentTypeSearch,
        select: ['id', 'name'],
      }),
    select: (data) => data.data,
    placeholderData: keepPreviousData,
    enabled: !editFlag, // Disable fetching when in edit mode
  });
  const documentTypeData = documentTypes?.rows;
  const documentTypeCount = documentTypes?.count;

  const handleSubmitDocumentType = async (inputValue) => {
    let payload;
    if (newValue?.toString().trim() === '') {
      makeAlert(editFlag ? 'Updation failed' : 'Creation Failed');
      setEditFlag(null);
      return;
    }

    if (editFlag) {
      payload = { name: inputValue.toString().trim(), id: editFlag };
    } else {
      payload = { name: inputValue.toString().trim() };
    }
    try {
      const res = await documentTypeService.documentTypeAdd(payload);
      refetch();
      if (res.error_code === 0) {
        if (editFlag) {
          setDocumentTypeId(payload);
          makeAlert('Document Type updated');
        } else {
          setDocumentTypeId(res?.data);
          makeAlert('New Document Type added Successfully');
        }
        setEditFlag(null); // Turn off edit mode after successful update or creation
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptionClick = (item) => {
    setEditFlag(null);
    setDocumentTypeId(item);
  };

  const handleEditIconClick = (item) => {
    setNewValue(item?.name);
    setEditFlag(item?.id);
  };

  // Add event listener for Enter key
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === 'Enter' && editFlag) {
        handleSubmitDocumentType(newValue);
      }
    };

    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [editFlag, newValue]);

  return (
    <div>
      <ReactSelectCreatable
        options={editFlag ? [] : documentTypeData}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        inputValue={newValue}
        value={documentTypeId}
        onInputChange={(value) => {
          if (!editFlag) {

            setDocumentTypeSearch(value);
            setNewValue(value);
          } else {
            setDocumentTypeId(0);
            setNewValue(value);
          }
        }}
       
        styles={CommonDropDownStyle}
        onMenuClose={() => {
          setEditFlag(null);
        }}
        menuPlacement='top'
        onChange={(selectedOption) => handleOptionClick(selectedOption)}
        onBlur={() => setEditFlag(null)}
        menuIsOpen={editFlag ? true : undefined} // Keep the menu open in edit mode
        isLoading={isLoading}
        menuPortalTarget={document.getElementById('MODAL')}
        classNames={{ menuPortal: () => 'z-index-100 fz-14px' }}
        noOptionsMessage={() =>
          editFlag
            ? 'Type and press enter to save update of document type...'
            : documentTypeCount === 0
            ? 'Type and press enter to create new'
            : 'No options'
        } // Custom message for no options available
        onCreateOption={(inputValue) => handleSubmitDocumentType(inputValue)}
        formatOptionLabel={(data, metaData) => {
          if (metaData.context === 'value') {
            return data.name;
          }

          if (data?.__isNew__) {
            return `${
              editFlag
                ? ' Press enter to save updates of document type...'
                : 'Press enter to create new type'
            } `;
          }

          return (
            <>
              <div className={`selection-option `}>
                <span className='selection-option-first'>{data?.name}</span>
                <Pencil
                  size={15}
                  className='edit-icon cursor-pointer'
                  onClick={(event) => {
                    event.stopPropagation();
                    handleEditIconClick(data);
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
// if editflag is set, then while click space key- never select first element of list. using this code, while clicking space key then selecting first element of list. tht actually i not need
