import {
  Calendar,
  CalendarClock,
  GanttChartSquare,
  LineChart,
  Newspaper,
  NotepadText,
  PieChart,
} from 'lucide-react';
import React from 'react';
import { ChatIcon, ProjectIconSidebar } from '../../Utils/SvgIcons';
import { RT } from '../../_constants';

export const widgetIcons = {
  calendar: <Calendar color='var(--bg-primary)' size={18} />,
  upcoming_deadlines: <CalendarClock color='var(--bg-primary)' size={18} />,
  workload_statics: <PieChart color='var(--bg-primary)' size={18} />,
  graphical_report: <LineChart color='var(--bg-primary)' size={18} />,
  my_projects: (
    <ProjectIconSidebar
      mainColor='var(--bg-primary)'
      secondaryColor='var(--bg-primary)'
      size={18}
    />
  ),
  chats: <ChatIcon color='var(--bg-primary)' height={18} width={18} />,
  updates: <Newspaper color='var(--bg-primary)' size={18} />,
  task_statics: <NotepadText color='var(--bg-primary)' size={18} />,
  status_counts: <GanttChartSquare color='var(--bg-primary)' size={18} />,
};

export const layoutDropdownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('field-error');
    return {
      ...styles,

      // height: '34px',
      minHeight: '25px',
      height: '25px',
      backgroundColor: 'var(--bg-primary)',
      borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : 'transparent',
      boxShadow: 'var(--input-border-shadow)',
      padding: '0px 1px 0px 4px',
      borderRadius: '8px',
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      // height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
      color: '#7e7e7e',
      backgroundColor: 'var(--bg-primary)',
      // width:'fit-content'
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      width: '100%',
      color: '#7e7e7e',
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '600',
      color: '#7e7e7e',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
      color: '#7e7e7e',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: '25px',
      padding: '0px 8px',
    };
  },
  input: (styles) => {
    return {
      ...styles,
      padding: '0px',
    };
  },
};

export const getLink = (status, props, user) => {
  if (props?.userType === 'team') {
    return `${RT.TASK_LIST}?status=${
      status?.status_default_name ?? status?.default_name
    }&list_type=team-task&&${props?.template_id ? 'template_id=' + props?.template_id : ''}
    &&${props?.project_id ? 'project_id=' + props?.project_id : ''}`;
  }
  return `${RT.TASK_LIST}?status=${status?.status_default_name ?? status?.default_name}&&user_id=${
    user?.id
  }&list_type=my-task&&${props?.template_id ? 'template_id=' + props?.template_id : ''}
    &&${props?.project_id ? 'project_id=' + props?.project_id : ''}`;
};
