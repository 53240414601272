/* eslint-disable no-unused-vars */
import { X } from 'lucide-react';
import React from 'react';
import { createPortal } from 'react-dom';
import '../File/file.css';
import '../common.css';

const Container = ({ className, children, handleClose = () => {} }) => {
  const modalContent = document.getElementById('MODAL');
  return createPortal(
    <div
      className={`fade-opacity ${className ? className : 'model_contain'}`}
      // onClick={() => handleClose()}
    >
      {children}
    </div>,
    modalContent,
  );
};

const View = ({ children, className, containerClass, modelViewInnerClass, ...rest }) => {
  return (
    <div className={containerClass ?? 'view-center'}>
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`scale-up-center modal_view ${className ? className : ''}`}
        {...rest}
      >
        <div className={modelViewInnerClass ? modelViewInnerClass : 'modal_view_inner'}>
          {children}
        </div>
      </div>
    </div>
  );
};

const Head = ({ handleClose, Heading, containerClass, headerClass }) => {
  const heading = Heading || 'Add Project';
  const containerStyle = containerClass || 'modal_header';
  const headerStyle = headerClass || 'header-modal';
  return (
    <div className={containerStyle}>
      <div className={headerStyle}>{heading}</div>
      <X size={20} color='#9197a3' onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
    </div>
  );
};

const Description = ({ children, className = 'description-wrapper' }) => {
  return <div className={className}>{children}</div>;
};

export const Modal = {
  Container,
  View,
  Head,

  Description,
};
