import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { ErrorComponent } from '../_components/ErrorComponent';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { COMMON_USER_SELECT, CommonDropDownStyle, USER_LIST_ACTIONS } from '../_constants';
import { projectConstants } from '../_constants/project.constant';
import useDebounce from '../_helpers/useDebounce';
import { projectService, taskService, userService } from '../_services';
import { milestoneService } from '../_services/milestone.service';
import { statusService } from '../_services/status.service';
import { STATUS, TASK_TABS } from '../Task/taskConstants';
import TaskListView from '../Task/TaskListView';
import { formatString, jsonToUrlEncoded, taskSortPayload } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
// TODO: TEMP SOLUTION, NEED TO CHANGE TO API
// let taskBasedUserList = null;
const ProjectTaskList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = useParams().id;
  const [urlQuery, setULRQuery] = useState();

  const [milestoneSearch, setMilestoneSearch] = useState('');
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [isSortProcessed, setIsSortProcessed] = useState(false);
  // const [currentView, setCurrentView] = useState(TASK_TABS.TASKS);

  const currentTaskViewType = searchParams.get('currentView');

  const [currentView, setCurrentView] = useState(
    currentTaskViewType ? currentTaskViewType : TASK_TABS.TASKS,
  );

  useEffect(() => {
    if (currentTaskViewType) {
      setCurrentView(currentTaskViewType);
    } else {
      setCurrentView(TASK_TABS.TASKS);
    }
  }, [currentTaskViewType]);

  const debouncedMilestoneSearch = useDebounce(milestoneSearch, 500);
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'note',
      'created_time',
      'task_end_date',
      'order_seq',
      'status',
      'priority',
      'recent_chat_message',
      'progress_status',
      'label',
      'project_id',
      'milestone_id',
      'task_start_date',
      'created_time',
      'days_difference',
      'status_id',
      'status_group_item_id',
      'parent_id',
    ],
    // searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    is_duration_required: true,
  });

  const handleChange = (selectedOption) => {
    setSelectedMilestone(selectedOption);
    const updatedSearchParams = new URLSearchParams(searchParams);

    if (!selectedOption) {
      updatedSearchParams.delete('milestone_id');
    } else {
      updatedSearchParams.set('milestone_id', formatString(selectedOption.id));
    }

    setSearchParams(updatedSearchParams);
  };

  const { data: project, error: projectError } = useQuery({
    queryKey: ['project', id],
    queryFn: () =>
      projectService.projectGet({
        id: id,
        select: ['project_type', 'status'],
      }),
    select: (data) => {
      return data.data;
    },

    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const milestone = searchParams?.get('milestone_id');
    if (project?.in_progress_milestone && !milestone) {
      handleChange(project.in_progress_milestone);
    }
  }, [project?.in_progress_milestone]);

  if (searchParams.get('pageNo') && query?.pageVo?.pageNo !== searchParams.get('pageNo')) {
    setQuery({
      ...query,
      pageVo: { ...query.pageVo, pageNo: searchParams.get('pageNo') },
    });
  }

  const fetchMilestoneList = async () => {
    const data = await milestoneService.milestoneList({
      searchKey: milestoneSearch,
      select: ['id', 'name', 'progress_status'],
      project_id: id,
    });

    // const isMilestoneSelected = searchParams.get('milestone_id');

    // if (!milestone_id) {
    //   const startedMilestone =
    //     data?.data?.rows?.filter((prev) => parseInt(prev.progress_status) === 1) ?? null;

    //   if (startedMilestone.length > 0) {
    //     handleChange(startedMilestone[0]);
    //   }
    // }

    return data?.data?.rows;
  };

  const {
    data: milestoneData,
    error,
    isLoading: milestoneLoading,
  } = useQuery({
    queryKey: ['milestoneList', query?.pageVo?.pageNo, debouncedMilestoneSearch],
    queryFn: () => fetchMilestoneList(),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const milestoneIdFromUrl = searchParams.get('milestone_id');
    if (milestoneIdFromUrl && milestoneData) {
      const selected = milestoneData.find((m) => m.id === milestoneIdFromUrl);
      setSelectedMilestone(selected);
    } else {
      setSelectedMilestone(null);
    }
  }, [searchParams, milestoneData]);

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  const { ...restQuery } = query;
  const taskSearchKey = getParam('search') ? getParam('search') : '';
  const debouncedSearch = useDebounce(taskSearchKey, 500);

  const priority = getArrayParam('priority_id');
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam('label_id');
  const milestone_id = getParam('milestone_id');
  const template_id = getArrayParam('template_id');
  const sort = getArrayParam('sort');
  const assignee_id = getArrayParam('user_id');
  const task_status = parseInt(getParam('task_status'));
  const client_id = getArrayParam('client_id');

  // const status = getArrayParam('status');

  const filterToUrl = { project_id: id };
  if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;
  if (milestone_id) filterToUrl.milestone_id = milestone_id;
  if (template_id) filterToUrl.template_id = template_id;
  if (client_id) filterToUrl.client_id = client_id;
  if (assignee_id) filterToUrl.assignee_id = assignee_id;

  const filterCount =
    Object.keys(filterToUrl).filter(
      (key) => key !== 'project_id' && key !== 'milestone_id' && key !== 'assignee_id',
    ).length + (task_status === STATUS.ARCHIVE ? 1 : 0);

  const {
    data: taskStatusList,
    isLoading: statusLoading,
    refetch: taskStatusListRefetch,
  } = useQuery({
    queryKey: ['taskStatusList', filterToUrl, debouncedSearch, currentView],
    queryFn: () => {
      const countServiceFn = project.has_multiple_templates
        ? statusService.statusWithTaskCount
        : statusService.statusGroupItemsWithTaskCount;
      const additionalPayload = project.has_multiple_templates
        ? {}
        : { group_id: project?.template_details?.[0]?.status_group };
      return countServiceFn({
        select: ['id', 'name', 'color', 'default_name', 'icon'],
        task_filter: { ...filterToUrl, searchKey: debouncedSearch, project_id: id },
        ...additionalPayload,
      });
    },
    select: (data) => data.data,
    enabled:
      Boolean(project?.id) && currentView == TASK_TABS.TASKS && task_status !== STATUS.ARCHIVE,
    // &&
    // project?.in_progress_milestone
    //   ? Boolean(selectedMilestone)
    //   : true,
    placeholderData: keepPreviousData,
  });

  const defaultStatusName = searchParams.get('status');
  const selectedStatus = taskStatusList?.find(
    (status) =>
      status.default_name?.toString()?.toLowerCase() ===
      defaultStatusName?.toString()?.toLowerCase(),
  );

  useEffect(() => {
    const sortToUrl = taskSortPayload(sort);

    if (sortToUrl?.length > 0) {
      setQuery((prev) => ({
        ...prev,
        pageVo: { ...prev.pageVo, sort: sortToUrl },
      }));
    } else {
      setQuery((prev) => {
        // eslint-disable-next-line no-unused-vars
        const { sort, ...restPageVo } = prev.pageVo; // Destructure to remove 'sort' key
        return {
          ...prev,
          pageVo: restPageVo,
        };
      });
    }
    setIsSortProcessed(true); // Mark sort processing as done
  }, [JSON.stringify(sort)]);

  const sortCount = sort?.length;

  const queryKey = [
    'task',
    restQuery,
    query?.pageVo?.pageNo,
    debouncedSearch,
    selectedStatus?.id,
    filterToUrl,
    selectedStatus?.default_name,
    project?.has_multiple_templates,
    currentView,
    task_status,
  ];

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const {
    data: taskData,
    error: taskError,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    // isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      taskService.taskListAdvanced(
        getPayLoad({
          ...restQuery,
          status_id: task_status === STATUS.ARCHIVE ? -1 : selectedStatus?.id,
          default_name: task_status === STATUS.ARCHIVE ? 'all' : selectedStatus?.default_name,
          ...(project?.has_multiple_templates
            ? {}
            : {
                status_group_item_id: selectedStatus?.status_group_item_id,
                is_status_group_item: true,
              }),
          // is_status_group_item: project?.has_multiple_templates ? false : true,
          ...(selectedStatus?.is_verified?.toString()
            ? { is_verified: selectedStatus.is_verified }
            : {}),
          ...filterToUrl,
          ...(task_status ? { status: task_status } : {}),
          project_id: id,
          searchKey: debouncedSearch,
          pageVo: { pageNo: pageParam, noOfItems: 10 },
        }),
      ),
    enabled:
      Boolean(id) &&
      Boolean(project?.id) &&
      (!sort || isSortProcessed) &&
      Boolean(selectedStatus?.id) &&
      currentView == TASK_TABS.TASKS, // Enable query only if no sort or sort is processed

    placeholderData: keepPreviousData,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  // to fetch task user list
  const { data: projectUserList, refetch: userListRefetch } = useQuery({
    queryKey: ['task-list-user-list', id],
    queryFn: () =>
      userService.getRoleBasedUserList({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: COMMON_USER_SELECT,
        project_id: parseInt(id),
      }),
    select: (response) => response.data.rows,
    enabled: Boolean(id),
  });

  // to refetch after task add - task list and task status list
  const refetchData = () => {
    refetch();
    taskStatusListRefetch();
    userListRefetch();
  };

  if (statusLoading) {
    return (
      <div className='page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }
  const hasMultipleTemplate = project?.has_multiple_templates
    ? project?.has_multiple_templates
    : false;
  const hasRelatedTask = project?.has_related_tasks ? project?.has_related_tasks : false;

  const isSimpleTask = hasMultipleTemplate || hasRelatedTask ? true : false;

  return (
    <div style={{ fontSize: '13px' }}>
      <ErrorComponent error={error?.message || taskError?.message || projectError?.message} />

      {project?.project_type !== projectConstants.NONE && (
        <div className='task-list-milestone-select my-3'>
          <ReactSelect
            name='milestone_id'
            getOptionLabel={(option) => {
              return option?.name;
            }}
            isLoading={milestoneLoading}
            getOptionValue={(option) => {
              return option?.id;
            }}
            onInputChange={(inputString) => {
              setMilestoneSearch(inputString);
            }}
            menuPortalTarget={document.getElementById('MODAL')}
            isClearable
            options={milestoneData}
            onChange={handleChange}
            placeholder={
              'Select ' +
              (project?.project_type === projectConstants.MILESTONE ? 'Milestone' : 'Sprint')
            }
            components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
            styles={{
              ...CommonDropDownStyle,
              control: (styles, { isFocused }) => {
                return {
                  ...styles,
                  // height: '34px',
                  backgroundColor: 'var(bg-primary)',
                  width: '183px',
                  borderRadius: '8px',
                  borderColor: isFocused ? '#3454D1' : 'var(--input-border)',
                  boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
                  '&:hover': {
                    borderColor: isFocused
                      ? '#3454D1'
                      : styles['&:hover']?.borderColor || styles.borderColor,
                  },
                };
              },
            }}
            value={selectedMilestone}
          />
        </div>
      )}
      <TaskListView
        query={query}
        // key={'project-task-list' + status}
        setQuery={setQuery}
        taskData={taskData}
        userList={projectUserList}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={isLoading}
        isSuccess={isSuccess}
        taskStatusList={taskStatusList}
        filterCount={filterCount}
        sortCount={sortCount}
        afterTaskAddFn={() => {
          const todoStatus = taskStatusList?.find((item) => {
            if (item.status_default_name) {
              return item.status_default_name === 'todo';
            } else {
              return item.default_name === 'todo';
            }
          });
          searchParams.set('status', todoStatus?.status_group_item_id ?? todoStatus?.default_name);
          setSearchParams(searchParams);
        }}
        projectId={id}
        milestoneId={milestone_id}
        refetch={refetchData}
        assignee_id={assignee_id ?? null}
        noTaskAdd={
          project?.status === projectConstants.IN_ACTIVE || selectedMilestone?.progress_status === 2
            ? false
            : true
        }
        actInlineAsSimple={isSimpleTask}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        urlQuery={urlQuery}
        currentView={currentView}
        setCurrentView={setCurrentView}
        clientDetails={project?.client_details?.id ? project?.client_details : null}
        isArchivedList={task_status === STATUS.ARCHIVE}
      />
    </div>
  );
};

export default ProjectTaskList;
