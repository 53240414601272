import { useContext, useEffect } from 'react';
import { GlobalContext } from '../Utils';
// Adjust this import based on your project structure

function useErrorAlert(error) {
  const { makeAlert } = useContext(GlobalContext);

  useEffect(() => {
    if (error) {
      makeAlert(error);
    }
  }, [error, makeAlert]);

  return { makeAlert };
}

export default useErrorAlert;
