import { useQuery } from '@tanstack/react-query';
import { Home } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from '../_components/DatePicker';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import UserDropDown from '../_components/UserDropDown';
import { COMMON_USER_SELECT, CommonDropDownStyle, RT, USER_LIST_ACTIONS } from '../_constants';
import { userService } from '../_services';
import { reportServices } from '../_services/reports.service';
import { useUser } from '../Hook/useUser';
import {
  formatMillisecondFeatured,
  getHourFormMilliSecond,
  GlobalContext,
  MyTeamIcon,
} from '../Utils';
import { ProgressIcon, User } from '../Utils/SvgIcons';
import { BarChartReport } from './BarChartReport';
import { PieChartReport } from './PieChartReport';
import { GRAPHICAL_REPORT_TYPE } from './Report.constant';
import { CustomSingleValue } from './TimeHistory';

export function GraphicalReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState(useUser());
  const { roleBasedPermission } = useContext(GlobalContext);

  const toDate = searchParams.get('toDate');
  const fromDate = searchParams.get('fromDate');
  const user_id = searchParams.get('user_id');

  const navigate = useNavigate();
  const GRAPH_TABS = [
    { name: 'Bar', value: GRAPHICAL_REPORT_TYPE.BAR },
    { name: 'Pie', value: GRAPHICAL_REPORT_TYPE.PIE },
  ];

  const activePath = (path) => location.pathname.includes(path);
  const isActive = (name) => {
    if (name === GRAPHICAL_REPORT_TYPE.BAR) {
      return activePath(`${RT.BAR_CHART}`);
    } else if (name === GRAPHICAL_REPORT_TYPE.PIE) {
      return activePath(`${RT.PIE_CHART}`);
    }
    return false;
  };

  // handle to set params like user_id
  const handleSetSearchParams = (name, value) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (value) {
      updatedSearchParams.set(`${name}`, value);
    } else {
      updatedSearchParams.delete(name);
    }
    setSearchParams(updatedSearchParams);
  };

  // handle change of date
  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day');
    const endDate = moment(dates.endDate).endOf('day');
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('fromDate', startDate.valueOf());
    updatedSearchParams.set('toDate', endDate.valueOf());
    setSearchParams(updatedSearchParams);
  };

  // fetch user Data assignee's with current user
  const { data: userData } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: COMMON_USER_SELECT,
        action: USER_LIST_ACTIONS.ACTION_REPORT_USER_LIST,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      return userData;
    },
  });

  // fetch user Data assignee's with current user
  const { data: report } = useQuery({
    queryKey: ['graph-report', user_id, fromDate, toDate],
    queryFn: () =>
      reportServices.graphicalTaskReportList({
        user_id,
        fromDate,
        toDate,
      }),
    enabled: roleBasedPermission?.ACTION_GRAPHICAL_REPORT,

    select: (data) => {
      const result = data?.data;

      // data for pie graph
      const totalReportInfo = {
        estimatedHour: result.totalEstimatedHoursOfUser
          ? Number(result.totalEstimatedHoursOfUser.toFixed(2))
          : result.totalEstimatedHoursOfUser,
        effort: result.totalEffortOfUser
          ? getHourFormMilliSecond(result.totalEffortOfUser, true)
          : 0,
      };

      // data for bar chat
      const reportData = data?.data?.data;

      const finalData = reportData.map((item) => {
        return {
          ...item,
          sum_of_effort: formatMillisecondFeatured(item.total_effort),
          total_effort_in_hour: getHourFormMilliSecond(item.total_effort, true),
        };
      });

      const updatedFinalDate = {
        finalData,
        totalReportInfo,
      };

      return updatedFinalDate;
    },
  });

  if (!roleBasedPermission?.ACTION_GRAPHICAL_REPORT) {
    return <NoPermission />;
  }

  return (
    <div className='fade-entry'>
      <section>
        <div className='my-team url-container'>
          <div className='d-flex align-items-center breadcrumbs'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home size={16} />}
              onClick={() => navigate(`/`)}
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <IconLabelSet
              label={'Graphical Report'}
              color={'#2A2E34'}
              fontWeight={500}
              icon={<ProgressIcon color='var(--icon-light)' height={15} width={14} />}
              onClick={() =>
                navigate(
                  `/${RT.GRAPH_REPORT}/${RT.BAR_CHART}?user_id=${searchParams.get(
                    'user_id',
                  )}&fromDate=${searchParams.get('fromDate')}&toDate=${searchParams.get('toDate')}`,
                )
              }
            />
          </div>
        </div>
      </section>

      <section>
        <div className='time-log-container page-container'>
          <div className='page-head mb-3' style={{ fontSize: 20 }}>
            Graphical Report
          </div>

          {/* tab section */}
          <section className='position-relative'>
            <div className='file-tab-line' style={{ top: 30 }} />
            <div className='d-flex gap-3 my-2 flex-wrap'>
              {GRAPH_TABS.map((item, index) => (
                <div
                  key={index}
                  style={{ zIndex: '2', fontSize: 13 }}
                  className={`${isActive(item.value) ? 'tab_active ' : 'tab_inactive'}`}
                  onClick={() => {
                    // to={`${RT.GRAPH_REPORT}/${RT.BAR_CHART}?user_id=${user}&fromDate=${monthStartDate}&toDate=${todayCurrent}`}

                    if (item.value === GRAPHICAL_REPORT_TYPE.BAR)
                      navigate(
                        `${RT.BAR_CHART}?user_id=${searchParams.get(
                          'user_id',
                        )}&fromDate=${searchParams.get('fromDate')}&toDate=${searchParams.get(
                          'toDate',
                        )}`,
                      );
                    else if (item.value === GRAPHICAL_REPORT_TYPE.PIE)
                      navigate(
                        `${RT.PIE_CHART}?user_id=${searchParams.get(
                          'user_id',
                        )}&fromDate=${searchParams.get('fromDate')}&toDate=${searchParams.get(
                          'toDate',
                        )}`,
                      );
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </section>

          <div className='d-flex gap-2 my-3'>
            <div style={{ width: 'fit-content' }}>
              <SearchableQuerySelect
                queryKey={'graphical-report-user-list'}
                queryFn={userService.getRoleBasedUserList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  action: USER_LIST_ACTIONS.ACTION_REPORT_USER_LIST,
                  select: COMMON_USER_SELECT,
                })}
                isCustomFunction
                initialValue={userData}
                menuPortalTarget={document.getElementById('MODAL')}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                )}
                getOptionValue={(option) => option.id}
                onChange={(selectedOptions) => {
                  setSelectedUser(selectedOptions);
                  handleSetSearchParams('user_id', selectedOptions?.id);
                }}
                styles={CommonDropDownStyle}
                components={{
                  SingleValue: (props) => (
                    <CustomSingleValue {...props} icon={<User color='var(--icon-light)' />} />
                  ),
                  DropdownIndicator: CustomDropdownIndicator,
                }}
                placeholder={
                  <div className='d-flex align-items-center gap-1'>
                    <User color='#07082F' /> User
                  </div>
                }
                value={selectedUser}
              />
            </div>
            <DatePicker
              inputContainerClass={'date-picker'}
              isDatePrefixNeed={false}
              onDateChange={handleDateChange}
              initialStartDate={parseInt(searchParams.get('fromDate'))}
              initialEndDate={parseInt(searchParams.get('toDate'))}
              isEditable
              monthFormat='short'
              showLabel={true}
            />
          </div>

          <section>
            {activePath(`${RT.BAR_CHART}`) && <BarChartReport reportData={report?.finalData} />}
            {activePath(`${RT.PIE_CHART}`) && (
              <PieChartReport reportData={report?.totalReportInfo} />
            )}
          </section>
        </div>
      </section>
    </div>
  );
}
