/* eslint-disable no-unused-vars */
import { Dot } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NOTIFICATION_CONTENT, NOTIFICATION_STATUS, RT } from '../_constants';
import { TruncatedText } from '../Dashboard/TruncatedText';
import { getTimeDifference } from '../Task/taskValidation';
import { Images } from '../Utils';
import NoPermission from '../_components/NoPermission';

export function ReminderNotification({ data }) {
  return (
    <>
      {data?.length === 0 ? (
        <NoPermission data='No reminders available' />
      ) : (
        data?.map((item, index) => {
          return (
            <>
              <ReminderItem data={item} />
            </>
          );
        })
      )}
    </>
  );
}

const ReminderItem = ({ data }) => {
  const navigate = useNavigate();
  const isUnRead =
    data?.is_read === NOTIFICATION_STATUS.VIEWED || data?.is_read == NOTIFICATION_STATUS.UNREAD;

  const time = getTimeDifference(parseInt(data?.updated_time));

  const getReminderData = (dataObj, value) => {
    let notificationHeading = 'New Reminder';
    let notificationBody = '...';
    let navLink = '/';

    if (value === NOTIFICATION_CONTENT.HEADING) {
      notificationHeading = dataObj?.Reminder?.title;

      return notificationHeading;
    } else if (value === NOTIFICATION_CONTENT.CONTENT) {
      notificationBody = `${dataObj?.note}`;
      return notificationBody;
    } else if (value === NOTIFICATION_CONTENT.NAV_LINK) {
      if (dataObj?.task_id) {
        // navLink = `/${RT.TASK}/${dataObj?.task_id}?tab=reminders`;
        navLink = `/${RT.TASK}/${dataObj?.task_id}?tab=reminders&status=${dataObj?.Task?.Status?.default_name}&list_type=team-task&&nextAndPrevFromNotificationListForReminder=true&&isUnRead=${isUnRead}&&notification_id=${data?.id}&&currentIndex=0`;
      }
      return navLink;
    }
  };

  const notificationHeading = getReminderData(data, NOTIFICATION_CONTENT.HEADING);
  const notificationBody = getReminderData(data, NOTIFICATION_CONTENT.CONTENT);
  const navigationLink = getReminderData(data, NOTIFICATION_CONTENT.NAV_LINK);

  const navigateToTask = () => {
    if (navigationLink) {
      navigate(navigationLink);
      // setIsNotificationVisible(false);
    }
  };

  return (
    <>
      <div className='nt-status-card pointer' onClick={() => navigateToTask(data)}>
        <div>
          <img src={Images.reminder} style={{ height: 38, width: 38 }} />
        </div>

        <div className='nt-status-content '>
          {isUnRead && (
            <Dot
              size={30}
              style={{
                position: 'absolute',
                right: 25,
              }}
              color='blue'
            />
          )}
          <TruncatedText className={'main-text'} text={notificationHeading} />
          <div className='nt-message-content'>
            <div className='nt-icon-container'>
              <span className='nt-message-text'>
                <TruncatedText className={'nt-update-message'} text={notificationBody} />
              </span>
            </div>
            <div className='nt-timestamp'>{time}</div>
          </div>
        </div>
      </div>
    </>
  );
};
