import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const workflowService = {
  getWorkflowList,
  getSingleWorkflow,
  addWorkflow,
  deactivateWorkflow,
  getWorkflowStepList,
  addWorkflowSteps,
  updateWorkflowStepPosition,
  getWorkflowProcessList,
};

async function getWorkflowList(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-list-advanced`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getWorkflowProcessList(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-process-list-advanced`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getSingleWorkflow(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.wfApiUrl}/get-workflow?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function addWorkflow(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-add`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
async function updateWorkflowStepPosition(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-step-position-update`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function deactivateWorkflow(payload) {
  const apiUrl = `${apiConstants.apiUrl}/workflow_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getWorkflowStepList(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-step-list`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function addWorkflowSteps(payload) {
  const apiUrl = `${apiConstants.wfApiUrl}/workflow-step-add`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
