import React, { useState } from 'react';
import { Button, Modal } from '../_components';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { Images } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const InvoiceDeleteConfirmation = ({
  setDeleteModal,
  handleMutate,
  // eslint-disable-next-line no-unused-vars
  makeAlert,
  isPending = false,
  editInvoiceId,
}) => {
  const [paymentDelete, setPaymentDelete] = useState(false);

  const handleInputChange = () => {
    setPaymentDelete(!paymentDelete);
  };

  const handleDeleteButton = () => {
    // Check if the input text is exactly "confirm task delete" in lowercase
    // if (inputText.trim() === 'confirm task delete') {
    handleMutate(editInvoiceId?.id, paymentDelete);
    // } else {
    //   makeAlert('Please enter "confirm task delete" to confirm deletion in lowercase.');
    // }
  };

  return (
    <Modal.Container>
      <Modal.View className={'delete-container'}>
        <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
        <Modal.Description className='delete-content'>
          <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
          <div className='del-description'>
            {editInvoiceId?.invoice_status === 1
              ? DELETE_NOTE.Invoice
              : 'You are already paid on this payment. Are you certain you wish to delete this invoice? This action is irreversible.'}
          </div>
          {editInvoiceId?.invoice_status !== 1 && (
            <div className='status-check-wrap'>
              <input
                type='checkbox'
                name='payment_delete'
                className='menu_check_box'
                value={paymentDelete}
                onChange={handleInputChange}
              />
              <label className='fz-13px-rem'>
                Do you also want to delete the related payments? 
              </label>
            </div>
          )}
        </Modal.Description>
        <div className='modal_footer'>
          <Button.Container handleOk={() => setDeleteModal(false)} className={'status-del-cancel'}>
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>
          <Button.Container handleOk={() => handleDeleteButton()} isDisabled={isPending}>
            <Tick />
            <Button.Title title={'Delete'} />
          </Button.Container>
        </div>
      </Modal.View>
    </Modal.Container>
  );
};
