import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import useDebounce from '../_helpers/useDebounce';

/***
 * @callback FormalLabelCallBack
 * @param data - The option data
 * @param metadata - THe metadata related to the react select
 */
/***
 * @callback GetCallBack
 * @param option - The option data
 */
/***
 * @callback GetFilterCallback
 * @param user - The user object
 */

/**
 * @callback QueryFnCB
 * @param {Object} param - The object created from getFunctionParams
 */

/***
 * @callback OnChangeCallback
 * @param {any} selectedOption - The selected option
 */

/***
 * Use this if you need to use multiple react select with same useQuery.
 * Ie. if you need a userList for two select and manage search for each.
 * THis will have separate search function, you don't have to add search key on initial call.
 * This will not have search conflict with other react select data.
 * @param {object} props
 * @param {string} props.queryKey - The key used for querying the function
 * @param {functionName?} props.queryFn - The function to be called on search
 * @param {(searchKey:string)=>{}?} props.getParamsFn - A function to generate the function params
 * @param {GetCallBack} props.getOptionLabel - For get the label from the list
 * @param {GetCallBack} props.getOptionValue - FOt getting teh select value from the list
 * @param {FormalLabelCallBack} props.formatOptionLabel - For formatting the select list
 * @param {GetFilterCallback | null} props.getFilteredSearchValues = For filtering the searched user list
 * @param {OnChangeCallback} props.onChange - Will trigger on value change
 * @param {boolean} props.queryEnabled - Will enabled useQuery based on this flag
 * @param {boolean} props.fetchValueFromResult - For resolving the value from the list itself, if you only have the key value in the object
 * @param {import("react-select").StylesConfig<any, false, import("react-select").GroupBase<any>>} props.styles
 * @param {import("react-select").ClassNamesConfig<any, false, import("react-select").GroupBase<any>>} props.classNames
 * @param {Partial<import("react-select/dist/declarations/src/components").SelectComponents<any, false, import("react-select").GroupBase<any>>>} props.components
 * @returns {React.ReactNode} - returns a react node
 */
function SearchableInfiniteQuerySelect({
  // query,
  // optionList = [],
  queryKey = 'unidentifiedKey',
  queryFn = () => {},
  getParamsFn = (searchKey) => ({
    searchKey,
  }),
  getFilteredSearchValues = null,
  select = null,
  itemToAdd = null,
  queryEnabled = true,
  classNames = {},
  fetchValueFromResult = false,
  ...restProps
}) {
  const [searchKey, setSearchKey] = useState('');
  const debouncedSearchKey = useDebounce(searchKey, 500);
  const params = getParamsFn(debouncedSearchKey);

  const queryKeyArray = typeof queryKey === 'string' ? [queryKey] : queryKey;

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryFn: ({ pageParam = 1 }) => queryFn(params, pageParam),
    queryKey: [...queryKeyArray, debouncedSearchKey],
    select: (data) => data.pages,
    getNextPageParam: (lastPage) =>
      lastPage?.data?.page < lastPage?.data?.pages ? lastPage?.data?.page + 1 : undefined,
    enabled: queryEnabled,
  });

  const allItems = React.useMemo(
    () => data?.flatMap((page) => (select ? select(page) : page?.data?.rows)),
    [data],
  );

  const searchData = allItems && itemToAdd ? [itemToAdd, ...allItems] : allItems;

  const options = getFilteredSearchValues ? searchData.filter(getFilteredSearchValues) : searchData;

  const handleBottomReach = () => {
    hasNextPage && fetchNextPage();
  };

  const fetchedValue =
    fetchValueFromResult && restProps.getOptionValue && !isLoading
      ? restProps.value
        ? options.find((item) => restProps.value === restProps.getOptionValue(item))
        : null
      : undefined;

  return (
    <ReactSelect
      {...restProps}
      value={fetchValueFromResult ? fetchedValue : restProps?.value}
      options={options}
      openMenuOnClick
      onMenuScrollToBottom={handleBottomReach}
      isLoading={isFetching}
      classNames={{
        menuPortal: () => 'z-index-100 fz-14px react-select-portal',
        ...classNames,
      }}
      filterOption={null}
      inputValue={searchKey}
      onInputChange={(inputString) => setSearchKey(inputString)}
    />
  );
}

export default SearchableInfiniteQuerySelect;
