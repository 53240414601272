/* eslint-disable no-unused-vars */
import { ChevronDown, Settings } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../Utils';
import { MENU, RT } from '../_constants';
export function SettingsLayout() {
  const { globalSettings, getMenuLabelName, roleBasedPermission } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const [settingsTabs, setSettingsTabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [toggleSettings, setToggleSettings] = useState(true);
  const [activeTab, setActiveTab] = useState({
    name: '',
    subname: '',
  });
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks which dropdown is open
  const sectionRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSettings = () => {
      const generalSettings =
        globalSettings &&
        globalSettings.length &&
        globalSettings.find((settings) => settings?.name === 'general-settings');

      const menuSettings =
        globalSettings &&
        globalSettings.length &&
        globalSettings.find((settings) => settings?.name === 'menu-settings');

      const labelSettings =
        generalSettings?.labels.length &&
        generalSettings.labels.find((settings) => settings?.label === 'Label');

      const projectSettings =
        menuSettings?.labels.length &&
        menuSettings.labels.find((settings) => settings?.name === LABEL_OF_PROJECT);

      const SETTINGS_TABS = [
        {
          name: 'General',
          id: 'general',
          path: RT.GENERAL,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Menu',
          id: 'menu',
          path: RT.MENU,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: `${LABEL_OF_PROJECT}`,
          id: 'project',
          path: RT.PROJECT,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Task',
          id: 'task',
          path: `${RT.TASK}?id=additional_tabs`,
          subLinks: [
            {
              name: 'additional_tabs',
              label: 'Additional tabs',
              path: `${RT.TASK}?id=additional_tabs`,
            },
            {
              name: 'default_status',
              label: 'Default Status',
              path: `${RT.TASK}?id=default_status`,
            },
            { name: 'priority', label: 'Priority', path: `${RT.TASK}?id=priority` },
          ],
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Employee',
          id: 'employee',
          path: RT.EMPLOYEE_SETTINGS,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'User',
          id: 'team',
          path: `${RT.TEAM}/${RT.USER_MANAGEMENT}?id=${RT.USER_MANAGEMENT}&&status=active-users&pageNo=1`,
          subLinks: [
            {
              name: 'user-management',
              label: 'List',
              // path: `${RT.USER_MANAGEMENT}?status=active-users&pageNo=1`,
              path: `${RT.TEAM}/${RT.USER_MANAGEMENT}?id=${RT.USER_MANAGEMENT}&&status=active-users&pageNo=1`,
            },
            {
              name: 'user_group',
              label: 'Group',
              path: `${RT.TEAM}/${RT.TEAM}?id=user_group`,
            },
            // { name: 'team', label: 'Team', path: `${RT.TEAM}/${RT.MY_TEAM_LIST}?id=team` },
          ],
          disableCondition: roleBasedPermission?.ACTION_USER_MANAGE,
        },
        {
          name: 'Chat',
          id: 'chat',
          path: 'chat',
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Entity',
          id: 'entity',
          path: `${RT.WORK_SPACE}/${RT.ORGANIZATION}?id=organization`,
          subLinks: [
            {
              name: 'organization',
              label: 'Organization',
              path: `${RT.WORK_SPACE}/${RT.ORGANIZATION}?id=organization`,
            },
            {
              name: 'department',
              label: 'Department',
              path: `${RT.WORK_SPACE}/${RT.DEPARTMENT}?id=department`,
            },
            {
              name: 'designation',
              label: 'Designation',
              path: `${RT.WORK_SPACE}/${RT.DESIGNATION}?id=designation`,
            },
          ],
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Label',
          id: 'label',
          path: RT.LABEL,
          disableCondition: roleBasedPermission?.ACTION_USER_MANAGE,
        },
        {
          name: 'Role',
          id: 'role',
          path: RT.ROLE,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },

        {
          name: 'Permission',
          id: 'permission',
          path: RT.ADVANCED_PERMISSION,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Theme',
          id: 'theme',
          path: RT.THEME,
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Configuration',
          id: 'config',
          path: `${RT.CONFIG}/${RT.GMAIL}?id=${RT.GMAIL}&&sub=smtp&&api_tab=headers`,
          subLinks: [
            {
              name: 'gmail',
              label: 'Gmail',
              path: `${RT.CONFIG}/${RT.GMAIL}?id=${RT.GMAIL}&&sub=smtp&&api_tab=headers`,
            },
            {
              name: 'whatsapp',
              label: 'WhatsApp',
              path: `${RT.CONFIG}/${RT.WHATSAPP}?id=${RT.WHATSAPP}&&sub=headers`,
            },
          ],
          disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
        },
        {
          name: 'Invoice',
          id: 'invoice',
          path: RT.INVOICE,
          disableCondition: roleBasedPermission?.ACTION_MANAGE_INVOICE,
        },
      ];

      if (!labelSettings?.value) {
        SETTINGS_TABS.splice(8, 1);
      }
      if (!projectSettings?.value) {
        SETTINGS_TABS.splice(2, 1);
      }

      setSettingsTabs(SETTINGS_TABS);
      setLoading(false);

      // Set openDropdown based on the URL
      const queryParams = new URLSearchParams(location.search);
      const currentId = queryParams.get('id'); // Extract 'id' parameter from the URL
      const formatPathname = (pathname) => {
        return pathname
          .replace('/settings/', '') // Remove /settings/
          .split('/') // Split into words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize
          .join(' / '); // Join with " / "
      };
      const currentPathname = formatPathname(window?.location?.pathname);
      //init
      console.log({ queryParams });

      setActiveTab({ name: currentPathname, subname: '' });
      if (currentId) {
        const matchingTab = SETTINGS_TABS.find(
          (tab) =>
            tab.subLinks?.some((subLink) => subLink.name === currentId) || tab.id === currentId,
        );

        if (matchingTab) {
          setOpenDropdown(matchingTab.id);
        }
      }
    };

    fetchSettings();
  }, [globalSettings, LABEL_OF_PROJECT]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    // <SettingsProtectedRoute>
    <>
      <section
        className='settings-toggle-container'
        onClick={() => setToggleSettings(!toggleSettings)}
      >
        <div className={`settings-toggler ${toggleSettings ? 'rotate-left' : 'rotate-right'}`}>
          <Settings size={20} />
        </div>
        {`${activeTab?.name}${activeTab?.subname ? ' / ' + activeTab?.subname : ''}`}
      </section>
      <div className='settings-page sub_padding-settings fade-entry'>
        <div className='d-flex gap-2'>
          {/* Tabs Section */}
          <section
            className={`settings_head layout-head ${toggleSettings ? 'head-visible' : 'head-none'}`}
          >
            {settingsTabs.map((tab, index) => (
              <NavLinkNavigation
                key={tab.id}
                index={index}
                tab={tab}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown} // Pass setOpenDropdown function
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setToggleSettings={setToggleSettings}
              />
            ))}
          </section>

          {/* Sections */}
          <div
            className={`sections ${toggleSettings ? 'expand-part' : 'full-width'}`}
            id='settings-content-section'
          >
            <section className='section'>
              <Outlet />
            </section>
          </div>
        </div>
      </div>
    </>
    // </SettingsProtectedRoute>
  );
}

const NavLinkNavigation = ({
  tab,
  index,
  openDropdown,
  setOpenDropdown,
  setActiveTab,
  setToggleSettings,
}) => {
  const isDropdownOpen = openDropdown === tab.id;
  const { roleBasedPermission } = useContext(GlobalContext);

  const handleTabClick = () => {
    setOpenDropdown(isDropdownOpen ? null : tab.id); // Toggle the dropdown for this tab
  };

  const handleSubLinkClick = (task_tab) => {
    const scrollElement = document.getElementById(task_tab.name);
    if (scrollElement) {
      scrollElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section>
      <NavLink
        key={index}
        className={({ isActive }) =>
          `settings_tab ${
            (isActive || isDropdownOpen) && tab?.disableCondition
              ? 'settings_tab_active'
              : 'settings_tab_inactive'
          }   ${!tab?.disableCondition ? 'cursor_no_drop_settings_tab settings_tab_inactive' : ''}`
        }
        to={`/settings/${tab.path}`}
        onClick={(e) => {
          setActiveTab({
            name: tab?.name,
          });
          if (!tab?.disableCondition) {
            e.preventDefault(); // Prevent navigation
          } else {
            handleTabClick();
            setActiveTab({
              name: tab?.name,
              subname: tab?.subLinks?.[0]?.label,
            });
          }
          setToggleSettings(false);
        }}
      >
        {tab.name}
        {tab?.subLinks?.length && <ChevronDown className='arrow' size={14} />}
      </NavLink>
      {isDropdownOpen && tab?.subLinks?.length && (
        <div>
          {tab.subLinks.map((task_tab) => (
            <div key={task_tab.name}>
              <NavLink
                aria-disabled={!roleBasedPermission?.ACTION_ORG_MANAGE}
                className={`settings_tab ps-3 ${
                  location.search.includes(`id=${task_tab.name}`)
                    ? 'settings_tab_active'
                    : 'settings_tab_inactive'
                }`}
                onClick={() => {
                  setActiveTab((prev) => ({
                    ...prev,
                    subname: task_tab?.label,
                  }));
                  const scrollElement = document.getElementById(task_tab.name);
                  const parentElement = document.getElementById('settings-content-section');
                  setToggleSettings(false);

                  if (scrollElement && parentElement) {
                    requestAnimationFrame(() => {
                      const elementPosition =
                        scrollElement.getBoundingClientRect().top -
                        parentElement.getBoundingClientRect().top;
                      parentElement.scrollTo({
                        top: elementPosition,
                        behavior: 'smooth',
                      });
                    });
                  }
                }}
                // to={`/settings/${tab.path}?id=${task_tab.name}`}
                to={task_tab?.path}
              >
                {task_tab.label}
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

const getNameAndSubName = () => {};
