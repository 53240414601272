import { AlarmClock, FileText, HomeIcon, UserRoundX } from 'lucide-react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IconLabelSet from '../_components/IconLabelSet';
import { RemindersAddWithList } from './RemindersAddWithList';

function ReminderList() {
  const currentUser = JSON.parse(localStorage.getItem('user'))?.id;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<HomeIcon size={13} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={`Reminder`}
            fontWeight={500}
            icon={<FileText size={13} />}
            color={'#2A2E34'}
          />
        </div>
      </div>
      <section className='task_tabs'>
        <div className='task_tab_contain '>
          <div
            className={` ${searchParams.get('tab') == 'alerts' ? 'tab_active' : 'tab_inactive'}`}
            onClick={() => setSearchParams({ tab: 'alerts' })}
          >
            <IconLabelSet
              label={'Alerts'}
              color={`${searchParams.get('tab') == 'alerts' ? 'black' : 'grey'}`}
              icon={<AlarmClock size={18} strokeWidth={1.5} />}
            />
          </div>
          <div
            className={`${searchParams.get('tab') == 'logs' ? 'tab_active' : 'tab_inactive'}`}
            onClick={() => setSearchParams({ tab: 'logs' })}
          >
            {' '}
            <IconLabelSet
              label={' Logs'}
              color={`${searchParams.get('tab') == 'logs' ? 'black' : 'grey'}`}
              icon={<UserRoundX size={16} />}
            />
          </div>
        </div>
      </section>
      <div className='reminder-container pt-3'>
        <RemindersAddWithList
          isHeading={false}
          isListView
          userId={currentUser}
          tab={searchParams.get('tab')}
        />
      </div>
    </div>
  );
}

export default ReminderList;
