import { MessageSquareDot, Plus, Search } from 'lucide-react';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatString } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { CHAT_TABS, CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import ChatCreationPopUp from './ChatCreationPopUp';
import ChatUserRow from './ChatUserRow';

export function ChatList({
  updates,
  observerRef,
  searchUserList,
  searchKey,
  onSearch,
  isActive = () => false,
  isTabActive = () => false,
  isLoading,
  isError,
  error,
  onGroupCreation,
  onUserClick,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [chatCreationPopUpVisible, setChatCreationPopUpVisible] = useState(false);

  return (
    <div
      className='chat_list_condainer pt-3 d-flex flex-column'
      style={{ height: `calc(100vh - var(--top-bar-height))` }}
    >
      <div className='d-flex justify-content-between update-padding'>
        {' '}
        <div className='heading fz-18px'>Chats</div>
        <div
          className='d-flex gap-2 position-relative'
          onClick={() => setChatCreationPopUpVisible(true)}
        >
          <div className='add_item_button add-btn-chat'>
            <Plus size={16} color='#000' />
          </div>
          {chatCreationPopUpVisible && (
            <ChatCreationPopUp
              onGroupCreation={onGroupCreation}
              onClose={(event) => {
                event && event.stopPropagation();

                setChatCreationPopUpVisible(false);
              }}
            />
          )}
        </div>
      </div>
      <div className='update-padding'>
        <section className='organization-layout header-border-bottom'>
          <div className='org-nav-contain'>
            {CHAT_TABS.map((tab, index) => {
              return (
                <div
                  key={index}
                  className={`${isTabActive(tab.path) ? 'tab_active' : 'tab_inactive'}`}
                  onClick={() => {
                    const updatedSearchParams = new URLSearchParams(searchParams);
                    updatedSearchParams.set('tab', formatString(tab.path));
                    updatedSearchParams.delete('id');
                    setSearchParams(updatedSearchParams);
                  }}
                >
                  {tab.name}
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <div className='d-flex justify-content-between gap-2 mt-3 update-padding'>
        <div className={'search_contain ' + (searchKey ? 'active' : '')} style={{ flex: 1 }}>
          <Search size={20} color='black' />
          <input
            type='text'
            value={searchKey}
            onChange={(event) => onSearch(event.target.value)}
            className='bodr_none'
            placeholder='Search...'
            style={{ flex: 1 }}
          />
        </div>
        <div className='list_top_card '>
          <MessageSquareDot size={15} />
        </div>
      </div>
      <div className='list_condainer update-padding' style={{ flex: 1 }}>
        <LoaderWrapper isLoading={isLoading} hasError={isError || !updates} error={error}>
          <>
            <div className='px-2 pb-2 sticky-head'>
              <div className='chat_msg fz-14px'>Messages</div>
            </div>
            {updates.map((update, key) => {
              return (
                <ChatUserRow
                  update={update}
                  key={key}
                  active={() => isActive(update)}
                  onClick={(update) => {
                    const updatedSearchParams = new URLSearchParams(searchParams);
                    updatedSearchParams.set('id', formatString(update.id));
                    setSearchParams(updatedSearchParams);
                  }}
                />
              );
            })}
          </>
        </LoaderWrapper>
        <SearchUserList
          list={
            searchUserList
              ? searchUserList.map((item) => ({
                  ...item,
                  User: item,
                  update_type: CHAT_UPDATE_TYPE.PERSONAL,
                }))
              : []
          }
          onUserClick={onUserClick}
          searchKey={searchKey}
        />
        <div ref={observerRef} style={{ height: 10, width: '100%' }} />
      </div>
    </div>
  );
}

const LoaderWrapper = ({ children, isLoading, hasError, error }) => {
  if (isLoading) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `calc(100vh - var(--tob-bar-height))` }}
      >
        <LoadingIcon size={50} />
      </div>
    );
  }

  if (hasError) {
    return error.message ?? 'Something went wrong';
  }

  return children;
};

const SearchUserList = ({ list, searchKey, onUserClick }) => {
  if (!searchKey || list?.length <= 0) {
    return null;
  }
  return (
    <>
      <div className='px-2 pb-2 sticky-head'>
        <div className='chat_msg fz-14px'>Users</div>
      </div>
      {list.map((item, key) => {
        return (
          <ChatUserRow
            key={key}
            onClick={() => onUserClick(item)}
            update={item}
            active={() => {}}
          />
        );
      })}
    </>
  );
};
