import { TAX_CONST } from './InvoiceConstant';

export function calculateIndianTaxes(invoiceData, company, invoiceItemData) {
  // let sameState = false;
  let tax_info = null;

  // invoice calculation

  const totalSubAmount = invoiceItemData?.reduce((acc, item) => acc + (item?.amount || 0), 0);
  const totalTaxAmount = invoiceItemData?.reduce(
    (acc, item) => acc + (item?.item_tax_amount || 0),
    0,
  );
  const adjustment = parseFloat(invoiceData?.adjustment) || 0;
  const shippingCharge = parseFloat(invoiceData?.shipping_charges) || 0;
  const totalBillAmount = totalSubAmount + shippingCharge + adjustment;
  const round_off = totalBillAmount % 1; // Extract decimal part
  const total = totalBillAmount - round_off; // Remove decimal part

  if (invoiceData?.tax !== TAX_CONST.NOT_SELECTED && invoiceData?.tax !== TAX_CONST.TAX_EXEMPT) {
    const clientStateCode = invoiceData?.client_id?.tax_number?.substring(0, 2);
    const companyStateCode = company?.companyTaxNumber?.substring(0, 2);

    // if (clientStateCode && companyStateCode && clientStateCode === companyStateCode) {
    //   sameState = true;
    // } else {
    //   sameState = false;
    // }

    // if (sameState) {
    //   const cgst = totalTaxAmount / 2;
    //   const sgst = totalTaxAmount / 2;
    //   tax_info = [
    //     { name: 'CGST', value: cgst.toFixed(2) },
    //     { name: 'SGST', value: sgst.toFixed(2) },
    //   ];
    // } else {
    //   const igst = totalTaxAmount;
    //   tax_info = [{ name: 'IGST', value: igst.toFixed(2) }];
    // }

    // if (sameState) {
    //   const cgst = totalTaxAmount / 2;
    //   const sgst = totalTaxAmount / 2;
    //   tax_info = [
    //     { name: 'CGST', value: cgst.toFixed(2) },
    //     { name: 'SGST', value: sgst.toFixed(2) },
    //   ];
    // }
    if (clientStateCode && companyStateCode && clientStateCode !== companyStateCode) {
      const igst = totalTaxAmount;
      tax_info = [{ name: 'IGST', value: igst.toFixed(2) }];
    } else {
      const cgst = totalTaxAmount / 2;
      const sgst = totalTaxAmount / 2;
      tax_info = [
        { name: 'CGST', value: cgst.toFixed(2) },
        { name: 'SGST', value: sgst.toFixed(2) },
      ];
    }
  }

  return {
    isSuccess: true,
    data: {
      ...invoiceData,
      sub_total: totalSubAmount.toFixed(2),
      tax_amount: totalTaxAmount.toFixed(2),
      total: total.toFixed(2), // Keep two decimal places
      round_off: round_off.toFixed(2), // Keep two decimal places
      tax_info: tax_info,
    },
  };
}

export function calculateGenericTaxes(invoiceData, company, invoiceItemData) {
  console.log(company); // for future

  // // item details calculation
  // const itemDetails = invoiceItemData?.map((item) => {
  //   const quantity = parseFloat(item.quantity) || 1;
  //   const rate = parseFloat(item.rate) || 0;
  //   const taxRate = parseFloat(item.default_tax?.rate) || 0;
  //   const totalAmount = quantity * rate;

  //   let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
  //   let taxAmount = 0;
  //   let finalAmount = totalAmount - discountAmount;

  //   if (invoiceData?.tax === TAX_CONST.EXCLUSIVE) {
  //     taxAmount = (totalAmount * taxRate) / 100;
  //     finalAmount += taxAmount;
  //   } else if (invoiceData?.tax === TAX_CONST.INCLUSIVE) {
  //     taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
  //   } else if (invoiceData?.tax === TAX_CONST.NOT_SELECTED) {
  //     taxAmount = 0;
  //   }

  //   return {
  //     ...item,
  //     amount: parseFloat(finalAmount.toFixed(2)),
  //     item_tax_amount: parseFloat(taxAmount.toFixed(2)),
  //   };
  // });

  // // invoice calculation
  // const totalSubAmount = invoiceItemData?.reduce((acc, item) => acc + (item?.amount || 0), 0);
  // const totalTaxAmount = invoiceItemData?.reduce(
  //   (acc, item) => acc + (item?.item_tax_amount || 0),
  //   0,
  // );
  // const adjustment = parseFloat(invoiceData?.adjustment) || 0;
  // const shippingCharge = parseFloat(invoiceData?.shipping_charges) || 0;
  // const totalBillAmount = totalSubAmount + shippingCharge + adjustment;
  // const round_off = totalBillAmount % 1; // Extract decimal part
  // const total = totalBillAmount - round_off; // Remove decimal part

  // return {
  //   isSuccess: true,
  //   data: {
  //     ...invoiceData,
  //     items: itemDetails,
  //     sub_total: totalSubAmount.toFixed(2),
  //     tax_amount: totalTaxAmount.toFixed(2),
  //     total: total.toFixed(2), // Keep two decimal places
  //     round_off: round_off.toFixed(2), // Keep two decimal places
  //     tax_info: null,
  //   },
  // };

  // invoice calculation

  const totalSubAmount = invoiceItemData?.reduce((acc, item) => acc + (item?.amount || 0), 0);
  const totalTaxAmount = invoiceItemData?.reduce(
    (acc, item) => acc + (item?.item_tax_amount || 0),
    0,
  );
  const adjustment = parseFloat(invoiceData?.adjustment) || 0;
  const shippingCharge = parseFloat(invoiceData?.shipping_charges) || 0;
  const totalBillAmount = totalSubAmount + shippingCharge + adjustment;
  const round_off = totalBillAmount % 1; // Extract decimal part
  const total = totalBillAmount - round_off; // Remove decimal part

  return {
    isSuccess: true,
    data: {
      ...invoiceData,
      sub_total: totalSubAmount.toFixed(2),
      tax_amount: totalTaxAmount.toFixed(2),
      total: total.toFixed(2), // Keep two decimal places
      round_off: round_off.toFixed(2), // Keep two decimal places
      tax_info: null,
    },
  };
}

export function calculateInvoiceAmount(invoiceData, company, invoiceItemData, alertFunction) {
  const clientCountry = invoiceData?.client_id?.country;
  // const taxRules = COUNTRY_BASED_TAX_RULES[clientCountry];
  // // If there's no specific rule set, use generic:
  // if (!taxRules) {
  //   return calculateGenericTaxes(invoiceData, company);
  // }

  if (!company?.companyTaxNumber && invoiceData?.client_id?.tax_treatment?.is_tax_number_required) {
    alertFunction(
      `Please submit company GST number before creating invoice for ${invoiceData?.client_id?.name.toUpperCase()}`,
    );
    return { isSuccess: false, data: invoiceData };
  }
  if (!invoiceData?.client_id?.id) {
    alertFunction('Please select client');
    return { isSuccess: false, data: invoiceData };
  }

  // Country-specific logic:
  switch (clientCountry) {
    case 'IN':
      return calculateIndianTaxes(invoiceData, company, invoiceItemData);
    default:
      return calculateGenericTaxes(invoiceData, company, invoiceItemData);
  }
}

export const calculateTaxAndAmount = (item, taxType, isTaxRequired = true) => {
  if (!item?.default_tax?.id && isTaxRequired) return item;

  const quantity = parseFloat(item.quantity) || 1;
  const rate = parseFloat(item.rate) || 0;
  const taxRate = parseFloat(item.default_tax?.rate) || 0;
  const totalAmount = quantity * rate;

  let discountAmount = item.discount ? (totalAmount * item.discount) / 100 : 0;
  let taxAmount = 0;
  let finalAmount = totalAmount - discountAmount;
  let default_tax = item?.default_tax;

  if (taxType === TAX_CONST.EXCLUSIVE) {
    taxAmount = (totalAmount * taxRate) / 100;
    finalAmount += taxAmount;
  } else if (taxType === TAX_CONST.INCLUSIVE) {
    taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
  } else if (taxType === TAX_CONST.NOT_SELECTED || taxType === TAX_CONST.TAX_EXEMPT) {
    taxAmount = 0;
    default_tax = null;
  }

  return {
    ...item,
    amount: parseFloat(finalAmount.toFixed(2)),
    item_tax_amount: parseFloat(taxAmount.toFixed(2)),
    default_tax,
  };
};
