export const userConstants = {};
export const USER_LIST_ACTIONS = {
  TASK_ADD: 'TASK-ADD',
  TASK_EDIT: 'TASK-EDIT',
  OKR_ADD: 'OKR-ADD',
  TASK_VERIFY: 'TASK-VERIFY',
  TASK_FILTER: 'TASK-FILTER',
  WORK_HISTORY: 'WORK-HISTORY',
  CHAT_UPDATE: 'CHAT-UPDATE',
  FILE_SYSTEM: 'FILE-SYSTEM',
  OKR: 'OKR',
  BOARD_FILTER: 'BOARD-FILTER',
  // new
  ACTION_TASK_ALLOCATION: 'ACTION_TASK_ADD_ALLOCATION',
  ACTION_TASK_VERIFY_USER_LIST: 'ACTION_TASK_VERIFY_USER_LIST',
  ACTION_TEMPLATE_ALLOCATION: 'ACTION_TEMPLATE_ADD_ALLOCATION',
  ACTION_PROJECT_ALLOCATION: 'ACTION_PROJECT_ADD_ALLOCATION',
  ACTION_FILE_ALLOCATION: 'ACTION_FILE_ADD_ALLOCATION',
  ACTION_OKR_ALLOCATION: 'ACTION_OKR_ADD_ALLOCATION',
  ACTION_CLIENT_ALLOCATION: 'ACTION_CLIENT_ADD_ALLOCATION',
  ACTION_USER_ALLOCATION: 'ACTION_USER_ADD_ALLOCATION',
  ACTION_REPORT_USER_LIST: 'ACTION_REPORT_USER_LIST',
  ACTION_TIME_SHEET_ALLOCATION: 'ACTION_TIME_SHEET_ADD_ALLOCATION',
  ACTION_TASK_LIST: 'ACTION_TASK_LIST',
  
  // non role based and non permission based
  ACTION_EVERY_USER: 'ACTION_EVERY_USER',
};

export const USER_PLACEHOLDER = {
  GROUP: '/group-placeholder2.svg',
  MALE: '/placeholder.svg',
  FEMALE: '/placeholder.svg',
};

export const ROLE = {
  ADMIN: 1,
  USER: 2,
  MANAGER: 3,
  // Roles below are currently not in use
  BUSINESS_UNIT: 7,
  AGENCY: 5,
  CANDIDATE: 3,
  EMPLOYER: 4,
  STAFF: 6,
};

export const USER_ROLE = {
  ADMIN: 1,
  EMPLOYEE: 2,
  MANAGER: 3,
};

export const COMMON_USER_SELECT = [
  'id',
  'first_name',
  'last_name',
  'gender',
  'image_url',
  'compressed_image_url',
];
