import { useInfiniteQuery } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { invoiceService } from '../_services/invoice.service';
import useObserver from '../Hook/useObserver';
import { Calendar, LoadingIcon, PaymentIcon2, StatusIcon } from '../Utils/SvgIcons';
import { AddInvoiceEstimate } from './AddInvoiceEstimate';
import { TRANSACTION_TYPE } from './InvoiceConstant';

export const InvoiceListSinglePage = ({ transactionType = TRANSACTION_TYPE.INVOICE }) => {
  const [addModal, setAddModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    select: [
      'id',
      'invoice_number',
      'order_number',
      'createdAt',
      'due_date',
      'invoice_status',
      'bill_date',
      'total',
    ],
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
    transaction_type: TRANSACTION_TYPE.INVOICE,
  });
  const {
    data: invoiceData,
    // error,
    isLoading,
    // isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,

    // refetch,
  } = useInfiniteQuery({
    queryKey: ['invoice-list'],
    queryFn: ({ pageParam = 1 }) =>
      invoiceService.invoiceList({
        ...query,
        //   searchKey: debouncedSearch,
        pageVo: { ...query.pageVo, pageNo: pageParam },
      }),
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  const flattedUpdateData = React.useMemo(
    () => invoiceData?.pages?.flatMap((page) => page.data.rows),
    [invoiceData],
  );

  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];

  console.log(allUpdateList, 'p[[[[[');

  return (
    <div className='p-3'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div style={{ fontWeight: 600, fontSize: 16 }}>
          All {transactionType === TRANSACTION_TYPE.INVOICE ? 'Invoices' : 'Estimates'}
        </div>
        <ToolTip
          tooltipText={`Add ${
            transactionType === TRANSACTION_TYPE.INVOICE ? 'Invoices' : 'Estimates'
          }`}
          isModern
        >
          <div className='menu-item-outline' onClick={() => setAddModal(true)}>
            <Plus size={16} color='#87909E' />
          </div>
        </ToolTip>
      </div>
      <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
        {allUpdateList?.map((invoice, index) => {
          return (
            <div className='invoice-list-single mb-3' key={index}>
              <div
                className='d-flex justify-content-between align-items-center mb-2 '
                style={{ fontWeight: 600 }}
              >
                <div>{invoice?.client?.name}</div>
                <div>${invoice?.total}</div>
              </div>
              <div className='d-flex gap-3 align-items-center'>
                <div className='d-flex'>
                  <span style={{ color: 'var(--second-text-color)' }}>
                    <PaymentIcon2 height={14} width={12} /> Payment :{' '}
                  </span>
                  <span>{invoice?.total} </span>
                </div>

                <div>
                  <span style={{ color: 'var(--second-text-color)' }}>
                    <Calendar /> Date :{' '}
                  </span>{' '}
                  <span>
                    {invoice?.bill_date
                      ? moment(parseInt(invoice?.bill_date)).format('MMM DD, YYYY')
                      : '--'}{' '}
                  </span>
                </div>

                <div>
                  <span style={{ color: 'var(--second-text-color)' }}>
                    <StatusIcon height={13} width={12} /> Status :{' '}
                  </span>{' '}
                  <span>{invoice?.invoice_status} </span>
                </div>
              </div>
            </div>
          );
        })}

        <div
          ref={observerRef}
          className='d-flex justify-content-center'
          style={{ minHeight: '2px' }}
        >
          {hasNextPage && isFetchingNextPage && isLoading && (
            <div
              className='cursor-pointer'
              onClick={() => hasNextPage && fetchNextPage()}
              style={{ height: '100px' }}
            >
              <LoadingIcon size={60} />
            </div>
          )}
        </div>
      </div>

      {addModal && (
        <Modal.Container
          handleClose={() => {
            setAddModal(false);
          }}
        >
          <Modal.View className={'w-75'}>
            <Modal.Head
              Heading={`New ${
                transactionType === TRANSACTION_TYPE.INVOICE ? 'Invoice' : 'Estimate'
              }`}
              handleClose={() => {
                setAddModal(false);
              }}
            />
            <AddInvoiceEstimate
              transactionType={transactionType}
              handleClose={() => {
                setAddModal(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};
