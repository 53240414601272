import React from 'react';
import { InfoIcon } from '../../Utils/SvgIcons';

export default function DeletedTaskPage({ task }) {
  return (
    <div
      className='d-flex justify-content-center align-items-center h-100 fz-13px gap-1'
      style={{ color: 'var(--second-text-color)' }}
    >
      <InfoIcon /> {task}
    </div>
  );
}
export function ErrorTaskPage({ message }) {
  return (
    <div
      className='d-flex justify-content-center align-items-center h-100 fz-13px gap-1'
      style={{ color: 'var(--second-text-color)' }}
    >
      <InfoIcon /> {typeof message === 'string' ? message : 'Something went wrong'}
    </div>
  );
}
