import React from 'react';
import { FILE_TYPE } from '../_constants/filesystem.constant';

export const FileAddTabs = ({ selectedTab, setSelectedTab }) => {

  const TABS = [
    { name: 'Digital', value: FILE_TYPE.DIGITAL },
    { name: 'Physical', value: FILE_TYPE.PHYSICAL },
  ];

  return (
    <div>
      <div className=' org-nav-contain'>
        {TABS.map((item, index) => (
          <div
            key={index}
            role='button'
            className={selectedTab === item?.value ? 'project-tabs-active' : 'project-tabs-in-active'}
            onClick={() => setSelectedTab(item?.value)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};
