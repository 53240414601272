/* eslint-disable no-unused-vars */
import draftToHtml from 'draftjs-to-html';
import { Check, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EDITOR_TOOLBAR_OPTIONS } from '../../_constants/template.constants';
import { removeTagsAndGetContent } from '../taskValidation';

export function EditableDescription({
  handleSave = () => {},
  handleChange = () => {},
  isEditable = false,
  children,
  data,
  isUpdate = false,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editError, setEditError] = useState(false);
  const [tempValue, setTempValue] = useState(data?.note);

  // For focusing the description on selection
  useEffect(() => {
    if (isEditing) document.querySelector('.editor-style div[contenteditable="true"]')?.focus();
  }, [isEditing]);

  // handles the editor state values
  const handleChangeNote = (e) => {
    const { name, value } = e.target;
    setTempValue({ [name]: value });
    if (isUpdate) handleChange(e);
  };

  // save
  const saveData = () => {
    const descriptionValue = draftToHtml(tempValue?.note);
    const noteWithoutTags = removeTagsAndGetContent(descriptionValue);
    if (!noteWithoutTags || noteWithoutTags?.length == 0) {
      setEditError('Description is required');
      setTimeout(() => {
        setEditError('');
      }, 3000);
      return;
    }
    handleSave({ note: descriptionValue });
    setTempValue(null);
    setIsEditing(false);
  };

  const handleIsEdit = (e) => {

    if (window.getSelection().toString().length > 0) {
      e.stopPropagation(); // Prevent event propagation if text is selected
      return;
    }

    e.preventDefault();
    isEditable && setIsEditing(true);
  };
  if (isEditing) {
    return (
      <>
        <div
          className={`task-add-description-container mt-1 mb-3 
            ${editError && 'has-error'}`}
        >
          <Editor
            toolbar={EDITOR_TOOLBAR_OPTIONS}
            stripPastedStyles={true}
            onChange={(contentState) =>
              handleChangeNote({
                target: { value: contentState, name: 'note' },
              })
            }
            defaultContentState={data?.note}
            wrapperClassName='wrapper-style wrapper-width'
            editorClassName='task_editor editor-style '
            toolbarClassName='editor-toolbar'
            placeholder='Type Your Description'
          />
        </div>
        {!isUpdate && (
          <div className='d-flex gap-1 justify-content-end'>
            <div className='close-box' onClick={() => setIsEditing(false)}>
              <X size={16} />
            </div>
            <div
              className='close-box'
              onClick={() => {
                saveData();
              }}
            >
              <Check size={16} />
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <div>
      <div onClick={(e) => handleIsEdit(e)}>{children}</div>
    </div>
  );
}
