import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modal, PaginationComponent, TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ErrorComponent } from '../_components/ErrorComponent';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants } from '../_constants';
import { fileHistoryService } from '../_services/fileHistory.service';
import { FileSystemImage, fullNameUser } from '../Utils';
import {
  ActivityStatus,
  Calendar,
  Clock,
  CompletedDot,
  DefaultTaskStatusIcon,
  LoadingIcon,
  ReceiverUser,
  SenderUser,
} from '../Utils/SvgIcons';
import { FileActivityAdd } from './FileActivityAdd';

export const FileActivityList = ({
  fileId,
  fileName,
  isActivityAdd,
  setIsActivityAdd,
  setIsActivityUpdated,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState({
    select: ['id', 'date', 'time', 'check_in_status'],
    pageVo: {
      pageNo: 1,
      noOfItems: 6,
    },
    file_id: fileId,
  });

  const {
    data: fileHistoryData,
    error: fileHistoryError,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['file-history-list', query.pageVo.pageNo, fileId],
    queryFn: () => fileHistoryService.fileHistoryList(query),
    placeholderData: keepPreviousData,
  });

  const renderTableData = () => {
    if (!fileHistoryData?.data?.rows) return null;

    return fileHistoryData.data.rows.map((item, index) => {
      const { id, date, time, check_in_status, sender, receiver } = item;
      const senderName = fullNameUser(sender?.first_name, sender?.last_name);
      const receiverName = fullNameUser(receiver?.first_name, receiver?.last_name);
      const senderUrl = sender?.image_url ? `${apiConstants?.imgUrlS3}${sender?.image_url}` : null;
      const receiverUrl = receiver?.image_url
        ? `${apiConstants.imgUrlS3}${receiver?.image_url}`
        : null;
      // const status =
      // const slNo = (fileHistoryData?.data?.page - 1) * fileHistoryData?.data?.limit + index + 1;
      // Calculate the actual index on the current page
      const actualIndex = (query.pageVo.pageNo - 1) * query.pageVo.noOfItems + index;

      return (
        <tr key={id}>
          <td>{moment(parseInt(date)).format('MMM DD, YYYY')}</td>
          <td>{time}</td>
          <td>
            <div className='d-flex align-items-center'>
              <CircularAvatar
                size={25}
                source={senderUrl}
                className='fz-none zoom-in employee-img-with-low-border'
                isMale={sender?.gender === 1}
                isNeutral={sender?.gender === 3}
              />
              <span style={{ fontWeight: 500 }}>{senderName}</span>
            </div>
          </td>
          <td>
            <div className='d-flex align-items-center'>
              <CircularAvatar
                size={25}
                source={receiverUrl}
                className='fz-none zoom-in employee-img-with-low-border'
                isMale={receiver?.gender === 1}
                isNeutral={receiver?.gender === 3}
              />
              <span style={{ fontWeight: 500 }}>{receiverName}</span>
            </div>
          </td>
          <td style={{ fontWeight: 500 }}>
            {check_in_status ? (
              <span className='d-flex align-items-center gap-1'>
                <CompletedDot color='#17C666' /> Check-in
              </span>
            ) : (
              <span className='d-flex align-items-center gap-1'>
                <CompletedDot color='#EA4D4D' /> Check-out
              </span>
            )}
          </td>
          <td>
            {actualIndex === 0 && (
              <ToolTip
                tooltipText={check_in_status ? 'check-out' : 'check-in'}
                isModern
                hideTooltip={isActivityAdd}
              >
                <span
                  className='file-status position-relative'
                  onClick={() => {
                    setIsActivityAdd(true);
                  }}
                >
                  <ActivityStatus />
                  {isActivityAdd && (
                    // <FileActivityAdd
                    //   handleClose={(event) => {
                    //     if (event) {
                    //       // event.preventDefault();
                    //       event.stopPropagation();
                    //     }
                    //     setIsActivityAdd(false);
                    //     refetch();
                    //   }}
                    //   fileName={fileName}
                    //   title={check_in_status ? 'Check-out' : 'Check-in'}
                    //   id={fileId}
                    //   setIsActivityUpdated={setIsActivityUpdated}
                    // />
                    <Modal.Container
                      handleClose={() => {
                        setIsActivityAdd(false);
                        setIsActivityUpdated(null);
                      }}
                    >
                      <Modal.View className={'modal_view_label'}>
                        <FileActivityAdd
                          handleClose={(event) => {
                            if (event) {
                              // event.preventDefault();
                              event.stopPropagation();
                            }
                            setIsActivityAdd(false);
                            refetch();
                          }}
                          fileName={fileName}
                          title={check_in_status ? 'Check-out' : 'Check-in'}
                          id={fileId}
                          setIsActivityUpdated={setIsActivityUpdated}
                        />
                      </Modal.View>
                    </Modal.Container>
                  )}
                </span>
              </ToolTip>
            )}
          </td>
        </tr>
      );
    });
  };

  const listItemCount = fileHistoryData?.data?.rows.length;

  return (
    <div>
      <ErrorComponent error={fileHistoryError?.message} />
      <div className='table_outer'>
        <table className='table table-hover tbl mb-0 activity'>
          <thead>
            <tr className='table-head'>
              {TABLE_HEADING.map((heading, index) => (
                <th
                  key={index}
                  scope='col'
                  style={{
                    minWidth: heading.minWidth,
                    maxWidth: heading.maxWidth,
                  }}
                >
                  {heading.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <TableRow noOfCols={6}>
                <LoadingIcon size={60} />
              </TableRow>
            )}
            {!listItemCount && isSuccess && (
              <TableRow noOfCols={7}>
                <EmptyActivity
                  id={fileId}
                  fileName={fileName}
                  refetch={refetch}
                  isActivityAdd={isActivityAdd}
                  setIsActivityAdd={setIsActivityAdd}
                  setIsActivityUpdated={setIsActivityUpdated}
                />
              </TableRow>
            )}

            {listItemCount > 0 && isSuccess && renderTableData()}
          </tbody>
        </table>
      </div>
      {listItemCount > 0 && (
        <section className='mt-2'>
          <PaginationComponent
            pageOnClick={(data) => {
              setQuery((prev) => ({
                ...prev,
                pageVo: {
                  ...prev.pageVo,
                  pageNo: data,
                },
              }));
              searchParams.set('pageNo', data);
              setSearchParams(searchParams);
            }}
            demoData={fileHistoryData?.data}
          />
        </section>
      )}
    </div>
  );
};

const TABLE_HEADING = [
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Calendar height={14} width={14} /> Date
      </div>
    ),
    minWidth: 110,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <Clock color='#87909E' />
        Time
      </div>
    ),
  },

  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <SenderUser /> Who’s Giving
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <ReceiverUser /> Who’s Taking
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        <DefaultTaskStatusIcon color='#87909E' /> Status
      </div>
    ),
    minWidth: 50,
  },

  {},
];

const EmptyActivity = ({
  id,
  fileName,
  isActivityAdd,
  setIsActivityAdd,
  refetch,
  setIsActivityUpdated,
}) => {
  return (
    <div className='empty-outer'>
      <div className='icon-outer-file'>
        <img src={FileSystemImage.EmptyDocument} alt='no' height={60} width={60} />
      </div>
      <span className='empty-head'>No record found</span>
      <span className='empty-text'> your file records are listed here</span>
      <div
        className='mt-3 empty-button '
        onClick={() => {
          setIsActivityAdd(true);
        }}
      >
        <Plus size={15} />
        {'Check in'}

        {isActivityAdd && (
          // <Popover.PopoverItem
          //   positionLeft={(width) => -width + width}
          //   positionTop={(top) => top - top}
          // >
          <Modal.Container
            handleClose={() => {
              setIsActivityAdd(false);
              setIsActivityUpdated(null);
            }}
          >
            <Modal.View className={'modal_view_label'}>
              <FileActivityAdd
                setIsActivityUpdated={setIsActivityUpdated}
                handleClose={(event) => {
                  if (event) {
                    event.stopPropagation();
                  }
                  setIsActivityAdd(false);
                  refetch();
                }}
                fileName={fileName}
                id={id}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
};
