import { X } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { GlobalContext, moveRecurrenceToDays } from '../../Utils';
import { Button, Modal } from '../../_components';
import { Calendar } from '../../_components/Calendar';
import ToolTip from '../../_components/Tooltip/ToolTip';
import {
  APPEND_NAME_STYLE,
  monthlyRecurType,
  recurEndType,
  recurFrequency,
} from '../../_constants/recurrence.constants';
import { errorToString } from '../../_helpers/utilFunctions';
import { taskService } from '../../_services';

import { Cancel, Tick } from '../../Utils/SvgIcons';
import { CommonDropDownStyle, PRE_POST } from '../../_constants';
import MonthInputs from './MonthInputs';
import WeekInputs from './WeekInputs';
import './recurring.css';

function RecurringTaskAddModal({
  onClose = () => {},
  taskId = 0,
  initialRecurringDetails = null,
  taskList = [],
  onSubmit = () => {},
}) {
  const { makeAlert } = useContext(GlobalContext);
  const requestedUser = JSON.parse(localStorage.getItem('user'));
  const today = moment();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultRecurrenceData = {
    task_id: taskId,
    user_id: requestedUser.userId,
    start_date: today.startOf('day').valueOf(),
    end_date: undefined,
    frequency: recurFrequency.WEEK_RECURRENCE,
    days_of_week: [],
    days_of_month: [1],
    creation_offset: 0,
    months_of_year: [],
    end_type: recurEndType.NEVER,
    monthly_recurrence_type: undefined,
    interval: 1,
    week_number: 1,
    task_details_list: taskList,
    pre_post: PRE_POST.OFF,
    append_name_style: APPEND_NAME_STYLE.DD_MM_YYYY,
  };

  //

  const initialDataIfExist = {
    ...initialRecurringDetails,
    start_date: initialRecurringDetails?.start_date
      ? moment(parseInt(initialRecurringDetails.start_date)).valueOf()
      : today.startOf('day').valueOf(),
    end_date: initialRecurringDetails?.end_date
      ? moment(initialRecurringDetails.end_date).format('YYYY-MM-DD')
      : undefined,
  };

  const [recurrenceData, setRecurrenceData] = useState(
    initialRecurringDetails ? initialDataIfExist : defaultRecurrenceData,
  );
  // eslint-disable-next-line no-unused-vars
  const [recurrenceMovedData, setRecurrenceMovedData] = useState(
    initialRecurringDetails ? initialDataIfExist : defaultRecurrenceData,
  );

  const validateRecurringData = (recurringData) => {
    if (recurringData.interval < 1) {
      return {
        errorMsg: 'Recurrence interval should be greater than 1',
        isValid: false,
      };
    }

    if (recurringData.task_details_list.length <= 0) {
      return {
        errorMsg: 'Task details is missing',
        isValid: false,
      };
    }

    if (recurringData.task_id < 1) {
      return {
        errorMsg: 'Invalid task',
        isValid: false,
      };
    }

    if (recurringData.user_id < 1) {
      return {
        errorMsg: 'Invalid user',
        isValid: false,
      };
    }

    if (!recurringData.start_date) {
      return {
        errorMsg: 'Start date not found',
        isValid: false,
      };
    }

    if (
      recurringData.frequency === recurFrequency.MONTH_RECURRENCE &&
      recurringData.monthly_recurrence_type === monthlyRecurType.BY_DATE
    ) {
      const startDateMoment = moment(recurringData.start_date);
      const isEndDateCurrentMonthAndYear =
        moment(recurringData.end_date).isSame(startDateMoment, 'month') &&
        moment(recurringData.end_date).isSame(startDateMoment, 'year');
      const parsedMonthDay = parseInt(recurringData.days_of_month[0]);

      if (isEndDateCurrentMonthAndYear && parsedMonthDay > moment(recurringData.end_date).date()) {
        return {
          errorMsg: 'End date is before the first recurrence. Please adjust the recurrence dates',
          isValid: false,
        };
      }
    }

    // if (recurringData.recurrenceStartDate < today.startOf('D').valueOf()) {
    //   return {
    //     errorMsg: 'Select a date greater than today',
    //     isValid: false,
    //   };
    // }

    const recurringFrequency = parseInt(recurringData.frequency);
    const monthlyRecurringType = parseInt(recurringData.monthly_recurrence_type);

    if (
      recurringFrequency === recurFrequency.WEEK_RECURRENCE &&
      recurringData.days_of_week.length < 1
    ) {
      return {
        errorMsg: 'Week days are not selected',
        isValid: false,
      };
    }

    if (
      parseInt(recurringData.end_type) === recurEndType.ON_SCHEDULE &&
      (!recurringData.end_date || moment(recurrenceData.end_date).isSameOrBefore(today))
    ) {
      return {
        errorMsg: 'Please select a valid end date',
        isValid: false,
      };
    }
    if (
      recurringFrequency === recurFrequency.MONTH_RECURRENCE &&
      monthlyRecurringType === monthlyRecurType.BY_DATE &&
      recurringData.days_of_month.length < 1
    ) {
      return {
        errorMsg: 'Please select a date',
        isValid: false,
      };
    }

    if (
      recurringFrequency === recurFrequency.MONTH_RECURRENCE &&
      monthlyRecurringType === monthlyRecurType.BY_WEEK &&
      recurringData.days_of_week.length < 1
    ) {
      return {
        errorMsg: 'Week days are not selected. Please select one',
        isValid: false,
      };
    }

    return {
      errorMsg: '',
      isValid: true,
    };
  };

  // const setClearableError = (error) => {
  //   setError(error);
  //   setTimeout(() => {
  //     setError(null);
  //   }, 3000);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = validateRecurringData(recurrenceData);
    if (!result.isValid) {
      // Handle error
      // setClearableError(result.errorMsg);
      makeAlert(result.errorMsg);
      return;
    }

    const creationOffset = recurrenceData?.creation_offset ? recurrenceData.creation_offset : 0;

    const updatedRecurrenceData = moveRecurrenceToDays(-parseInt(creationOffset), recurrenceData);
    // setRecurrenceMovedData(updatedRecurrenceData);

    const payload = {
      ...updatedRecurrenceData,
      creation_offset:
        recurrenceData.frequency === recurFrequency.DAY_RECURRENCE ||
        !updatedRecurrenceData?.creation_offset
          ? 0
          : updatedRecurrenceData.creation_offset,
    };
    if (payload.end_date) {
      payload.end_date = moment(recurrenceData.end_date).endOf('day').valueOf();
    }
    try {
      setIsSubmitting(true);

      const response = await taskService.addRecurring(payload);

      if (response.error_code === 0) {
        // Success

        makeAlert('Successfully Created', 'success');
        onClose();
        onSubmit();
      }
    } catch (e) {
      // Handle Error
      makeAlert(errorToString(e));
    } finally {
      setIsSubmitting(false);
    }
  };

  /***
   * Reset the recurrence data,Also persist the recurrenceId if present
   * @returns an object with reset data.
   */
  const getResetRecurrenceData = (oldRecurrenceData) => {
    const resetData = { ...defaultRecurrenceData };
    if (oldRecurrenceData?.id) {
      resetData.id = oldRecurrenceData.id;
    }
    return resetData;
  };

  const handleChange = (event) => {
    const { value, name } = event.target;

    if (name === 'pre_post') {
      setRecurrenceData((prev) => ({
        ...prev,
        [name]: value,
      }));
      return;
    }
    if (name === 'frequency') {
      // reset state
      const updatedRecurrenceData = getResetRecurrenceData(recurrenceData);
      updatedRecurrenceData.frequency = value;
      if (parseInt(value) === recurFrequency.MONTH_RECURRENCE) {
        updatedRecurrenceData.monthly_recurrence_type = monthlyRecurType.BY_DATE;
      }
      setRecurrenceData(updatedRecurrenceData);
      return;
    }

    if (name === 'days_of_month') {
      setRecurrenceData((prev) => ({
        ...prev,
        [name]: [value],
      }));
      return;
    }

    if (name === 'end_type') {
      setRecurrenceData((prev) => ({
        ...prev,
        [name]: [value],
        end_date: undefined,
      }));
      return;
    }

    setRecurrenceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleArrayChange = (event) => {
    const { value, name } = event.target;

    const isValueAlreadyExist = recurrenceData[[name]]?.includes(value);
    const updatedRecurrenceData = { ...recurrenceData };
    if (isValueAlreadyExist) {
      //remove
      updatedRecurrenceData[[name]] = recurrenceData[[name]].filter((itm) => {
        return itm !== value;
      });
    } else {
      updatedRecurrenceData[[name]] = [...recurrenceData[[name]], value];
    }
    setRecurrenceData(updatedRecurrenceData);
  };
  const recurrenceFrequency = parseInt(recurrenceData.frequency);
  const isDayRecurrence = recurrenceFrequency === recurFrequency.DAY_RECURRENCE;
  const isMonthRecurrence = recurrenceFrequency === recurFrequency.MONTH_RECURRENCE;
  const isWeekRecurrence = recurrenceFrequency === recurFrequency.WEEK_RECURRENCE;

  const isIntervalGreaterThanOne = recurrenceData.interval > 1;

  return (
    <Modal.Container>
      <Modal.View
        containerClass={'h-100 justify-content-center align-items-center d-flex'}
        className={'recur-modal-content'}
      >
        <div
          className='modal-wrapper vd__sc-modal anim-fade-in recurrence-container '
          style={{
            color: ' rgba(92, 95, 98, 1)',
            // 'font-size': '13px',
            zIndex: '9999',
            maxHeight: '100vh',
          }}
        >
          <div
            className='vd__sc-modal-content p-0 recur-modal-content'
            style={{
              maxWidth: '660px',
              fontSize: '14px',
              color: '#3D3D3D',
              fontWeight: 500,
            }}
          >
            <div className='modal-header '>
              <div className='d-flex recurrence-heading'>Recurring Task</div>
              <ToolTip
                tooltipText={'Close'}
                onClose={() => {
                  onClose();
                }}
              >
                <span className='vd__sc_modal_btn d-flex  ' style={{ background: 'transparent' }}>
                  {/* position of close the modal  */}
                  <a className='h-100 d-flex '>
                    <X size={20} />
                    {/* <img
                    src='dist\assets\img\close_mark.png'
                    alt='jj'
                    width={'20px'}
                    height={'20px'}
                  /> */}
                  </a>
                </span>
              </ToolTip>
            </div>
            {/* recurring content  */}
            <div className='recurring-content d-flex'>
              <div className='half recurring-x-padding content-padding left'>
                <form
                  className='d-flex flex-column justify-content-between h-100'
                  onSubmit={handleSubmit}
                >
                  <div className='recur-input-container'>
                    {/* 1st row */}
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='font-normal-500 pr-14 text-nowrap'>Repeat every </div>

                      <input
                        type='number'
                        name='interval'
                        value={recurrenceData.interval}
                        onChange={handleChange}
                        min={1}
                        max={999}
                        className='text-center recur-interval'
                      />

                      <select
                        value={recurrenceData.frequency}
                        name='frequency'
                        className='recur-frequency-select'
                        onChange={(e) => {
                          // const selectedValue = parseInt(e.target.value);
                          handleChange(e);
                        }}
                        style={CommonDropDownStyle}
                      >
                        <option value={recurFrequency.DAY_RECURRENCE}>
                          Day{isIntervalGreaterThanOne ? 's' : ''}
                        </option>
                        <option value={recurFrequency.WEEK_RECURRENCE}>
                          Week{isIntervalGreaterThanOne ? 's' : ''}
                        </option>
                        <option value={recurFrequency.MONTH_RECURRENCE}>
                          Month{isIntervalGreaterThanOne ? 's' : ''}
                        </option>
                        {/* <option value={recurFrequency.YEAR_RECURRENCE}>Year</option> */}
                      </select>
                    </div>
                    {/* 2nd row */}
                    {isWeekRecurrence && (
                      <WeekInputs
                        checkIsActive={(value) => recurrenceData.days_of_week.includes(value)}
                        onSelect={handleArrayChange}
                      />
                    )}
                    {/* 3rd row */}
                    {isMonthRecurrence && (
                      <MonthInputs
                        recurringWeekNumber={recurrenceData.week_number}
                        recurrenceDaysOfMonth={recurrenceData.days_of_month}
                        monthlyRecurrenceType={parseInt(recurrenceData.monthly_recurrence_type)}
                        checkIsActive={(value) => recurrenceData.days_of_week.includes(value)}
                        onChange={handleChange}
                        onWeekSelect={handleArrayChange}
                      />
                    )}
                    {!isDayRecurrence && (
                      <div className='d-flex align-items-center justify-content-between mt-20'>
                        <div className='font-normal-500 pr-14 text-nowrap'>Create before </div>
                        <input
                          type='number'
                          min={'0'}
                          className='number-input-sm'
                          value={recurrenceData?.creation_offset ?? 0}
                          name='creation_offset'
                          onChange={handleChange}
                        />
                        <div className='ms-2 font-normal-500 text-nowrap'>day(s)</div>
                      </div>
                    )}
                    <div className='mt-20 '>
                      <div className='d-flex align-items-center justify-content-between mt-2'>
                        <div className='font-normal-500 pr-14 text-nowrap'>End recurrence </div>
                        <select
                          className='recur-drop-down'
                          name='end_type'
                          onChange={handleChange}
                          value={recurrenceData.end_type}
                        >
                          <option value={recurEndType.NEVER}>Never</option>
                          {/* <option value={1}>When Complete</option> */}
                          <option value={recurEndType.ON_SCHEDULE}>On Schedule</option>
                        </select>
                      </div>
                      {parseInt(recurrenceData.end_type) === recurEndType.ON_SCHEDULE && (
                        <input
                          min={moment().add(1, 'd').format('YYYY-MM-DD')}
                          type='date'
                          name='end_date'
                          onChange={handleChange}
                          value={recurrenceData.end_date}
                          className='w-100 mt-2'
                        />
                      )}
                    </div>

                    {/* name change */}
                    <div className='mt-20'>
                      <div className='mb-2 font-normal-500' style={{ fontSize: 13 }}>
                        Name Customize
                      </div>
                      <div style={{ gap: 15 }}>
                        <label className='font-normal-500' style={{ fontSize: 13 }}>
                          <input
                            type='radio'
                            value={PRE_POST.PRE}
                            name='pre_post'
                            style={{ marginRight: 8 }}
                            checked={recurrenceData.pre_post == PRE_POST.PRE}
                            onChange={handleChange}
                          />
                          Pre
                        </label>

                        <label className='font-normal-500' style={{ marginLeft: 10, fontSize: 13 }}>
                          <input
                            type='radio'
                            name='pre_post'
                            value={PRE_POST.POST}
                            style={{ marginRight: 8 }}
                            checked={recurrenceData.pre_post == PRE_POST.POST}
                            onChange={handleChange}
                          />
                          Post
                        </label>
                        <label className='font-normal-500' style={{ marginLeft: 10, fontSize: 13 }}>
                          <input
                            type='radio'
                            name='pre_post'
                            value={PRE_POST.OFF}
                            style={{ marginRight: 8 }}
                            checked={recurrenceData.pre_post == PRE_POST.OFF}
                            onChange={handleChange}
                          />
                          Off
                        </label>
                      </div>

                      {/* select part */}

                      {recurrenceData.pre_post != PRE_POST.OFF && (
                        <div className='mt-2'>
                          <select
                            style={{ fontSize: 13 }}
                            className='recur-drop-down'
                            name='append_name_style'
                            onChange={handleChange}
                            value={recurrenceData.append_name_style}
                          >
                            <option value={APPEND_NAME_STYLE.DD_MM_YYYY}>DD-MM-YYYY</option>
                            <option value={APPEND_NAME_STYLE.MM_DD_YYYY}>MM-DD-YYYY</option>
                            <option value={APPEND_NAME_STYLE.Month}>Month</option>
                            <option value={APPEND_NAME_STYLE.Year}>Year</option>
                          </select>
                        </div>
                      )}
                      {/* select part */}
                    </div>
                    {/* name change */}
                  </div>
                  <div>
                    <div
                      className='d-flex justify-content-end mt-3 recurring-button'
                      style={{ gap: 8 }}
                    >
                      <div className='status-btn-wrapper'>
                        <Button.Container
                          type='button'
                          handleOk={onClose}
                          className={'cancel-btn'}
                          disabled={isSubmitting}
                        >
                          <Cancel size={20} />
                          <Button.Title title='Cancel' />
                        </Button.Container>
                        <Button.Container
                          isDisabled={isSubmitting}
                          type={'submit'}
                          handleOk={handleSubmit}
                        >
                          <Tick />
                          <Button.Title title={` Sav${isSubmitting ? 'ing' : 'e'}`} />
                          <Button.Loading isLoading={isSubmitting} size={20} />
                        </Button.Container>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='right'>
                <Calendar darkMode={false} width={360} pattern={recurrenceData} />
              </div>
            </div>
          </div>
        </div>
      </Modal.View>
    </Modal.Container>
  );
}

export default RecurringTaskAddModal;
