/* eslint-disable no-unused-vars */
import { Calendar, CaseSensitive, ChevronDown, ChevronUp, Clock3, Plus } from 'lucide-react';
import React from 'react';
import { CustomFileUpload, ToggleButton } from '../_components';
import { UploadIcon } from '../Utils/SvgIcons';

export const ColorPicker = ({ heading, description, handleChange, id, value, onBlur }) => {
  return (
    <>
      <section className='flex-1'>
        <Heading heading={heading} />
        {description && <Description description={description} />}
      </section>
      <div className='color_head'>
        <input
          onMouseLeave={onBlur}
          onChangeCapture={handleChange}
          type='color'
          className='color_picker'
          value={value}
        />
      </div>
    </>
  );
};

export const FileUpload = ({ heading, description, handleOk, accept, id, value }) => {
  return (
    <>
      <section className='flex-1'>
        <Heading heading={heading} />
        {description && <Description description={description} />}
      </section>
      <CustomFileUpload
        name={id}
        accept={accept}
        handleOK={handleOk}
        CustomView={
          <div>
            <label htmlFor='upload'>
              <div className='upload_contain'>
                <UploadIcon color='#67677F' width={16} height={13} />
                <div className='upload_txt'>{value ? 'Change' : 'Upload'}</div>
              </div>
            </label>
          </div>
        }
      />
    </>
  );
};

export const Container = ({ children }) => {
  return <div className='sub_container'>{children}</div>;
};

export const Heading = ({ heading, className }) => {
  const headStyle = className ? className : 'general_head';
  return <div className={headStyle}>{heading}</div>;
};
export const Description = ({ description, className }) => {
  const headStyle = className ? className : 'description-text';
  return <div className={headStyle}>{description}</div>;
};
export const ToggleWithHeading = ({
  className,
  heading,
  isChecked,
  handleToggle,
  id,
  updateOnBlur,
  headStyle,
  description,
}) => {
  const toggleStyle = className ? className : 'toggle-container';  

  return (
    <div className={toggleStyle}>
      <section className='flex-1'>
        <Heading heading={heading} />
        {description && <Description description={description} />}
      </section>
      <ToggleButton
        updateOnBlur={updateOnBlur}
        key={id}
        id={id}
        isChecked={isChecked}
        handleToggle={handleToggle}
        size={35}
      />
    </div>
  );
};

export const CheckBoxWithHeading = ({
  updateOnBlur,
  description,
  heading,
  isChecked,
  handleCheckBox,
  id,
}) => {
  return (
    <div className='sub_container'>
      <section className='flex-1'>
        <Heading heading={heading} />
        {description && <Description description={description} />}
      </section>
      <input
        onBlur={updateOnBlur}
        className='menu_check_box'
        type='checkbox'
        checked={isChecked}
        onChange={handleCheckBox}
      />
    </div>
  );
};

export const Number = () => {
  return <div className='number'>123</div>;
};

export const FieldTypes = [
  { icon: <CaseSensitive />, name: 'Text', type: 'text' },
  { icon: <Calendar />, name: 'Date', type: 'date' },
  { icon: <Clock3 />, name: 'Time', type: 'time' },
  { icon: <Number />, name: 'Number', type: 'number' },
];

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 25,
    boxShadow: 'none',
    minHeight: 32,
    fontSize: 13,
  }),
  option: (style) => ({
    ...style,
    fontSize: 13,
  }),
};

export function AddNewView({ head, handleOK }) {
  const headName = head ? head : 'Add New';
  return (
    <div className='add-new' onClick={handleOK}>
      <Plus size={15} />
      <div className='general_head'>{headName}</div>
    </div>
  );
}

export const HeadWithArrow = ({ head, isDown, handler, note }) => {
  let arrow = isDown ? <ChevronDown size={20} /> : <ChevronUp size={20} />;
  return (
    <div className='head-contain'>
      <section className='head-with-arrow'>
        <div className='general_head'>{head}</div>
        {note && <div className='description-text'>{note}</div>}
      </section>
      {/* <div className='arow' onClick={handler}>
        {arrow}
      </div> */}
    </div>
  );
};

export const StatusCard = ({ LABEL, color, defaultName, is_default }) => {
  const roundColor = color ? color : '#d1d1d1';

  return (
    <div className='status-wrapper'>
      <div style={{ background: roundColor }} className='round'></div>
      <div className='status-txt'>
        {LABEL} {`${is_default ? `(${defaultName})` : ''}`}
      </div>
    </div>
  );
};
