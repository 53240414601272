import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CommonDropDownStyle, MENU } from '../_constants';
import { projectService } from '../_services';
import { labelService } from '../_services/label.service';
import { statusService } from '../_services/status.service';
import { templateService } from '../_services/template.service';
import { billFilter, GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const BillListFilter = ({ handleClose = () => {}, setSearchParams, filterToUrl = {} }) => {
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  // eslint-disable-next-line no-unused-vars
  // const { status, ...filteredFilterToUrl } = filterToUrl;
  console.log('shrr', filterToUrl);

  // const { data: taskStatusList } = useQuery({
  //   queryKey: ['taskStatusList', filteredFilterToUrl], // Remove 'status' from the query key
  //   queryFn: () =>
  //     statusService.statusWithTaskCount({
  //       select: ['id', 'name', 'color', 'default_name', 'icon'],
  //       task_filter: filteredFilterToUrl, // Remove 'status' from the task_filter
  //     }),
  //   select: (data) => data.data,
  // });

  const {
    data: taskStatusList,
    // isLoading,
    // refetch: statusListRefetch,
  } = useQuery({
    queryKey: ['status-list'],
    queryFn: () =>
      statusService.statusList({
        status: 1,
        select: [
          'id',
          'name',
          'status',
          'default_name',
          'is_default',
          'color',
          'icon',
          // 'is_counted',
          // 'unique_string',
          // 'is_failed_status',
          // 'order',
        ],
      }),
    select: (data) => {
      return data.data.rows;
    },
  });

  const [filterData, setFilterData] = useState(billFilter);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchTemplatesByIds = async (ids) => {
      const promises = ids.map((id) =>
        templateService
          .getSingleTemplate({ id: id, select: ['id', 'name'] })
          .then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const fetchProjectsByIds = async (ids) => {
      const promises = ids.map((id) =>
        projectService.projectGet({ id: id, select: ['id', 'name'] }).then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const params = new URLSearchParams(window.location.search);
    const newFilterData = structuredClone(billFilter);

    const templateIds = [];
    const projectIds = [];

    params.forEach(async (value, key) => {
      if (key === 'label_id' || key === 'template_id' || key === 'project_id') {
        if (!newFilterData[key]) newFilterData[key] = [];
        newFilterData[key].push(isNaN(value) ? value : parseInt(value));
        if (key === 'template_id') {
          templateIds.push(parseInt(value));
        }
        if (key === 'project_id') {
          projectIds.push(parseInt(value));
        }
      } else if (key === 'task_start_date' || key === 'task_end_date') {
        const date = moment(parseInt(value));
        newFilterData[key] = date.format('YYYY-MM-DD');
      } else if (key === 'status') {
        newFilterData[key] = value;
      }
    });

    const updateFilterDataWithTemplatesAndProjects = async () => {
      if (templateIds.length > 0) {
        const templates = await fetchTemplatesByIds(templateIds);
        newFilterData.template_id = templates.map((template) => ({
          id: template[0].id,
          name: template[0].name,
        }));
      }

      if (projectIds.length > 0) {
        const projects = await fetchProjectsByIds(projectIds);
        newFilterData.project_id = projects.map((project) => ({
          id: project.id,
          name: project.name,
        }));
      }

      setFilterData(newFilterData);
    };

    updateFilterDataWithTemplatesAndProjects();
  }, []);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const { data: labelData } = useQuery({
    queryKey: ['labelList'],
    queryFn: () =>
      labelService.labelList({
        select: ['id', 'name', 'label_color', 'text_color'],
        pageVo: {
          // pageNo: parseInt(searchParams.get('pageNo')) || 1,
          noOfItems: 20,
        },
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'template_id' || name === 'project_id') {
      setFilterData((prev) => ({
        ...prev,
        [name]: value?.map((item) => item),
      }));
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickItem = (name, value) => {
    if (name === 'label_id') {
      const exists = filterData[name]?.includes(value);
      const updatedArray = exists
        ? filterData[name]?.filter((item) => item !== value)
        : [...filterData[name], value];

      setFilterData((prev) => ({
        ...prev,
        [name]: updatedArray,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    params.delete('task_start_date');
    params.delete('task_end_date');
    params.delete('label_id');
    params.delete('template_id');
    params.delete('project_id');
    params.delete('status');

    let project = filterData?.project_id?.map((item) => parseInt(item?.id));
    let template = filterData?.template_id?.map((item) => parseInt(item?.id));
    // let status = filterData?.status?.default_name;

    // Convert task_start_date and task_end_date to milliseconds if they exist
    const updatedFilterData = {
      ...filterData,
      project_id: project,
      template_id: template,
      // status: status,
    };

    if (updatedFilterData?.task_start_date) {
      updatedFilterData.task_start_date = moment(
        updatedFilterData.task_start_date,
        'YYYY-MM-DD',
      ).valueOf();
    }
    if (updatedFilterData.task_end_date) {
      updatedFilterData.task_end_date = moment(
        updatedFilterData.task_end_date,
        'YYYY-MM-DD',
      ).valueOf();
    }

    // Add the filter data to the URL parameters
    Object.keys(updatedFilterData).forEach((key) => {
      if (Array.isArray(updatedFilterData[key])) {
        updatedFilterData[key].forEach((item) => params.append(key, item));
      } else if (updatedFilterData[key]) {
        params.set(key, updatedFilterData[key]);
      }
    });
    params.set('pageNo', 1);

    // Update the URL
    setSearchParams(params, { replace: true });
    setIsLoading(false);
    handleClose(event);
  };

  const handleClear = (event) => {
    // const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();
    // Update the URL with the new parameters
    setSearchParams(newParams);
    // Clear the filter data
    setFilterData(billFilter);
    handleClose(event);
  };

  return (
    <div className={`menu_popup filter-container `} style={{ padding: 20, right: 0 }}>
      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Task Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
          {/* <div className='d-flex justify-content-between my-2 filter-item-name fz-16px'>
            Billable tasks{' '}
            <ToggleButton
              key={1}
              id={1}
              // isChecked={filterData?.task_status === STATUS.ARCHIVE}
              // isChecked={expandAll ? 1 : 0}
              // handleToggle={() => handleArchiveOption()}
              size={25}
              // disabled={currentView != TASK_TABS.TASKS}
            />
          </div> */}

          <div className=''>
            <div className='row mb-1'>
              <div className='filter-item-name'>STATUS</div>

              <ReactSelect
                name='status'
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                getOptionValue={(option) => {
                  return option?.default_name;
                }}
                onChange={(selectedOption) => {
                  handleChange({
                    target: { value: selectedOption?.default_name, name: 'status' },
                  });
                }}
                options={taskStatusList}
                styles={CommonDropDownStyle}
                value={
                  taskStatusList?.find((status) => filterData?.status === status?.default_name) ??
                  null
                }
                placeholder='Choose status'
              />
            </div>

            <div className='row mb-1'>
              <div className='filter-item-name'>TEMPLATE</div>

              <SearchableInfiniteQuerySelect
                name='template_id'
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                getOptionValue={(option) => {
                  return option?.id;
                }}
                // queryFn={(params) =>
                //   templateService.getTemplateList({ ...params, select: ['name', 'id'] })
                // }
                queryFn={(params, page) =>
                  templateService.getTemplateList({
                    ...params,
                    select: ['name', 'id'],
                    pageVo: {
                      pageNo: page,
                      noOfItems: 10,
                    },
                  })
                }
                queryKey={['template-list']}
                enableCondition={true}
                isCustomFunction
                getFunctionParams={(searchKey) => {
                  return {
                    searchKey: searchKey,
                    select: ['id', 'name'],
                  };
                }}
                isMulti
                onChange={(selectedOption) => {
                  handleChange({
                    target: { value: selectedOption, name: 'template_id' },
                  });
                }}
                styles={CommonDropDownStyle}
                value={filterData?.template_id}
                placeholder='Choose Template'
              />
            </div>

            {hasExist(MENU.project.default_name) && (
              <div className='row'>
                <div className=' filter-item-name text-uppercase'>{LABEL_OF_PROJECT}</div>

                <SearchableInfiniteQuerySelect
                  name='project_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  // queryFn={(params) =>
                  //   projectService.projectListAdvanced({
                  //     ...params,
                  //     select: ['name', 'id'],
                  //     listFor: 'active-inactive',
                  //   })
                  // }
                  queryFn={(params, page) =>
                    projectService.projectListAdvanced({
                      ...params,
                      select: ['name', 'id'],
                      listFor: 'active-inactive',
                      pageVo: {
                        pageNo: page,
                        noOfItems: 10,
                      },
                    })
                  }
                  isMulti
                  queryKey={['project-list-filter']}
                  enableCondition={true}
                  isCustomFunction
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name'],
                    };
                  }}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption, name: 'project_id' },
                    });
                  }}
                  styles={CommonDropDownStyle}
                  value={filterData?.project_id}
                  placeholder='Choose Project'
                />
              </div>
            )}
          </div>

          {/* archive task list filter  */}
          {/* {currentView === TASK_TABS.TASKS && ( */}
          <>
            <div className='row'>
              <span className='filter-item-name'>DATE</span>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 menu-item-clear'>From</label>
                  <input
                    type='date'
                    className='input-box'
                    style={{ height: 34, fontSize: 13 }}
                    // placeholder={today}
                    value={moment(filterData?.task_start_date).format('YYYY-MM-DD')}
                    name='task_start_date'
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 menu-item-clear'>To</label>
                  <div>
                    <input
                      type='date'
                      className='input-box'
                      style={{ height: 34, fontSize: 13 }}
                      // placeholder={today}
                      min={moment(filterData?.task_start_date).format('YYYY-MM-DD')}
                      value={moment(filterData?.task_end_date).format('YYYY-MM-DD')}
                      name='task_end_date'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=''>
              <span className='filter-item-name'>LABEL</span>
              <div className='labels-container'>
                {labelData?.map((item, index) => (
                  <div
                    className='label-item-filter'
                    key={index}
                    onClick={() => handleClickItem('label_id', parseInt(item?.id))}
                    style={{
                      background: item?.label_color,
                      color: item?.text_color,
                      border: filterData?.label_id?.includes(parseInt(item?.id))
                        ? `1px solid ${item?.text_color}`
                        : '',
                    }}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            </div>
          </>
          {/* )} */}
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container className={'cancel-btn'} type='button' handleOk={handleClose}>
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
              <Tick />
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
