import { Plus } from 'lucide-react';
/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Editor } from 'react-draft-wysiwyg';
import ReactSelect, { components } from 'react-select';
import { Button, SelectableContextMenu } from '../../_components';
import { Popover } from '../../_components/Popover';
import SearchableQuerySelect from '../../_components/SearchableQuerySelect';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { MENU } from '../../_constants';
import { projectService } from '../../_services';
import { statusService } from '../../_services/status.service';
import { templateService } from '../../_services/template.service';
import { LabelAdd } from '../../Label/LabelAdd';
import ErrorBlock from '../../Template/SubComponents/ErrorBlock';
import { GlobalContext } from '../../Utils';
import { LoadingIcon, Search, Tick } from '../../Utils/SvgIcons';
import { PROJECT_CHANGE_ITEMS, toolbarOptions } from '../taskConstants';

export const CustomVariableInput = ({
  name = '',
  value = '',
  placeholder = 'Type',
  onChange = () => {},
  inputCustomFields,
  className = 'task-name-input w-100 form-control',
  onBlur = () => {},
  isDisabled = false,
}) => {
  // adjusts height of the text area and value change
  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   if (value.length > 100 || value === '\n') {
  //     // e.target.style.height = '1px';
  //     e.target.style.height = 33 + e.target.scrollHeight + 'px';
  //   } else {
  //     // e.target.style.height = '1px';
  //     e.target.style.height = e.target.scrollHeight - 10 + 'px';
  //   }
  //   onChange(e);
  // };
  const handleChange = (e) => {
    const { value } = e.target;
    const rows = value.split('\n').length;
    if (rows > 3 || e.target.scrollHeight > 100) {
      e.target.style.overflowY = 'auto';
      e.target.style.height = '100px'; // Adjust this as needed
    } else {
      e.target.style.overflowY = 'hidden';
      e.target.style.height = 'auto'; // Reset height to auto for fewer rows
      e.target.style.height = e.target.scrollHeight  + 'px'; // Adjust height to fit content
    }
    onChange(e);
  };
  return (
    // <section>
    <textarea
      type='text'
      className={className}
      rows={1}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={(event) => handleChange(event)}
      onBlur={onBlur}
      {...inputCustomFields}
      autoFocus
      disabled={isDisabled}
      style={{ minHeight: 40 }}
    />
    // </section>
  );
};

export const CustomEditor = ({
  contentState,
  handleChange,
  className,
  wrapperClassName = `wrapper-style wrapper-width`,
}) => {
  return (
    <Editor
      stripPastedStyles={true}
      wrapperClassName={wrapperClassName}
      editorClassName={className ? className : 'editor-style'}
      defaultContentState={contentState}
      toolbar={toolbarOptions}
      placeholder=' Enter Description'
      editorStyle={{
        fontSize: 12,
      }}
      onChange={(contentState) =>
        handleChange({
          target: { value: contentState, name: 'note' },
        })
      }
      // onEditorStateChange={(editorState) => {
      //   handleEditorChange(editorState);
      // }}
    />
  );
};
// input for select option
export const getFormattedLabel = (item, from = false) => {
  return (
    <div
      className={`d-flex align-items-center gap-2 ${from ? 'tags-container' : ''}`}
      key={item?.value}
    >
      <div
        style={{
          background: item?.color,
          padding: 5,
        }}
      ></div>
      <div style={{ color: 'var(--btn-text-light) !import', fontSize: 12 }}>{item?.label}</div>
    </div>
  );
};

export const CustomTimeInput = ({
  handleChange = () => {},
  value = {},
  validationData,
  focusField = false,
  className = '',
  isHeadInVisible = false,
}) => {
  return (
    <div className='px-2 pb-1'>
      <span className='fz-14px-rem inline-task-sub-head ' hidden={isHeadInVisible}>
        Expected Time
      </span>
      <div className='d-flex gap-2 align-items-center pt-2'>
        <div style={{ flex: '1' }}>
          <label className='fz-13px-rem inline-task-content '>Hours</label>
          <input
            type='number'
            className={`${className} input-box ${
              validationData?.includes('expected_hours') ? ' has-error' : ''
            }`}
            // style={{ width: '7vw' }}
            id='expected_hours'
            name='expected_hours'
            placeholder='Hours '
            min='0'
            onChange={handleChange}
            value={value?.expected_hours}
            autoFocus={focusField}
          />
        </div>
        <span style={{ paddingTop: 18 }}>:</span>
        <div style={{ flex: '1' }}>
          <label className='fz-13px-rem inline-task-content '>Minutes</label>
          <input
            type='number'
            className={`${className} input-box ${
              validationData?.includes('expected_hours') ? ' has-error' : ''
            }`}
            // style={{ width: '7vw' }}
            id='expected_minutes'
            min='0'
            max='59'
            name='expected_minutes'
            placeholder='Minutes '
            onChange={handleChange}
            value={value?.expected_minutes}
          />
        </div>
      </div>
    </div>
  );
};

export const CustomStartTimeInput = ({
  isHeadInVisible = false,
  setTimeAddVisible,
  timeAddVisible,
  onSave = () => {},
  time = '',
}) => {
  const [inputTime, setInputTime] = useState(time);

  const handlePeriodChange = (event) => {
    const newPeriod = event.target.value;

    setInputTime((prev) => ({
      ...prev,
      period: newPeriod,
    }));
  };
  const handleTimeChange = (event) => {
    const { name, value } = event.target;

    setInputTime((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  // Handle correction on blur (e.g., inputting `13:00` converts to `12:59`)
  const handleBlur = () => {
    let hr = inputTime.hours;
    let min = inputTime.minutes;

    if (inputTime.hours > 12) {
      hr = 12;
    } else if (inputTime.hours < 1) {
      hr = 1;
    }
    if (inputTime.minutes > 59) {
      min = 59;
    } else if (inputTime.minutes < 0) {
      min = 0;
    }

    setInputTime((prev) => ({
      ...prev,

      hours: hr,
      minutes: min,
    }));
  };

  return (
    <SelectableContextMenu
      isContextVisible={timeAddVisible}
      onRequestClose={() => setTimeAddVisible(false)}
      className='time-modal-context-container  menu_popup inline-priority-list start-time-popup'
    >
      <div className='d-flex flex-column gap-1'>
        {isHeadInVisible && <div className='inline-task-sub-head mb-1 mx-2'>Start Time</div>}
        {/* <div className=' mx-2'>
          <input
            style={{ fontSize: '13px' }}
            type='time'
            className='field-input-field'
            id='start_time'
            name='start_time'
            onChange={(event) => {
              setInputTime(event?.target?.value);
              // handleTaskUpdate({
              //   start_time: timeStringToSeconds(event?.target?.value),
              // });
            }}
            value={inputTime}
          />
        </div> */}
        <div className='d-flex px-2 align-items-center'>
          <input
            type='number'
            name='hours'
            value={inputTime?.hours}
            onChange={handleTimeChange}
            min='1'
            max='12'
            className='input-box'
            onBlur={handleBlur}
          />

          <span className='mx-1'>:</span>
          <input
            type='number'
            name='minutes'
            value={inputTime?.minutes}
            onChange={handleTimeChange}
            min='0'
            max='59'
            className='input-box'
            onBlur={handleBlur}
          />
          <select
            value={inputTime?.period}
            onChange={(value) => {
              handlePeriodChange(value);
            }}
            className='input-box ms-1'
          >
            <option value='AM'>AM</option>
            <option value='PM'>PM</option>
          </select>
        </div>

        <div className='d-flex justify-content-end w-100 gap-2 pt-1 pe-2'>
          <Button.Container
            type='submit'
            handleOk={() => {
              setTimeAddVisible(false);
              onSave({ start_time: { ...inputTime } });
            }}
          >
            <Tick width={16} height={16} />
            <Button.Title title={'Save'} />
          </Button.Container>
        </div>
      </div>
    </SelectableContextMenu>
  );
};

export const CustomEndTimeInput = ({
  isHeadInVisible = false,
  setTimeAddVisible,
  timeAddVisible,
  onSave = () => {},
  time = '',
}) => {
  const [inputTime, setInputTime] = useState(time);

  const handlePeriodChange = (event) => {
    const newPeriod = event.target.value;

    setInputTime((prev) => ({
      ...prev,
      period: newPeriod,
    }));
  };
  const handleTimeChange = (event) => {
    const { name, value } = event.target;

    setInputTime((prev) => ({
      ...prev,

      [name]: value,
    }));
  };

  // Handle correction on blur (e.g., inputting `13:00` converts to `12:59`)
  const handleBlur = () => {
    let hr = inputTime.hours;
    let min = inputTime.minutes;

    if (inputTime.hours > 12) {
      hr = 12;
    } else if (inputTime.hours < 1) {
      hr = 1;
    }
    if (inputTime.minutes > 59) {
      min = 59;
    } else if (inputTime.minutes < 0) {
      min = 0;
    }

    setInputTime((prev) => ({
      ...prev,

      hours: hr,
      minutes: min,
    }));
  };

  return (
    <SelectableContextMenu
      isContextVisible={timeAddVisible}
      onRequestClose={() => setTimeAddVisible(false)}
      className='time-modal-context-container  menu_popup inline-priority-list start-time-popup'
    >
      <div className='d-flex flex-column gap-1'>
        {isHeadInVisible && <div className='inline-task-sub-head mb-1 mx-2'>End Time</div>}
        <div className='d-flex px-2 align-items-center'>
          <input
            type='number'
            name='hours'
            value={inputTime?.hours}
            onChange={handleTimeChange}
            min='1'
            max='12'
            className='input-box'
            onBlur={handleBlur}
          />

          <span className='mx-1'>:</span>
          <input
            type='number'
            name='minutes'
            value={inputTime?.minutes}
            onChange={handleTimeChange}
            min='0'
            max='59'
            className='input-box'
            onBlur={handleBlur}
          />
          <select
            value={inputTime?.period}
            onChange={(value) => {
              handlePeriodChange(value);
            }}
            className='input-box ms-1'
          >
            <option value='AM'>AM</option>
            <option value='PM'>PM</option>
          </select>
        </div>
        <div className='d-flex justify-content-end w-100 gap-2 pt-1 pe-2'>
          <Button.Container
            type='submit'
            handleOk={() => {
              setTimeAddVisible(false);
              onSave({ end_time: { ...inputTime } });
            }}
          >
            <Tick width={16} height={16} />
            <Button.Title title={'Save'} />
          </Button.Container>
        </div>
      </div>
    </SelectableContextMenu>
  );
};
export const SearchInput = ({ handleChange, className = '' }) => {
  return (
    <div className='inline-task-search-input-container'>
      <Search />
      <input
        type='text'
        className={`inline-task-search-input ${className}`}
        placeholder='Search'
        onChange={(event) => {
          handleChange(event.target.value);
        }}
        // value={}
      />
    </div>
  );
};

export const CustomSelectModal = ({
  handleChange,
  setSearch,
  listData,
  top = 0,
  left = 0,
  right = 0,
  padding = 0,
  valueId,
  menuCssPosition = null,
  // eslint-disable-next-line no-unused-vars
  search = '',
  // eslint-disable-next-line no-unused-vars
  selectedValueVisibility = true, // show the selected value
  isDisabled = false,
  formatOptionLabel = (data) => data.label, // Default to simply displaying the label
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '32px', // Ensure the minimum height is also 32px
      border: 'none',
      borderRadius: 0,
      borderLeft: 0,
      cursor: 'text',
      boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
      outline: 'none',
      width: '181px', // Set the width of the menu list container
      backgroundColor: 'var(--bg-primary)',
      flexDirection: 'row-reverse', // Move indicator to the left
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      padding: '8px', // Add some padding for better positioning
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      border: 'none',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      borderTop: '1px solid var(--border-color)',
      borderRadius: 0,
      position: menuCssPosition ? menuCssPosition : provided.position,
      maxHeight: '190px',
      overflow: 'hidden',
      color: '#7e7e7e',
      backgroundColor: 'var(--bg-primary)',
      width: '181px', // Set the width of the menu list container
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '190px',
      overflowY: 'auto',
      backgroundColor: 'var(--bg-primary)',
    }),
    option: (provided, state) => ({
      ...provided,

      height: '31px',
      display: 'flex',
      alignItems: 'center',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#87909E',
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--icon-light)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--icon-light)',
    }),
  };

  // Custom Dropdown Indicator component
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Search />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      style={{
        padding: { padding },
        position: 'relative',
        top: `${top}px`,
        left: `${left}px`,
        right: `${right}px`,
      }}
      className='custom-select-container'
    >
      <ReactSelect
        placeholder='Search'
        autoFocus
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={(option) => option?.label}
        getOptionValue={(option) => parseInt(option?.id)}
        onInputChange={(inputString) => setSearch(inputString)}
        filterOption={null}
        tabIndex={'0'}
        options={listData}
        onChange={handleChange}
        value={listData?.filter((option) => option.id == valueId)}
        menuIsOpen={true}
        isDisabled={isDisabled}
        components={{ DropdownIndicator }} // Use the custom Dropdown Indicator
      />
    </div>
  );
};

export const CustomLabelSelectModal = ({
  labelData = [],
  labelDataLoading = false,
  defaultLabelDetails = [],
  setLabelSearch = () => {},
  handleAdd = () => {},
  handleRemove = () => {},
  top = '10px',
}) => {
  return (
    <div
      style={{
        padding: 10,
        position: 'absolute',
        top: top,
        left: 0,
        // right: 0,
        background: 'var(--bg-primary)',
        boxShadow: '0px 0px 3px 0px var(--bg-light)',
      }}
      className='inline-context-container menu_popup inline-priority-list '
    >
      <div className='inline-task-label-container'>
        {defaultLabelDetails?.map((item, idx) => (
          <div
            key={idx}
            className='label-item-task-list'
            style={{
              background: item?.label_color,
              color: item?.text_color,
              margin: ' 0 10px 10px 0',
              border: `1px solid ${item?.text_color}`,
            }}
            onClick={() => {
              handleRemove(item.id);
            }}
          >
            {item.name} {/* Display the name property of the label object */}
          </div>
        ))}
      </div>
      <SearchInput
        handleChange={(value) => {
          setLabelSearch(value);
        }}
      />

      <div className='inline-task-label-container mt-1'>
        {labelDataLoading && <LoadingIcon size={16} />}
        {labelData?.map(
          (item, idx) =>
            !defaultLabelDetails?.includes(item) && (
              <div
                key={idx}
                className='label-item-task-list pointer'
                style={{
                  background: item?.label_color,
                  color: item?.text_color,
                  margin: ' 0 10px 10px 0',
                }}
                onClick={() => {
                  handleAdd(item.id);
                }}
              >
                {item.name} {/* Display the name property of the label object */}
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export const CustomEmptyContainer = ({
  text,
  Icon,
  disableNonEmptyView,
  disableAdd = false,
  handleAdd = () => {},
}) => {
  return (
    <div
      className={`col d-flex flex-column justify-content-center align-items-center gap-2 recurring-wrapper mt-2 ${
        disableNonEmptyView ? 'p-1' : 'p-3'
      }`}
    >
      {!disableNonEmptyView && (
        <>
          <Icon />
          <div className='reminder-empty-container-text'>{text}</div>
        </>
      )}
      {!disableAdd && (
        <div role='button' className='btn-wrapper mt-1 button-container' onClick={handleAdd}>
          <Plus strokeWidth={1.5} size={16} /> Add
        </div>
      )}
    </div>
  );
};

export const StatusOption = ({ data, metaData, isLoading = false }) => {
  if (metaData.context == 'value') {
    return (
      <div
        className='d-flex '
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          fontSize: 12,
          textOverflow: 'ellipsis',
          fontWeight: 500,
          whiteSpace: 'nowrap',
          color: data.color,
        }}
      >
        {/* <div className='d-flex gap-2 align-items-center pointer'> */}
        {/* <span
            style={{
              width: 6,
              height: 6,
              borderRadius: 10,
              // background: data.color ? data.color : '#000',
            }}
          />
          <span
            style={{
              maxWidth: '100px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          > */}
        {data.label}
        {/* </span> */}
        {/* </div> */}
        {isLoading && (
          <div className='ps-2'>
            <LoadingIcon size={18} />
          </div>
        )}
      </div>
    );
  }
  return (
    <div className='d-flex gap-2 align-items-center  '>
      <span
        style={{
          width: 6,
          height: 6,
          borderRadius: 10,
          background: data.color ? data.color : '#000',
        }}
      />
      <span
        style={{
          // minWidth: 0,
          maxWidth: 140,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {data.label}
      </span>

      {/* <span>{data.label}</span> */}
    </div>
  );
};

export const ProjectChangeInputs = ({
  setIsInputVisible,
  onSubmit = () => {},
  initialValue,
  // isHeadInvisible = false,
  userId,
}) => {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useState(initialValue);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // initial value state setting for show values in select box
  useMemo(() => {
    if (!selectedProject?.id || initialValue?.project_id != selectedProject?.id)
      setSelectedProject({ name: initialValue?.project_name, id: initialValue.project_id });
    if (!selectedTemplate?.id || initialValue?.template_id != selectedTemplate?.id)
      setSelectedTemplate({ name: initialValue?.template_name, id: initialValue.template_id });
  }, [initialValue?.project_id]);

  // project list fetch by user id - here user id will be assignee id of task
  const {
    data: projectData,
    error,
    isLoading: projectLoading,
  } = useQuery({
    queryKey: ['projectList', userId],
    queryFn: () =>
      projectService.projectListAdvanced({
        select: ['id', 'name', 'progress_status'],
        // user_id: userId,
      }),
    select: (data) => {
      const response = data.data.rows;
      const currentProjectData = {
        id: state?.project_id,
        name: state?.project_name,
        value: state?.project_id,
        label: state?.project_name,
      };
      if (currentProjectData.id && !response.find((item) => item.id == currentProjectData.id)) {
        response.push(currentProjectData);
      }
      return response;
    },
    enabled: Boolean(userId),
  });

  // for template list fetch by project_id
  const {
    data: templateData = [{ id: state.template_id, name: state.template_name }],
    error: templateFetchError,
    isLoading: templateLoading,
  } = useQuery({
    queryKey: ['templateList', state?.project_id],
    queryFn: () =>
      templateService.getTemplateList({
        select: ['id', 'name', 'is_default', 'status_group'],
        project_id: state?.project_id,
        pageVo: { sort: 'is_default', sort_order: 'DESC' },
      }),
    select: (data) => {
      const templateList = data?.data?.rows;
      // Check if the default assignee already exists in the userData array
      // const isDefaultTemplatePresent = templateList.some(
      //   (template) => template.id === state?.template_id,
      // );
      const updatedTemplateData = templateList;
      return updatedTemplateData;
    },
    enabled: !!state?.project_id && !projectLoading,
  });

  // for template list fetch by project_id
  const {
    data: statusData,
    error: statusFetchError,
    isLoading: statusLoading,
  } = useQuery({
    queryKey: ['statusList', state?.project_id],
    queryFn: () =>
      statusService.statusGroupItemList({
        select: ['id', 'name', 'is_default', 'status_id'],
        status_group_id: state?.status_group_id,
        pageVo: { sort: 'is_default', sort_order: 'DESC' },
      }),
    select: (data) => {
      const statusList = data?.data;
      // Check if the default assignee already exists in the userData array
      // const isDefaultStatusPresent = statusList.some((status) => status.id === state?.status_id);
      const updatedStatusData = statusList;
      return updatedStatusData;
    },
    enabled: !!state?.template_id && !!state?.status_group_id && !templateLoading,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsSubmitted(false);

    if (name === PROJECT_CHANGE_ITEMS.project) {
      setState({
        ...state,
        template_id: '',
        status_id: '',
        status_group_item_id: '',
        status_group_id: '',
        [name]: value.id,
      });
      setSelectedProject({ name: value.name, id: value.id });
      setSelectedTemplate(null);
    } else if (name === PROJECT_CHANGE_ITEMS.template) {
      setState({
        ...state,
        [name]: value?.id,
        status_group_id: value?.status_group,
        status_id: '',
        status_group_item_id: '',
      });
      setSelectedTemplate({ name: value.name, id: value.id });
    } else {
      setState({ ...state, [name]: value?.id, status_id: value?.status_id });
    }
  };

  const handleSubmit = (e) => {
    // handle form submission
    e.preventDefault();
    setIsSubmitted(true);
    try {
      if (state?.project_id && state?.template_id && state?.status_group_item_id) {
        onSubmit(state);
        setIsInputVisible(false);
      }
    } catch (err) {
      console.error(err);
      setIsSubmitted(false);
    }
  };

  return (
    <section className='time-context-view'>
      <ErrorBlock
        errorMsg={statusFetchError?.message || templateFetchError?.message || error?.message}
      />
      <form
        className='d-flex flex-column gap-2 p-2'
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        style={{ fontSize: 13 }}
      >
        {/* <div hidden={isHeadInvisible}>Expected Time</div> */}
        {/* project selection start */}
        <div className='d-flex gap-2 align-items-center'>
          <SearchableQuerySelect
            name={PROJECT_CHANGE_ITEMS.project}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            isLoading={projectLoading}
            getOptionValue={(option) => {
              return option?.id;
            }}
            queryFn={projectService.projectListAdvanced}
            queryKey={['projectList', userId]}
            isCustomFunction
            initialValue={projectData}
            getFunctionParams={(searchKey) => {
              return {
                searchKey: searchKey,
                select: ['id', 'name', 'progress_status'],
                // user_id: userId,
              };
            }}
            onChange={(val) =>
              handleChange({ target: { name: PROJECT_CHANGE_ITEMS.project, value: val } })
            }
            placeholder={`Select ${LABEL_OF_PROJECT}`}
            className={` w-100 ${isSubmitted && !state?.project_id ? 'has-error' : ''}`}
            value={selectedProject ?? ''}
          />
        </div>
        {/* project selection end */}
        {/* template selection start */}
        <div className='d-flex gap-2 align-items-center'>
          <SearchableQuerySelect
            name={PROJECT_CHANGE_ITEMS.template}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            isLoading={templateLoading}
            getOptionValue={(option) => {
              return option?.id;
            }}
            queryFn={templateService.getTemplateList}
            queryKey={['templateList', state?.project_id]}
            isCustomFunction
            initialValue={templateData}
            getFunctionParams={(searchKey) => {
              return {
                searchKey: searchKey,
                select: ['id', 'name', 'is_default'],
                project_id: state?.project_id,
              };
            }}
            onChange={(val) => {
              // handleChange({ target: { name: 'status_group_id', value: val?.status_group } });
              handleChange({ target: { name: PROJECT_CHANGE_ITEMS.template, value: val } });
            }}
            placeholder={'Select Template'}
            className={` w-100 ${isSubmitted && !state?.template_id ? 'has-error' : ''}`}
            value={selectedTemplate}
          />
        </div>
        {/* template selection end */}
        {/* status item selection start */}
        <div className='d-flex gap-2 align-items-center'>
          <SearchableQuerySelect
            name={PROJECT_CHANGE_ITEMS.status}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            isLoading={templateLoading}
            getOptionValue={(option) => {
              return option?.id;
            }}
            queryFn={statusService.statusGroupItemList}
            queryKey={['statusList', state?.status_group_id]}
            isCustomFunction
            initialValue={statusData}
            getFunctionParams={(searchKey) => {
              return {
                searchKey: searchKey,
                select: ['id', 'name', 'progress_status'],
                status_group_id: state?.status_group_id,
              };
            }}
            onChange={(val) =>
              handleChange({ target: { name: PROJECT_CHANGE_ITEMS.status, value: val } })
            }
            placeholder={'Select status'}
            className={` w-100 ${isSubmitted && !state?.status_group_item_id ? 'has-error' : ''}`}
            value={statusData?.filter((item) => item.id == parseInt(state?.status_group_item_id))}
          />
        </div>
        <div className='d-flex justify-content-end w-100 gap-2'>
          <div
            className='btn-wrapper pe-1 py-1'
            role='button'
            onClick={() => setIsInputVisible(false)}
          >
            Cancel
          </div>
          <button type='submit' className='unset-btn btn-wrapper pe-1 py-1'>
            Save
          </button>
        </div>
      </form>
    </section>
  );
};

export const TimeRangeSelector = ({
  startTime,
  endTime,
  onChange,
  className = 'form-control w-80',
  style = {},
}) => {
  return (
    <div className='d-flex flex-nowrap gap-1'>
      <TimeInput
        value={startTime}
        onChange={onChange}
        placeholder='Start time'
        className={className}
        name={'start_time'}
        style={style}
      />
      <span>:</span>
      <TimeInput
        value={endTime}
        onChange={onChange}
        placeholder='End time'
        className={className}
        name={'end_time'}
        style={style}
      />
    </div>
  );
};

const TimeInput = ({
  value = null,
  onChange = () => {},
  placeholder = 'Choose time',
  className,
  name,
  style,
}) => {
  const [inputType, setInputType] = useState('text');
  const value12hour = moment(value, 'HH:mm').format('hh:mm A');
  return (
    <input
      name={name}
      type={inputType}
      value={inputType == 'text' && value ? value12hour : value}
      placeholder={placeholder}
      onFocus={() => setInputType('time')}
      onBlur={(e) => {
        if (!e.target.value) {
          setInputType('text');
        }
      }}
      onChange={onChange}
      className={className}
      style={style}
    />
  );
};

export const CreateCustomLabelAdd = ({
  tableRef = null,
  onClose = () => {},
  afterLabelAdd = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    onClose();
  };

  return (
    <>
      <ToolTip tooltipText={'Add a label'} isModern>
        <div
          className={`menu-item-outline task-menu-item${isEdit ? ' active' : ''}`}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          <Plus color={isEdit ? '#3454d1' : '#87909E'} size={18} />
        </div>
      </ToolTip>

      {isEdit && (
        <Popover.PopoverItem
          onClose={() => {
            setIsEdit(false);
          }}
          positionLeft={(width) => -width + 5}
          positionTop={() => '0px'}
          scrollRef={tableRef}
          isVisible={isEdit}
          zIndex={100}
        >
          <section className='task-label-modal'>
            <LabelAdd handleClose={handleClose} afterAdd={afterLabelAdd} />
          </section>
        </Popover.PopoverItem>
      )}
    </>
  );
};

export const DateAndTimeRangePicker = ({
  subTaskDate = '',
  start = '',
  end = '',
  onDateAndTimeRangeChange,
}) => {
  const [date, setDate] = useState(subTaskDate);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);

  // handler for date change
  const handleDateChange = (e) => {
    setDate(e);
    if (onDateAndTimeRangeChange) {
      onDateAndTimeRangeChange({ target: { name: 'date', value: e } });
    }
  };

  // handler for start time change
  const handleStartTimeChange = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      return;
    }
    setStartTime(e.target.value);
    if (onDateAndTimeRangeChange) {
      onDateAndTimeRangeChange({ target: { name: 'startTime', value: e.target.value } });
    }
  };

  // handler for end time change
  const handleEndTimeChange = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      return;
    }
    setEndTime(e.target.value);
    if (onDateAndTimeRangeChange) {
      onDateAndTimeRangeChange({ target: { name: 'endTime', value: e.target.value } });
    }
  };

  // Function to prevent certain keys (Backspace, Delete)
  const preventKeyAction = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      e.preventDefault();
    }
  };

  return (
    <div className={`input-container ${(!date || !startTime || !endTime) && 'has-error'}`}>
      <DatePicker
        selected={moment(date, 'DD-MM-YYYY').toDate()}
        // value={date}
        // date={date}
        onChange={handleDateChange}
        placeholderText='dd-mm-yyyy'
        minDate={new Date()} // Set minimum date to today
        dateFormat='dd-MM-yyyy'
        className='date-input'
        onKeyDown={preventKeyAction}
      />
      <input
        type='time'
        placeholder='Start Time'
        value={startTime}
        onChange={handleStartTimeChange}
        className='time-input'
        onKeyDown={preventKeyAction} // Prevent Backspace and Delete
      />
      <span className='mx-1'>to</span>
      <input
        type='time'
        value={endTime}
        onChange={handleEndTimeChange}
        placeholder='End Time'
        className='time-input'
        onKeyDown={preventKeyAction} // Prevent Backspace and Delete
      />
    </div>
  );
};
