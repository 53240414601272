import { FileText, Play, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Td, Tr } from 'react-super-responsive-table';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import PositionedContextMenu from '../_components/PositionedContextMenu';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, RT, typeConstants } from '../_constants';
import { TASK_TYPES } from '../_constants/template.constants';
import { okrService } from '../_services';
import { useUser } from '../Hook/useUser';
import { fullNameUser, GlobalContext, Images } from '../Utils';
import { ChildTreeIcon, FileIcon, KeyIcon } from '../Utils/SvgIcons';
import { ExistingTaskAdd } from './ExistingTaskAdd';
import { EXPIRY, manualProgressType, objectiveType } from './okrConstants';

const OkrTableRow = ({
  data,
  slNo,
  reference,
  // contextMenuVisible,
  // setContextMenuVisible,
  // editOkrId,
  // setEditOkrId,
  layer,
  refetch,
  isExpand,
  setAddChildModal,
  setParentData,
  setIsChildObjective,
  // addContextMenuIndex,
  // setAddContextMenuIndex,
  isTaskSelectionPopup,
  setIsTaskSelectionPopup,
  setAddTaskModal,
  // handleClickOption = () => {},
  contextMenuIndex,
  setContextMenuIndex,
  index,
}) => {
  const [isChildVisible, setIsChildVisible] = useState(false);
  const [iconPosition, setIconPosition] = useState({ x: 0, y: 0 }); // Store the position

  const { roleBasedPermission } = useContext(GlobalContext);

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [okrData, setOkrData] = useState(data);
  const navigate = useNavigate();
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'status',
      'type',
      'task_start_date',
      'task_end_date',
      'visibility_team',
      'children',
      'okr_parent_id',
      'objective_type',
      'created_time',
      'okr_progress_type',
      'manual_progress_type',
      'manual_current_progress',
      'okr_progress',
      'progress_measure_end',
      'assignee_id',
      'task_visible_users',
    ],
  });
  const assigneeName = fullNameUser(okrData?.User?.first_name, okrData?.User?.last_name);
  const isMale = okrData?.User?.gender === 1;
  const isNeutral = okrData?.User?.gender === 3;
  const url = okrData?.User?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

  const normalTaskTypes = [typeConstants.REQUEST, typeConstants.TASK, typeConstants.TODO];

  const getChildOKR = async (id) => {
    let queryNew = {
      ...query,
      id: id,
    };
    setQuery(queryNew);
    setIsLoading(true);
    if (okrData?.children?.length == okrData?.ChildrenDetails?.length) {
      setIsLoading(false);
      return null;
    }
    try {
      const okr = await okrService.getOKR(queryNew);
      if (okr?.data) {
        setOkrData(okr?.data);
        refetch();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsChildVisible(false);
    setOkrData(data);
  }, [isExpand, data]);

  const loggedUser = useUser().id;

  const pathName = window.location.pathname;

  const handleTaskClick = (task) => {
    const isTaskOwner = loggedUser == parseInt(task?.assignee_id);
    const tab =
      isTaskOwner && task?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK ? 'subtask' : 'description';
    // navigate(`/${RT.TASK}/${task?.id}?tab=${tab}&&${params}`);
    navigate(`/${RT.TASK}/${task?.id}?tab=${tab}&&prevpath=${pathName}`);
  };
  // const isActive = contextMenuIndex === index;

  return (
    <>
      <Tr>
        <Td>
          <div className='d-flex gap-1'>
            <div>
              {okrData?.children?.length > 0 && layer == 0 && (
                <div
                  onClick={() => {
                    getChildOKR(okrData.id), setIsChildVisible((prev) => !prev);
                  }}
                  className='cursor-pointer'
                >
                  {isChildVisible ? (
                    <img src={Images.PlayDown} />
                  ) : (
                    <Play color='#87909E' fill='#87909E' size={10} />
                  )}
                </div>
              )}  
            </div>
            {slNo}
          </div>
        </Td>
        <Td>
          <div className='d-flex gap-2' style={{ paddingLeft: layer * 35 }}>
            {okrData?.children?.length > 0 && layer > 0 && (
              <div
                onClick={() => {
                  getChildOKR(okrData.id), setIsChildVisible((prev) => !prev);
                }}
                className='cursor-pointer'
              >
                {isChildVisible ? (
                  <img src={Images.PlayDown} />
                ) : (
                  <Play color='#87909E' fill='#87909E' size={10} />
                )}
              </div>
            )}
            <div className='okr-name-container'>
              <div
                onClick={() => {
                  normalTaskTypes.includes(parseInt(data?.type))
                    ? data?.isTaskClickable === false &&
                      !(
                        parseInt(data?.type) === typeConstants.KEY_RESULT ||
                        parseInt(data?.type) === typeConstants.OBJECTIVE
                      )
                      ? ''
                      : handleTaskClick(data)
                    : navigate(`/${RT.OKR_VIEW}/${okrData?.id}`);
                }}
                className=' d-flex gap-1 align-items-baseline flex-1 '
                style={{
                  // color:
                  //   data?.isTaskClickable === false &&
                  //   !(
                  //     parseInt(data?.type) === typeConstants.KEY_RESULT ||
                  //     parseInt(data?.type) === typeConstants.OBJECTIVE
                  //   )
                  //     ? 'rgba(135, 144, 158, 1)'
                  //     : 'rgba(42, 46, 52, 1)',
                  cursor:
                    data?.isTaskClickable === false &&
                    !(
                      parseInt(data?.type) === typeConstants.KEY_RESULT ||
                      parseInt(data?.type) === typeConstants.OBJECTIVE
                    )
                      ? 'default'
                      : 'pointer',
                }}
              >
                <span>
                  {normalTaskTypes.includes(parseInt(data?.type)) ? (
                    <FileText size={17} />
                  ) : (
                    <OkrIconWithName
                      type={okrData?.objective_type}
                      isKeyResult={
                        okrData?.objective_type &&
                        parseInt(okrData?.type) === typeConstants?.KEY_RESULT
                      }
                    />
                  )}
                </span>

                <span
                  style={{
                    maxWidth: 400,
                    overflow: 'hidden',
                  }}
                >
                  {okrData?.name}
                </span>
              </div>

              {!normalTaskTypes.includes(parseInt(data?.type)) && (
                <div
                  className={`d-flex gap-4 justify-content-end icon-div ${
                    contextMenuIndex === data?.id || isTaskSelectionPopup === okrData?.id
                      ? ''
                      : 'invisible'
                  }`}
                >
                  <div className='d-flex gap-2 position-relative '>
                    <ToolTip
                      tooltipText={'Add'}
                      isModern
                      popOver
                      hideTooltip={contextMenuIndex === data?.id}
                    >
                      <div className='add-icon-bg ellipsis_icon'>
                        {roleBasedPermission?.ACTION_OKR_ADD && (
                          <div
                            onClick={(event) => {
                              setContextMenuIndex(data?.id);
                              setIconPosition({
                                x: event.clientX, // X-coordinate of the click
                                y: event.clientY, // Y-coordinate of the click
                              });
                            }}
                            style={{
                              background: contextMenuIndex === data?.id ? '#e9f2ff' : '',
                            }}
                            className='okr-plus-icon'
                          >
                            <Plus
                              size={17}
                              color={contextMenuIndex === data?.id ? '#3454d1' : '#87909E'}
                            />
                          </div>
                        )}
                        <PositionedContextMenu
                          isContextVisible={contextMenuIndex === data?.id}
                          onRequestClose={() => {
                            setContextMenuIndex(null);
                          }}
                          top={iconPosition.y + 10}
                          left={iconPosition.x - 10}
                        >
                          <div style={{ width: 150 }}>
                            {parseInt(okrData?.objective_type) !== objectiveType.personal &&
                              parseInt(okrData?.type) !== typeConstants.KEY_RESULT && (
                                <div
                                  className='menu_items'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setContextMenuIndex(null);
                                    setAddChildModal(true);
                                    setIsChildObjective(true);
                                    setParentData(okrData);
                                    // setDetailsVisibleIndex(folder?.id);
                                  }}
                                >
                                  <div className='d-flex align-items-center  w-100'>
                                    <div style={{ width: '17%' }}>
                                      <ChildTreeIcon />
                                    </div>
                                    <div className='flex-1'>Child Objective</div>
                                  </div>
                                </div>
                              )}
                            {parseInt(okrData?.type) !== typeConstants.KEY_RESULT && (
                              <div
                                className='menu_items'
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setContextMenuIndex(null);
                                  // setEditVisibleIndex(folder?.id);
                                  setAddChildModal(true);
                                  setIsChildObjective(false);
                                  setParentData(okrData);
                                }}
                                // onClick={() => {
                                //   setAddChildModal(true), setIsChildObjective(false);
                                //   setParentData(okrData);
                                // }}
                              >
                                <div className='d-flex align-items-center w-100'>
                                  <div style={{ width: '17%' }}>
                                    <KeyIcon />
                                  </div>
                                  <div className='flex-1'>Key Result</div>
                                </div>
                              </div>
                            )}
                            <div
                              className='menu_items'
                              onClick={(event) => {
                                event.stopPropagation();
                                setContextMenuIndex(null);

                                setIsTaskSelectionPopup(okrData?.id);
                              }}
                            >
                              <div className='d-flex align-items-center  w-100'>
                                <div style={{ width: '17%' }}>
                                  <FileIcon size={18} />
                                </div>
                                <div className='flex-1'>Existing Task</div>
                              </div>
                            </div>
                            <div
                              className='menu_items'
                              onClick={(event) => {
                                event.stopPropagation();
                                setContextMenuIndex(null);
                                setAddTaskModal(true);
                                setParentData(okrData);
                              }}
                            >
                              <div className='d-flex align-items-center  w-100'>
                                <div style={{ width: '17%' }}>
                                  <FileIcon size={18} />
                                </div>
                                <div className='flex-1'>Add Task</div>
                              </div>
                            </div>
                          </div>
                        </PositionedContextMenu>
                      </div>
                    </ToolTip>
                    {isTaskSelectionPopup === okrData?.id && (
                      <ExistingTaskAdd
                        handleClose={(event) => {
                          if (event) {
                            event.stopPropagation();
                          }
                          setContextMenuIndex(null);
                          setIsTaskSelectionPopup(null);
                          refetch();
                        }}
                        parentId={okrData?.id}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Td>
        {/* <td>Incomplete - 20%</td> */}
        <Td>
          {(parseInt(okrData?.type) === typeConstants.KEY_RESULT ||
            parseInt(okrData?.type) === typeConstants.OBJECTIVE) && (
            <div className='d-flex align-items-center gap-2'>
              <span style={{ color: okrData?.progressDetails?.color, fontWeight: 500 }}>
                {parseInt(data?.manual_progress_type) !== manualProgressType.binary ? (
                  <div className='d-flex align-items-center justify-content-center gap-2'>
                    <div
                      className='progress-bar-parent'
                      style={{
                        backgroundColor: data?.progressDetails?.backgroundColor,
                      }}
                    >
                      <div
                        className='progress-bar-child'
                        style={{
                          width: data?.progressDetails?.progress + '%',
                          backgroundColor: data?.progressDetails?.color,
                          // ?.full,
                        }}
                      />
                    </div>
                    {data?.progressDetails?.progressText}
                  </div>
                ) : // data?.progressDetails?.progressText
                parseInt(data?.progressDetails?.progress) === 100 ? (
                  'Completed'
                ) : (
                  'In-completed'
                )}
              </span>
            </div>
          )}
        </Td>
        <Td>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 500 }}>{assigneeName}</span>
          </div>
        </Td>

        <Td>
          {normalTaskTypes.includes(parseInt(data?.type)) ? (
            'Task'
          ) : (
            <OkrIconWithName
              isNameRequired={true}
              type={okrData?.objective_type}
              isKeyResult={
                okrData?.objective_type && parseInt(okrData?.type) === typeConstants?.KEY_RESULT
              }
            />
          )}
        </Td>

        <Td>{moment(parseInt(okrData.created_time)).format('MMM DD, YYYY')} </Td>
        <Td>
          <span style={{ color: okrData.expiry_status === EXPIRY.EXPIRED ? '#ea4d4d' : '' }}>
            {moment(parseInt(okrData.task_end_date)).format('MMM DD, YYYY')}
          </span>
        </Td>
      </Tr>

      {isChildVisible &&
        okrData?.children?.length > 0 &&
        okrData?.ChildrenDetails?.map((child) => {
          return (
            <OkrTableRow
              layer={layer + 1}
              data={child}
              key={child.id}
              setAddChildModal={setAddChildModal}
              setIsChildObjective={setIsChildObjective}
              setParentData={setParentData}
              setAddTaskModal={setAddTaskModal}
              // addContextMenuIndex={addContextMenuIndex}
              // setAddContextMenuIndex={setAddContextMenuIndex}
              // contextMenuVisible={contextMenuVisible}
              // setContextMenuVisible={setContextMenuVisible}
              // editOkrId={editOkrId}
              // setEditOkrId={setEditOkrId}
              reference={reference}
              // handleClickOption={(value, okr_parent_id) => handleClickOption(value, okr_parent_id)}
              setContextMenuIndex={setContextMenuIndex}
              contextMenuIndex={contextMenuIndex}
              setIsTaskSelectionPopup={setIsTaskSelectionPopup}
              isTaskSelectionPopup={isTaskSelectionPopup}
              index={index}
              refetch={refetch}
            />
          );
        })}
    </>
  );
};

export default OkrTableRow;
export const OkrIconWithName = ({ type, isKeyResult, isNameRequired = false, size = 18 }) => {
  const icons = {
    [objectiveType.company]: { value: 'C', color: '#3454D1', name: 'Company' }, // Company: Blue
    [objectiveType.team]: { value: 'T', color: '#28a745', name: 'Team' }, // Team: Green
    [objectiveType.personal]: { value: 'P', color: '#ffc107', name: 'Personal' }, // Personal: Yellow
    default: { value: 'I', color: '#6c757d' }, // Default: Gray
  };

  const { value, color, name } = isKeyResult
    ? { value: 'K', color: '#ff0000', name: 'Key Result' } // Key Result: Red
    : icons[type] || icons.default;

  return (
    <>
      {isNameRequired ? (
        <span>{name}</span>
      ) : (
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: color,
            color: 'var(--bg-primary)',
            fontWeight: 500,
            fontSize: 11,
            width: size,
            height: size,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {value}
        </div>
      )}
    </>
  );
};
