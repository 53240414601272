/* eslint-disable react/react-in-jsx-scope */
import { AtSign, Bookmark, Calendar, SquareCheck, Zap } from 'lucide-react';
import { SINoIcon } from './Utils/SvgIcons';

export const TASK_TABLE_HEADINGS = [
  { name: <SINoIcon />, minWidth: 50 },
  {
    name: (
      <>
        <SquareCheck size={16} /> Tasks
      </>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <>
        <Zap size={16} /> Action
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        <AtSign size={16} /> Assignee
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='d-flex align-items-center gap-1'>
        {/* <LineChart size={16} color='var(--second-text-color)' /> Status */}
        <Calendar size={16} /> Due Date
      </div>
    ),
    minWidth: 50,
  },

  {
    name: (
      <>
        <Bookmark size={16} /> Priority
      </>
    ),
    minWidth: 50,
  },
  {
    name: (
      <>
        <Calendar size={16} /> Start Date
      </>
    ),
    minWidth: 50,
  },

  { name: <></>, minWidth: 25 },

  // { name: <CirclePlus size={16} />  },
];
