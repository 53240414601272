/* eslint-disable no-unused-vars */
import { Copy, Pen, Play, Trash } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal } from '../../_components';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import { getPriorityByContextAPI } from '../../Task/taskValidation';
import { Images, validateTaskWithTemplateTest } from '../../Utils';
import TemplateTaskPreviewExpanded from './TemplateTaskPreviewExpanded';

/***
 * Template related task preview
 */
export function TemplateTaskPreviewItem({
  relatedTaskData,
  setTemplateDetails = () => {},
  index,
  allRelatedTask,
  validation_fields,
  onEdit = () => {},
  onDuplicate = () => {},
  onDelete = () => {},
  expandAll,
  setExpandAll = () => {},
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [validationData, setValidationData] = useState([]); //state for add tags visibility

  const priorityLabelList = getPriorityByContextAPI(); // to fetch the priority  label from context api
  const priorityLabel = priorityLabelList.filter(
    (item) => parseInt(item.id) === parseInt(relatedTaskData?.priority),
  );

  const url = relatedTaskData?.assignee_id?.image_url;

  const precedingTask =
    relatedTaskData?.preceding_task_id > 0
      ? allRelatedTask.find((item) => item.tempId == relatedTaskData?.preceding_task_id)
      : '';

  useEffect(() => {
    let validationFields = { ...validation_fields };
    const validationError = validateTaskWithTemplateTest(validationFields, [relatedTaskData]);
    if (!validationError?.isValid) {
      setValidationData(validationError?.requiredFields);
      return;
    }
  }, []);
  useMemo(() => {
    if (expandAll) {
      setIsExpanded(expandAll);
    } else {
      setIsExpanded(expandAll);
      setExpandAll(false);
    }
  }, [expandAll]);

  // to handle subtasks in expanded view
  const handleSubTask = (data) => {
    relatedTaskData.subtasks = data;
    setTemplateDetails((prev) => ({
      ...prev,
      related_tasks: prev?.related_tasks?.map((itm, idx) => {
        if (idx === index) {
          return relatedTaskData;
        }
        return itm;
      }),
    }));
  };

  return (
    <div className={'inline-task-container w-100 my-1 px-1'}>
      <div className='d-flex flex-wrap gap-2 align-items-baseline  justify-content-between related-task-preview-head'>
        <div
          className='d-flex gap-2  align-items-baseline  flex-1 '
          onClick={() => setIsExpanded((prev) => !prev)}
          role='button'
        >
          <Play
            color={`${isExpanded ? '#3454D1' : '#87909E'}`}
            fill={`${isExpanded ? '#3454D1' : '#87909E'}`}
            size={10}
            className={`cursor-pointer ${
              isExpanded ? 'rotate-down-related ' : 'rotate-up-related'
            }`}
          />
          <div className='mb-1'>
            <div className='inline-related-task-name'>
              {!isExpanded
                ? relatedTaskData?.name.length > 70
                  ? relatedTaskData?.name?.substring(0, 70) + '...'
                  : relatedTaskData?.name
                : relatedTaskData?.name}
            </div>{' '}
          </div>
        </div>
        <div className='d-flex gap-2 justify-content-center align-items-center w-fit-content'>
          <ToolTip tooltipText={'Edit'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <Pen width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip>
          <ToolTip tooltipText={'Duplicate'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                onDuplicate();
              }}
            >
              <Copy size={10} color={'#87909E'} />
              {/* <Pen width={10} height={10} color={'#87909E'} /> */}
            </div>
          </ToolTip>
          <ToolTip tooltipText={'Delete'} isModern>
            <div
              role='button'
              className={`col-md-1 inline-related-task-item-container custom-icon `}
              onClick={(e) => {
                e.stopPropagation();
                setDeleteModal(true);
              }}
            >
              <Trash width={10} height={10} color={'#87909E'} />
            </div>
          </ToolTip>
        </div>
      </div>

      {/* related task - expanded view  */}
      <TemplateTaskPreviewExpanded
        key={relatedTaskData?.subtasks?.length}
        validation_fields={validation_fields}
        relatedTaskData={relatedTaskData}
        onSubmitSubtask={handleSubTask}
        isExpanded={isExpanded}
        precedingTask={precedingTask}
        validationFields={validation_fields}
        precedingTaskName={
          allRelatedTask.find(
            (task) => (task.tempId ?? task.id) === relatedTaskData.preceding_task_id,
          )?.name ?? ''
        }
      />

      {/* Delete modal */}
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
              <div className='del-description'>{DELETE_NOTE.RELATED_TASK}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setDeleteModal(false)}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                handleOk={() => {
                  onDelete();
                  setDeleteModal(false);
                }}
              >
                <Button.Title title={'Delete'} />
                <Button.Loading color='#FFF' />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
