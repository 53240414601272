/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation } from '@tanstack/react-query';
import { AlignJustify, Check, X } from 'lucide-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { Button } from '../../_components';
import useAlert from '../../_components/ActionPopup/useAlert';
import InputWithIcons from '../../_components/Inputs/InputWithIcons';
import { CommonDropDownStyle, DEFAULT_STATUS } from '../../_constants';
import { MENU_ICONS } from '../../_constants/template.constants';
import { statusService } from '../../_services/status.service';
import { DropDownStyle } from '../../OKR/okrConstants';
import { GlobalContext } from '../../Utils';
import { Cancel, CustomTrashIcon, Tick } from '../../Utils/SvgIcons';

export function StatusGroupAdd({
  modalClose,
  nonDefaultStatus,
  initialData,
  name,
  editData,
  editGroupId,
  isDefault,
  groupData,
  refetch,
  StatusData,
}) {
  const { makeAlert } = useContext(GlobalContext);

  const isMutating = useIsMutating();

  const [groupStatus, setGroupStatus] = useState(editData || initialData);
  const [submitted, setSubmitted] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({ id: null, groupId: null, isDelete: false });
  const [groupName, setGroupName] = useState(name || '');

  const dragItem = useRef(0);
  const dragEnterItem = useRef(0);

  // const options = useMemo(
  //   () => StatusData?.map((status) => ({ label: status.name, value: status.id, data: status })),
  //   [StatusData],
  // );

  const options = useMemo(
    () =>
      StatusData?.filter(
        (status) =>
          status.default_name === DEFAULT_STATUS.IN_PROGRESS ||
          status.default_name === DEFAULT_STATUS.FAILED,
      ).map((status) => ({ label: status.name, value: status.id, data: status })),
    [StatusData],
  );

  //-- when input status change
  // const handleChange = (status, value, property) => {
  //   setGroupStatus((prev) => {
  //     return prev.map((item) => {
  //       if (item.id === status.id) {
  //         return { ...item, [property]: value };
  //       }
  //       return item;
  //     });
  //   });
  // };

  const { makeActionAlert } = useAlert();

  const handleChange = (value, property, index) => {
    setGroupStatus((prev) => {
      const updatedStatus = [...prev];
      updatedStatus[index] = { ...updatedStatus[index], [property]: value };
      return updatedStatus;
    });
  };

  const handleDelete = (index, statusGroup) => {
    // Find the item to be deleted
    const itemToDelete = statusGroup[index];
    const defaultNameToDelete = itemToDelete.default_name || itemToDelete.status_default_name;
    setDeleteStatus({ id: null, groupId: null, isDelete: false });

    if (!itemToDelete) {
      makeAlert({ type: 'error', message: 'Item to delete not found.' });

      return;
    }

    // Count the number of 'in-progress' and 'failed' statuses before deletion
    const inProgressCount = statusGroup.filter(
      (item) => (item.default_name || item.status_default_name) === 'in-progress',
    ).length;
    const failedCount = statusGroup.filter(
      (item) => (item.default_name || item.status_default_name) === 'failed',
    ).length;

    // Check if the item to delete is the last 'in-progress' or 'failed' status
    if (
      (inProgressCount === 1 && defaultNameToDelete === 'in-progress') ||
      (failedCount === 1 && defaultNameToDelete === 'failed')
    ) {
      makeAlert(
        'The status group must contain at least one "in-progress" and one "failed" status.',
        'error',
      );

      return;
    }

    // Filter out the item to be deleted
    const updatedStatusGroup = statusGroup.filter((item, i) => i !== index);

    // Proceed with updating the state
    setGroupStatus(updatedStatusGroup);
  };

  // //--when delete status
  // const handleDelete = (id, statusGroup) => {
  //

  //   return;
  //   // setGroupStatus((prev) => {
  //   //   return prev.filter((item) => item.id !== id);
  //   // });
  // };

  //-- when status choose from select drop
  const handleSelect = (statusData) => {
    setGroupStatus((prev) => [...prev, statusData.data]);
  };

  // //-- drag and drop handle
  // const handleDragEnd = () => {
  //   // If either dragItem or dragEnterItem is null, or if they are equal, do nothing
  //   if (dragItem === null || dragEnterItem === null || dragItem === dragEnterItem) {
  //     return;
  //   }
  //   // Copy the array to avoid mutating the original array
  //   const groupStatusClone = [...groupStatus];
  //   const dragStatus = groupStatusClone[dragItem.current];
  //   groupStatusClone[dragItem.current] = groupStatusClone[dragEnterItem.current];
  //   groupStatusClone[dragEnterItem.current] = dragStatus;

  //   setGroupStatus(groupStatusClone);
  // };

  const handleDragEnd = () => {
    // If either dragItem or dragEnterItem is null, or if they are equal, do nothing
    if (
      dragItem.current === null ||
      dragEnterItem.current === null ||
      dragItem.current === dragEnterItem.current
    ) {
      return;
    }

    // Copy the array to avoid mutating the original array
    const groupStatusClone = [...groupStatus];

    // Remove the dragged item from its original position
    const [draggedItem] = groupStatusClone.splice(dragItem.current, 1);

    // Insert the dragged item at the target position
    groupStatusClone.splice(dragEnterItem.current, 0, draggedItem);

    // Update the state with the new array
    setGroupStatus(groupStatusClone);

    // Reset dragItem and dragEnterItem
    dragItem.current = null;
    dragEnterItem.current = null;
  };

  const handleGroupAddUpdate = async (groupData) => {
    const newData = groupData.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    // return;
    setSubmitted(true);
    if (groupName) {
      const groupObj = {
        name: groupName,
        groupData: newData,
        id: editGroupId,
        is_default: isDefault,
      };
      await statusService.statusGroupAdd(groupObj);
      // queryClient.invalidateQueries({ queryKey: ['group-status-list'] });
      refetch();
      modalClose();
      makeAlert('Success');
    }
  };

  // --react query fot mutation function
  const {
    mutate: handleMutate,
    isError,
    error,
  } = useMutation({
    mutationFn: handleGroupAddUpdate,
  });

  useEffect(() => {
    if (isError) {
      makeAlert(error?.message, 'error');
    }
  }, [isError]);

  //-- group selected status
  const groupStatusList = groupStatus?.map((status, index) => {
    const defaultName = status.status_default_name || status?.default_name;

    let is_visible =
      defaultName === DEFAULT_STATUS.IN_PROGRESS || defaultName === DEFAULT_STATUS.FAILED;

    return (
      <>
        <div
          draggable
          onDragOver={(e) => e.preventDefault()}
          onDragStart={() => (dragItem.current = index)}
          onDragEnter={() => (dragEnterItem.current = index)}
          onDragEnd={() => handleDragEnd()}
          className='status-drag-contain'
        >
          <div>
            <AlignJustify size={18} opacity={0.7} cursor={'pointer'} />
          </div>
          <div className='status-wrap'>
            {defaultName && <div className='pos-default'>{defaultName}</div>}
            {/* <div> */}
            <InputWithIcons
              onIconChange={(icon) => handleChange(icon.name, 'icon', index)}
              getIconUrl={(icon) => icon.path}
              iconList={MENU_ICONS}
              findIcon={(icon) => status?.icon === icon.name}
              inputVisibility={false}
              iconContainerClass={'status-group-icon-container'}
              inputIconWrapper=''
            />
            {/* </div> */}
            <div className='group-color'>
              <input
                onChange={(e) => handleChange(e.target.value, 'color', index)}
                type='color'
                className='color_picker'
                value={status?.color || '#000000'}
              />
            </div>

            <input
              className='status-non-inp'
              onChange={(e) => handleChange(e.target.value, 'name', index)}
              value={status?.name}
              type='text'
            />
            {is_visible && !deleteStatus?.isDelete && (
              <div
                className='dl-contain pointer'
                onClick={() => setDeleteStatus({ id: index, groupId: groupStatus, isDelete: true })}
              >
                <CustomTrashIcon size={16} />
              </div>
            )}
            {deleteStatus?.isDelete && index === deleteStatus.id && (
              <div style={{ display: 'flex', gap: 10 }}>
                <div className='icon-container'>
                  <X
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteStatus({ id: null, groupId: null, isDelete: false });
                    }}
                    size={16}
                  />
                </div>
                <div className='icon-container'>
                  <Check
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(index, groupStatus);
                    }}
                    size={16}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  });

  return (
    <>
      <HeadAndClose modalClose={modalClose} />
      <section className='group-section'>
        <div className='mb-2'>
          <p className='group-head'>Group name</p>
          <input
            className='input-box'
            onChange={(e) => setGroupName(e.target.value)}
            type='text'
            value={groupName}
          />
          {submitted && !groupName && <p className='status-error '>group name required </p>}
        </div>
        <div className='mb-2'>
          <p className='group-head'>Select status to add</p>
          <Select
            className='full-select-style'
            styles={CommonDropDownStyle}
            onChange={(e) => handleSelect(e)}
            // isMulti
            options={options}
          />
        </div>
        {/* list of status */}
        <div className='status-list'>{groupStatusList}</div>
        {/* end list of status */}
      </section>
      <SaveAndCancel
        isSubmitting={isMutating}
        onCancel={modalClose}
        onSave={() => handleMutate(groupStatus)}
      />
    </>
  );
}

const HeadAndClose = ({ modalClose }) => {
  return (
    <div className='modal_header'>
      <p className='group-head-txt'>Status Group Add</p>
      <X size={20} cursor={'pointer'} onClick={() => modalClose()} />
    </div>
  );
};

//--save and cancel button
const SaveAndCancel = ({ onCancel, isSubmitting, onSave }) => {
  return (
    <div className=' modal_footer'>
      <Button.Container type='button' handleOk={onCancel} className={'cancel-btn'}>
        <Cancel />
        <Button.Title title='Cancel' />
      </Button.Container>
      <Button.Container isDisabled={isSubmitting} handleOk={onSave}>
        <Tick />
        <Button.Title title='Save' />
        <Button.Loading isLoading={isSubmitting} />
      </Button.Container>
    </div>
  );
};
