import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatString, getCurrentStatusIcon, isActive } from '../Utils';

// eslint-disable-next-line no-unused-vars
export function TaskTabs({ taskStatusList = [], isMainTaskList }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentStatus = searchParams.get('status');
  const containerRef = useRef(null);

  const updateTabPosition = (index, isClick = false) => {
    if (index < 0 || !containerRef.current) {
      return;
    }

    const requiredElement = containerRef.current.children[index];

    if (requiredElement) {
      window.requestAnimationFrame(() => {
        requiredElement.scrollIntoView({
          behavior: 'smooth',
          block: isClick ? 'start' : 'nearest',
          inline: 'center',
        });
      });
    }
  };
  // For scrolling to the active tab if is in the overflowed section.
  useEffect(() => {
    const currentTabIndex = taskStatusList.findIndex((item) => isActive(item.default_name));
    updateTabPosition(currentTabIndex);
  }, [containerRef, currentStatus]);

  return (
    <section className='task_tabs sticky_tab'>
      <div
        className='task_tab_contain'
        ref={containerRef}
        // style={{ paddingLeft: isMainTaskList ? 0 : 15 }}
      >
        {taskStatusList.map((status, index) => {
          return (
            <div
              key={index}
              className={`${
                isActive(status?.default_name)
                  ? 'task_tab_active user-select-none'
                  : 'task_tab_inactive user-select-none'
              }`}
              onClick={() => {
                const updatedSearchParams = new URLSearchParams(searchParams);
                updatedSearchParams.set('pageNo', 1);
                updatedSearchParams.set('status', formatString(status?.default_name));
                setSearchParams(updatedSearchParams);
              }}
            >
              {getCurrentStatusIcon(status.icon)}
              <div
                className='fz-14px-rem '
                style={{ minWidth: 'fit-content' }}
              >{`${status?.name} `}</div>
              <div
                // className='task-count-border'
                className={`${
                  isActive(status?.default_name)
                    ? 'task-count-border-active'
                    : 'task-count-border-inactive'
                }`}
              >
                {' '}
                {`${status?.task_count}`}
              </div>
              {/* {`${status?.name}  (${status?.task_count})`} */}
            </div>
          );
        })}
      </div>
    </section>
  );
}
