import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Plus } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Modal, PaginationComponent } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import NoPermission from '../_components/NoPermission';
import { Popover } from '../_components/Popover';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { LIST_PERMISSION_VALUE } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { folderService } from '../_services';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { GlobalContext, Images } from '../Utils';
import { Cancel, FolderListIcon, FolderViewIcon, Tick } from '../Utils/SvgIcons';
import EmptyFolder from './EmptyFolder';
import { FolderAdd } from './FolderAdd';
import { FolderListView } from './FolderListView';
import { FolderView } from './FolderView';

export default function FolderList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const listViewType = searchParams.get('type') || 'folder';
  const [isAddFolder, setIsAddFolder] = useState(false);
  const [deleteModal, setDeleteModal] = useState(null);
  const queryClient = useQueryClient();
  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);

  const [query, setQuery] = useState({
    select: ['id', 'name'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 12,
    },
    searchKey: '',
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: folderData,
    error: folderError,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['folder-list', debouncedSearch, query?.pageVo?.pageNo],
    queryFn: () => folderService.folderList(query),
    enabled: roleBasedPermission?.ACTION_FILE_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
    placeholderData: keepPreviousData,
  });

  if (roleBasedPermission?.ACTION_FILE_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }
  const listItemCount = folderData?.data?.rows.length;

  const handleDeactivate = async () => {
    try {
      await folderService.folderDeactivate({ id: deleteModal });
      let isNeedToBack = folderData?.data?.rows.length === 1 && folderData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('folder-list');
      setDeleteModal(null);
      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');
      console.error('Error deleting folder :', error);
    }
  };
  const { containerRef: folderAddRef } = useOutsideClickHandler({
    onClose: () => setIsAddFolder(false),
  });
  const handleViewTypeChange = () => {
    const newType = listViewType === 'list' ? 'folder' : 'list';
    searchParams.set('type', newType);
    setSearchParams(searchParams);
  };

  return (
    <div>
      <div className='folder-list-container'>
        <ErrorComponent error={folderError?.message} />

        {isSuccess && folderData?.data?.count === 0 && !debouncedSearch ? (
          <EmptyFolder />
        ) : (
          <>
            <div className='tab_contain m-0 '>
              <div className='d-flex w-100 justify-content-between'>
                <SearchBar
                  handleSearch={(event) => {
                    searchParams.set('pageNo', 1);
                    setSearchParams(searchParams);
                    setQuery({
                      ...query,
                      searchKey: event.target.value,
                      pageVo: { ...query.pageVo, pageNo: 1 },
                    });
                  }}
                  searchKey={query.searchKey}
                />

                <div>
                  <div className='d-flex gap-1 align-items-center'>
                    <div className='position-relative'>
                      {roleBasedPermission?.ACTION_FILE_ADD && (
                        <ToolTip tooltipText={'Add Folder'} isModern hideTooltip={isAddFolder}>
                          <div
                            className='menu-item-outline'
                            onClick={() => {
                              setIsAddFolder(true);
                            }}
                            style={{ background: isAddFolder ? '#e9f2ff' : '' }}
                          >
                            <Plus size={16} color={isAddFolder ? '#3454d1' : '#87909E'} />
                          </div>
                        </ToolTip>
                      )}
                      {isAddFolder && (
                        <Popover.PopoverItem
                          onClose={() => setIsAddFolder(false)}
                          positionLeft={(width) => -330 + width}
                          scrollRef={folderAddRef}
                        >
                          <div ref={folderAddRef}>
                            <FolderAdd
                              position={POSITION.RIGHT}
                              handleClose={(event) => {
                                if (event) {
                                  event.stopPropagation();
                                }

                                setIsAddFolder(false);
                                setQuery((prev) => ({
                                  ...prev,
                                  pageVo: { ...prev.pageVo, pageNo: 1 },
                                }));
                              }}
                            />
                          </div>
                        </Popover.PopoverItem>
                      )}
                    </div>

                    <ToolTip tooltipText={'Switch View'} isModern>
                      <div
                        className='menu-item-outline'
                        onClick={handleViewTypeChange}
                      >
                        {listViewType === 'list' ? (
                          <FolderListIcon color='#3454d1' />
                        ) : (
                          <FolderViewIcon color='#3454d1' />
                        )}
                      </div>
                    </ToolTip>
                  </div>
                </div>
              </div>
            </div>
            <section className='folder-contents'>
              <div className='py-4'>
                {listViewType === 'folder' ? (
                  <FolderView
                    setDeleteModal={setDeleteModal}
                    list={folderData?.data?.rows}
                    count={listItemCount}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    alert={makeAlert}
                    isSuccess={isSuccess}
                  />
                ) : (
                  <FolderListView
                    setDeleteModal={setDeleteModal}
                    list={folderData?.data}
                    count={listItemCount}
                    isSuccess={isSuccess}
                    isFetching={isFetching}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </section>
            {listItemCount > 0 && (
              <section className='mt-2'>
                <PaginationComponent
                  pageOnClick={(data) => {
                    setQuery((prev) => ({
                      ...prev,
                      pageVo: {
                        ...prev.pageVo,
                        pageNo: data,
                      },
                    }));
                    searchParams.set('pageNo', data);
                    setSearchParams(searchParams);
                  }}
                  demoData={folderData?.data}
                />
              </section>
            )}
          </>
        )}
      </div>

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(null)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.FOLDER}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setDeleteModal(null)}
                className={'status-del-cancel'}
              >
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate()}>
                <Tick />
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
