import { Eye, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Td, Tr } from 'react-super-responsive-table';
import { Button, Modal } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { Images } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { convertMillisecondsToHoursAndMinutes } from './timesheetMisc';

export const TimeSheetListBody = ({ data, handleClick, handleDelete }) => {
  const [isDeleteModal, setIsDeleteModal] = useState({ modal: false, id: null });
  const modalOpen = (id) => {
    setIsDeleteModal({ modal: true, id: id });
  };
  const modalClose = () => {
    setIsDeleteModal({ modal: false, id: null });
  };
  const onDelete = (id) => {
    handleDelete(id);
    setIsDeleteModal({ modal: false, id: null });
  };
  const rows = data.map((timesheet, index) => {
    return (
      <Tr key={index}>
        <Td>{index + 1}</Td>
        <Td>{timesheet?.timesheet_name}</Td>
        <Td>{moment(parseInt(timesheet?.timesheet_from_date)).format('DD-MMM-YYYY')}</Td>
        <Td>{moment(parseInt(timesheet?.timesheet_to_date)).format('DD-MMM-YYYY')}</Td>
        <Td>{timesheet?.client_name}</Td>
        <Td>{convertMillisecondsToHoursAndMinutes(timesheet?.timesheet_total_hours)}</Td>
        <Td>{convertMillisecondsToHoursAndMinutes(timesheet?.timesheet_billing_hours)}</Td>
        <Td>
          <div className='d-flex gap-2'>
            <ToolTip
              tooltipText={'View Time Sheet'}
              isModern
              popOver
              //   scrollRef={tableContainerRef}
            >
              <div
                className='icon-bg pointer'
                onClick={() => {
                  handleClick(timesheet);
                  //   navigate(`/${RT.MY_TEAM}/${user?.id}`);
                }}
              >
                <Eye size={15} />
              </div>
            </ToolTip>
            <ToolTip
              tooltipText={'Delete Time Sheet'}
              isModern
              popOver
              //   scrollRef={tableContainerRef}
            >
              <div className='icon-bg pointer' onClick={() => modalOpen(timesheet.timesheet_id)}>
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
        <td></td>
      </Tr>
    );
  });
  return (
    <>
      {rows}
      {isDeleteModal?.modal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.TIME_SHEET_DELETE}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'cancel-btn'}>
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => onDelete(isDeleteModal.id)}>
                <Tick />
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};
