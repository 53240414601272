/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Download } from 'lucide-react';
import moment from 'moment';
import React, { useContext } from 'react';
import { apiConstants } from '../_constants';
import { invoiceService } from '../_services/invoice.service';
import { currencyType } from '../Settings/InvoiceSettings';
import { INVOICE_IDS } from '../Settings/SettingsLabels';
import { GlobalContext } from '../Utils';
import './invoice.css';
import { TAX_CONST, TAX_LIST, TAX_TYPE_LIST, TRANSACTION_TYPE } from './InvoiceConstant';

export default function InvoiceSingle({ invoice_id }) {
  const { globalSettings } = useContext(GlobalContext);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const hasFieldExist = (labelID) => {
    const setting = invoiceSettings?.labels?.requiredFields?.find((label) => label.id == labelID);
    return setting ? setting.value : undefined;
  };

  const getInvoiceDetails = async () => {
    const response = await invoiceService.invoiceDetailsGet({ id: invoice_id });
    console.log('response', response);

    return response.data;
  };

  const { isLoading, isSuccess, error, refetch, data } = useQuery({
    queryKey: ['invoice-details'],
    queryFn: () => getInvoiceDetails(),
    enabled: invoice_id ? true : false,
  });

  const invoiceData = data?.invoiceData;
  const invoiceItems = data?.invoiceItems || [];
  const settingsData = data?.settingsData;

  function getCurrencyById(id) {
    return currencyType.find((currency) => currency.id === id);
  }

  const formattedCompanyAddress = invoiceData?.company_address
    ?.split(',')
    .map((line, index) => <div key={index}>{line.trim()}</div>);

  const formattedClientAddress = invoiceData?.company_address
    ?.split(',')
    .map((line, index) => <div key={index}>{line.trim()}</div>);

  let issuedDate = moment(parseInt(invoiceData?.bill_date)).format('D MMM YYYY');
  let dueDate = moment(parseInt(invoiceData?.due_date)).format('D MMM YYYY');

  // general settings update
  const handleSettingUpdate = async (data) => {
    let response = await invoiceService.invoiceDownload(data);
    window.open(apiConstants.imgUrlS3 + response.data, '_blank', 'noopener,noreferrer');
  };

  // --react query fot mutation function
  const {
    mutate: handleMutate,
    isError,
    isPending,
  } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  const downloadInvoice = () => {
    handleMutate({ invoice_id: invoice_id });
  };

  const companyIcon = settingsData?.labels?.organizationIcon
    ? apiConstants.imgUrlS3 + settingsData?.labels?.organizationIcon
    : null;

  const currentCurrency = getCurrencyById(parseInt(invoiceData?.client_currency));

  console.log('currentCurrency', currentCurrency);

  return (
    <section style={{ overflow: 'auto', padding: '10px 10px 10px 10px' }}>
      {/* start */}
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'flex-end',
          marginBottom: 8,
          marginRight: 8,
        }}
      >
        {isPending ? (
          <div>Downloading..</div>
        ) : (
          <Download onClick={() => downloadInvoice()} size={16} style={{ cursor: 'pointer' }} />
        )}
      </div>

      {/* <div className='invoice-line'></div> */}
      {/* start */}
      {/* head */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 5px 13px 0px',
        }}
      >
        <div style={{ fontSize: 17, fontWeight: 600 }}>
          {invoiceData?.transaction_type === TRANSACTION_TYPE.INVOICE ? 'INVOICE' : 'ESTIMATE'} #
          {invoiceData?.invoice_number}
        </div>
        {companyIcon && (
          <img src={companyIcon} style={{ objectFit: 'contain', height: 20, width: 30 }} />
        )}
      </div>

      {/* head */}
      <div className='invoice-line'></div>
      <div
        style={{
          margin: '25px 0px 30px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Bill from,</div>
          <div className='invoice-txt'>{invoiceData?.company_name || '--'},</div>
          <div className='invoice-txt'>{formattedCompanyAddress || '--'}</div>
          <div className='invoice-txt'>Email: {invoiceData?.company_email || '--'}</div>
          <div className='invoice-txt'>Phone: {invoiceData?.company_phone || '--'}</div>
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Bill to,</div>
          <div className='invoice-txt'>{invoiceData?.client_name || '--'},</div>
          <div className='invoice-txt'>{formattedClientAddress || '--'}</div>
          <div className='invoice-txt'>Email: {invoiceData?.client_email || '--'}</div>
          <div className='invoice-txt'>Phone: {invoiceData?.client_phone || '--'}</div>
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Issued On,</div>
          <div>{issuedDate}</div>
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Due On,</div>
          <div>{dueDate}</div>
        </div>
        {/* section 1 */}
      </div>

      <div className='invoice-line mb-4'></div>

      <div className='invoice-sub-table-container'>
        <table className='invoice-table'>
          {/* Table Header */}
          <thead>
            <tr className='invoice-table-header'>
              <th style={{ width: '40%' }}>Item & Description</th>
              <th>Quantity</th>
              <th>Rate</th>
              {invoiceData?.tax !== TAX_CONST.NOT_SELECTED &&
                invoiceData?.tax !== TAX_CONST.TAX_EXEMPT && (
                  <th>
                    {TAX_TYPE_LIST?.find((tax) => tax.id === invoiceData?.tax_type)?.name} Amount
                  </th>
                )}
              <th>Amount</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {invoiceItems &&
              invoiceItems.length &&
              invoiceItems?.map((item, index) => (
                <tr key={index} className='invoice-table-row'>
                  <td style={{ width: '40%' }}>{item?.item_name}</td>
                  <td>{item?.quantity}</td>
                  <td>
                    {currentCurrency?.textIcon}
                    {item?.rate}
                  </td>
                  {invoiceData?.tax !== TAX_CONST.NOT_SELECTED &&
                    invoiceData?.tax !== TAX_CONST.TAX_EXEMPT && (
                      <td>
                        {currentCurrency?.textIcon}
                        {item?.item_tax_amount}
                      </td>
                    )}
                  <td>
                    {currentCurrency?.textIcon}
                    {item?.amount}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className='invoice-table-footer mt-4'>
          <div className='invoice-footer-label'>Sub Total</div>
          <div className='invoice-footer-value'>
            {currentCurrency?.textIcon}
            {invoiceData?.sub_total}
          </div>
        </div>
        {invoiceData?.tax_info?.map((tax, index) => {
          return (
            <div className='invoice-table-footer' key={index}>
              <span className='invoice-footer-label'>{tax?.name}</span>
              <span className='invoice-footer-value'>
                {currentCurrency?.textIcon}
                {/* invoiceData?.client_email */}
                {tax?.value ?? 0}
              </span>
            </div>
          );
        })}
        {invoiceData?.tax !== TAX_CONST.NOT_SELECTED &&
          invoiceData?.tax !== TAX_CONST.TAX_EXEMPT && (
            <div className='invoice-table-footer'>
              <div className='invoice-footer-label'>
                Total {TAX_TYPE_LIST?.find((tax) => tax.id === invoiceData?.tax_type)?.name} Amount
                <span style={{ fontSize: 11, fontWeight: 600 }}>
                  ({TAX_LIST.find((tax) => tax.id == invoiceData?.tax)?.name})
                </span>
              </div>
              <div className='invoice-footer-value'>
                {currentCurrency?.textIcon}
                {invoiceData?.tax_amount}
              </div>
            </div>
          )}
        {hasFieldExist(INVOICE_IDS.SHIPPING_CHARGE) && (
          <div className='invoice-table-footer'>
            <div className='invoice-footer-label'>Shipping Charge</div>
            <div className='invoice-footer-value'>
              {currentCurrency?.textIcon}
              {invoiceData?.shipping_charges}
            </div>
          </div>
        )}
        {hasFieldExist(INVOICE_IDS.ADJUSTMENT) && (
          <div className='invoice-table-footer'>
            <div className='invoice-footer-label'>Adjustments</div>
            <div className='invoice-footer-value'>
              {currentCurrency?.textIcon}
              {invoiceData?.adjustment}
            </div>
          </div>
        )}
        {/* <div className='invoice-table-footer'>
          <div className='invoice-footer-label'>Tax (VAT)</div>
          <div className='invoice-footer-value'>$5</div>
        </div> */}
        ,
        <div className='invoice-table-footer total'>
          <div className='invoice-footer-label'>Total</div>
          <div className='invoice-footer-value'>
            {currentCurrency?.textIcon}
            {invoiceData?.total}
          </div>
        </div>
      </div>
    </section>
  );
}
