/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ArrowUpDown, File, ListFilter, Plus } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
// import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { BoardView } from '../BoardView/BoardView';
import TimeBoxCalendar from '../Calander/TimeBoxCalendar';
import { DuplicateTaskAdd } from '../Duplicate/Task/DuplicateTaskAdd';
import useObserver from '../Hook/useObserver';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { useUser } from '../Hook/useUser';
import {
  convertHtmlToRawContent,
  floatToDayHour,
  fullNameUser,
  getCurrentTimeInMilliseconds,
  getHoursFromMs,
  getMinutesFromMs,
  getPeriodFromMs,
  GlobalContext,
} from '../Utils';
import { ClientIcon, LoadingIcon } from '../Utils/SvgIcons';
import { Button, Modal, TableRow, ToggleButton } from '../_components';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { MENU } from '../_constants';
import { taskService, userService } from '../_services';
import { TASK_TABLE_HEADINGS } from '../tableHeadings';
import { ExceedUserListPopUp } from './ExceedUserListPopUp';
import { TaskAdd } from './TaskAdd';
import { TaskListFilter } from './TaskListFilter';
import { TaskListSort } from './TaskListSort';
import { TaskTableBody } from './TaskTableContent';
import { TaskTabs } from './TaskTabs';
import { formMode, TASK_TABS } from './taskConstants';
import { TaskOptions } from './taskValidation';
import moment from 'moment';

export const TaskListView = ({
  templateData,
  // query,
  setQuery,
  taskData,
  userList,
  searchParams,
  setSearchParams,
  afterTaskAddFn = null,
  loading,
  isSuccess,
  // pending,
  projectId = null,
  templateId = null,
  milestoneId = null,
  taskStatusList,
  // eslint-disable-next-line no-unused-vars
  filterCount,
  // eslint-disable-next-line no-unused-vars
  sortCount,
  // noTaskAdd = true,
  refetch = () => {},
  assignee_id = null,
  actInlineAsSimple = false,
  urlQuery,
  hasNextPage,
  fetchNextPage = () => {},
  isFetchingNextPage,
  currentView,
  setCurrentView,
  clientDetails = null,
  isArchivedList = false,
}) => {
  // const navigate = useNavigate();

  const location = useLocation();
  const reference = useRef(null);
  const { roleBasedPermission, getMenuLabelName, globalSettings, makeAlert } =
    useContext(GlobalContext);
  const [isExporting, setIsExporting] = useState(false);

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  // const [currentView, setCurrentView] = useState(TASK_TABS.TASKS);
  const [isSortVisible, setIsSortVisible] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [addDuplicateModal, setAddDuplicateModal] = useState({ modal: false, id: null, data: {} });

  // eslint-disable-next-line no-unused-vars
  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  const flattedUpdateData = React.useMemo(
    () => taskData?.pages?.flatMap((page) => page.data.rows),
    [taskData],
  );

  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];

  const [expandedRows, setExpandedRows] = useState(new Map());

  // Function to toggle individual row
  const toggleRow = (newExpandedRows) => {
    if (newExpandedRows instanceof Map) {
      setExpandedRows(newExpandedRows);
    } else {
      setExpandedRows((prevRows) => {
        const newRows = new Map(prevRows);
        newRows.set(newExpandedRows, !newRows.get(newExpandedRows));
        return newRows;
      });
    }
  };

  // Function to toggle all rows
  const toggleAllRows = () => {
    const newExpandAll = !expandAll;
    setExpandAll(newExpandAll);

    if (newExpandAll && allUpdateList) {
      // Expand all rows
      const newExpandedRows = new Map(allUpdateList.map((item) => [item.id, true]));
      setExpandedRows(newExpandedRows);
    } else {
      // Collapse all rows
      setExpandedRows(new Map());
    }
  };

  // Effect to handle expand all and newly fetched items
  useEffect(() => {
    if (allUpdateList.length > 0 && !isFetchingNextPage) {
      setExpandedRows((prev) => {
        const newMap = new Map(prev);
        allUpdateList.forEach((item) => {
          if (expandAll && !newMap.has(item.id)) {
            newMap.set(item.id, true);
          } else if (!expandAll) {
            newMap.delete(item.id);
          }
        });
        return newMap;
      });
    }
  }, [isFetchingNextPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (reference.current && !reference.current.contains(event.target)) {
        setIsSortVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // to access if
    const state = location.state;
    if (state && state.taskModal) {
      setAddModal(true);
      location.state = {};
    }
  }, [location]);

  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => {
      setIsFilterVisible(false);
    },
  });
  const userId = assignee_id && assignee_id?.length === 1 ? assignee_id[0] : null;

  // Function to export data to Excel
  const exportToExcel = async () => {
    setIsExporting(true);

    // Dynamically load the XLSX library
    const XLSX = await import('xlsx');

    const flattenedData = allUpdateList.flatMap((detail, index) => {
      return {
        '#': index + 1,
        'Task Name': detail.name,
        Assignee: fullNameUser(detail?.user_details?.first_name, detail?.user_details?.last_name),
        Contact: detail?.client_name ?? '--',
        'Start date': moment(parseInt(detail?.task_start_date)).format('MMM DD, YYYY'),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'TaskList');

    XLSX.writeFile(workbook, `${moment().format('MMM DD, YYYY-HH:mm:ss')}-Task-details.xlsx`);

    setIsExporting(false);
  };

  // eslint-disable-next-line no-unused-vars
  const { data: specificUser } = useQuery({
    queryKey: ['task-add-for-user', userId],
    queryFn: () =>
      userService.userGet({
        select: ['id', 'first_name', 'last_name', 'gender', 'image_url'],
        id: userId,
      }),
    select: (data) => {
      return data?.data;
    },
    enabled: Boolean(userId),
    placeholderData: keepPreviousData,
  });

  const isIndividualType = searchParams.get('type') === 'individual';
  const isMyTask = searchParams.get('list_type') === 'my-task';

  const removedUser = searchParams.get('user_status') === 'removed' ? true : false;

  // tcode to detect the scroll bar present or not in table
  const tableContainerRef = useRef(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  useEffect(() => {
    const checkScrollbar = () => {
      if (tableContainerRef.current) {
        setHasScrollbar(
          tableContainerRef.current.scrollHeight > tableContainerRef.current.clientHeight,
        );
      }
    };

    checkScrollbar();

    window.addEventListener('resize', checkScrollbar);
    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, [tableContainerRef.current]);

  const loggedUser = useUser();

  const handleDuplicate = async (data) => {
    const responseDuplicateTask = await taskService.getTaskDuplication({ id: data?.id }).then(
      (response) => {
        const taskDuplicateData = response?.data?.[0];
       

        return {
          ...taskDuplicateData?.parent_task?.[0],
          assigned_user: taskDuplicateData?.parent_task?.[0]?.assignee_details?.[0],
          note: convertHtmlToRawContent(taskDuplicateData?.parent_task?.[0]?.note),
          milestone_id: milestoneId ?? null,
          verifying_users: taskDuplicateData?.parent_task?.[0].verify_users_details,
          label: taskDuplicateData?.parent_task?.[0]?.label?.map(String) ?? [],
          start_time: taskDuplicateData?.parent_task?.[0]?.has_time
            ? {
                hours: getHoursFromMs(taskDuplicateData?.parent_task?.[0]?.start_time),
                minutes: getMinutesFromMs(taskDuplicateData?.parent_task?.[0]?.start_time),
                period: getPeriodFromMs(taskDuplicateData?.parent_task?.[0]?.start_time),
              }
            : 0,
          end_time: taskDuplicateData?.parent_task?.[0]?.has_time
            ? {
                hours: getHoursFromMs(taskDuplicateData?.parent_task?.[0]?.end_time),
                minutes: getMinutesFromMs(taskDuplicateData?.parent_task?.[0]?.end_time),
                period: getPeriodFromMs(taskDuplicateData?.parent_task?.[0]?.end_time),
              }
            : 0,
          relatedTasks: taskDuplicateData?.related_task
            ? taskDuplicateData?.related_task?.map((tsk) => {
                const customStartTime = floatToDayHour(tsk.custom_start_time);
                return {
                  ...tsk,
                  assigned_user: tsk?.assignee_details?.[0],
                  label: tsk?.label,
                  label_details: tsk?.label_details,
                  labelDetails: tsk?.label_details,
                  custom_start_days: customStartTime.days,
                  custom_start_hours: customStartTime.hours,
                  note: convertHtmlToRawContent(tsk.note),
                  assignee_id: tsk?.assignee_details?.[0]?.id,
                  project_id: taskDuplicateData?.parent_task?.[0]?.project_id,
                  milestone_id: milestoneId ?? null,
                  subtasks: tsk?.subtasks ?? [],
                };
              })
            : [],
          validation_fields: taskDuplicateData?.validation_fields,
          is_verify_user_changeable: taskDuplicateData?.is_verify_user_changeable,
          expected_hours: Math.floor(taskDuplicateData?.parent_task?.[0]?.expected_hours),
          expected_minutes: Math.round(
            (taskDuplicateData?.parent_task?.[0]?.expected_hours % 1) * 60,
          ),
        };
      },
      (err) => {
        console.error(err);
        makeAlert(err?.message, 'err');
      },
    );

    if (responseDuplicateTask) {
      setAddDuplicateModal({ id: data.id, modal: true, data: responseDuplicateTask });
    }
  };

  // const goToBoardView = () => {
  //   const currentIds = searchParams.getAll('user_id');
  //   const listType = searchParams.getAll('list_type');

  //   let url = `/board-view?`;
  //   if (projectId) {
  //     url += `project_id=${projectId}&list_type=${TaskListTypeConst.Project}`;
  //   }
  //   if (templateId) {
  //     url += `&template_id=${templateId}&list_type=${TaskListTypeConst.Template}`;
  //   }
  //   if (milestoneId) {
  //     url += `&milestone_id=${milestoneId}`;
  //   }
  //   if (currentIds.length > 0) {
  //     for (const id of currentIds) {
  //       url += `&user_id=${id}`;
  //     }
  //   }
  //   if (!projectId && !templateId) {
  //     if (listType) {
  //       url += `&list_type=${listType}`;
  //     }
  //     // url += `&task_type=${1}`;
  //   }

  //   navigate(url);
  // };

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const TASK_LIST_HEADING = hasExist(MENU.contact.default_name)
    ? [
        ...TASK_TABLE_HEADINGS.slice(0, 5), // Take the first 5 headings
        {
          name: (
            <>
              <ClientIcon height={17} width={17} color='var(--second-text-color)' />{' '}
              {LABEL_OF_CLIENT}
            </>
          ),
          minWidth: 50,
        },
        ...TASK_TABLE_HEADINGS.slice(5), // Include the rest of the headings starting from index 5
      ]
    : TASK_TABLE_HEADINGS;

  return (
    <div className='task-list'>
      <div className='task-list-container'>
        <div
          className={`${
            currentView == TASK_TABS.TASKS ? 'tab_contain ' : 'tab_contain no_task '
          } py-1 fz-13px-rem`}
        >
          <div className='d-flex gap-2'>
            <SearchBar
              clearSearch={() => {
                searchParams.delete('search');
                setSearchParams(searchParams);
              }}
              handleSearch={(event) => {
                searchParams.set('pageNo', 1);
                if (event.target.value) {
                  searchParams.set('search', event.target.value);
                  setSearchParams(searchParams);
                } else {
                  searchParams.delete('search');
                  setSearchParams(searchParams);
                }
              }}
              searchKey={searchParams.get('search') ?? ''}
            />
            {!isIndividualType && !isMyTask && (
              <>
                <div>
                  <TaskUserList
                    visibleUsers={userList}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    assignee_id={assignee_id}
                  />
                </div>
                {assignee_id?.length > 0 && (
                  <div
                    className='user-filter-clear'
                    onClick={() => {
                      searchParams.delete('user_id');
                      searchParams.set('pageNo', 1);
                      setSearchParams(searchParams);
                      assignee_id = null;
                    }}
                  >
                    Clear Filter
                  </div>
                )}
              </>
            )}
          </div>
          <div className='d-flex gap-1 align-items-center justify-content-end'>
            <TaskOptions
              currentView={currentView}
              // setCurrentView={setCurrentView}
              isClickable={!isArchivedList}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            {/* {permissionsMap?.task_add && !removedUser && ( */}
            {/* <ToolTip
              tooltipText={'Archived Tasks'}
              isModern
              // hideTooltip={currentView != TASK_TABS.TASKS}
            >
              <div
                className='menu-item-outline'
                onClick={() => {
                  setAddModal(true);
                }}
              >
                <ArchiveIcon height={16} width={16} color='#87909E' />
              </div>
            </ToolTip> */}
            {/* )} */}
            {roleBasedPermission?.ACTION_TASK_ADD && !removedUser && (
              <ToolTip
                tooltipText={'Add Task'}
                isModern
                hideTooltip={currentView != TASK_TABS.TASKS || isArchivedList}
              >
                <div
                  className={`${
                    currentView == TASK_TABS.TASKS && !isArchivedList
                      ? 'menu-item-outline'
                      : 'disabled-menu-item-outline'
                  } `}
                  onClick={() => {
                    if (currentView == TASK_TABS.TASKS && !isArchivedList) {
                      setAddModal(true);
                    }
                  }}
                >
                  <Plus size={16} color='#87909E' />
                </div>
              </ToolTip>
            )}
            <ToolTip
              tooltipText={'Sort'}
              isModern
              hideTooltip={isSortVisible || currentView != TASK_TABS.TASKS}
            >
              <div
                className={`${
                  currentView == TASK_TABS.TASKS
                    ? 'menu-item-outline'
                    : 'disabled-menu-item-outline'
                } `}
                ref={reference}
                style={{
                  background: isSortVisible || Boolean(sortCount) ? '#e9f2ff' : '',
                }}
                onClick={() => {
                  if (currentView == TASK_TABS.TASKS) {
                    setIsFilterVisible(false);
                    setIsSortVisible(true);
                  } else {
                    console.log('disabled');
                  }
                }}
              >
                <ArrowUpDown
                  size={16}
                  color={isSortVisible || Boolean(sortCount) ? '#3454d1' : '#87909E'}
                />
                {isSortVisible && (
                  <div>
                    <TaskListSort
                      searchParams={searchParams}
                      setSearchParams={setSearchParams}
                      handleClose={(event) => {
                        event.stopPropagation();
                        setIsSortVisible(false);
                      }}
                      setQuery={setQuery}
                    />
                  </div>
                )}
              </div>
            </ToolTip>
            <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
              <div
                className='menu-item-outline'
                style={{ background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '' }}
                ref={filterRef}
                onClick={() => {
                  setIsSortVisible(false);
                  setIsFilterVisible(true);
                }}
              >
                <ListFilter
                  size={16}
                  color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                />
                {isFilterVisible && (
                  <TaskListFilter
                    projectId={projectId}
                    templateId={templateId}
                    clientId={clientDetails?.id}
                    handleClose={(event) => {
                      event.stopPropagation();
                      setIsFilterVisible(false);
                    }}
                    setSearchParams={setSearchParams}
                    currentView={currentView}
                  />
                )}
                {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
              </div>
            </ToolTip>
            <ToolTip
              tooltipText={expandAll ? 'Collapse All' : 'Expand All'}
              isModern
              hideTooltip={currentView != TASK_TABS.TASKS}
            >
              <div style={{ marginLeft: 8 }}>
                <ToggleButton
                  key={1}
                  id={1}
                  isChecked={expandAll ? 1 : 0}
                  handleToggle={() => toggleAllRows()}
                  size={25}
                  disabled={currentView != TASK_TABS.TASKS}
                />
              </div>
            </ToolTip>

            {currentView == TASK_TABS.TASKS && (
              <ToolTip
                tooltipText={isExporting ? 'Exporting...' : 'Export to Excel'}
                isModern
                // hideTooltip={currentView != TASK_TABS.TASKS || isArchivedList}
              >
                <div
                  className={`${'menu-item-outline'} `}
                  onClick={() => {
                    if (!isExporting) {
                      exportToExcel();
                    }
                  }}
                >
                  <File size={15} color='var(--second-text-color)' />
                </div>
              </ToolTip>
            )}
          </div>
        </div>
        {currentView == TASK_TABS.TASKS && (
          <>
            {!isArchivedList && (
              <TaskTabs
                taskStatusList={taskStatusList}
                isMainTaskList={Boolean(projectId) || Boolean(templateId)}
              />
            )}
            <section className='mt-2  fade-entry'>
              <div
                className={'table-container no-padding'}
                style={{ borderRadius: 4 }}
                ref={tableContainerRef}
              >
                <Table className='table tbl mb-0 task-table'>
                  <Thead>
                    <Tr className='table-head'>
                      {TASK_LIST_HEADING.map((heading, index) => {
                        return (
                          <Th
                            key={index}
                            scope='col'
                            style={{
                              minWidth: heading.minWidth,
                              maxWidth: heading.maxWidth,
                              width: index === 0 ? '50px' : 'auto',
                            }}
                          >
                            {heading.name}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {loading && (
                      <TableRow noOfCols={9}>
                        <LoadingIcon size={60} />
                      </TableRow>
                    )}
                    {!allUpdateList?.length > 0 && isSuccess && (
                      <TableRow noOfCols={9}>No data found</TableRow>
                    )}
                    {allUpdateList?.length > 0 && isSuccess && (
                      <TaskTableBody
                        urlQuery={urlQuery}
                        taskData={allUpdateList}
                        expandedRows={expandedRows}
                        toggleRow={toggleRow}
                        searchParams={searchParams}
                        refetch={refetch}
                        tableContainerRef={tableContainerRef}
                        handleDuplicate={handleDuplicate}
                        expandAll={expandAll}
                        setExpandAll={setExpandAll}
                        totalTaskCount={taskData?.pages[0]?.data?.total}
                        projectId={projectId}
                        templateId={templateId}
                        isArchivedList={isArchivedList}
                        isClientVisible={hasExist(MENU.contact.default_name)}
                      />
                    )}
                    <Tr>
                      <Td colSpan='8' style={{ height: '0px', border: 'none', padding: 0 }}>
                        <div ref={observerRef} className='d-flex justify-content-center'>
                          {hasNextPage && isFetchingNextPage && (
                            <div
                              className='cursor-pointer'
                              onClick={() => hasNextPage && fetchNextPage()}
                              style={{ height: '90px' }}
                            >
                              <LoadingIcon size={60} />
                            </div>
                          )}
                        </div>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </section>
            {!actInlineAsSimple &&
              roleBasedPermission?.ACTION_TASK_ADD &&
              !removedUser &&
              !isArchivedList && (
                <section className='inline-task'>
                  <Tr className='tr-inline-task-add'>
                    <Td colSpan='8'>
                      <TaskAdd
                        id={0}
                        handleClose={() => {
                          setAddModal(false);
                          refetch();
                        }}
                        defaultTemplate={templateData}
                        queryKey='default-template-inline'
                        tableRef={tableContainerRef}
                        selectDefaultTemplateIfPresent
                        defaultTemplateId={templateId}
                        projectID={projectId}
                        componentMode={formMode.INLINE}
                        showHeaderAndFooter={true}
                        afterTaskAddFn={afterTaskAddFn}
                        templateSelectionDisabled={templateId ? true : false}
                        milestoneID={milestoneId}
                        assignTo={userId && assignee_id?.length === 1 ? userId : loggedUser?.id}
                        // defaultAssignee={specificUser ? specificUser : loggedUser}
                        defaultAssignee={assignee_id?.length === 1 ? specificUser : loggedUser}
                        clientDetails={clientDetails}
                      />
                    </Td>
                  </Tr>
                </section>
              )}
            <section>
              {addModal && (
                <Modal.Container>
                  <Modal.Head containerClass={'task-add-head'}></Modal.Head>
                  <Modal.View className={'task-add-container'}>
                    <TaskAdd
                      id={0}
                      handleClose={() => {
                        setAddModal(false);
                        refetch();
                      }}
                      defaultTemplate={templateData}
                      queryKey='default-template-popup'
                      selectDefaultTemplateIfPresent
                      defaultTemplateId={templateId}
                      projectID={projectId}
                      afterTaskAddFn={afterTaskAddFn}
                      templateSelectionDisabled={templateId ? true : false}
                      milestoneID={milestoneId}
                      assignTo={assignee_id?.length === 1 ? assignee_id : loggedUser?.id}
                      defaultAssignee={specificUser ? specificUser : loggedUser}
                      clientDetails={clientDetails}
                      // defaultAssignee={assignee_id?.length === 1 ? specificUser : loggedUser}
                    />
                  </Modal.View>
                </Modal.Container>
              )}
              {addDuplicateModal.modal && (
                <Modal.Container>
                  <Modal.Head containerClass={'task-add-head'}></Modal.Head>
                  <Modal.View className={'task-add-container'}>
                    <DuplicateTaskAdd
                      afterTaskAddFn={afterTaskAddFn}
                      id={addDuplicateModal?.id}
                      handleClose={() => {
                        setAddDuplicateModal({ modal: false, id: null, data: {} });
                        refetch();
                      }}
                      defaultAssignee={addDuplicateModal?.data?.assigned_user}
                      taskDetails={addDuplicateModal?.data}
                    />
                  </Modal.View>
                </Modal.Container>
              )}
            </section>
          </>
        )}
        {currentView == TASK_TABS.CALENDAR && (
          <section className='mt-2'>
            <TimeBoxCalendar
              defaultPayload={{
                project_id: projectId,
                template_id: templateId,
                client_id: clientDetails?.id,
              }}
              isFrom={'task'}
              isNeedProject={projectId === null}
              isNeedTemplate={templateId === null}
              isNeedClient={Boolean(!clientDetails?.id)}
            />
          </section>
        )}
        {currentView == TASK_TABS.BOARD && (
          <section className='mt-2'>
            <BoardView
              templateData={templateData}
              project_id={projectId}
              template_id={templateId}
              client_id={clientDetails?.id}
            />
          </section>
        )}
      </div>
    </div>
  );
};

export default TaskListView;

export const TaskUserList = ({ visibleUsers, searchParams, setSearchParams, assignee_id }) => {
  if (!visibleUsers || visibleUsers.length <= 0 || visibleUsers[0] === 0) {
    return <></>;
  }

  // click user avatar to select user filter
  const handleAvatarClick = (user, allUsers) => {
    searchParams.delete('user_id');
    searchParams.set('pageNo', 1);
    allUsers.forEach((item) => {
      searchParams.append('user_id', item);
    });
    setSearchParams(searchParams);
  };

  // deselect avatar filter
  const handleAvatarDeselect = (user) => {
    const currentIds = searchParams.getAll('user_id');
    // Remove the user from currentIds
    const updatedIds = currentIds.filter((item) => item !== user);
    searchParams.delete('user_id');
    searchParams.set('pageNo', 1);

    // Append the remaining ids back to the searchParams
    updatedIds.forEach((item) => {
      searchParams.append('user_id', item);
    });
    setSearchParams(searchParams);
  };

  return (
    <StackedAvatars
      getSourceByKey={(user) =>
        user.compressed_image_url ? user.compressed_image_url : user.image_url
      }
      getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
      getGenderByKey={(user) => user.gender === 1}
      imageList={visibleUsers}
      showInitials={true}
      initialActiveIds={assignee_id ?? []}
      size={30}
      limit={5}
      isClickable
      onAvatarClick={(user, selectedUserIds) => handleAvatarClick(user, selectedUserIds)}
      onAvatarDeselect={(user) => handleAvatarDeselect(user?.id)}
      searchParams={searchParams}
      renderExceedList={({
        exceededMembers,
        updateSelection,
        toggleSelect,
        nonExceededUserList,
        setIsUserSelected,
      }) => {
        return (
          <ExceedUserListPopUp
            userList={exceededMembers}
            updateSelection={updateSelection}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            nonExceededUserList={nonExceededUserList}
            toggleSelect={toggleSelect}
            setIsUserSelected={setIsUserSelected}
          />
        );
      }}
    />
  );
};
