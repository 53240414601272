import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import parse from 'html-react-parser';
import { ArrowLeftIcon, CloudUpload, FileText, Home, PenLineIcon } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Archive } from '../Archive/Archive';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import ChatContainer from '../Chat/ChatContainer';
import { Favorite } from '../Favorites';
import { RemindersAddWithList } from '../Reminders/RemindersAddWithList';
import ActivityReport from '../Report/ActivityReports/ActivityReport';
import SubtaskListWithAdd from '../Template/SubComponents/SubtaskListWithAdd';
import {
  convertHtmlToRawContent,
  convertTimeIn12ToMillisecond,
  getHoursFromMs,
  getMinutesFromMs,
  getPeriodFromMs,
  GlobalContext,
} from '../Utils';
import { CircleLoader } from '../Utils/SvgIcons';
import IconLabelSet from '../_components/IconLabelSet';
import { MENU, RT } from '../_constants';
import { CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import { TASK_TYPES } from '../_constants/template.constants';
import { taskService } from '../_services';
import { reportServices } from '../_services/reports.service';
import RelatedTaskList from './RelatedTask/RelatedTaskList';
import { EditableDescription } from './TaskComponents';
import DeletedTaskPage, { ErrorTaskPage } from './TaskComponents/DeletedTaskPage';
import { NextAndPrev } from './TaskComponents/NextAndPrev';
import { TaskAttachmentsWithAdd } from './TaskComponents/TaskAttachmentsWithAdd';
import { TaskDelete } from './TaskComponents/TaskDelete';
import TaskEditableSingleView from './TaskComponents/TaskEditableSingleView';
import TaskRecurrence from './TaskComponents/TaskRecurrence';
import TaskWorkHistory from './TaskComponents/TaskWorkHistory';
import {
  STATUS,
  STATUS_NAME,
  subTaskSelect,
  TASK_ACTIONS,
  TaskSingleLayoutMenu,
} from './taskConstants';
import { LinkOptions, removeTagsAndGetContent } from './taskValidation';

export function Task({
  id: _id,
  tab: _tab,
  is_modal_view = false,
  currentIndex,
  breadCrumbs = true,
}) {
  const [params, setParams] = useSearchParams();
  const { makeAlert, currentTaskRefetch, globalSettings, getMenuLabelName } =
    useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const [isActionLoading, setActionLoading] = useState({ isLoad: false, value: null });
  const taskContainerRef = useRef();
  const [taskHeight, setTaskHeight] = useState(0);

  const [tabName, setTabName] = useState(_tab || params.get('tab'));
  const isArchivedTask = parseInt(params.get('task_status')) === STATUS.ARCHIVE ? true : false;
  // const { data } = useQuery({
  //   queryKey: ['task', params.get('id')],
  //   queryFn: () => {
  //     return taskService.autoStop();
  //   },
  // });
  //
  const { roleBasedPermission } = useContext(GlobalContext);

  useEffect(() => {
    if (taskContainerRef.current) {
      const taskContentHeight = taskContainerRef.current.clientHeight;
      setTaskHeight(taskContentHeight);
    }
  }, [taskContainerRef.current]);

  const id = _id || useParams().id;
  // to fetch the task data with id
  const {
    data: taskData,
    refetch,
    isLoading: isTaskLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['task', id],
    queryFn: () =>
      taskService.taskGet({
        id: id,
        action: TASK_ACTIONS.ALL,
        notification: true,
        okr_parent_id: true,
        status: isArchivedTask ? STATUS.ARCHIVE : STATUS.ACTIVE,
      }),
    select: (data) => {
      const result = data?.data;
      return {
        ...result,
        end_time: result?.end_time
          ? {
              hours: getHoursFromMs(result?.end_time),
              minutes: getMinutesFromMs(result?.end_time),
              period: getPeriodFromMs(result?.end_time),
            }
          : 0, // Check if start_time exists, otherwise format it
        start_time: result?.start_time
          ? {
              hours: getHoursFromMs(result?.start_time),
              minutes: getMinutesFromMs(result?.start_time),
              period: getPeriodFromMs(result?.start_time),
            }
          : 0, // Check if start_time exists, otherwise format it
      };
    },
  });

  // for fetch log
  const {
    data: activityLog,
    fetchNextPage,
    refetch: logRefetch,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [id, 'log-list'],
    queryFn: ({ pageParam = 1 }) =>
      reportServices.workLogList({
        task_id: id,
        select: ['id', 'action', 'status', 'time_difference', 'createdAt', 'data'],
        pageVo: { pageNo: pageParam, noOfItems: 10 },
      }),
    select: (data) => data.pages,
    enabled: Boolean(id),
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });

  const navigate = useNavigate();

  let isSubTaskEdit = false;
  // to fetch subtasks with task id
  const { data: subTasks, refetch: subTaskRefetch } = useQuery({
    queryKey: ['subtasks', id],
    queryFn: () =>
      taskService.subTaskList({
        task_id: id,
        select: subTaskSelect,
        assign_id: taskData?.assignee_details?.[0]?.id,
      }),
    select: (data) => {
      isSubTaskEdit = data?.data?.edit; // default only current user subtask can edit
      return data?.data?.data;
    },
    enabled: Boolean(taskData?.assignee_details?.[0]?.id),
  });

  // handler for task update
  const handleTaskUpdate = async (dataValues) => {
    dataValues.id = id;

    setActionLoading({ isLoad: true });
    // if (taskData?.progress_status == 1) {
    //   makeAlert('Please stop the task');
    // }

    if (dataValues?.assignee_id && dataValues?.assignee_id == taskData?.assignee_details?.[0]?.id) {
      return;
    }
    if (
      dataValues?.task_start_date &&
      dataValues?.task_end_date &&
      taskData?.task_start_date == dataValues?.task_start_date &&
      taskData?.task_end_date == dataValues?.task_end_date
    ) {
      return;
    }
    if (dataValues?.expected_hours && taskData?.expected_hours == dataValues?.expected_hours) {
      return;
    }
    if (dataValues?.priority && taskData?.priority == dataValues?.priority) {
      return;
    }
    if (dataValues?.order_seq && taskData?.order_seq == dataValues?.order_seq) {
      return;
    }
    if (dataValues?.milestone_id && taskData?.milestone_id == dataValues?.milestone_id) {
      return;
    }
    if (dataValues?.client_id && taskData?.client_id == dataValues?.client_id) {
      return;
    }

    // if (
    //   dataValues?.start_time?.hours == taskData?.start_time?.hours &&
    //   dataValues?.start_time?.minutes == taskData?.start_time?.minutes &&
    //   dataValues?.start_time?.period == taskData?.start_time?.period
    // ) {
    //   return;
    // }
    // if (
    //   dataValues?.end_time?.hours == taskData?.end_time?.hours &&
    //   dataValues?.end_time?.minutes == taskData?.end_time?.minutes &&
    //   dataValues?.end_time?.period == taskData?.end_time?.period
    // ) {
    //   return;
    // }
    if (
      dataValues?.project_id == taskData?.project_id &&
      dataValues?.status_group_item_id == taskData?.status_group_item_id &&
      dataValues?.template_id == taskData?.template_id
    ) {
      return;
    }
    if (dataValues?.project_id == taskData?.project_id) {
      delete dataValues?.project_id;
      delete dataValues?.project_name;
    }
    if (dataValues?.status_group_item_id == taskData?.status_group_item_id) {
      delete dataValues?.status_group_item_id;
    }
    if (dataValues?.template_id == taskData?.template_id) {
      delete dataValues?.template_id;
    }
    // to convert the start time value
    if (dataValues?.start_time) {
      const milliSecondTime = convertTimeIn12ToMillisecond(
        dataValues?.start_time?.hours,
        dataValues?.start_time?.minutes,
        dataValues?.start_time?.period,
      );
      dataValues.start_time = milliSecondTime;
    }
    if (dataValues?.end_time) {
      const milliSecondTime = convertTimeIn12ToMillisecond(
        dataValues?.end_time?.hours,
        dataValues?.end_time?.minutes,
        dataValues?.end_time?.period,
      );
      dataValues.end_time = milliSecondTime;
    }
    // for check validation
    const currentStartTime = convertTimeIn12ToMillisecond(
      taskData?.start_time?.hours,
      taskData?.start_time?.minutes,
      taskData?.start_time?.period,
    );
    const currentEndTime = convertTimeIn12ToMillisecond(
      taskData?.end_time?.hours,
      taskData?.end_time?.minutes,
      taskData?.end_time?.period,
    );
    // validation for start and end time
    if (
      (dataValues?.end_time && currentStartTime > dataValues?.end_time) ||
      (dataValues?.start_time && currentEndTime < dataValues?.start_time)
    ) {
      makeAlert('Please choose valid start and end time');
      return;
    }

    try {
      await taskService.taskAdd(dataValues);
      makeAlert('Task updated');
      refetch();
      logRefetch();
      setActionLoading({ isLoad: false });
    } catch (e) {
      makeAlert(e?.message);
      setActionLoading({ isLoad: false });
    }
  };

  // handler for subtask
  const handleSubtaskUpdate = async (subtask) => {
    try {
      await taskService.subTaskAdd(subtask);
      subTaskRefetch();
    } catch (e) {
      console.log(e);
      makeAlert(e?.message);
    }
  };

  /***
   * single subtask add individual subtask add
   */
  const handleSubTaskAddOrUpdate = async (
    value,
    index = null,
    status = '',
    startDate = '',
    endDate = '',
  ) => {
    if (!value) {
      return;
    }
    // Check if startDate is a Date object; if not, convert it
    const startDateObj = startDate instanceof Date ? startDate : new Date(parseInt(startDate));

    // Check if endDate is a Date object; if not, convert it
    const endDateObj = endDate instanceof Date ? endDate : new Date(parseInt(endDate));

    const subtask = {
      ...(index !== null && subTasks[index]),
      content: value,
      startDate: startDateObj,
      start_date_milli: moment(startDateObj).valueOf(),
      end_date_milli: moment(endDateObj).valueOf(),
      updated_time: moment().valueOf(),
      user_id: taskData?.assignee_details?.[0]?.id,
      status: index !== null ? status ?? subTasks[index].status : 1,
      ...(index === null && {
        task_id: id,
        created_time: moment().valueOf(),
      }),
    };
    handleSubtaskUpdate(subtask);
  };

  // to delete the subtasks
  const handleSubtaskDelete = (index) => {
    if (index == null) {
      return;
    }
    let subtask = subTasks[index];
    subtask.status = 0;
    subtask.updated_time = moment().valueOf();
    handleSubtaskUpdate(subtask);
  };

  // for handle action button and update status
  const handleAction = async (data) => {
    if (isActionLoading.isLoad) {
      return;
    }
    if (data.selected) return;
    setActionLoading({ isLoad: true, value: data?.value });
    try {
      data.id = parseInt(id);
      await taskService.updateStatus(data);
      currentTaskRefetch();
      refetch();
      logRefetch();
      setActionLoading({ isLoad: false, value: null });
    } catch (e) {
      makeAlert(e?.message);
      setActionLoading({ isLoad: false, value: null });
    }
  };

  // to go back to previous window
  const handleGoBack = () => {
    params.delete('tab');
    window.history.back();
    //
    // const pathParams = params?.get('prevpath')?.split('/');
    // if (pathParams[1] == 'template') {
    //   navigate(
    //     `/${RT.TEMPLATE}/${pathParams[2]}?status=${params.get('status')}&pageNo=${params.get(
    //       'pageNo',
    //     )}`,
    //   );
    // } else if (pathParams[1] == 'project') {
    //   navigate(
    //     `/${pathParams[1]}/${pathParams[2]}/${pathParams[3]}?status=${params.get('status')}`,
    //   );
    // } else if (pathParams[1] == 'okr-list') {
    //   navigate(`/${pathParams[1]}`);
    // } else if (pathParams[1] == 'notification') {
    //   navigate(`/${pathParams[1]}/${pathParams[2]}`);
    // } else {
    // navigate(
    //   `/${RT.TASK_LIST}?status=${params.get('status')}&list_type=${params.get('list_type')}`,
    // );
    // }
  };

  // to change tabs
  const changeTab = (newTabName) => {
    setTabName(newTabName);
    params.set('tab', newTabName);
    setParams(params, { replace: true });
  };

  // menu fields by task tabs from taskData
  const MenuFieldsBySettings = () => {
    return (
      <>
        {TaskSingleLayoutMenu.map((tab, index) => {
          if (taskData?.tabs?.find((item) => item?.label == tab?.label && item?.value))
            return (
              <div
                key={index}
                className={
                  'task-single-tab ' + (tabName === tab.name ? 'menu_active' : 'menu_inactive')
                }
                onClick={() => changeTab(tab?.name)}
                role='button'
              >
                {tab.label}
              </div>
            );
        })}
      </>
    );
  };
  const tableContainerRef = useRef(null);

  // const getBreadcrumbsLabel = () => {
  //   // if (isArchivedTask) {
  //   //   return 'Archive';
  //   // } else {
  //   return 'Responsibilities';
  //   // }
  // };

  // const getBreadcrumbsLabelNavigation = () => {
  //   // if (isArchivedTask) {
  //   //   navigate(`/${RT.ARCHIVE}?list_type=${params.get('list_type')}`);
  //   // } else {
  //   navigate(
  //     `/${RT.TASK_LIST}?status=${params.get('status') ?? 'todo'}&list_type=${
  //       params.get('list_type') ?? 'team-task'
  //     }&task_status=${params.get('task_status') ?? 1}`,
  //   );
  //   // }
  // };

  // will do the breadcrumbs later with hooks
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const getSidebarLabel = (default_name) => {
    return menuSettings?.labels.find((label) => label.default_name === default_name)?.name;
  };
  const listType = params.get('list_type');

  const getBreadcrumbsLabel = () => {
    if (listType === TaskListTypeConst.Project) {
      return `${LABEL_OF_PROJECT} Task List`;
    } else if (listType === TaskListTypeConst.Template) {
      return 'Template Task List';
    } else if (listType === TaskListTypeConst.MyTask) {
      return getSidebarLabel('my-responsibility');
    } else if (listType === TaskListTypeConst.TeamTask) {
      return getSidebarLabel('team-responsibility');
    } else if (listType === TaskListTypeConst.ContactTask) {
      return `${LABEL_OF_CLIENT} Task List`;
    } else {
      return 'Responsibilities';
    }
  };

  const getBreadcrumbsLabelNavigation = () => {
    // if (isArchivedTask) {
    //   navigate(`/${RT.ARCHIVE}?list_type=${params.get('list_type')}`);
    // } else {
    if (listType === TaskListTypeConst.Project) {
      navigate(
        `/${RT.PROJECT}/${params.get('project_id')}/${RT.PROJECT_TASK_LIST}?status=${
          params.get('status') ?? 'todo'
        }&list_type=${params.get('list_type') ?? 'team-task'}&task_status=${
          params.get('task_status') ?? 1
        }`,
      );
    } else if (listType === TaskListTypeConst.Template) {
      navigate(
        `/${RT.TEMPLATE}/${params.get('template_id')}?status=${
          params.get('status') ?? 'todo'
        }&list_type=${params.get('list_type') ?? 'team-task'}&task_status=${
          params.get('task_status') ?? 1
        }`,
      );
    } else if (listType === TaskListTypeConst.MyTask) {
      navigate(
        `/${RT.TASK_LIST}?status=${params.get('status') ?? 'todo'}&list_type=${
          params.get('list_type') ?? TaskListTypeConst.MyTask
        }&task_status=${params.get('task_status') ?? 1}`,
      );
    } else if (listType === TaskListTypeConst.TeamTask) {
      navigate(
        `/${RT.TASK_LIST}?status=${params.get('status') ?? 'todo'}&list_type=${
          params.get('list_type') ?? TaskListTypeConst.TeamTask
        }&task_status=${params.get('task_status') ?? 1}`,
      );
    } else if (listType === TaskListTypeConst.ContactTask) {
      navigate(
        `/${RT.CLIENT_SINGLE_PAGE}/${params.get('client_id')}/${RT.CLIENT_TASK_LIST}?status=${
          params.get('status') ?? 'todo'
        }&list_type=${params.get('list_type') ?? TaskListTypeConst.ContactTask}&task_status=${
          params.get('task_status') ?? 1
        }`,
      );
    } else {
      navigate(
        `/${RT.TASK_LIST}?status=${params.get('status') ?? 'todo'}&list_type=${
          params.get('list_type') ?? 'team-task'
        }&task_status=${params.get('task_status') ?? 1}`,
      );
    }
    // }
  };

  if (isError) {
    return <ErrorTaskPage message={error.message} />;
  }

  if (taskData?.status === 0) {
    return <DeletedTaskPage task={taskData?.name} />;
  }
  return (
    <div className='' ref={tableContainerRef}>
      <div
        className={`space-between breadcrumbs table-header-breadcrumbs ${
          !breadCrumbs ? 'd-none' : ''
        }`}
        style={{ zIndex: 9 }}
      >
        <div className='d-flex ptr'>
          {!is_modal_view && (
            <div
              className='back-button-container'
              onClick={(e) => {
                e.preventDefault();
                handleGoBack();
              }}
            >
              <ArrowLeftIcon strokeWidth={1.5} size={23} color='#87909E' />
            </div>
          )}
          <div className='task-breadcrumbs'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home strokeWidth={1.5} size={16} />}
              onClick={() => navigate('/')}
            />
            <img className='slash-img-icon-size m-0 mt-1' src='/icons/Lineicon.png' alt='' />
            <IconLabelSet
              label={getBreadcrumbsLabel()}
              fontWeight={400}
              icon={<FileText strokeWidth={1.5} size={16} />}
              onClick={() => getBreadcrumbsLabelNavigation()}
            />
            <img className='slash-img-icon-size m-0  mt-1' src='/icons/Lineicon.png' alt='' />
            <IconLabelSet
              label={taskData?.name}
              fontWeight={400}
              // icon={<FileText strokeWidth={1.5} size={16} />}
              // onClick={() => handleGoBack()}
              color={'#07082F'}
              textClassName='cursor-default'
            />
          </div>
        </div>
        <div className='d-flex gap-2'>
          <Archive
            taskId={taskData?.id}
            status={taskData?.status}
            refetch={refetch}
            isTaskStarted={taskData?.is_task_started}
          />
          <Favorite taskId={taskData?.id} projectId={taskData?.project_id} />
          {roleBasedPermission?.ACTION_TASK_DELETE && (
            <TaskDelete
              taskId={taskData?.id}
              status={taskData?.status}
              refetch={refetch}
              isTaskStarted={taskData?.is_task_started}
              navigate={getBreadcrumbsLabelNavigation}
              succeed_task_ids={taskData?.succeed_task_ids}
            />
          )}
          <div>
            <NextAndPrev
              currentIndex={currentIndex}
              status={isArchivedTask ? STATUS.ARCHIVE : STATUS.ACTIVE}
            />
          </div>
        </div>
      </div>
      {isTaskLoading ? (
        <div className='task-single-card d-flex w-100 justify-content-center align-items-center p-2'>
          <CircleLoader />
        </div>
      ) : (
        <div className='row  task-single-card'>
          <div className='col-lg-8 p-0 task-single-card-body '>
            <TaskEditableSingleView
              taskData={taskData}
              handleTaskUpdate={(val) => handleTaskUpdate(val)}
              handleAction={handleAction}
              isUpdating={isActionLoading}
              isArchivedTask={isArchivedTask || taskData?.status == STATUS.ARCHIVE}
              projectBasedClientList={
                taskData?.project_client_id
                  ? [{ id: taskData?.project_client_id, name: taskData?.project_client_name }]
                  : []
              }
            />
            <section className='task-menu task-single-tabs py-2'>
              <div
                className={
                  ' task-single-tab ' + (tabName == 'description' ? 'menu_active' : 'menu_inactive')
                }
                onClick={() => changeTab('description')}
                role='button'
              >
                Description
              </div>
              <MenuFieldsBySettings />

              {taskData?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK && (
                <div
                  className={
                    ' task-single-tab ' +
                    (tabName == 'work-history' ? 'menu_active' : 'menu_inactive')
                  }
                  onClick={() => changeTab('work-history')}
                  role='button'
                >
                  Work History
                </div>
              )}

              <div
                className={
                  ' task-single-tab ' + (tabName == 'log' ? 'menu_active' : 'menu_inactive')
                }
                onClick={() => changeTab('log')}
                role='button'
              >
                log
              </div>
            </section>
            <div className='task-menu-container'>
              {tabName == 'description' && (
                <EditableDescription
                  key={taskData?.note}
                  isEditable={taskData?.edit_fields?.includes('Description')}
                  handleSave={(val) => handleTaskUpdate(val)}
                  data={{ note: convertHtmlToRawContent(taskData?.note) }}
                >
                  <div className=' py-3 align-items-center ptr task-description-body'>
                    {!removeTagsAndGetContent(taskData?.note) ? (
                      <section className='clr-grey'>
                        Add a description...
                        <PenLineIcon size={18} />
                      </section>
                    ) : (
                      parse(taskData?.note, LinkOptions)
                    )}
                  </div>
                </EditableDescription>
              )}
              {tabName == 'related-task' && (
                <div className='row m-0 py-3 ' style={{ maxHeight: 200 }}>
                  <RelatedTaskList
                    taskData={taskData}
                    tableContainerRef={tableContainerRef}
                    makeAlert={makeAlert}
                  />
                </div>
              )}
              {tabName == 'subtask' && (
                <div className='subtask-container'>
                  <SubtaskListWithAdd
                    list={subTasks}
                    onAddOrUpdate={handleSubTaskAddOrUpdate}
                    onDelete={handleSubtaskDelete}
                    isHeading={false}
                    from='list'
                    isChangeStatus={isSubTaskEdit}
                    disableAdd={taskData?.default_status_name == STATUS_NAME.done || !isSubTaskEdit}
                  />
                </div>
              )}
              {tabName == 'reminders' && (
                <div className='subtask-container'>
                  <RemindersAddWithList
                    taskId={id}
                    isHeading={false}
                    userId={taskData?.assignee_details?.[0]?.id}
                    defaultAssignee={taskData?.assignee_details?.[0]}
                  />
                </div>
              )}
              {tabName == 'work-history' && (
                <div className='row py-3 sub-section'>
                  <TaskWorkHistory taskData={taskData} />
                </div>
              )}
              {tabName == 'recurring-task' && (
                <div className='subtask-container'>
                  <TaskRecurrence taskData={taskData} urlId={id} isTaskLoading={isTaskLoading} />
                </div>
              )}
              {tabName == 'log' && (
                <div className='row py-3 sub-section'>
                  <ActivityReport
                    taskId={taskData?.id}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    activityLog={activityLog}
                    hasNextPage={hasNextPage}
                  />
                </div>
              )}
            </div>
            <div className='task-menu-container'>
              <TaskAttachmentsWithAdd
                taskId={id}
                name={taskData?.name}
                ownerID={taskData?.assignee_details?.[0]?.id}
                accept={'all'}
                handleOK={() => {}}
                CustomView={
                  <div>
                    <label htmlFor='upload'>
                      <div className='upload_contain'>
                        <CloudUpload size={17} color='black' />
                        <div className='upload_txt'>Upload</div>
                      </div>
                    </label>
                  </div>
                }
              />
            </div>
          </div>

          <ChatContainer
            listPayload={{ task_id: taskData?.id }}
            initialHeight={taskHeight}
            key={id}
            isInTask={true}
            containerClassName='task-chat-container'
            listClassName={'chat-list-task'}
            headerVisibility={false}
            inputPayload={{ type: CHAT_UPDATE_TYPE.TASK, task_id: taskData?.id }}
          />
        </div>
      )}
    </div>
  );
}
