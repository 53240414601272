import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Home, Pen, Plus, Trash } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { GlobalContext, Images, MyTeamIcon } from '../Utils';
import {
  Calendar,
  Cancel,
  InvoiceIcon,
  LoadingIcon,
  PaymentIcon,
  SINoIcon,
  Tick,
} from '../Utils/SvgIcons';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { taxService } from '../_services/tax.service';
import '../common.css';
import { AddTaxModal } from './AddTaxModal';
import './invoice.css';
export function TaxList() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [addModal, setAddModal] = useState(false);

  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <InvoiceIcon height={15} width={16} primeColor='var(--second-text-color)' /> Tax
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' />
          Rate
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Created On
        </div>
      ),
      minWidth: 50,
    },

    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef();
  const reference = useRef();

  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'rate', 'createdAt'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [editTaxId, setEditTaxId] = useState(null);

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const modalClose = () => {
    setDeleteModal(false);
    setEditTaxId(null);
  };

  const {
    data: taxData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ['tax-list', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => taxService.taxList(query),
    enabled: roleBasedPermission?.ACTION_MANAGE_INVOICE,
    placeholderData: keepPreviousData,
  });
  // NO ACCESS MANAGE
  if (!roleBasedPermission?.ACTION_MANAGE_INVOICE) {
    return <NoPermission />;
  }

  // section trigger when delete option selected
  const handleDeactivate = async (id) => {
    try {
      await taxService.taxDeactivate({ id });
      let isNeedToBack = taxData?.data?.rows.length === 1 && taxData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('tax-list');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');

      console.error('Error deleting project :', error);
    }
  };

  // get the count of project
  const listItemCount = taxData?.data?.rows.length;

  const tableData = taxData?.data?.rows?.map((tax, index) => {
    const slNo = (taxData?.data?.page - 1) * taxData?.data?.limit + index + 1;

    return (
      <Tr key={tax?.id}>
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {tax?.name}
        </Td>

        <Td>{tax?.rate}%</Td>
        <Td>
          {' '}
          {new Date(tax?.createdAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip tooltipText={`Edit tax`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditTaxId(tax?.id);
                  setAddModal(true);
                }}
              >
                <Pen size={15} />
              </div>
            </ToolTip>

            <ToolTip tooltipText={`Delete tax`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditTaxId(tax?.id);
                  setDeleteModal(true);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Item'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<InvoiceIcon height={12} width={12} />}
            onClick={() => nav(`/${RT.TAX_LIST}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='project-table-container'>
          {/* header input section */}
          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* {roleBasedPermission?.ACTION_PROJECT_ADD && ( */}
                <ToolTip tooltipText={`Add Tax`} isModern>
                  <div className='menu-item-outline' onClick={() => setAddModal(true)}>
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
                {/* )} */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={taxData?.data}
              />
            </section>
          )}
        </div>

        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.COMMON}</div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Cancel />
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editTaxId)}>
                  <Tick />
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
        {addModal && (
          <Modal.Container
            handleClose={() => {
              setAddModal(false);
            }}
          >
            <Modal.View className={'w-25'}>
              <Modal.Head
                Heading={'New Tax'}
                handleClose={() => {
                  setAddModal(false);
                }}
              />
              <AddTaxModal
                id={editTaxId}
                handleClose={() => {
                  setAddModal(false);
                  setEditTaxId(null);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
