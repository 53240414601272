import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ArrowRight, Home, ListFilter } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { getArrayParam } from '../Calander/TimeBoxHelperFunctions';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, MyTeamIcon } from '../Utils';
import {
  ClientIcon,
  InvoiceIcon,
  LoadingIcon,
  PaymentIcon,
  SINoIcon,
  TotalTaskIcon,
  User,
} from '../Utils/SvgIcons';
import { Modal, PaginationComponent, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { LIST_PERMISSION_VALUE, MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService } from '../_services';
import '../common.css';
import { BillDetails } from './BillDetails';
import { BillListFilter } from './BillListFilter';
import './invoice.css';
export function InvoiceClientList() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [detailsModal, setDetailsModal] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { getMenuLabelName, roleBasedPermission, invoiceSettings } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const todayCurrent = moment().format('YYYY-MM-DD');
  // const monthStartDate = moment().startOf('month').format('YYYY-MM-DD');
  const sixMonthsAgo = moment().subtract(6, 'months').format('YYYY-MM-DD');

  console.log(todayCurrent);

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User color='var(--second-text-color)' />
          {LABEL_OF_CLIENT} name
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <TotalTaskIcon />
          Total Billable Tasks
          {/* Billable Task Count */}
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <InvoiceIcon height={15} width={16} primeColor='var(--second-text-color)' />
          Unbilled Tasks {/* Unbilled Task Count" */}
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon height={15} width={16} primeColor='var(--second-text-color)' />
          Due Amount
        </div>
      ),
      minWidth: 50,
    },

    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef();

  const [query, setQuery] = useState({
    select: ['id', 'name', 'currency'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  // filter popup reference
  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);
  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };
  // filter ---
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam(searchParams, 'label_id');
  const status = getParam('status');
  const project_id = getArrayParam(searchParams, 'project_id');
  const template_id = getArrayParam(searchParams, 'template_id');

  const filterToUrl = {};
  // if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;
  if (project_id) filterToUrl.project_id = project_id;
  if (template_id) filterToUrl.template_id = template_id;
  if (status) filterToUrl.status = status;

  const filterCount = Object.keys(filterToUrl)?.length;

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  // to get clients using react query
  const {
    data: clientData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['client-list', debouncedSearch, query?.pageVo?.pageNo, filterToUrl],
    queryFn: () =>
      clientService.clientListForBilling({
        ...query,
        filter: { ...filterToUrl },
      }),
    enabled: roleBasedPermission?.ACTION_CLIENT_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
    placeholderData: keepPreviousData,
  });
  // get the count of project
  const listItemCount = clientData?.data?.rows.length;

  const tableData = clientData?.data?.rows?.map((item, index) => {
    const slNo = (clientData?.data?.page - 1) * clientData?.data?.limit + index + 1;

    return (
      <Tr
        key={item?.id}
        onClick={() => {
          nav(
            `/${RT.LEDGER}?client_id=${item?.id}&startDate=${sixMonthsAgo}&endDate=${todayCurrent}`,
          );
        }}
        className='cursor-pointer'
      >
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {item?.name}
        </Td>

        <Td>{item?.total_billable_task_count}</Td>
        <Td>{item?.un_billable_task_count}</Td>
        <Td
          style={{
            color:
              (
                parseFloat(item?.client_total_amount) -
                  parseFloat(item?.client_total_amount_received) || 0
              ).toFixed(2) > 0
                ? 'red'
                : 'green',
          }}
        >
          {item?.currency
            ? currencyType.find((currency) => currency.id === item?.currency)?.textIcon
            : currencyTextIcon}
          {/* {(
            (parseFloat(item?.client_total_amount) || 0) -
            (parseFloat(item?.client_total_paid_amount) || 0)
          ).toFixed(2)} */}
          {/* // ${
          //   (parseFloat(item?.client_total_amount_received) -
          //     parseFloat(item?.client_total_amount) || 0) >= 0
          //     ? '+ '
          //     : ''
          // } */}
          {/* ${
           (parseFloat(item?.client_total_amount_received) -
             parseFloat(item?.client_total_amount) || 0) >= 0
             ? '+'
             : ''
         } */}
          {`
          ${(
            parseFloat(item?.client_total_amount ?? 0) -
              parseFloat(item?.client_total_amount_received ?? 0) || 0
          ).toFixed(2)}`}
        </Td>

        <Td
          className='cursor-pointer d-flex gap-4'
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {/* {item?.un_billable_task_count > 0 && ( */}
          {/* <ToolTip tooltipText={'View Client'} isModern popOver>
            <span
              onClick={() => {
                nav(
                  `/${RT.LEDGER}?client_id=${item?.id}&startDate=${monthStartDate}&endDate=${todayCurrent}`,
                );
              }}
            >
              <InvoiceIcon strokeWidth={1} height={15} width={16} />
            </span>
          </ToolTip> */}
          {/* )} */}

          {/* {item?.un_billable_task_count > 0 && ( */}
          <ToolTip tooltipText={'Generate Bill'} isModern popOver>
            <ArrowRight
              strokeWidth={1}
              height={23}
              width={25}
              onClick={() => {
                if (item?.un_billable_task_count > 0) setDetailsModal(item);
              }}
              stroke={
                item?.un_billable_task_count > 0
                  ? 'var(--main-text-color)'
                  : 'var(--second-text-color)'
              }
            />
          </ToolTip>
          {/* )} */}
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={LABEL_OF_CLIENT}
            fontWeight={400}
            icon={<ClientIcon color='#2A2E34' height={16} width={15} />}
            onClick={() => nav(`/${RT.INVOICE_CLIENT_LIST}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        {/* <ErrorComponent error={error?.message} /> */}
        <div className='common-table-container w-75'>
          {/* header input section */}

          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* {roleBasedPermission?.ACTION_PROJECT_ADD && ( */}
                {/* <ToolTip tooltipText={`Add Item`} isModern>
                  <div className='menu-item-outline' onClick={() => setDetailsModal(true)}>
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip> */}

                <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                    }}
                    ref={filterRef}
                    onClick={() => {
                      setIsFilterVisible(true);
                    }}
                  >
                    <ListFilter
                      size={16}
                      color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                    />
                    {isFilterVisible && (
                      <BillListFilter
                        setSearchParams={setSearchParams}
                        handleClose={(event) => {
                          event.stopPropagation();
                          setIsFilterVisible(false);
                        }}
                        filterToUrl={filterToUrl}
                        // searchParams={searchParams}
                      />
                    )}

                    {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                  </div>
                </ToolTip>
                {/* )} */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div className='common-table table-container no-padding' ref={tableContainerRef}>
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={clientData?.data}
              />
            </section>
          )}
        </div>

        {detailsModal?.id && (
          <Modal.Container
            handleClose={() => {
              setDetailsModal(null);
            }}
          >
            <Modal.View className={'w-50'}>
              <Modal.Head
                // Heading={editOrganizationID ? 'Edit Organization' : 'Add Organization'}
                Heading={'Bill Details'}
                handleClose={() => {
                  setDetailsModal(null);
                }}
              />
              <BillDetails
                // id={editItemId}
                handleClose={() => {
                  setDetailsModal(null);
                  refetch();
                }}
                billDetails={detailsModal}
                searchParams={searchParams}
                filter={filterToUrl}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
