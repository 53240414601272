/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { Ellipsis } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Images, generateRecurrenceString, moveRecurrenceToDays } from '../../Utils';
import { LoadingIcon, RecurIcon, RecurringTaskIcon } from '../../Utils/SvgIcons';
import { Button, ContextMenu, Modal } from '../../_components';
import { CONTEXT_MENU_OPTIONS } from '../../_constants/contextMenu.constants';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import { taskService } from '../../_services';
import RecurringTaskAddModal from '../RecurringTask/RecurringTaskAddModal';
import { STATUS, TASK_LIST_EMPTY_MESSAGE } from '../taskConstants';
import { CustomEmptyContainer } from './CustomInputs';

function TaskRecurrence({ taskData, urlId, isTaskLoading }) {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const getRecurrence = async () => {
    const response = await taskService.getRecurringList(
      taskData?.recurring_id && parseInt(taskData.recurring_id) > 0
        ? { id: taskData.recurring_id, status: STATUS.ACTIVE }
        : { task_id: taskData.id, status: 1 },
    );

    const recurrenceData = response?.data?.rows?.[0] ?? null;

    if (!recurrenceData) {
      return response.data.rows;
    }

    const updatedRecurrence = moveRecurrenceToDays(
      parseInt(recurrenceData.creation_offset),
      recurrenceData,
    );

    return [updatedRecurrence];
  };

  // Recurring data fetch
  const {
    data: recurringList,
    isLoading: isRecurrenceLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['recurring-task', taskData, urlId],
    queryFn: () => getRecurrence(),
    enabled: Boolean(taskData?.id),
    select: (data) => {
      const requiredData = data?.map((item) => ({
        ...item,
        end_date: item.end_date ? moment(parseInt(item.end_date)).format('YYYY-MM-DD') : undefined,
      }));
      return requiredData;
    },
  });

  console.log('ERROR', error);

  const generateInitRecurrenceDataOnEdit = (recurringList) => {
    if (!recurringList || recurringList?.length <= 0) {
      return null;
    }
    return recurringList[0];
  };

  return (
    <>
      <RecurringListView
        recurringList={recurringList}
        isLoading={isRecurrenceLoading || isTaskLoading}
        taskData={taskData}
        setIsAddModalVisible={setIsAddModalVisible}
        refetch={refetch}
      />
      {isAddModalVisible && (
        <RecurringTaskAddModal
          onClose={() => setIsAddModalVisible(false)}
          taskList={[taskData]}
          taskId={taskData.id}
          initialRecurringDetails={generateInitRecurrenceDataOnEdit(recurringList)}
          onSubmit={refetch}
        />
      )}
    </>
  );
}

const RecurringListView = ({
  recurringList,
  isLoading,
  setIsAddModalVisible,
  refetch,
  taskData,
}) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [currentDeletionId, setCurrentDeletionId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const isDeleteModalVisible = Boolean(currentDeletionId);
  const hasEditPrivilege = !taskData?.has_only_visibility;

  const handleRecurDeactivate = async () => {
    try {
      setIsDeleting(true);
      const response = await taskService.deactivateRecurrence({ id: currentDeletionId });
      if (response.error_code === 0) {
        // Success
        setCurrentDeletionId(null);
        refetch();
      }
    } catch (err) {
      // Handle error
    } finally {
      setIsDeleting(false);
    }
  };

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <LoadingIcon size={40} />
      </div>
    );
  }

  if (recurringList && recurringList.length > 0) {
    return (
      <div className='d-flex justify-content-between  recurring-wrapper '>
        <div className='d-flex gap-1 align-items-center'>
          <RecurIcon />
          <div className='fz-14px'>{generateRecurrenceString(recurringList[0])}</div>
        </div>
        {hasEditPrivilege && (
          <div className='fz-14px position-relative'>
            <div className='btn-wrapper pe-1 ' onClick={() => setIsContextMenuVisible(true)}>
              <Ellipsis size={18} color='black' />
            </div>
            {isContextMenuVisible && (
              <ContextMenu
                isContextVisible
                onRequestClose={() => setIsContextMenuVisible(false)}
                handleClick={(action) => {
                  if (action === CONTEXT_MENU_OPTIONS.EDIT) {
                    setIsAddModalVisible(true);
                  }
                  if (action === CONTEXT_MENU_OPTIONS.DELETE) {
                    setCurrentDeletionId(recurringList[0].id);
                  }
                }}
              />
            )}
          </div>
        )}
        {isDeleteModalVisible && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head
                handleClose={() => setCurrentDeletionId(null)}
                Heading={'Delete Confirmation'}
              />
              <Modal.Description>
                <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
                <div className='del-description'>{DELETE_NOTE.RECURRENCE}</div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container
                  isDisabled={isDeleting}
                  handleOk={() => setCurrentDeletionId(null)}
                  className={'status-del-cancel'}
                >
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container isDisabled={isDeleting} handleOk={() => handleRecurDeactivate()}>
                  <Button.Title title={'Delete'} />
                  <Button.Loading isLoading={isDeleting} color='#FFF' />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    );
  }

  return (
    <CustomEmptyContainer
      disableAdd={!hasEditPrivilege}
      Icon={() => {
        return <RecurringTaskIcon />;
      }}
      text={TASK_LIST_EMPTY_MESSAGE.recurring_empty}
      handleAdd={() => setIsAddModalVisible(true)}
    />
  );
};

export default TaskRecurrence;
