import React from 'react';
import ReactSelect from 'react-select';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import { CommonDropDownStyle, USER_LIST_ACTIONS } from '../../_constants';
import { TASK_VISIBLE_TYPE, TEMPLATE_ERROR_MSGS } from '../../_constants/template.constants';
import { userService } from '../../_services';
import { taskAssignUserSelect } from '../../Task/taskConstants';
import { fullNameUser } from '../../Utils';
import ErrorBlock from './ErrorBlock';

function RelatedTaskVisibilitySelector({
  handleChange,
  templateDetails,
  setTemplateDetails = () => {},
  userList,
  isSubmitted,
  containerRef,
}) {
  const options = [
    { label: 'Default Users', value: TASK_VISIBLE_TYPE.OWNERS },
    { label: 'All assigned users of related task', value: TASK_VISIBLE_TYPE.ALL },
    { label: 'Selected Users', value: TASK_VISIBLE_TYPE.SELECTED_USERS },
  ];

  const selectedValue = options.find((item) => item.value === templateDetails.task_visibility_type);

  return (
    <div
      style={{
        paddingTop: 20,
        borderTop: '0.5px solid var(--border-color)',
        marginTop: 18,
      }}
    >
      <div>
        {/* Related task visibility */}
        <div className='title-small mt-5px'>Related Task Visibility</div>
        <div className='row mt-5px'>
          <div className='col'>
            <ReactSelect
              options={options}
              value={selectedValue}
              onChange={(selectedValue) =>
                handleChange({
                  target: { value: selectedValue.value, name: 'task_visibility_type' },
                })
              }
              styles={CommonDropDownStyle}
            />
          </div>
        </div>
        {templateDetails.task_visibility_type === TASK_VISIBLE_TYPE.SELECTED_USERS && (
          <div className='row mt-8px'>
            <div className='col'>
              <div className='my-1 grey-rounded-container'>
                <StackedAvatars
                  selectProps={{
                    isCustomFunction: true,
                    // menuPlacement: 'top',
                    scrollRef: containerRef,
                    queryKey: 'user-list-related-task',
                    queryFn: userService.getRoleBasedUserList,
                    getFunctionParams: (searchKey) => ({
                      searchKey,
                      select: taskAssignUserSelect,
                      action: USER_LIST_ACTIONS.ACTION_TASK_VERIFY_USER_LIST,
                    }),
                  }}
                  // selectProps={{ scrollRef: containerRef }}
                  imageList={templateDetails.task_visible_users ?? []}
                  getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
                  getSourceByKey={(user) =>
                    user.compressed_image_url ? user.compressed_image_url : user.image_url
                  }
                  getGenderByKey={(user) => user.gender}
                  size={30}
                  dropdownSize={40}
                  isClearable={(user) => user.id > 0}
                  onAvatarRemove={(user) => {
                    setTemplateDetails((prev) => ({
                      ...prev,
                      task_visible_users: prev.task_visible_users.filter(
                        (item) => parseInt(item.id) !== parseInt(user.id),
                      ),
                    }));
                  }}
                  options={userList}
                  isAddable
                  justifyPosition=''
                  showInitials={false}
                  getValueByKey={(user) => user.id}
                  onAdd={(selectedUsers) => {
                    handleChange({
                      target: {
                        value: [...(templateDetails.task_visible_users ?? []), ...selectedUsers],
                        name: 'task_visible_users',
                      },
                    });
                  }}
                />
              </div>

              {/* <SearchableQuerySelect
                initialValue={userList}
                queryKey='user-list'
                isMulti
                classNames={{
                  control: () => {
                    return 'default-select';
                  },
                  menu: () => 'rs-menu',
                }}
                // className='w-50'
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} />
                )}
                onChange={(selectedOptions) =>
                  handleChange({
                    target: { value: selectedOptions, name: 'task_visible_users' },
                  })
                }
                getOptionLabel={(option) => option.first_name}
                getOptionValue={(option) => option.id}
                value={templateDetails.task_visible_users}
              /> */}
              <ErrorBlock
                errorMsg={TEMPLATE_ERROR_MSGS.TASK_VISIBLE_USER_REQUIRED}
                hasError={
                  isSubmitted &&
                  (!templateDetails.task_visible_users ||
                    templateDetails.task_visible_users?.length <= 0)
                }
              />
            </div>
          </div>
        )}

        {/* TODO: Add option for adding status change in status */}
        {/* <div className='mt-18px'>
          <input
            type='checkbox'
            name='show_status_changes_in_chat'
            checked={templateDetails.show_status_changes_in_chat}
            onChange={handleChange}
            id='temp3'
          />
          <label htmlFor='temp3' className='ms-2'>
            Show status change in chats
          </label>
        </div> */}

        {/* <div className='mt-20px'>
    <input
      type='checkbox'
      name='assign_to_main'
      onChange={handleChange}
      checked={templateDetails.assign_to_main === ALLOW_ASSIGN_TO_MAIN.ALLOW}
      value={templateDetails.assign_to_main}
      id='temp3'
    />
    <label htmlFor='temp3' className='ms-2'>
      Allow assign user to main task
    </label>
  </div> */}
      </div>
    </div>
  );
}

export default RelatedTaskVisibilitySelector;
