import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../_components';
import { Popover } from '../_components/Popover';
import { POSITION } from '../_constants/contextMenu.constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService } from '../_services';
import { Cancel, LoadingIcon, Tick } from '../Utils/SvgIcons';

export const ExistingTaskAdd = ({
  // eslint-disable-next-line no-unused-vars
  position = POSITION.LEFT,
  handleClose = () => {},
  parentId = null,
  // eslint-disable-next-line no-unused-vars
  right = 0,
  // top = 0,
}) => {
  // const popUpPosition = position === POSITION.RIGHT ? 'right' : 'left';
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    select: ['id', 'name'],
    searchKey: '',
    // pageVo: {
    //   pageNo: parseInt(searchParams.get('pageNo')) || 1,
    //   noOfItems: 10,
    // },
    // is_duration_required: true,
  });

  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });
  const [selectedTask, setSelectedTask] = useState([]);
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  // task list of current user and under user

  const {
    data: taskList,
    // refetch,
    // error,
    isLoading: taskLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['task-list-for-okr', query, debouncedSearch],
    queryFn: () => taskService.taskListAdvanced({ ...query, exclude_okr_id: parentId }),
    select: (data) => data?.data?.rows,
    placeholderData: keepPreviousData,
  });

  const handleCheckBoxChange = (task) => {
    setSelectedTask((prevSelectedTask) => {
      if (prevSelectedTask.includes(task)) {
        // If the task is already selected, remove it
        return prevSelectedTask.filter((selected) => selected !== task);
      } else {
        // Otherwise, add it to the selected tasks
        return [...prevSelectedTask, task];
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    selectedTask.map((data) => {
      let payload = { id: data, okr_parent_id: parentId };
      try {
        taskService.taskAdd(payload).then((res) => {
          if (res?.statusCode == 200) {
            console.log('success');
            handleClose();
          } else {
            console.log('fail');
          }
        });
      } catch (e) {
        console.log(e);
      }
    });
  };

  return (
    <Popover.PopoverItem
      onClose={handleClose}
      positionLeft={(width) => width}
      // positionLeft={() => 710}
    >
      <div
      // className='file-pop-up '
      // style={{
      //   top: top,
      //   // right: right,
      // }}
      >
        <div
          className={` filter-container  `}
          style={{
            padding: 0,
            width: 500,
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
            background: 'var(--bg-primary)',
          }}
        >
          <div
            style={{
              boxShadow: scrollFlag.isTopReached ? '' : '0 6px 5px -5px #091e4229',
            }}
          >
            <div className='objective-add-head ' style={{ padding: '  15px 15px 0 15px' }}>
              <h6 className='menu-item-head'>Add Existing Tasks</h6>
            </div>

            <div className='d-flex gap-2'>
              <div className='search_contain' style={{ width: '100%', margin: '5px 15px' }}>
                <input
                  onChange={(event) => {
                    searchParams.set('pageNo', 1);
                    setSearchParams(searchParams);
                    setQuery({
                      ...query,
                      searchKey: event.target.value,
                      pageVo: { ...query.pageVo, pageNo: 1 },
                    });
                  }}
                  placeholder='Search...'
                  type='text'
                  className='bodr_none'
                />
                <Search size={18} color='#87909E' />
              </div>
            </div>
          </div>

          {taskLoading && isSuccess && (
            <div className='text-center'>
              <LoadingIcon size={50} />
            </div>
          )}

          {!taskLoading && isSuccess && (
            <div onScroll={handleScroll} className='task-assign-popup-body'>
              {taskList?.map((data) => {
                return (
                  <div className='d-flex align-items-center gap-3 mb-2 px-3' key={data?.id}>
                    <input
                      className='project_menu_check_box '
                      type='checkbox'
                      checked={selectedTask.includes(parseInt(data?.id))}
                      //   checked={checkedItems.some((item) => parseInt(item.id) === parseInt(user?.id))}
                      onChange={() => handleCheckBoxChange(parseInt(data?.id))}
                    />
                    <span className='flex-grow-1'>
                      <div className='d-flex align-items-center'>
                        <span className='name-overflow'>{data?.name}</span>
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          )}

          {!taskLoading && isSuccess && taskList.length === 0 && (
            <div className='text-center w-100 mb-2 '>No Data</div>
          )}

          {/* button section  */}

          <div
            className='modal_footer'
            style={{
              boxShadow: scrollFlag.isBottomReached
                ? ''
                : '0 -6px 5px -5px var(--shadow, #091e4229)',
            }}
          >
            <Button.Container
              className={'cancel-btn'}
              type='button'
              handleOk={handleClose}
            >
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container
              type='submit'
              className='blue-btn'
              handleOk={handleSubmit}
              isDisabled={isLoading}
            >
              <Tick />
              <Button.Title title={'Add '} /> <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </div>
    </Popover.PopoverItem>
  );
};
