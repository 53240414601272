/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Utils/Contexts';
import { LoadingIcon, Tick } from '../Utils/SvgIcons';
import { Button } from '../_components';
import { settingsService } from '../_services';
import { MENU_LABELS } from './SettingsLabels';
import { CheckBoxWithHeading, Description } from './UtilsInSettings';

export function MenuSettings() {
  const [query] = useState({ select: ['name', 'labels'], name: 'menu-settings' });

  const {
    data: menuSettingsData,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['menu-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  return (
    <section className='common_contain'>
      {isLoading && (
        <div className='loading-wrapper'>
          <LoadingIcon size={70} />
        </div>
      )}
      {isSuccess && <MenuForm refetch={refetch} initialData={menuSettingsData?.labels} />}
    </section>
  );
}

// --form component
const MenuForm = ({ initialData, refetch }) => {
  //-- hooks
  const isMutating = useIsMutating();
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);

  //-- state
  const [menuSettings, setMenuSettings] = useState(initialData || MENU_LABELS);
  const [hasChanged, setHasChanged] = useState(false);

  //-- effect
  useEffect(() => {
    setHasChanged(JSON.stringify(menuSettings) !== JSON.stringify(initialData || MENU_LABELS));
  }, [menuSettings]);

  // handle change for color and slogan
  const handleChange = (id, value) => {
    setMenuSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );
  };

  // handle change text
  const handleTextChange = (id, newText) => {
    setMenuSettings((prevSettings) =>
      prevSettings.map((MENU) => (MENU.id === id ? { ...MENU, label: newText } : MENU)),
    );
  };

  // menu settings update
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'menu-settings';
    await settingsService.settingsAdd(newQuery);
    makeAlert('Success');
    setHasChanged(false);
    //-- refetching global settings and menu settings
    refetch();
    globalSettingsRefetch();

    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  return (
    <>
      {menuSettings?.map((MENU) => {
        if (MENU.type === 'check-box') {
          return (
            <CheckBoxWithHeading
              id={MENU.id}
              key={MENU.id}
              heading={MENU.label}
              isChecked={MENU.value}
              handleCheckBox={() => handleChange(MENU.id, MENU.value)}
              description={MENU?.description}
            />
          );
        } else if (MENU.type === 'other') {
          return (
            <>
              <div className='sub_container align-items-center'>
                <section className='flex-1'>
                  <div style={{ width: 200 }}>
                    <input
                      type='text'
                      className='menu-tab'
                      value={MENU.label}
                      style={{ fontSize: 13, padding: 0 }}
                      onChange={(e) => handleTextChange(MENU.id, e.target.value)}
                    />
                  </div>
                  {MENU?.description && <Description description={MENU?.description} />}
                </section>

                <input
                  className='menu_check_box'
                  type='checkbox'
                  checked={MENU.value}
                  onChange={() => handleChange(MENU.id, MENU.value)}
                />
              </div>
            </>
          );
        }
      })}

      {hasChanged && (
        <Button.Container className={'sett-save-btn'} handleOk={() => handleMutate(menuSettings)}>
          <Tick />
          <Button.Title title={'Save & Proceed'} />
          <Button.Loading isLoading={isMutating} />
        </Button.Container>
      )}
    </>
  );
};
