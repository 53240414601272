/* eslint-disable no-unused-vars */
import { Plus, Trash } from 'lucide-react';
import React, { useRef } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../../_components';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { CommonDropDownStyle } from '../../_constants';
import { headersHeadings, Methods, WHATSAPP_CONFIG_TABS } from './configitems';

function ConfigApiForm({
  onChange = () => {},
  config = {},
  handleAddHeader = () => {},
  handleHeaderChange = () => {},
  handleDeleteHeader = () => {},
  tabName,
  changeTab = () => {},
}) {
  // for tooltip
  const reference = useRef();

  return (
    <div>
      <div className='config_heading_container'>
        <ReactSelect
          styles={{
            ...CommonDropDownStyle,
            control: (styles, { isFocused }) => ({
              ...styles,
              minHeight: '28px',
              minWidth: '6rem',
              backgroundColor: 'var(--bg-primary)',
              border: '1px solid var(--border-color)',
              borderRadius: 8,
              boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
              '&:hover': {
                borderColor: isFocused
                  ? '#3454D1'
                  : styles['&:hover']?.borderColor || styles.borderColor,
              },
              fontSize: 13,
            }),
          }}
          options={Methods}
          getOptionLabel={(option) => {
            return option?.label;
          }}
          getOptionValue={(option) => {
            return option?.value;
          }}
          onChange={(selectedOption) => {
            onChange({
              target: {
                name: 'method',
                value: selectedOption.value,
              },
            });
          }}
          value={Methods.find((option) => option?.value === config.method) || null}
        />
        <input
          type='text'
          className='input-box border-f0'
          name='ip_address'
          value={config?.ip_address}
          placeholder='Enter Ip Address'
          onChange={(event) => {
            onChange(event);
          }}
        />
        <Button.Container>
          <Button.Title title={'Send'} />
        </Button.Container>
      </div>
      <section className='task-menu task-single-tabs m-0  config_heading_container'>
        {WHATSAPP_CONFIG_TABS.map((tab, index) => {
          return (
            <div
              key={tab.name}
              className={`config_child_tab ${tabName === tab.value ? ' child_tab_active' : ''}`}
              onClick={() => changeTab(tab.value)}
              role='button'
            >
              {tab.name}
            </div>
          );
        })}
      </section>
      {tabName === 'headers' && (
        <>
          <table className='table table-hover tbl mb-0 mt-2 headers_table'>
            <thead className='headers_heading_container'>
              <tr className='table-head'>
                {headersHeadings.map((heading, index) => {
                  return (
                    <th key={index} scope='col'>
                      {heading.name}
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {config?.headers?.map((header, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type='text'
                      placeholder='Key'
                      value={header.key}
                      onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                      className='headers_input_container'
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      placeholder='Value'
                      value={header.value}
                      onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                      className='headers_input_container'
                    />{' '}
                  </td>
                  <td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
                    <div className='d-flex gap-1'>
                      <div
                        className='d-flex gap-2 justify-content-end icon-div invisible'
                        style={{ width: 'fit-content' }}
                      >
                        <ToolTip tooltipText={'Delete'} isModern scrollRef={reference}>
                          <div
                            className='subtask-icon-bg'
                            onClick={() => handleDeleteHeader(index)}
                          >
                            <Trash size={15} />
                          </div>
                        </ToolTip>
                      </div>
                      <div className='icon-div invisible' style={{ width: 'fit-content' }}></div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <section className='mt-2'>
            <div className='w-100 d-flex align-items-center ms-1 '>
              <ToolTip tooltipText={'Add new header'} scrollRef={reference} popOver isModern>
                <div
                  role='button'
                  onClick={() => {
                    handleAddHeader();
                  }}
                  className='pe-1 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper my-1 '
                >
                  <Plus strokeWidth={1.5} color='#879096' size={18} />
                </div>
              </ToolTip>
            </div>
          </section>
        </>
      )}
      {tabName === 'body' && (
        <textarea
          className='body_text_container'
          name='body'
          onChange={onChange}
          value={config?.body}
        />
      )}
    </div>
  );
}

export default ConfigApiForm;
