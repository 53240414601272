/* eslint-disable no-unused-vars */
import { useQueryClient } from '@tanstack/react-query';
import { Eye, EyeOff } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RT } from '../_constants';
import { authService } from '../_services';
import { GlobalContext, Images } from '../Utils';
import './auth.css';

const Login = () => {
  let { globalSettingsRefetch, currentTaskRefetch, getRolePermission } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const [user, setUser] = useState({
    username: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setError('');
    }, 3000);
    return () => clearTimeout(timeout);
  }, [error]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { username, password } = user;
    const PayLoad = { username: username.toString().trim(), password: password };
    if (username && password) {
      setIsLoading(true);
      try {
        const login = await authService.login(PayLoad);
        globalSettingsRefetch();
        currentTaskRefetch();
        getRolePermission();

        if (login?.data?.is_profile_completed === false) {
          navigate(`/${RT.INVITE_ACCEPT}?view=signUp`);
        } else {
          globalSettingsRefetch();
          currentTaskRefetch();
          // getPermissions();

          navigate('/');
        }
      } catch (error) {
        if (error.message) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <div className='login_header_contain'>
        {' '}
        <div className='login_company_icon_contain ' style={{ border: 'none' }}>
          <img src={Images.hidile} />
        </div>
      </div>
      <div className='login_body_container'>
        <div className='row d-flex' style={{ gap: '100px' }}>
          <div className='col-md-5  login_left_side'>
            <div className='mt-5'>
              <div>
                <div className='login-welcome-txt'>Welcome to HiDesk</div>
                <div className='login-welcome-txt' style={{ color: '#87909E' }}>
                  Sign in to continue
                </div>
              </div>

              <form name='form' onSubmit={handleSubmit}>
                <div className='card_Container mt-3'>
                  <div>
                    <label className='mt-2 login_label mb-1' htmlFor='username'>
                      Username
                    </label>
                    <input
                      type='text'
                      className={
                        'input-box ' + (submitted && !user.username ? ' login-has-error' : '')
                      }
                      style={{ borderColor: 'var(--second-text-color)' }}
                      name='username'
                      id='username'
                      value={user.username}
                      onChange={handleChange}
                    />
                    {submitted && !user.username && (
                      <div className='help-block'>Username is required</div>
                    )}
                  </div>
                  <div>
                    <label className='mt-2 login_label mb-1' htmlFor='password'>
                      Password
                    </label>
                    {/* <input
                type='password'
                className='form-control inputbox'
                name='password'
                value={user.password}
                onChange={handleChange}
              /> */}

                    <div className='conatiner'>
                      <div>
                        <div className='image'>
                          <span
                            className='ab'
                            onClick={() => {
                              setShowPassword((prev) => !prev);
                            }}
                          >
                            {showPassword ? (
                              <Eye color='#87909E' size={18} />
                            ) : (
                              <EyeOff color='#87909E' size={18} />
                            )}
                          </span>
                          <input
                            // className={
                            //   'form-control inputbox' +
                            //   (submitted && !user.password ? ' has-error' : '')
                            // }
                            className={
                              'input-box ' +
                              (submitted && !user.username ? '  login-has-error' : '')
                            }
                            style={{ borderColor: 'var(--second-text-color)' }}
                            size='5'
                            id='password'
                            name='password'
                            type={showPassword ? 'text' : 'password'}
                            value={user.password}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {submitted && !user.password && (
                      <div className='help-block'>Password is required</div>
                    )}
                  </div>
                  <div
                    className='forgot-txt mt-1 cursor-pointer'
                    onClick={() => navigate(`/${RT.FORGOT_PASSWORD}`)}
                  >
                    Forgot password?
                  </div>
                  <div className='form-group   mt-3'>
                    <button className='btn login_Button width-100'>Sign In</button>
                  </div>
                  <div className='mt-2 remember_box_container'>
                    <div className='d-flex align-items-center'>
                      <input
                        // className='remember_box '
                        type='checkbox'
                        name='is_enable_notification'
                      />
                      <span className='ms-1 remember_txt'>Remember Me</span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center error-txt'>{error}</div>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className='col-md-5 login_right_side'>
            <div className='login_img_container'>
              <img src='/icons/loginImg.png' style={{ height: '450px' }} />
              {/* <img src='/Group.svg' /> */}
            </div>
          </div>
        </div>
      </div>
      ,
    </div>
  );
};

export default Login;
