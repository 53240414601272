/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PencilLine, Plus, Trash } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Utils/Contexts';
import { LoadingIcon, Tick } from '../Utils/SvgIcons';
import { Button, Modal } from '../_components';
import { settingsService } from '../_services';
import { EmployeeFieldAdd } from './Employee/EmployeeFieldAdd';
import { CUSTOM_FIELD, CUSTOM_FIELD_EDITED, EMPLOYEE_LABELS } from './SettingsLabels';
import { ToggleWithHeading } from './UtilsInSettings';

export function EmployeeSettings() {
  const [query] = useState({ select: ['name', 'labels'], name: 'employee-settings' });

  const {
    data: employeeSettingsData,
    isLoading,
    isSuccess,
    isPaused, //-- no internet offline
    error,
  } = useQuery({
    queryKey: ['employee-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  console.log({ error });

  if (isPaused) {
    return <div style={{ textAlign: 'center', marginTop: 30 }}>No internet</div>;
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <div>{error?.message}</div>
        <div>Try again</div>
      </div>
    );
  }

  return (
    <section className='common_contain pt-2'>
      {isLoading && (
        <div className='loading-wrapper'>
          <LoadingIcon size={70} />
        </div>
      )}
      {isSuccess && <EmployeeForm initialData={employeeSettingsData?.labels} />}
    </section>
  );
}

const EmployeeForm = ({ initialData }) => {
  const isMutating = useIsMutating();
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const [createModal, setCreateModal] = useState(false);
  const [inputFields, setInputFields] = useState([CUSTOM_FIELD]);

  const [employeeSettings, setEmployeeSettings] = useState(initialData || EMPLOYEE_LABELS);
  const [selectedField, setSelectedField] = useState('text');

  const [hasChanged, setHasChanged] = useState(false);
  useEffect(() => {
    setHasChanged(
      JSON.stringify(employeeSettings) !== JSON.stringify(initialData || EMPLOYEE_LABELS),
    );
  }, [employeeSettings]);

  // handle toggle and mutate
  const handleToggle = (id, value) => {
    setEmployeeSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );
  };

  // employee settings update
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'employee-settings';
    await settingsService.settingsAdd(newQuery);
    makeAlert('Success');
    setHasChanged(false);
    globalSettingsRefetch();
    return newQuery;
  };

  // --react query for mutation function
  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    // networkMode: 'online',
    mutationFn: handleSettingUpdate,
    mutationKey: ['employee-settings'],
    onSuccess: (data) => {
      queryClient.setQueryData(['employee-settings'], (old) => {
        let oldData = { ...old };
        oldData.data[0] = data;
        return oldData;
      });
    },
  });

  //-- for preventing multiple calls and when internet offline
  const saveSettings = () => {
    if (isPaused) {
      makeAlert('No internet');
      return;
    }
    if (isPending) {
      return;
    }

    handleMutate(employeeSettings);
  };

  //-- when modal close
  const modalClose = () => {
    setInputFields(() => [{ fieldType: selectedField, ...CUSTOM_FIELD_EDITED }]);
    setCreateModal(false);
  };

  const handleCreate = (data) => {
    if (!data.length || !data[0].label) {
      // setCreateModal(false);
      return;
    }
    let tempEmployeeSettings = [...employeeSettings];
    const lastElement = tempEmployeeSettings[tempEmployeeSettings.length - 1];
    let iterateId = lastElement.id;

    let existingSettingIndex = null;

    for (let obj of data) {
      // edit case
      if (obj.id) {
        existingSettingIndex = tempEmployeeSettings.findIndex((setting) => setting.id === obj.id);
        if (existingSettingIndex !== -1) {
          tempEmployeeSettings[existingSettingIndex] = {
            ...tempEmployeeSettings[existingSettingIndex],
            ...obj,
          };
        }
      }
      // add case
      else {
        iterateId++;
        obj.id = iterateId;
      }
    }

    // edit case
    if (existingSettingIndex) {
      setEmployeeSettings(() => tempEmployeeSettings);
      setEmployeeSettings((prevLabels) => {
        modalClose();
        handleMutate(prevLabels);
        return prevLabels;
      });
    }
    // add case
    else {
      setEmployeeSettings((prevLabels) => [...prevLabels, ...data]);
      setEmployeeSettings((prevLabels) => {
        modalClose();
        handleMutate(prevLabels);
        return prevLabels;
      });
    }
  };

  //-- when edit icon click
  const handleEdit = (employeeLabel) => {
    setSelectedField(employeeLabel.fieldType);
    setInputFields([employeeLabel]), setCreateModal(true);
  };

  //-- when delete icon click
  const handleDelete = (employeeLabel) => {
    const temp = [...employeeSettings];
    const updatedLabels = temp.filter((label) => label.id !== employeeLabel.id);

    setEmployeeSettings(() => {
      handleMutate(updatedLabels);
      return updatedLabels;
    });
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {employeeSettings?.map((employeeLabel, index) => {
          if (employeeLabel.type === 'toggle') {
            return (
              <div
                key={index}
                className={`${employeeLabel?.isCustom ? 'custom-field-container' : ''}`}
              >
                <ToggleWithHeading
                  className='sub_container'
                  key={employeeLabel?.id}
                  id={employeeLabel?.id}
                  handleToggle={() => handleToggle(employeeLabel?.id, employeeLabel.value)}
                  isChecked={employeeLabel.value}
                  heading={employeeLabel.label}
                  description={employeeLabel?.description}
                />
                {employeeLabel?.isCustom && (
                  <EditAndDelete
                    handleDelete={() => handleDelete(employeeLabel)}
                    handleEdit={() => handleEdit(employeeLabel)}
                  />
                )}
              </div>
            );
          }
        })}
        {/* employee enable time only  */}
        <CreateCustomField handleOK={() => setCreateModal(true)} />

        {hasChanged && (
          <div className='sticky-div'>
            <Button.Container className='sett-save-btn' handleOk={() => saveSettings()}>
              <Tick />
              <Button.Title title={'Save & Proceed'} />
              <Button.Loading isLoading={isMutating && !isPaused} />
            </Button.Container>
          </div>
        )}

        {createModal && (
          <Modal.Container handleClose={() => modalClose()}>
            <Modal.View className={'emp-modal_view'}>
              <EmployeeFieldAdd
                handleCreate={handleCreate}
                selectedField={selectedField}
                setSelectedField={setSelectedField}
                modalClose={modalClose}
                inputFields={inputFields}
                setInputFields={setInputFields}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </>
  );
};

const EditAndDelete = ({ handleEdit, handleDelete }) => {
  return (
    <div className='edit-delete-contain'>
      <PencilLine onClick={handleEdit} size={15} opacity={0.7} style={{ cursor: 'pointer' }} />
      <Trash onClick={handleDelete} style={{ cursor: 'pointer' }} size={15} opacity={0.7} />
    </div>
  );
};

const CreateCustomField = ({ handleOK }) => {
  return (
    <div className='emp-create' onClick={handleOK}>
      <Plus size={18} />
      <div className='create-custom-head'>Create Custom Field</div>
    </div>
  );
};
