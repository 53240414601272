/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pen, Plus, Trash, User } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { GlobalContext, Images } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { roleService } from '../_services';
import { RoleAdd } from './RoleAdd';

export function RoleList() {
  // hook section
  const tableContainerRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();

  // reference section

  // useState section
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editRoleID, setEditRoleID] = useState(null);
  const [roleAddModal, setRoleAddModal] = useState(false);

  const { roleBasedPermission } = useContext(GlobalContext);

  const [query, setQuery] = useState({
    select: ['id', 'name'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: roleData,
    error,
    isLoading,
    isFetching,
    isSuccess,
    isPaused,
    refetch,
  } = useQuery({
    queryKey: ['role-list', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => roleService.roleList({ ...query }),
    placeholderData: keepPreviousData,
  });

  const modalClose = () => {
    setDeleteModal(false);
    setEditRoleID(null);
  };

  const handleDeactivate = async (id) => {
    try {
      setIsDeleting(true);
      await roleService.roleDeactivate({ id });
      let isNeedToBack = roleData?.data?.rows.length === 1 && roleData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('role-list');
      modalClose();
      setIsDeleting(false);
    } catch (error) {
      console.error('Error deleting role:', error);
    }
  };

  // get the count of organization
  const listItemCount = roleData?.data?.rows.length;

  const tableData = roleData?.data?.rows?.map((role, index) => {
    const slNo = (roleData?.data?.page - 1) * roleData?.data?.limit + index + 1;

    return (
      <Tr key={role?.id}>
        <Td>{slNo}</Td>
        <Td>{role?.name}</Td>
        <Td>
          {/* {(roleBasedPermission?.ACTION_USER_EDIT || roleBasedPermission?.ACTION_USER_DELETE) && ( */}
          {roleBasedPermission?.ACTION_ORG_MANAGE && (
            <div className='d-flex gap-2'>
              {/* {roleBasedPermission?.ACTION_USER_EDIT && ( */}
              <ToolTip tooltipText={'Edit Role'} isModern popOver scrollRef={tableContainerRef}>
                <div
                  className='icon-bg'
                  onClick={() => {
                    setEditRoleID(role?.id);
                    // navigate(`/${RT.MY_TEAM_ADD}/${user?.id}`);
                    setRoleAddModal(true);
                  }}
                >
                  <Pen size={15} />
                </div>
              </ToolTip>
              {/* )} */}

              {role?.isDeletable && roleBasedPermission?.ACTION_ORG_MANAGE && (
                <ToolTip tooltipText={'Delete Role'} isModern popOver scrollRef={tableContainerRef}>
                  <div
                    className='icon-bg'
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditRoleID(role?.id);
                      setDeleteModal(true);
                    }}
                  >
                    <Trash size={15} />
                  </div>
                </ToolTip>
              )}
            </div>
          )}
          {/* )} */}
        </Td>
        <Td></Td>
      </Tr>
    );
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  return (
    <div className='fade-entry'>
      <div className='my-team-list '>
        {/* component will active when error occurs   */}
        {error && <ErrorComponent error={error?.message} />}

        <section>
          {/* <div className='my-task-tabs'>{roleData?.data?.isFilterVisible && <MyTeamTabs />}</div> */}
          <div>
            <section className=' myteam-table-container py-3'>
              <section>
                <div className='tab_contain'>
                  <SearchBar handleSearch={handleSearch} searchKey={query.searchKey} />
                  <div className='d-flex align-items-center justify-content-end gap-3'>
                    {/* {roleBasedPermission?.ACTION_ROL_ADD && (
                      <> */}

                    {roleBasedPermission?.ACTION_ORG_MANAGE && (
                      <ToolTip tooltipText={'Add Role'} isModern>
                        <div
                          className='menu-item-outline'
                          onClick={() => {
                            setRoleAddModal(true);
                          }}
                        >
                          <Plus size={16} color='#87909E' />
                        </div>
                      </ToolTip>
                    )}
                    {/* </>
                    )} */}
                  </div>
                </div>
                <div className=' mt-3'>
                  <div className='myTeam-table-container ' ref={tableContainerRef}>
                    <Table className='table table-hover tbl mb-0'>
                      <Thead className='table-head '>
                        <Tr className='table-head'>
                          <Th>
                            <img src={Images.SerialNo} height={14} width={14} />
                          </Th>
                          <Th>
                            <IconLabelSet
                              label={'Employee'}
                              icon={<User size={16} strokeWidth={'3px'} />}
                              color={'gray'}
                            />
                          </Th>

                          <Th style={{ width: 100 }}></Th>
                          <Th></Th>
                        </Tr>
                      </Thead>
                      <div></div>
                      <Tbody style={{ verticalAlign: 'middle' }}>
                        {isFetching && (
                          <TableRow noOfCols={3}>
                            <LoadingIcon size={50} />
                          </TableRow>
                        )}
                        {!listItemCount && isSuccess && !isPaused && (
                          <TableRow noOfCols={3}>No data found</TableRow>
                        )}
                        {isPaused && <TableRow noOfCols={3}>No Internet</TableRow>}
                        {listItemCount > 0 && isSuccess && !isPaused && !isFetching && tableData}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </section>
              {listItemCount > 0 && (
                <section className='mt-3'>
                  <PaginationComponent
                    pageOnClick={(data) => {
                      setQuery((prev) => ({
                        ...prev,
                        pageVo: {
                          ...prev.pageVo,
                          pageNo: data,
                        },
                      }));
                      searchParams.set('pageNo', data);
                      setSearchParams(searchParams);
                    }}
                    demoData={roleData?.data}
                  />
                </section>
              )}
            </section>
          </div>
          <section>
            {roleAddModal && (
              <Modal.Container>
                <Modal.View
                  className={'invite-modal-view modal_end'}
                  containerClass={'invite-view-center'}
                >
                  <RoleAdd
                    id={editRoleID}
                    handleClose={() => {
                      setRoleAddModal(false);
                      setEditRoleID(null);
                      refetch();
                    }}
                  />
                </Modal.View>
              </Modal.Container>
            )}
          </section>

          {/* delete modal calling  */}
          {deleteModal && (
            <Modal.Container>
              <Modal.View className={'status-delete'}>
                <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
                <Modal.Description>
                  <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                  <div className='del-description'>{DELETE_NOTE.ROLE}</div>
                </Modal.Description>
                <div className='modal_footer'>
                  <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                    <Button.Title title={'Cancel'} />
                  </Button.Container>
                  <Button.Container
                    handleOk={() => handleDeactivate(editRoleID)}
                    isDisabled={isDeleting}
                  >
                    <Button.Title title={'Delete'} />
                    <Button.Loading isLoading={isDeleting} />
                  </Button.Container>
                </div>
              </Modal.View>
            </Modal.Container>
          )}
        </section>
      </div>
    </div>
  );
}
