import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CommonDropDownStyle, MENU } from '../_constants';
import { clientService } from '../_services';
import { GlobalContext, invoiceFilter } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const InvoiceFilter = ({ handleClose = () => {}, setSearchParams = {} }) => {
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const [filterData, setFilterData] = useState(invoiceFilter);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchClientsByIds = async (ids) => {
      const promises = ids.map((id) =>
        clientService.clientGet({ id: id, select: ['id', 'name'] }).then((res) => res.data?.[0]),
      );
      return Promise.all(promises);
    };

    const params = new URLSearchParams(window.location.search);
    const newFilterData = structuredClone(invoiceFilter);

    const clientIds = [];

    params.forEach((value, key) => {
      if (key === 'client_id') {
        if (!newFilterData[key]) newFilterData[key] = [];
        newFilterData[key].push(isNaN(value) ? value : parseInt(value));
        if (key === 'client_id') {
          clientIds.push(parseInt(value));
        }
      } else if (key === 'due_date') {
        const date = moment(parseInt(value));
        newFilterData[key] = date.format('YYYY-MM-DD');
      } else {
        newFilterData[key] = parseInt(value);
      }
    });

    const updateFilterDataWithClients = async () => {
      if (clientIds?.length > 0) {
        const clients = await fetchClientsByIds(clientIds);

        newFilterData.client_id = clients.map((client) => ({
          id: client.id,
          name: client.name,
        }));
      }

      setFilterData(newFilterData);
    };

    updateFilterDataWithClients();
  }, []);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'client_id') {
      setFilterData((prev) => ({
        ...prev,
        [name]: value?.map((item) => item),
      }));
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    params.delete('due_date');
    params.delete('client_id');
    params.delete('payment_status');

    let client = filterData?.client_id?.map((item) => parseInt(item?.id));

    // Convert due_date and task_end_date to milliseconds if they exist
    const updatedFilterData = {
      ...filterData,
      client_id: client,
    };

    if (updatedFilterData?.due_date) {
      updatedFilterData.due_date = moment(updatedFilterData.due_date, 'YYYY-MM-DD').valueOf();
    }

    // Add the filter data to the URL parameters
    Object.keys(updatedFilterData).forEach((key) => {
      if (Array.isArray(updatedFilterData[key])) {
        updatedFilterData[key].forEach((item) => params.append(key, item));
      } else if (updatedFilterData[key]) {
        params.set(key, updatedFilterData[key]);
      }
    });
    params.set('pageNo', 1);

    // Update the URL
    setSearchParams(params, { replace: true });
    setIsLoading(false);
    handleClose(event);
  };

  const handleClear = (event) => {
    // const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();
    // Update the URL with the new parameters
    setSearchParams(newParams);
    // Clear the filter data
    setFilterData(invoiceFilter);
    handleClose(event);
  };

  return (
    <div className={`menu_popup filter-container `} style={{ padding: 20, right: 0 }}>
      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Invoice Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
          {hasExist(MENU.contact.default_name) && (
            <div className=''>
              <div className='row mb-1'>
                <div className='filter-item-name'>{LABEL_OF_CLIENT}</div>

                <SearchableInfiniteQuerySelect
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryFn={(params, page) =>
                    clientService.clientList({
                      ...params,
                      select: ['name', 'id', 'currency'],
                      pageVo: {
                        pageNo: page,
                        noOfItems: 10,
                      },
                    })
                  }
                  queryKey={['template-list']}
                  enableCondition={true}
                  isCustomFunction
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name'],
                    };
                  }}
                  isMulti
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption, name: 'client_id' },
                    });
                  }}
                  styles={CommonDropDownStyle}
                  value={filterData?.client_id}
                  placeholder='Choose'
                />
              </div>
            </div>
          )}

          <div className='row'>
            <span className='filter-item-name'>DATE</span>
            <div>
              <label className='mb-1 menu-item-clear'>Due Date</label>
              <input
                type='date'
                className='input-box'
                style={{ height: 34, fontSize: 13 }}
                value={moment(filterData?.due_date).format('YYYY-MM-DD')}
                name='due_date'
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className='row'>
            <span className='filter-item-name'>PAYMENT STATUS</span>
            <div>
              <div
                className='form-check form-check-inline fz-13px-rem '
                style={{ width: 'fit-content' }}
              >
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment_status'
                  id='paid'
                  onChange={() => {
                    handleChange({
                      target: {
                        name: 'payment_status',
                        value: 3,
                        type: 'radio',
                      },
                    });
                  }}
                  checked={filterData?.payment_status === 3}
                />
                <label className='form-check-label' htmlFor='paid'>
                  Paid
                </label>
              </div>
              <div
                className='form-check form-check-inline fz-13px-rem '
                style={{ width: 'fit-content' }}
              >
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment_status'
                  id='unpaid'
                  checked={filterData?.payment_status === 1}
                  onChange={() => {
                    handleChange({
                      target: {
                        name: 'payment_status',
                        value: 1,
                        type: 'radio',
                      },
                    });
                  }}
                />
                <label className='form-check-label' htmlFor='unpaid'>
                  Unpaid
                </label>
              </div>
              <div
                className='form-check form-check-inline fz-13px-rem'
                style={{ width: 'fit-content' }}
              >
                <input
                  className='form-check-input'
                  type='radio'
                  name='payment_status'
                  id='partially_paid'
                  checked={filterData?.payment_status === 2}
                  onChange={() => {
                    handleChange({
                      target: {
                        name: 'payment_status',
                        value: 2,
                        type: 'radio',
                      },
                    });
                  }}
                />
                <label className='form-check-label' htmlFor='partially_paid'>
                  Partially Paid
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container className={'cancel-btn'} type='button' handleOk={handleClose}>
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
              <Tick />
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
