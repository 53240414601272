/* eslint-disable no-unused-vars */
import { Clock, Plus, Turtle } from 'lucide-react';
import React from 'react';
import { Handle } from 'reactflow';
import { Modal } from '../_components';
import { NodeAddComponent } from './NodeAddComponent';
import { formatTimeString } from './workflowHelper';

export function DelayNode(props) {
  const { data, ...rest } = props;

  const [addModal, setAddModal] = React.useState(false);
  const addIconNeed = data?.addIconNeed;
  const timeFormatted = formatTimeString(data?.operation_details?.time);

  return (
    <>
      <div className='nd-container'>
        <div className='nd-header'>
          <Turtle className='nd-header-icon' size={14} />
          <span className='nd-header-text'>Delay</span>
        </div>

        <div>
          <div className='nd-content'>
            <Clock className='nd-content-icon' size={16} />
            <span className='nd-content-text'>Pause Workflow Execution</span>
          </div>
          <div className='nd-divider'></div>
          <div className='nd-description'>
            Delays workflow for <span className='nd-description-bold'>{timeFormatted}</span>
          </div>
        </div>

        <Handle type='target' position='top' id='condition-input' className='nd-handle' />
        <Handle type='source' position='bottom' id='condition-output' className='nd-handle' />

        {addIconNeed && (
          <div onClick={() => setAddModal(true)} className='nd-add-icon'>
            <Plus size={13} />
          </div>
        )}
      </div>

      {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View className='modal-view-trigger' containerClass='view-end'>
            <NodeAddComponent
              rest={rest}
              data={data}
              addModal={addModal}
              setAddModal={setAddModal}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
