/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Home, Plus, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { MENU, RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { invoicePaymentService } from '../_services/invoicePayment.service';
import '../common.css';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, Images, MyTeamIcon } from '../Utils';
import {
  Calendar,
  LoadingIcon,
  ModeIcon,
  OrderNumberIcon,
  PaymentIcon,
  PaymentIcon2,
  SINoIcon,
  User,
} from '../Utils/SvgIcons';
import { AddPaymentReceived } from './AddPaymentReceived';
import './invoice.css';
import { paymentType } from './InvoiceConstant';
import PaymentSingle from './PaymentSingle';

export function PaymentReceived() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [addModal, setAddModal] = useState(false);
  const [paymentSingle, setPaymentSingle] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  const { getMenuLabelName, globalSettings, roleBasedPermission, makeAlert } =
    useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editPaymentId, setEditPaymentId] = useState(null);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon2 height={15} width={16} primeColor='var(--second-text-color)' /> Payment
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <OrderNumberIcon height={15} width={16} primeColor='var(--second-text-color)' />{' '}
          Ref.Number
        </div>
      ),
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User height={15} width={16} color='var(--second-text-color)' /> {LABEL_OF_CLIENT} Name
        </div>
      ),
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ModeIcon /> Mode
        </div>
      ),
    },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' /> Amount
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={16} width={16} color='var(--second-text-color)' /> Date
        </div>
      ),
      minWidth: 50,
    },

    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef();

  // useState section
  const [query, setQuery] = useState({
    select: [
      'id',
      'reference_number',
      'payment_date',
      'amount_received',
      'payment_mode',
      'payment_id',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: paymentData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ['payment-list', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => invoicePaymentService.invoicePaymentList(query),
    enabled: roleBasedPermission?.ACTION_MANAGE_INVOICE,
    placeholderData: keepPreviousData,
  });
  const modalClose = () => {
    setDeleteModal(false);
    setEditPaymentId(null);
  };
  const handleDeactivate = async (id) => {
    try {
      await invoicePaymentService.invoicePaymentDeactivate({ id });
      let isNeedToBack = paymentData?.data?.rows.length === 1 && paymentData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      // await queryClient.invalidateQueries('projectList');
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');

      console.error('Error deleting payment :', error);
    }
  };
  const reference = useRef(null);

  // NO ACCESS MANAGE
  if (!roleBasedPermission?.ACTION_MANAGE_INVOICE) {
    return <NoPermission />;
  }
  console.log(editPaymentId, 'eee');

  // get the count of project
  const listItemCount = paymentData?.data?.rows.length;

  const tableData = paymentData?.data?.rows?.map((payment, index) => {
    const slNo = (paymentData?.data?.page - 1) * paymentData?.data?.limit + index + 1;
    const currencyTextIconForPayment = payment?.client?.currency
      ? currencyType.find((currency) => currency.id === payment?.client?.currency)?.textIcon
      : currencyTextIcon;
    return (
      <Tr
        onClick={() => {
          setPaymentSingle(true);
          setPaymentId(payment?.id);
        }}
        key={payment?.id}
      >
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {payment?.payment_id ? payment?.payment_id : `Payment${payment?.id}`}
        </Td>

        <Td>{payment?.reference_number ? payment?.reference_number : '--'}</Td>
        <Td>{payment?.client?.name}</Td>
        <Td>{paymentType?.find((type) => type.id == payment?.payment_mode)?.name}</Td>
        <Td>
          {currencyTextIconForPayment}
          {payment?.amount_received}
        </Td>

        <Td>{moment(parseInt(payment?.payment_date)).format('MMM DD, YYYY')}</Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip tooltipText={`Delete Payment`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditPaymentId(payment?.id);
                  setDeleteModal(true);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Payment'}
            fontWeight={500}
            icon={<PaymentIcon2 height={12} width={12} primeColor='var(--main-text-color)' />}
            onClick={() => nav(`/${RT.PAYMENT_RECEIVED}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='payment-table-container'>
          {/* header input section */}
          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* {roleBasedPermission?.ACTION_PROJECT_ADD && ( */}
                <ToolTip tooltipText={`Record Payment`} isModern>
                  <div className='menu-item-outline' onClick={() => setAddModal(true)}>
                    <Plus size={16} color='var(--second-text-color)' />
                  </div>
                </ToolTip>
                {/* )} */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={paymentData?.data}
              />
            </section>
          )}
        </div>

        {addModal && (
          <Modal.Container
            handleClose={() => {
              setAddModal(false);
              //   setEditOrganizationID(null);
            }}
          >
            <Modal.View className={'w-75'}>
              <Modal.Head
                // Heading={editOrganizationID ? 'Edit Organization' : 'Add Organization'}
                Heading={'Record Payment'}
                handleClose={() => {
                  setAddModal(false);
                  //   setEditOrganizationID(null);
                }}
              />
              <AddPaymentReceived
                handleClose={() => {
                  setAddModal(false);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}

        {paymentSingle && (
          <Modal.Container
            handleClose={() => {
              setPaymentSingle(false);
            }}
          >
            <Modal.View
              modelViewInnerClass={'payment-inner-modal-view'}
              containerClass='payment_received_view'
              style={{ marginTop: '5px', width: '45%', marginRight: '8px', marginBottom: '5px' }}
            >
              <Modal.Head
                Heading={'Invoice Payment'}
                handleClose={() => {
                  setPaymentSingle(false);
                }}
              />
              <PaymentSingle payment_id={paymentId} />
            </Modal.View>
          </Modal.Container>
        )}

        {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>{DELETE_NOTE.PAYMENT}</div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editPaymentId)}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
