import React from 'react';
import NoPermission from '../_components/NoPermission';
import { NotificationItem } from './NotificationItem';

const ChatNotification = ({ data, setIsNotificationVisible }) => {
  return (
    <div>
      {data?.length === 0 ? (
        <NoPermission data='No chats available' />
      ) : (
        data?.map((item, index) => {
          return (
            <NotificationItem
              index={item?.id}
              key={index}
              setIsNotificationVisible={setIsNotificationVisible}
              data={item}
            />
          );
        })
      )}
    </div>
  );
};

export default ChatNotification;
