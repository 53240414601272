/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ListFilter, Pen, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { components } from 'react-select';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ErrorComponent } from '../_components/ErrorComponent';
import NoPermission from '../_components/NoPermission';
import { Popover } from '../_components/Popover';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { apiConstants, LIST_PERMISSION_VALUE, MENU, RT } from '../_constants';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { EXPIRY, FILE_TYPE } from '../_constants/filesystem.constant';
import useDebounce from '../_helpers/useDebounce';
import { clientService, documentTypeService, fileService, folderService } from '../_services';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { fullNameUser, getSize, GlobalContext, Images } from '../Utils';
import {
  Calendar,
  CompletedDot,
  DigitalFileIcon,
  DocumentIcon,
  FileIcon,
  FileSize,
  InfoIcon,
  LoadingIcon,
  PhysicalFileIcon,
  SINoIcon,
  UploadIcon,
  User,
} from '../Utils/SvgIcons';
import EmptyFile from './EmptyFile';
import { FileAdd } from './FileAdd';
import { FileDetails } from './FileDetails';
import { FileListFilter } from './FileListFilter';

export default function FileList() {
  const { folderId } = useParams();
  const queryClient = useQueryClient();
  const { makeAlert, globalSettings, getMenuLabelName, roleBasedPermission } =
    useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const [searchParams, setSearchParams] = useSearchParams();

  // ref section
  const tableContainerRef = useRef(null);
  const reference = useRef(null);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  // useState section
  const [scrollFlag, setScrollFlag] = useState({
    canScroll: true,
    isLeftReached: true,
    isRightReached: false,
  });
  const [isAddFile, setIsAddFile] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [detailsVisibleIndex, setDetailsVisibleIndex] = useState(false);
  const [editFileId, setEditFileId] = useState(false);
  const [selectedTab, setSelectedTab] = useState(FILE_TYPE.DIGITAL);
  const [filter, setFilter] = useState({});
  const [isActivityUpdated, setIsActivityUpdated] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'issued_date',
      'expiry_date',
      'shared_with_team',
      'shared_with_user',
      'check_in_status',
      'size',
      'file_type',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);
  const isFromClient = location.pathname.includes(RT.CLIENT_SINGLE_PAGE);

  // expired on filter list
  const expiry_option = [
    { name: '1 month', id: 1 },
    { name: '3 month', id: 2 },
    { name: '6 month', id: 3 },
    { name: '1 year', id: 4 },
  ];

  // file type filter list
  const type_option = [
    { name: 'Digital File', id: 1 },
    { name: 'Physical File', id: 2 },
  ];

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const newFilterData = {};

  //   params.forEach((value, key) => {
  //     if (key === 'folder' || key === 'document_type' || key === 'client') {
  //       newFilterData[key] = value;
  //     }
  //     if (key === 'date') {
  //       newFilterData[key] = expiry_option.filter((item) => parseInt(item.id) === parseInt(value));
  //     }
  //     if (key === 'type') {
  //       newFilterData[key] = type_option.filter((item) => parseInt(item.id) === parseInt(value));
  //     }
  //   });

  //   setFilter(newFilterData);
  // }, []);

  const fetchFolder = async () => {
    const data = await folderService.folderGet({ id: filter?.folder, select: ['id', 'name'] });

    setFilter((prev) => ({
      ...prev,
      folder: data?.data?.[0],
    }));

    return data;
  };

  const fetchClient = async () => {
    const data = await clientService.clientGet({ id: filter?.client, select: ['id', 'name'] });
    setFilter((prev) => ({
      ...prev,
      client: data?.data?.[0],
    }));

    return data;
  };

  const fetchDocumentType = async () => {
    const data = await documentTypeService.documentTypeGet({
      id: filter?.document_type,
      select: ['id', 'name'],
    });
    setFilter((prev) => ({
      ...prev,
      document_type: data?.data?.[0],
    }));

    return data;
  };

  // filter ids in url---
  const folder_id = searchParams.get('folder') || folderId;
  const client_id = searchParams.get('client');
  const document_type_id = searchParams.get('document_type');
  const file_type = searchParams.get('type');
  const expiry_date = searchParams.get('expiry');

  // filter objects pass with API
  const filterToUrl = {};
  if (folder_id) filterToUrl.folder_id = folder_id;
  if (client_id) filterToUrl.client_id = client_id;
  if (document_type_id) filterToUrl.document_type_id = document_type_id;
  if (file_type) filterToUrl.file_type = file_type;
  if (expiry_date) filterToUrl.expiry_date = expiry_date;

  const {
    data: fileData,
    error: fileError,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['file-list', debouncedSearch, query?.pageVo?.pageNo, filterToUrl],
    queryFn: () => fileService.fileList({ ...query, ...filterToUrl }),
    select: (data) => data.data,
    enabled: roleBasedPermission?.ACTION_FILE_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
    placeholderData: keepPreviousData,
  });

  const listItemCount = fileData?.rows?.length;

  const handleDeactivate = async () => {
    try {
      await fileService.fileDeactivate({ id: deleteModal });
      let isNeedToBack = fileData?.rows.length === 1 && fileData?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      await queryClient.invalidateQueries('file-list');
      setDeleteModal(null);
      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message, 'Failed to delete');
      console.error('Error deleting file :', error);
    }
  };

  // handle filter change
  const handleFilterChange = (selectedOption, filter) => {
    setFilter((prev) => ({
      ...prev,
      [filter]: selectedOption,
    }));
    searchParams.delete(filter);

    if (selectedOption) {
      searchParams.append(filter, selectedOption?.id);
      searchParams.set('pageNo', 1);
      setQuery({
        ...query,
        pageVo: { ...query.pageVo, pageNo: 1 },
      });
    }
    setSearchParams(searchParams);
  };

  const TABLE_HEADING = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <FileIcon color='#87909E' />
          File Name
        </div>
      ),
      width: 160,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <DocumentIcon /> Document Type
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <User /> Owner
        </div>
      ),
      minWidth: 100,
    },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <Calendar height={14} width={14} /> Issued On
        </div>
      ),
      minWidth: 70,
    },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <Calendar height={14} width={14} /> Expired On
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='file-list-table-header' style={{ color: '#87909E' }}>
          <FileSize /> File Size
        </div>
      ),
      minWidth: 50,
    },
    {},
  ];

  // document type list
  const { data: documentTypes } = useQuery({
    queryKey: ['document-type-list'],
    queryFn: () =>
      documentTypeService.documentTypeList({
        searchKey: '',
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  // Client list
  const { data: clientData } = useQuery({
    queryKey: ['clientList'],
    queryFn: () =>
      clientService.clientList({ searchKey: '', select: ['id', 'name'], forFile: true }),
    select: (data) => {
      return data?.data?.rows;
    },
    enabled: !isFromClient,
  });

  const { data: folderData } = useQuery({
    queryKey: ['file-folder-list'],
    queryFn: () => folderService.folderList(query),
    select: (data) => {
      return data?.data?.rows;
    },
    placeholderData: keepPreviousData,
  });

  // fetch file details
  const { data: folder } = useQuery({
    queryKey: ['folder-data', filter?.folder],
    queryFn: () => fetchFolder(),
    enabled:
      !!filter?.folder &&
      !folderData?.some((item) => parseInt(item.id) === parseInt(filter.folder.id)), // Only run the query if the id is set
    placeholderData: keepPreviousData,
  });

  // fetch client details
  const { data: client } = useQuery({
    queryKey: ['client-data', filter?.client],
    queryFn: () => fetchClient(),
    enabled:
      !!filter?.client &&
      !clientData?.some((item) => parseInt(item.id) === parseInt(filter.client.id)), // Only run the query if the id is set
    placeholderData: keepPreviousData,
  });

  // fetch document type details
  const { data: docType } = useQuery({
    queryKey: ['doc-type-data', filter?.document_type],
    queryFn: () => fetchDocumentType(),
    enabled:
      !!filter?.document_type &&
      !documentTypes?.some((item) => parseInt(item.id) === parseInt(filter.document_type.id)), // Only run the query if the id is set
    placeholderData: keepPreviousData,
  });

  // filter popup reference
  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });

  const filterCount = Object.keys(filterToUrl).length;

  // extract File list data to be display on list
  const tableData = fileData?.rows?.map((file, index) => {
    const slNo = (fileData?.page - 1) * fileData?.limit + index + 1;
    const ownerName = fullNameUser(file?.owner?.first_name, file?.owner?.last_name);
    const isMale = file?.owner?.gender === 1;
    const isNeutral = file?.owner?.gender === 3;
    const url = file?.owner?.compressed_image_url
      ? file?.owner?.compressed_image_url
      : file?.owner?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    return (
      <Tr key={file?.id}>
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td
          className='cursor-pointer'
          style={{ fontWeight: 500, maxWidth: 300 }}
          onClick={() => {
            setDetailsVisibleIndex(file?.id);
          }}
        >
          {file?.task_id ? (
            <span className='d-flex align-items-center gap-1'>
              <span>
                <FileIcon />
              </span>
              <span className='folder-view-type-name'>{file?.name}</span>
            </span>
          ) : file?.file_type === FILE_TYPE.DIGITAL ? (
            <span className='d-flex align-items-center gap-1'>
              <span>
                <DigitalFileIcon />
              </span>
              <span className='folder-view-type-name'>{file?.name}</span>
            </span>
          ) : (
            <span className='physical-item'>
              <span className='col-1'>
                <PhysicalFileIcon />
              </span>
              <span
                style={{ width: file?.name?.length > 30 ? '100%' : 'auto' }}
                className='folder-view-type-name'
              >
                {file?.name}
              </span>
              <span className='col-1'>
                {file?.check_in_status ? (
                  <CompletedDot color='#17C666' />
                ) : (
                  <CompletedDot color='#EA4D4D' />
                )}
              </span>
            </span>
          )}
        </Td>
        <Td>
          <div className='d-flex align-items-center'>
            {file?.document_type_id ? file?.document_type?.name : '---'}
          </div>
        </Td>
        <Td>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 500 }}>{ownerName}</span>
          </div>
        </Td>
        <Td>
          <span>
            {file?.issued_date ? moment(parseInt(file?.issued_date)).format('MMM DD, YYYY') : '--'}
          </span>
        </Td>
        <Td>
          <span style={{ color: file?.expiry_status === EXPIRY.EXPIRED ? 'red' : 'black' }}>
            {file?.expiry_date
              ? file?.expiry_status == EXPIRY.TODAY
                ? 'Today'
                : moment(parseInt(file?.expiry_date)).format('MMM DD, YYYY')
              : '--'}
          </span>
        </Td>
        <Td>{getSize(file?.size)}</Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div
            className={`d-flex gap-2 justify-content-end icon-div ${
              detailsVisibleIndex === file?.id ? '' : 'invisible'
            }`}
          >
            {/* details icon  */}
            <div className='position-relative'>
              <ToolTip
                tooltipText={'Details'}
                isModern
                popOver
                scrollRef={tableContainerRef}
                hideTooltip={detailsVisibleIndex}
              >
                <div
                  className='icon-bg'
                  onClick={() => {
                    setDetailsVisibleIndex(file?.id);
                  }}
                >
                  <InfoIcon />
                </div>
              </ToolTip>
              {detailsVisibleIndex === file?.id && (
                <Modal.Container>
                  {/* <Modal.Head containerClass={'task-add-head'}></Modal.Head> */}
                  <Modal.View className={'task-add-container'}>
                    <FileDetails
                      setIsActivityUpdated={setIsActivityUpdated}
                      isDigital={file?.file_type === FILE_TYPE.DIGITAL}
                      id={file?.id}
                      handleClose={(event) => {
                        if (event) {
                          event.stopPropagation();
                        }
                        if (isActivityUpdated) {
                          refetch();
                          setIsActivityUpdated(false);
                        }
                        setDetailsVisibleIndex(null);
                      }}
                    />
                  </Modal.View>
                </Modal.Container>
              )}
            </div>
            {/* end of details icon  */}
            {!file?.task_id && (
              <>
                {roleBasedPermission?.ACTION_FILE_EDIT && (
                  <div className='position-relative'>
                    <ToolTip
                      tooltipText={'Edit File'}
                      isModern
                      popOver
                      scrollRef={tableContainerRef}
                      hideTooltip={editFileId}
                    >
                      <div
                        className='icon-bg'
                        onClick={() => {
                          setEditFileId(file?.id);
                          setSelectedTab(file?.file_type);
                        }}
                      >
                        <Pen size={15} />
                      </div>
                    </ToolTip>
                  </div>
                )}
                {roleBasedPermission?.ACTION_FILE_DELETE && (
                  <ToolTip
                    tooltipText={'Delete File'}
                    isModern
                    popOver
                    scrollRef={tableContainerRef}
                  >
                    <div
                      className='icon-bg'
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteModal(file?.id);
                      }}
                    >
                      <Trash size={15} />
                    </div>
                  </ToolTip>
                )}
              </>
            )}
          </div>
        </Td>
      </Tr>
    );
  });
  const handleScroll = (event) => {
    const { scrollLeft, scrollWidth, clientWidth } = event.target;

    // Determine if the scroll is at the start or end
    const isLeftReached = scrollLeft === 0;
    const isRightReached = scrollLeft + clientWidth >= scrollWidth;

    // Set flags for showing shadows based on scroll position
    setScrollFlag({
      isLeftReached,
      isRightReached,
    });
  };

  if (roleBasedPermission?.ACTION_FILE_LIST === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }

  return (
    <div>
      <div
        className='file-list-container'
        style={{ padding: isFromClient ? '16px 0' : '10px 24px' }}
      >
        <ErrorComponent error={fileError?.message} />
        {fileData?.count === 0 &&
        isSuccess &&
        !debouncedSearch &&
        Object.keys(filterToUrl).length === 0 ? (
          <>
            <EmptyFile />
          </>
        ) : (
          <>
            <div className='file-list-filter-main'>
              <div className={'tab_contain '}>
                <SearchBar
                  handleSearch={(event) => {
                    searchParams.set('pageNo', 1);
                    setSearchParams(searchParams);
                    setQuery({
                      ...query,
                      searchKey: event.target.value,
                      pageVo: { ...query.pageVo, pageNo: 1 },
                    });
                  }}
                  searchKey={query.searchKey}
                />
              </div>
              <div className='files-filter-container'>
                <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                    }}
                    ref={filterRef}
                    onClick={() => {
                      // setIsSortVisible(false);
                      setIsFilterVisible(true);
                    }}
                  >
                    <ListFilter
                      size={16}
                      color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                    />
                    {isFilterVisible && (
                      <Popover.PopoverItem>
                        <FileListFilter
                          setSearchParams={setSearchParams}
                          handleClose={(event) => {
                            event.stopPropagation();
                            setIsFilterVisible(false);
                          }}
                          searchParams={searchParams}
                          fileListFromClient={false}
                        />
                      </Popover.PopoverItem>
                    )}

                    {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                  </div>
                </ToolTip>

                {roleBasedPermission?.ACTION_FILE_ADD && (
                  <div style={{ padding: '0 6px' }}>
                    <ToolTip tooltipText={'Add File'} isModern hideTooltip={isAddFile}>
                      <div
                        className='default-btn'
                        style={{ fontSize: 13, padding: '6px 10px' }}
                        onClick={() => {
                          setIsAddFile(true);
                        }}
                      >
                        <UploadIcon />
                        Upload
                      </div>
                    </ToolTip>
                  </div>
                )}
              </div>
            </div>
            <div>
              <section>
                <div
                  className='table-container no-padding'
                  ref={tableContainerRef}
                  style={{ maxHeight: '65vh' }}
                >
                  <Table className='table table-hover tbl mb-0'>
                    <Thead>
                      <Tr className='table-head'>
                        {TABLE_HEADING.map((heading, index) => {
                          return (
                            <Th
                              key={index}
                              scope='col'
                              style={{
                                minWidth: heading.minWidth,
                                maxWidth: heading.maxWidth,
                                width: heading.width,
                              }}
                            >
                              {heading.name}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody style={{ verticalAlign: 'middle' }}>
                      {(isLoading || isFetching) && (
                        <TableRow noOfCols={8}>
                          <LoadingIcon size={50} />
                        </TableRow>
                      )}
                      {!listItemCount && isSuccess && (
                        <TableRow noOfCols={8}>No data found</TableRow>
                      )}
                      {listItemCount > 0 && isSuccess && !isFetching && tableData}
                    </Tbody>
                  </Table>
                </div>
              </section>
            </div>

            {listItemCount > 0 && (
              <section className='mt-2'>
                <PaginationComponent
                  pageOnClick={(data) => {
                    setQuery((prev) => ({
                      ...prev,
                      pageVo: {
                        ...prev.pageVo,
                        pageNo: data,
                      },
                    }));
                    searchParams.set('pageNo', data);
                    setSearchParams(searchParams);
                  }}
                  demoData={fileData}
                />
              </section>
            )}
          </>
        )}
      </div>

      {isAddFile && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <FileAdd
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              handleClose={(event) => {
                if (event) {
                  event.stopPropagation();
                }
                setIsAddFile(false);
                setSelectedTab(FILE_TYPE.DIGITAL);
                setQuery((prev) => ({
                  ...prev,
                  pageVo: { ...prev.pageVo, pageNo: 1 },
                }));
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {editFileId && (
        <Modal.Container>
          <Modal.Head containerClass={'task-add-head'}></Modal.Head>
          <Modal.View className={'task-add-container'}>
            <FileAdd
              handleClose={(event) => {
                if (event) {
                  event.stopPropagation();
                }
                setEditFileId(false);
                setSelectedTab(FILE_TYPE.DIGITAL);
              }}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              id={editFileId}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(null)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.FILE}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setDeleteModal(null)}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate()}>
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}

const CustomSingleValue = ({ children, icon, title, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className='d-flex align-items-center gap-1'>
        {icon} {title} : {children}
      </div>
    </components.SingleValue>
  );
};
