import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { Images } from '../Utils';
import { Popover } from './Popover';

const DatePicker = ({
  onDateChange,
  initialStartDate,
  initialEndDate,
  showLabel = false,
  inputContainerClass = 'date-input-container',
  isEditable = false,
  calenderStyle = {
    position: 'absolute',
    zIndex: 1000,
    background: 'var(--bg-primary)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  isDatePrefixNeed = true,
  monthFormat = 'long',
}) => {
  const [dateVisible, setDateVisible] = useState(false);
  const [state, setState] = useState([
    {
      startDate: initialStartDate,
      endDate: initialEndDate,
      key: 'selection',
    },
  ]);

  const handleSave = () => {
    if (
      state[0]?.startDate &&
      state[0]?.endDate &&
      (state[0]?.startDate != initialStartDate || state[0]?.endDate != initialEndDate)
    ) {
      onDateChange(state[0]);
    }
  };

  const { containerRef: dateRef } = useOutsideClickHandler({
    onClose: () => {
      handleSave();
      setDateVisible(false);
    },
    dependencies: [state[0].startDate, state[0].endDate],
  });

  const handleSelect = (item) => {
    const startDate = item.selection.startDate;
    const endDate = item.selection.endDate;
    setState([{ startDate: startDate, endDate: endDate, key: 'selection' }]);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: monthFormat,
      // year: 'numeric',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={dateRef}
        className={inputContainerClass}
        onClick={() => {
          isEditable && setDateVisible(!dateVisible);
        }}
      >
        {isDatePrefixNeed && (
          <span style={{ color: 'gray' }} hidden={showLabel}>
            Date:
          </span>
        )}
        {state[0].startDate
          ? `${formatDate(state[0].startDate)} - ${formatDate(state[0].endDate)}`
          : 'Choose date'}{' '}
        {/* <ChevronDown size={16} display={`${showLabel && 'none'}`} /> */}
        {!showLabel && (
          <img className='img-calendar' src={Images.dropdownIcon} style={{ paddingLeft: 6 }} />
        )}
      </div>
      {dateVisible && (
        <div ref={dateRef} style={calenderStyle} className='date-range-calendar'>
          <Popover.PopoverItem>
            <DateRange
              editableDateInputs={true}
              onChange={handleSelect}
              moveRangeOnFirstSelection={false}
              ranges={state.map((range) => ({
                ...range,
                startDate: range.startDate,
                endDate: range.endDate,
              }))}
              startDatePlaceholder='choose from date'
              endDatePlaceholder='choose to date'
            />
          </Popover.PopoverItem>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
