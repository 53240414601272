export const TRANSACTION_TYPE = {
  INVOICE: 1,
  ESTIMATE: 2,
};
export const TAX_CONST = {
  INCLUSIVE: 1,
  EXCLUSIVE: 2,
  NOT_SELECTED: 0,
  TAX_EXEMPT: 3,
};
export const TAX_LIST = [
  {
    name: 'Inclusive',
    id: TAX_CONST.INCLUSIVE,
  },
  {
    name: 'Exclusive',
    id: TAX_CONST.EXCLUSIVE,
  },
  {
    name: 'Not Selected',
    id: TAX_CONST.NOT_SELECTED,
  },
  {
    name: 'Tax Exempt',
    id: TAX_CONST.TAX_EXEMPT,
  },
];

export const TAX_TYPE_CONST = {
  NOT_SELECTED: 0,
  VAT: 1,
  GST: 2,
  OTHER_TAX: 3,
};
export const TAX_TYPE_LIST = [
  {
    name: 'VAT',
    id: TAX_TYPE_CONST.VAT,
  },
  {
    name: 'GST',
    id: TAX_TYPE_CONST.GST,
  },
  // {
  //   name: 'Other Tax',
  //   id: TAX_TYPE_CONST.OTHER_TAX,
  // },
];

// export const TAX_TYPES = [
//   {
//     name: 'VAT',
//     id: TAX_TYPE_CONST.VAT,
//   },
//   {
//     name: 'GST',
//     id: TAX_TYPE_CONST.GST,
//   },
//   {
//     name: 'Not selected',
//     id: TAX_TYPE_CONST.NOT_SELECTED,
//   },
// ];

export const paymentType = [
  { id: 1, name: 'Cash' },
  { id: 2, name: 'Bank Transfer' },
  { id: 3, name: 'Cheque' },
  { id: 4, name: 'Credit Card' },
];

export const INVOICE_STATUS_TYPE = {
  1: 'Unpaid',
  2: 'Partially Paid',
  3: 'Paid',
};

export const treatment = {
  tax_regimes: [
    {
      region: 'United States of America (Sales Tax)',
      tax_system: 'Sales Tax',
      currency_code: 'USD',
      treatments: [
        {
          id: 1,
          name: 'In-State Sale',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          unique_fields: {
            nexus_requirement: true,
            reseller_permit_number: false,
          },
          other_info:
            'When the seller and buyer are in the same state (and the seller has nexus). Sales tax rate depends on the specific state/county/city.',
        },
        {
          id: 2,
          name: 'Out-of-State Sale',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          unique_fields: {
            nexus_requirement: true,
            reseller_permit_number: false,
          },
          other_info:
            "If the seller has economic or physical nexus in the buyer’s state, charge that state's tax. Otherwise, no sales tax.",
        },
        {
          id: 3,
          name: 'Tax-Exempt or Resale',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: 'Resale/Exempt Certificate',
          unique_fields: {
            nexus_requirement: true,
            reseller_permit_number: true,
          },
          other_info:
            'Buyer provides a valid resale or tax-exempt certificate. No sales tax is charged.',
        },
      ],
    },
    {
      region: 'Canada (GST/HST/PST)',
      tax_system: 'GST/HST/PST',
      currency_code: 'CAD',
      treatments: [
        {
          id: 1,
          name: 'GST/HST-Registered Business',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'GST/HST Number',
          unique_fields: {
            province_specific_rules: true,
          },
          other_info:
            'Collect GST or HST based on the province of supply. Some provinces also have PST or QST.',
        },
        {
          id: 2,
          name: 'Non-Registered Business or Consumer (B2C)',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          unique_fields: {
            province_specific_rules: true,
          },
          other_info:
            'Charge GST/HST/PST/QST based on province. No tax number is required from the customer.',
        },
        {
          id: 3,
          name: 'Outside Canada (Export)',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          unique_fields: {
            province_specific_rules: false,
          },
          other_info: 'Zero-rated if shipped outside Canada. Proof of export may be required.',
          isExport: true,
        },
      ],
    },
    {
      region: 'Australia (GST)',
      tax_system: 'GST',
      currency_code: 'AUD',
      treatments: [
        {
          id: 1,
          name: 'GST-Registered Business',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'ABN (GST Registered)',
          other_info:
            '10% GST applies unless zero-rated or exempt (e.g., certain food, health, education).',
        },
        {
          id: 2,
          name: 'Non-Registered Business or Consumer',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          other_info:
            'For domestic B2C, typically charge 10% GST. If the seller is not registered for GST, no GST is collected.',
        },
        {
          id: 3,
          name: 'Export (Outside Australia)',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          other_info: 'Usually zero-rated exports. Must keep export documentation for compliance.',
          // isExport: true,
          // tax_not_applicable: true,
        },
      ],
    },
    {
      region: 'India (GST)',
      tax_system: 'GST',
      currency_code: 'INR',
      treatments: [
        {
          id: 1,
          name: 'Registered Business - Regular',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'GSTIN',
          other_info: 'Charges CGST+SGST (intra-state) or IGST (inter-state).',
        },
        {
          id: 2,
          name: 'Registered Business - Composition',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'GSTIN',
          other_info: 'Composition scheme; invoices typically do not show tax separately.',
        },
        {
          id: 3,
          name: 'Unregistered',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          other_info:
            'No GSTIN. May still charge GST if the supplier is registered, depending on B2C transaction rules.',
        },
        {
          id: 4,
          name: 'Overseas (Export)',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          other_info: 'Zero-rated supply if goods/services are exported outside India.',
        },
      ],
    },
    {
      region: 'United Kingdom (VAT)',
      tax_system: 'VAT',
      currency_code: 'GBP',
      treatments: [
        {
          id: 1,
          name: 'VAT-Registered Business',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'VAT Number',
          other_info: 'Standard rate is 20%, reduced rates apply to specific goods/services.',
        },
        {
          id: 2,
          name: 'Non-VAT Registered Business',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          other_info:
            'No VAT number. Supplier charges VAT if they are registered, typically 20% or reduced rate.',
        },
        {
          id: 3,
          name: 'Consumer (B2C)',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          other_info: 'End consumer. Standard or reduced rates depending on items.',
        },
        {
          id: 4,
          name: 'Outside UK (Export)',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          other_info: 'Exports generally zero-rated if proof of export is maintained.',
        },
      ],
    },
    {
      region: 'EU (VAT)',
      tax_system: 'VAT',
      currency_code: 'EUR',
      treatments: [
        {
          id: 1,
          name: 'VAT-Registered (Intra-EU B2B)',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'EU VAT Number',
          other_info:
            'Reverse charge applies if valid VAT number is provided for cross-border B2B in the EU.',
        },
        {
          id: 2,
          name: 'Local VAT-Registered (Same Country)',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'VAT Number',
          other_info:
            'For domestic transactions within the same EU country. Standard or reduced VAT rate.',
        },
        {
          id: 3,
          name: 'Non-VAT Registered Consumer (B2C)',
          is_tax_number_required: false,
          place_of_supply_required: true,
          tax_number_label: '',
          other_info:
            'Private consumer in the EU. VAT typically charged at the seller’s or buyer’s location, depending on OSS rules.',
        },
        {
          id: 4,
          name: 'Outside EU (Export)',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          other_info: 'Exports outside the EU generally zero-rated (no EU VAT).',
        },
      ],
    },
    {
      region: 'Other/Generic',
      tax_system: 'Varies by Country',
      currency_code: '',
      treatments: [
        {
          id: 1,
          name: 'Standard VAT/GST',
          is_tax_number_required: true,
          place_of_supply_required: true,
          tax_number_label: 'Tax ID',
          other_info: 'For countries with a standard consumption tax regime (VAT or GST).',
        },
        {
          id: 2,
          name: 'Exempt/Non-Registered',
          is_tax_number_required: false,
          place_of_supply_required: false,
          tax_number_label: '',
          other_info:
            'Scenario where no local tax is due, or the business is below registration threshold.',
        },
      ],
    },
  ],
};

export const COUNTRY_BASED_TAX_RULES = {
  IN: { CGST: 0, SGST: 0, IGST: 0 },
  UK: { VAT: 0 },
};

export const FROM_VALUE = {
  CLIENT: 'client',
  LEDGER: 'ledger',
};

export const LEDGER_MENUS = [
  {
    default_name: 'invoice_id',
    label_name: 'Invoice number',
    custom_name: 'Particular',
    value: true,
  },
  { default_name: 'date', label_name: 'Date', custom_name: 'Date', value: true },
  { default_name: 'debit', label_name: 'Debit', custom_name: 'Debit', value: true },
  { default_name: 'credit', label_name: 'Credit', custom_name: 'Credit', value: true },
];
