import { Calendar } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';

// ... existing code ...
export const convertMillisecondsToHoursAndMinutes = (milliseconds) => {
  const totalMinutes = Math.floor(milliseconds / 60000); // Convert milliseconds to total minutes
  const hours = Math.floor(totalMinutes / 60); // Get hours
  const minutes = totalMinutes % 60; // Get remaining minutes
  return `${hours}:${minutes.toString().padStart(2, '0')}`; // Return formatted string
};
export const convertMillisecondsToTimeDetails = (milliseconds) => {
  const totalMinutes = Math.floor(milliseconds / 60000); // Convert milliseconds to total minutes
  const hours = Math.floor(totalMinutes / 60); // Get hours
  const minutes = totalMinutes % 60; // Get remaining minutes
  // Update to conditionally format the return string
  return hours > 0
    ? `${hours} hr ${minutes < 10 ? minutes : minutes.toString().padStart(2, '0')}Mins`
    : `${minutes} Mins`; // Return formatted string
};
// ... existing code ...

export const calculateHourDifference = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'milliseconds'); // Calculate the difference in milliseconds
};

export const TimeSheetDate = ({
  usedFor = 'From',
  submitted,
  date,
  handleChange = () => {},
  min,
  max,
}) => {
  const [showDatePick, setShowDatePick] = useState(false);

  return (
    <>
      {!showDatePick ? (
        <div className='timesheet-selectdate-option' onClick={() => setShowDatePick(true)}>
          <Calendar size={15} /> {usedFor} Date
        </div>
      ) : (
        <input
          type='date'
          className={'input-box timesheet-date ' + (submitted && !date ? 'has-error' : '')}
          id='fromDate'
          autoFocus
          name='fromDate'
          value={moment(date).format('YYYY-MM-DD')}
          min={min}
          max={max}
          // value={timesheetData?.name}
          placeholder={`From Date `}
          onChange={(event) => {
            handleChange(event);
          }}
        />
      )}
    </>
  );
};
