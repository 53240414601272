/* eslint-disable no-unused-vars */
import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { Calendar, Clock, File, Info, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarEventAdd from '../Calander/CalendarEventAdd';
import { CalenderSubTaskAdd } from '../Calander/CalenderSubTaskAdd';
import useObserver from '../Hook/useObserver';
import { useUser } from '../Hook/useUser';
import { TaskAdd } from '../Task';
import { EditableDescription } from '../Task/TaskComponents';
import { CustomEmptyContainer, CustomVariableInput } from '../Task/TaskComponents/CustomInputs';
import {
  reminderSelect,
  reminderState,
  TASK_LIST_EMPTY_MESSAGE,
  TASK_TABS,
  taskAssignUserSelect,
  today,
} from '../Task/taskConstants';
import {
  getReminderActive,
  getSubTaskIsActive,
  removeTagsAndGetContent,
} from '../Task/taskValidation';
import '../Template/template.css';
import { convertHtmlToRawContent, fullNameUser, GlobalContext } from '../Utils';
import { Cancel, CircleLoader, LoadingIcon, ReminderIcon, Tick } from '../Utils/SvgIcons';
import { Button, Modal, SelectableContextMenu } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import ToolTip from '../_components/Tooltip/ToolTip';
import UserDropDown from '../_components/UserDropDown';
import { apiConstants, CommonDropDownStyle, USER_LIST_ACTIONS } from '../_constants';
import { CONTEXT_MENU_OPTIONS } from '../_constants/contextMenu.constants';
import { taskService, userService } from '../_services';
import RemindersSingle from './RemindersSingle';
import './reminder.css';

// eslint-disable-next-line no-unused-vars
export function RemindersAddWithList({
  taskId = null,
  userId = null,
  title = 'Reminder',
  SingleClassName,
  position,
  isHeading = true,
  defaultAssignee = useUser(),
  isListView = false,
  tab = 'alerts',
}) {
  const [isTyping, setIsTyping] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  // const [editIndex, setEditIndex] = useState(null);
  const [reminderData, setReminderData] = useState({ ...reminderState, user_id: userId });
  const [isLoad, setIsLoad] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { makeAlert } = useContext(GlobalContext);

  const queryParams = {
    task_id: taskId,
    select: reminderSelect,
    user_id: userId,
    status: 1,
    type: 1,
    currentDateTime: tab === 'alerts' ? new Date().getTime() : null,
  };

  // to fetch reminders with task id
  const {
    data: reminders,
    fetchNextPage,
    hasNextPage,
    error: reminderError,
    refetch: reminderRefetch,
    isLoading: reminderLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['reminders', tab, taskId],
    queryFn: ({ pageParam = 1 }) =>
      taskService.remindersGet({
        ...queryParams,
        pageVo: {
          pageNo: pageParam,
          noOfItems: 10,
        },
      }),
    select: (data) => data?.pages,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    placeholderData: keepPreviousData,
  });

  // to add reminder to user - reporters and ourself
  const { data: userData, error: assignUserFetchError } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      const updatedDefaultUser = defaultAssignee
        ? {
            value: defaultAssignee.id,
            label: defaultAssignee.first_name + ' ' + defaultAssignee.last_name,
            id: defaultAssignee.id,
            ...defaultAssignee,
          }
        : null;
      // Check if the default assignee already exists in the userData array
      const isDefaultUserPresent = userData?.some((user) => user.id === defaultAssignee?.id);
      const updatedUserData =
        updatedDefaultUser?.id && !isDefaultUserPresent
          ? [...userData, updatedDefaultUser]
          : userData;

      return updatedUserData;
    },
  });

  // handler for active reminder add or update form
  const handleRemindersAdd = () => {
    setReminderData({ ...reminderData, user_id: userId });
    setIsTyping(true);
  };

  // handler for context action in reminder list edit or delete
  const handleClickContext = (item, type, index) => {
    if (type === CONTEXT_MENU_OPTIONS.EDIT) {
      setContextMenuIndex(null);
      setIsTyping(true);
      const currentDateTime = moment(parseInt(item.reminder_date)).format('YYYY-MM-DDTHH:mm');
      setReminderData({
        ...item,
        note: convertHtmlToRawContent(item.note),
        reminder_date: currentDateTime,
      });
      return;
    }
    if (type === CONTEXT_MENU_OPTIONS.DELETE) {
      handleReminderDelete(index, item);
      setContextMenuIndex(null);
      return;
    }
  };

  // handler for reminder data change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setReminderData({ ...reminderData, [name]: value });
  };

  // reminder data validation and destructuring for add or update
  const handleAddOrUpdateReminder = (index = null, status = '') => {
    const { isValidTime, message } = validateReminderDate(reminderData?.reminder_date); // validate reminder date with current date time

    if (!isValidTime || !reminderData?.reminder_date) {
      // check if the time is valid - the time should be 1 minute greater than current
      return;
    }
    const dateMillie = moment(reminderData?.reminder_date).valueOf(); // conversion to milliseconds
    const reminder = {
      ...reminderData,
      updated_time: moment().valueOf(),
      status: index !== null ? status ?? reminders[index].status : 1,
      reminder_date: dateMillie,
      ...(index === null && {
        task_id: taskId ?? reminderData?.task_id,
        created_time: moment().valueOf(),
      }),
    };
    if (!reminder.user_id) {
      reminder.user_id = reminderData?.user_id ?? JSON.parse(localStorage.getItem('user'))?.id;
    }
    if (typeof reminder?.note === 'object') {
      reminder.note = draftToHtml(reminder?.note);
    }
    handleReminderUpdate(reminder);
  };

  // to delete the reminder
  const handleReminderDelete = (index, item) => {
    if (index == null) {
      return;
    }
    const reminder = { ...item };
    reminder.status = 0;
    reminder.updated_time = moment().valueOf();
    handleReminderUpdate(reminder);
  };

  // reminder update action call
  const handleReminderUpdate = async (reminder) => {
    setIsSubmitted(true);
    try {
      await taskService.reminderAdd(reminder);
      reminderRefetch();
    } catch (e) {
      console.log(e);
      makeAlert(e?.message);
    } finally {
      setReminderData(reminderState);
      setIsLoad(false);
      setIsSubmitted(false);
      setIsTyping(false);
    }
  };

  const isHeadVisible = reminders?.length > 0 || isTyping;

  // for fetch next page if it has next page and the observer ref is visible
  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  // close reminder editing and state initialization
  const closeEditing = () => {
    setReminderData(reminderState);
    setIsTyping(false);
  };

  // display reminders
  const MappedValuesByInfiniteQuery = ({ data = [] }) => {
    const [expandedRows, setExpandedRows] = useState(new Array(data?.length).fill(false));
    // to toggle open or close single list item
    const toggleRow = (index) => {
      const newExpandedRows = [...expandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      setExpandedRows(newExpandedRows);
    };
    const valuesData = data?.map((item, index) => {
      return (
        <RemindersSingle
          key={item.id ?? index}
          index={index}
          item={item}
          SingleClassName={SingleClassName}
          isContentVisible={contextMenuIndex === index}
          setIsContextVisible={(value) => {
            value ? setContextMenuIndex(index) : setContextMenuIndex(null);
          }}
          position={position}
          handleContextClick={(type) => {
            handleClickContext(item, type, index);
          }}
          isListView={isListView}
          toggleRow={toggleRow}
          expandedRows={expandedRows}
        />
      );
    });
    return valuesData;
  };
  // return - loading if reminder data is loading
  if (reminderLoading) {
    return (
      <div className='d-flex justify-content-center p-2'>
        <CircleLoader />
      </div>
    );
  }
  // return boolean value - no reminders if reminder data is empty
  const isReminderEmpty = reminders?.[0]?.data?.rows?.length == 0;
  return (
    <section className='position-relative'>
      {isTyping && (
        <AddReminderModal
          handleChange={handleChange}
          closeEditing={closeEditing}
          handleSubmit={handleAddOrUpdateReminder}
          data={reminderData}
          isSubmitted={isSubmitted}
          isLoading={isLoad}
          userData={userData}
          isListView={isListView}
        />
      )}
      <div className='reminder-list-container responsiveTable '>
        {isReminderEmpty ? (
          <CustomEmptyContainer
            Icon={() => {
              return <ReminderIcon />;
            }}
            text={TASK_LIST_EMPTY_MESSAGE.reminder_empty}
            handleAdd={handleRemindersAdd}
          />
        ) : (
          <div
            className={isHeadVisible ? `sub-section ` : `my-20px`}
            style={{ overflowY: 'auto', maxHeight: 'calc(90vh - 182px)' }}
          >
            <ErrorComponent error={reminderError?.message || assignUserFetchError?.message} />
            {isHeadVisible && isHeading && <div className='fw-500'>{title}</div>}
            {reminders?.map((item, idx) => {
              return <MappedValuesByInfiniteQuery key={idx} data={item?.data?.rows} />;
            })}
            <div ref={observerRef} style={{ height: 5, width: 5 }}>
              {hasNextPage && isFetchingNextPage && (
                <div
                  className='cursor-pointer'
                  onClick={() => hasNextPage && fetchNextPage()}
                  style={{ height: '90px' }}
                >
                  <LoadingIcon size={60} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {!isReminderEmpty && (
        <div className='position-absolute'>
          <ToolTip tooltipText={'Add reminder'} isModern position='right'>
            <div className='mx-4 my-0'>
              <div
                role='button'
                onClick={handleRemindersAdd}
                className='mt-1 mb-1 mx-2 px-3 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper'
              >
                <Plus strokeWidth={1.5} color='#879096' size={18} />
                <div className='grey-txt'></div>
              </div>
            </div>
          </ToolTip>
        </div>
      )}
    </section>
  );
}

const AddReminder = ({
  closeEditing,
  handleSubmit,
  handleChange,
  data,
  isSubmitted,
  isLoading,
}) => {
  const [contextDateItem, setContextDateItem] = useState(false); //state for add date visibility
  const [contextTimeItem, setContextTimeItem] = useState(false); //state for add time visibility
  return (
    <div className='reminder-container'>
      <div className='d-flex flex-wrap gap-1 my-1 '>
        <div className='col'>
          <input
            type='text'
            className={`reminder-input-box 
                 ${isLoading && !data?.title && 'error-border '}`}
            id='title'
            name='title'
            onChange={handleChange}
            value={data?.title}
            placeholder='Enter title'
          />
        </div>
        <div className='col '>
          <div className='d-flex gap-1'>
            <ToolTip tooltipText={'Date'} isModern hideTooltip={contextDateItem}>
              <div
                role='button'
                className={` inline-task-item-container custom-icon
                  ${isLoading && !data?.reminder_date ? 'has-error' : ''}`}
                style={{
                  border: contextDateItem ? '1px solid #3454D1' : '  ',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextDateItem(true);
                }}
              >
                <Calendar height={15} width={14} color={contextDateItem ? '#3454D1' : '#87909E'} />
                {contextDateItem && (
                  <SelectableContextMenu
                    isContextVisible={true}
                    onRequestClose={() => setContextDateItem(false)}
                    className='inline-context-container custom-field position-right '
                  >
                    <div className='pt-1'>
                      <div className='d-flex'>
                        <div className='col'>
                          <div className=' px-2 py-1'>
                            <input
                              type='date'
                              className={` input-box mb-2 fz-13px-rem inline-task-content   
                   ${isSubmitted && !data?.reminder_date && 'has-error'}`}
                              min={today}
                              id='reminder_date'
                              name='reminder_date'
                              onChange={handleChange}
                              value={data?.reminder_date}
                              onFocus={(e) => e.target.showPicker()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SelectableContextMenu>
                )}
              </div>
            </ToolTip>
            <ToolTip tooltipText={'Time'} isModern hideTooltip={contextTimeItem}>
              <div
                role='button'
                className={` inline-task-item-container custom-icon ${
                  isLoading && !data?.reminder_time ? 'has-error' : ''
                }`}
                style={{ border: contextTimeItem ? '1px solid #3454D1' : '  ' }}
                onClick={(e) => {
                  e.preventDefault();
                  setContextTimeItem(true);
                }}
              >
                <Clock color={contextTimeItem ? '#3454D1' : '#87909E'} height={14} width={14} />
                {contextTimeItem && (
                  <SelectableContextMenu
                    isContextVisible={true}
                    onRequestClose={() => setContextTimeItem(false)}
                    className='inline-context-container inline-time custom-field p-1'
                  >
                    <input
                      type='time'
                      className={` form-control mt-2 
                   ${isLoading && !data?.reminder_time && 'has-error'}`}
                      id='reminder_time'
                      name='reminder_time'
                      onChange={handleChange}
                      value={data?.reminder_time}
                      onFocus={(e) => e.target.showPicker()}
                    />
                  </SelectableContextMenu>
                )}
              </div>
            </ToolTip>
          </div>
        </div>
        <div className='col '>
          <div className='d-flex gap-2 justify-content-end'>
            <SaveAndCancel
              onCancel={closeEditing}
              isSubmitting={isSubmitted && isLoading}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *  validateReminderDate - validation for reminder date time with current date time
 * @param {date} date -reminder date
 * @returns object with boolean value for is valid time, message if invalid
 */
export const validateReminderDate = (date) => {
  const dateMillie = moment(date).valueOf();
  const currentMillie = moment().valueOf();
  if (date == null) {
    return { isValidTime: false, message: `Time is invalid` };
  }
  if (dateMillie == currentMillie) {
    return { isValidTime: false, message: `Can't choose current time` };
  }
  if (dateMillie < currentMillie + 60000) {
    return { isValidTime: false, message: `Time is invalid` };
  }
  return { isValidTime: true };
};

// reminder add modal
export const AddReminderModal = ({
  closeEditing,
  handleSubmit,
  handleChange,
  data,
  isSubmitted,
  isLoading,
  userData,
  isListView,
  from = 'list',
  eventRefetch = () => {},
  defaultAssignee = {},
}) => {
  data = {
    ...data,
    reminder_date: data?.reminder_date,
    // reminder_date: moment(data?.reminder_date).format('dd-MM-YYYY hh:mm a'),
  };

  const [isSubmit, setIsSubmit] = useState(false); // for display the error field in reminder
  const [tab, setTab] = useState(from == TASK_TABS.CALENDAR ? 'task' : 'reminder'); // for display the error field in reminder
  const handleAdd = () => {
    setIsSubmit(true);
    if (!data.title && tab == 'reminder') return;
    handleSubmit();
  };
  const isMenuSubTaskOn = getSubTaskIsActive();
  const isMenuReminderOn = getReminderActive();

  // to display error
  const { isValidTime, message } = validateReminderDate(data?.reminder_date);

  return (
    <Modal.Container className={'model_contain reminder-modal'}>
      <Modal.Head
        containerClass={'task-add-head'}
        Heading={'Reminder Add'}
        handleClose={closeEditing}
      ></Modal.Head>
      <Modal.View c className={'reminder-add-modal-container'}>
        <section>
          {from == TASK_TABS.CALENDAR && (
            <section className='task-menu task-single-tabs py-2 mt-2'>
              <div
                className={' task-single-tab ' + (tab === 'task' ? 'menu_active' : 'menu_inactive')}
                onClick={() => setTab('task')}
                role='button'
              >
                Task
              </div>
              {isMenuSubTaskOn && (
                <div
                  className={
                    ' task-single-tab ' + (tab === 'subtask' ? 'menu_active' : 'menu_inactive')
                  }
                  onClick={() => setTab('subtask')}
                  role='button'
                >
                  Subtask
                </div>
              )}
              {isMenuReminderOn && (
                <div
                  className={
                    ' task-single-tab ' + (tab === 'reminder' ? 'menu_active' : 'menu_inactive')
                  }
                  onClick={() => setTab('reminder')}
                  role='button'
                >
                  Reminder
                </div>
              )}
              <div
                className={
                  ' task-single-tab ' + (tab === 'event' ? 'menu_active' : 'menu_inactive')
                }
                onClick={() => setTab('event')}
                role='button'
              >
                Event
              </div>
            </section>
          )}
          {tab === 'reminder' && (
            <>
              {from != TASK_TABS.CALENDAR && (
                <div className={`d-flex align-items-center px-4 justify-content-between pt-4 `}>
                  <div className='d-flex align-items-center gap-3'>
                    <span className='font-16'>Add Reminder</span>
                  </div>
                </div>
              )}
              <div>
                <div className={`modal_view_content`}>
                  <section className='w-100'>
                    <div
                      className='w-100 position-relative'
                      style={{ minHeight: 160, width: '30vw', zIndex: 9999 }}
                    >
                      <div className={`form-group  py-1 d-flex gap-2 `}>
                        <div className='mt-10px  w-80'>
                          <CustomVariableInput
                            onChange={(event) => {
                              setIsSubmit(false);
                              handleChange(event);
                            }}
                            className={`form-control w-100  border-none
                        ${isSubmit && !data?.title && 'error-border-bottom '}`}
                            id='title'
                            name='title'
                            value={data?.title}
                            placeholder={
                              !isSubmit || data?.title
                                ? tab === 'reminder'
                                  ? 'Reminder name'
                                  : 'Event title'
                                : ''
                            }
                          />
                        </div>
                        {isSubmit && !data?.title && (
                          <ToolTip tooltipText={'Reminder name required'} isModern>
                            <Info size={16} color='#f24f4f' />
                          </ToolTip>
                        )}
                      </div>
                      <div className={`form-group  `}>
                        <div>
                          <EditableDescription
                            key={2}
                            isEditable={true}
                            isUpdate
                            handleChange={(val) => handleChange(val)}
                            data={{ note: data?.note }}
                          >
                            <div className={`task-description-dummy mb-3`}>
                              <div className='d-flex align-items-center ptr ms-1 '>
                                {!removeTagsAndGetContent(draftToHtml(data?.note)) ? (
                                  <IconLabelSet
                                    textClassName='mb-1'
                                    label={'Add Description'}
                                    iconClassName='d-flex justify-content-center align-items-center'
                                    icon={<File width={14} height={16} />}
                                  />
                                ) : (
                                  parse(draftToHtml(data?.note))
                                )}
                              </div>{' '}
                            </div>
                          </EditableDescription>
                        </div>
                      </div>
                      <div className='d-flex gap-2 ms-1 align-items-center position-relative mb-5'>
                        {/* <div className={`row gap-2 `}> */}

                        <section>
                          <div className='position-fixed'>
                            <SearchableQuerySelect
                              queryKey='user-list'
                              queryFn={userService.getRoleBasedUserList}
                              getFunctionParams={(searchKey) => ({
                                searchKey,
                                action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
                                select: taskAssignUserSelect,
                              })}
                              querySelect={(val) =>
                                val?.data?.rows?.map((item) => {
                                  return {
                                    ...item,
                                    value: item.id,
                                    label: item.first_name + ' ' + item.last_name,
                                    id: item.id,
                                  };
                                })
                              }
                              isDisabled={!isListView}
                              initialValue={userData}
                              className={` select-box ${isListView ? 'pointer' : ''}`}
                              isCustomFunction
                              styles={{
                                ...CommonDropDownStyle,
                                control: (styles) => {
                                  return {
                                    ...styles,
                                    width: 120,
                                    borderRadius: 8,
                                    borderColor: 'var(--border-color)',
                                  };
                                },
                              }}
                              placeholder='Select User'
                              getOptionLabel={(option) => option?.label}
                              getOptionValue={(option) => option.id}
                              value={userData.find((item) => item.id == data?.user_id)}
                              onChange={(selectedOptions) => {
                                handleChange({
                                  target: {
                                    value: parseInt(selectedOptions?.id),
                                    name: 'user_id',
                                  },
                                });
                              }}
                              formatOptionLabel={(data, metaData) => {
                                if (metaData.context === 'value') {
                                  const url = data?.image_url;
                                  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
                                  const isMale = data.gender === 1;
                                  const isNeutral = data.gender === 3;
                                  const fullname = data.name
                                    ? data.name
                                    : fullNameUser(data.first_name, data?.last_name);
                                  const isAvatarVisible = !data.hideAvatar;
                                  return (
                                    <ToolTip tooltipText={fullname} isModern>
                                      <div
                                        className='d-flex align-items-center'
                                        style={{
                                          gap: 5,
                                        }}
                                      >
                                        {isAvatarVisible && (
                                          <CircularAvatar
                                            key={url}
                                            source={finalUrl}
                                            size={25}
                                            isMale={isMale}
                                            isNeutral={isNeutral}
                                          />
                                        )}
                                        <span className='avatar-name'>{fullname}</span>
                                      </div>
                                    </ToolTip>
                                  );
                                }
                                return <UserDropDown data={data} metaData={metaData} />;
                              }}
                              components={{ IndicatorsContainer: () => null }}
                              menuPortalTarget={document.getElementById('MODAL')}
                            />
                          </div>
                        </section>
                        <section>
                          <div className='position-fixed reminder-date-container'>
                            <div>
                              <DatePicker
                                selected={data?.reminder_date}
                                onChange={(date) => {
                                  handleChange({
                                    target: { name: 'reminder_date', value: date },
                                  });
                                }}
                                timeInputLabel='Time:'
                                dateFormat='MMM dd    hh:mm a'
                                showTimeInput
                                className='date-field date-field-date-picker date-item px-2'
                                minDate={new Date()}
                              />
                            </div>
                          </div>
                        </section>
                        <section>
                          <div className='error-tooltip'>
                            {isSubmit && !isValidTime && (
                              <ToolTip tooltipText={message} isModern>
                                <Info size={16} color='#f24f4f' />
                              </ToolTip>
                            )}
                          </div>
                        </section>
                        {/* </div> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div className={`modal_footer`}>
                <div className='d-flex gap-2'>
                  <Button.Container
                    className='cancel-btn'
                    isDisabled={isSubmitted}
                    handleOk={closeEditing}
                  >
                    <Cancel />
                    <Button.Title title='Cancel' />
                  </Button.Container>
                  <Button.Container
                    type='submit'
                    handleOk={() => {
                      handleAdd();
                    }}
                    isDisabled={isSubmitted}
                  >
                    <Tick />
                    <Button.Title />
                    <Button.Loading color='#FFF' isLoading={isSubmitted} size={20} />
                  </Button.Container>
                </div>
              </div>
            </>
          )}
          {tab === 'task' && (
            <TaskAdd
              id={0}
              handleClose={() => {
                closeEditing();
              }}
              queryKey='default-template-popup'
              selectDefaultTemplateIfPresent
              from={TASK_TABS.CALENDAR}
              afterTaskAddFn={() => {
                eventRefetch();
                closeEditing();
              }}
              templateSelectionDisabled={true}
              // defaultAssignee={assignee_id?.length === 1 ? specificUser : loggedUser}
              customDates={{
                task_start_date: data?.reminder_date,
                task_end_date: data?.reminder_date,
              }}
              projectID={data?.project_id}
              assignTo={data?.user_id}
              defaultAssignee={defaultAssignee}
              startTimeData={moment(data?.reminder_date).format('HH:mm')}
            />
          )}
          {tab === 'subtask' && (
            <CalenderSubTaskAdd
              closeEditing={() => {
                eventRefetch();
                closeEditing();
              }}
              startDate={data.reminder_date}
              endDate={data.endDate}
            />
          )}
          {tab === 'event' && (
            <CalendarEventAdd
              data={data}
              handleChange={handleChange}
              handleAdd={eventRefetch}
              closeEditing={closeEditing}
              isSubmit={isSubmit}
              setIsSubmit={setIsSubmit}
              isEditable={true}
            />
          )}
        </section>
      </Modal.View>
    </Modal.Container>
  );
};

//--save and cancel button
export const SaveAndCancel = ({ onCancel, isSubmitting, handleSubmit }) => {
  return (
    <div className='status-btn-wrapper gap-2'>
      <Button.Container type='button' handleOk={onCancel} className={'cancel-btn'}>
        <Cancel />
        <Button.Title title='Cancel' />
      </Button.Container>
      <Button.Container
        isDisabled={isSubmitting}
        className={'inline-add-button '}
        type={'submit'}
        handleOk={handleSubmit}
      >
        <Tick />
        <Button.Title title='Add' />
        <Button.Loading isLoading={isSubmitting} size={20} />
      </Button.Container>
    </div>
  );
};

// removed due to html 5 absence in some browser

/* <div className={`date-field cursor-pointer justify-content-start `}>
      <label className='datepicker px-2'>
        <Calendar size={13} />
        <span className='date-item px-2'>{` ${date ?? ' - - '}`} </span>
        <span className='date-item px-2'>{`${time ?? ' - - '}`}</span>
        <input
          type='datetime-local'
          name='reminder_date'
          value={data?.reminder_date}
          onChange={handleChange}
          min={currentDateTime}
        />
      </label>
    </div> */

// add modal
// const [startDate, setStartDate] = useState(new Date());
// const currentDateTime = moment().format('dd-MM-YYYY hh:mm a'); //min value
// const { date, time } = formatDateAndTime(data?.reminder_date);
