import { useRef } from 'react';

export const useThrottle = (callback, delay) => {
  const throttling = useRef(false);

  return (...args) => {
    if (!throttling.current) {
      callback(...args);
      throttling.current = true;
      setTimeout(() => {
        throttling.current = false;
      }, delay);
    }
  };
};
