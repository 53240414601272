import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CommonDropDownStyle, MENU } from '../_constants';
import { clientService } from '../_services';
import { invoiceService } from '../_services/invoice.service';
import { invoicePaymentService } from '../_services/invoicePayment.service';
import { SelectedFiles } from '../FileSystem/SelectedFiles';
import useObserver from '../Hook/useObserver';
import { currencyType } from '../Settings/InvoiceSettings';
import {
  FileSystemImage,
  getFileType,
  GlobalContext,
  InvoicePaymentState,
  sanitizeFileName,
} from '../Utils';
import { Cancel, LoadingIcon, Tick } from '../Utils/SvgIcons';
import './invoice.css';
import { INVOICE_STATUS_TYPE, paymentType, TRANSACTION_TYPE } from './InvoiceConstant';

const TABLE_COLUMN = [
  'Invoice number',
  'Status',
  'Date',
  'Due Date',
  'Amount',
  'Amount Due',
  'Payment',
];

export const AddPaymentReceived = ({ handleClose = () => {}, selectedInvoice = null }) => {
  const { getMenuLabelName, globalSettings, makeAlert } = useContext(GlobalContext);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  // useState section
  const [paidInvoiceList, setPaidInvoiceList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [submitted, setSubmitted] = useState(false);
  const [invoicePaymentData, setInvoicePaymentData] = useState(
    selectedInvoice?.id
      ? { ...InvoicePaymentState, client_id: selectedInvoice?.client }
      : InvoicePaymentState,
  );

  // Managing amount information
  const { amount_excess, amount_for_payment } = useMemo(() => {
    let totalPaid =
      paidInvoiceList?.reduce((acc, item) => acc + (parseFloat(item?.new_payment) || 0), 0) || 0;
    let amount_for_payment = invoicePaymentData?.amount_received ? parseFloat(totalPaid) : 0;
    let amount_excess =
      invoicePaymentData?.amount_received > amount_for_payment
        ? (parseFloat(invoicePaymentData?.amount_received) - parseFloat(totalPaid)).toFixed(2)
        : 0;

    return {
      amount_excess,
      amount_for_payment: amount_for_payment > 0 ? amount_for_payment : 0,
    };
  }, [invoicePaymentData?.amount_received, paidInvoiceList]);

  useEffect(() => {
    setInvoicePaymentData((prev) => ({
      ...prev,
      amount_excess: amount_excess,
      amount_for_payment: amount_for_payment,
    }));
  }, [amount_excess, amount_for_payment]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInvoicePaymentData({
      ...invoicePaymentData,
      [name]: value,
    });
  };

  const handleInvoiceChange = (event, updatedInvoice) => {
    const { value } = event.target;
    const newPayment = parseFloat(value) || 0;

    setPaidInvoiceList((prevList) => {
      const existingIndex = prevList.findIndex((invoice) => invoice.id === updatedInvoice.id);
      const totalAmount = parseFloat(updatedInvoice?.total) || 0;
      const currentPaidAmount = parseFloat(updatedInvoice?.paid_amount) || 0;

      // if (existingIndex !== -1) {
      //   // Clone the previous list
      //   const updatedList = [...prevList];
      //   const prevNewPayment = parseFloat(updatedList[existingIndex]?.new_payment) || 0;

      //   // Ensure new paid amount follows the existing condition
      //   let newPaidAmount =
      //     invoicePaymentData?.amount_for_payment + newPayment > invoicePaymentData?.amount_received
      //       ? currentPaidAmount + 0.0
      //       : currentPaidAmount + newPayment;

      //   let newNewPayment =
      //     invoicePaymentData?.amount_for_payment + newPayment - prevNewPayment >
      //     invoicePaymentData?.amount_received
      //       ? 0.0
      //       : newPayment;
      //   if (
      //     invoicePaymentData?.amount_for_payment + newPayment - prevNewPayment >
      //     invoicePaymentData?.amount_received
      //   ) {
      //     makeAlert('Entered amount is larger than received amount');
      //   }

      //   // Ensure paid_amount never exceeds invoice.total
      //   const excessAmount = Math.max(0, newPaidAmount - totalAmount);
      //   newPaidAmount -= excessAmount;
      //   newNewPayment -= excessAmount;

      //   updatedList[existingIndex] = {
      //     ...updatedList[existingIndex],
      //     // paid_amount: newPaidAmount,
      //     new_payment: newNewPayment,
      //   };

      //   return updatedList;
      // }
      if (existingIndex !== -1) {
        // Clone the previous list
        const updatedList = [...prevList];
        const prevNewPayment = parseFloat(updatedList[existingIndex]?.new_payment) || 0;

        // Ensure new paid amount follows the existing condition
        let newPaidAmount =
          parseFloat(invoicePaymentData?.amount_for_payment) + newPayment >
          invoicePaymentData?.amount_received
            ? currentPaidAmount + 0.0
            : currentPaidAmount + newPayment;

        let newNewPayment =
          parseFloat(invoicePaymentData?.amount_for_payment) + newPayment - prevNewPayment >
          invoicePaymentData?.amount_received
            ? 0.0
            : newPayment;
        if (
          parseFloat(invoicePaymentData?.amount_for_payment) + newPayment - prevNewPayment >
          invoicePaymentData?.amount_received
        ) {
          makeAlert('Entered amount is larger than received amount');
        }
        console.log(newNewPayment, newPaidAmount, 'ice');

        // Ensure paid_amount never exceeds invoice.total
        // const excessAmount = Math.max(0, newPaidAmount - totalAmount);
        // newPaidAmount -= excessAmount;
        // newNewPayment -= excessAmount;

        if (newNewPayment > totalAmount - currentPaidAmount) {
          updatedList[existingIndex] = {
            ...updatedList[existingIndex],
            // paid_amount: newPaidAmount,
            new_payment: totalAmount - currentPaidAmount,
          };
        } else {
          updatedList[existingIndex] = {
            ...updatedList[existingIndex],
            // paid_amount: newPaidAmount,
            new_payment: newNewPayment,
          };
        }

        return updatedList;
      } else {
        // New invoice entry
        let finalPaidAmount =
          invoicePaymentData?.amount_for_payment + newPayment > invoicePaymentData?.amount_received
            ? 0.0
            : Math.min(newPayment, totalAmount);

        return [...prevList, { ...updatedInvoice, new_payment: finalPaidAmount }];
      }
    });
  };

  const {
    data: invoiceListData,
    // error,
    // isLoading,
    // isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,

    // refetch,
  } = useInfiniteQuery({
    queryKey: ['invoice-list', invoicePaymentData?.client_id?.id],
    queryFn: ({ pageParam = 1 }) =>
      invoiceService.invoiceList({
        select: [
          'id',
          'invoice_number',
          'paid_amount',
          'due_date',
          'invoice_status',
          'bill_date',
          'total',
        ],

        searchKey: '',
        transaction_type: TRANSACTION_TYPE.INVOICE,
        client_id: invoicePaymentData?.client_id?.id,
        list_type: 'payment',
        pageVo: {
          noOfItems: 5,
          pageNo: pageParam,
        },
      }),
    enabled: Boolean(invoicePaymentData?.client_id?.id) && !selectedInvoice?.id,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  const flattedUpdateData = React.useMemo(
    () => invoiceListData?.pages?.flatMap((page) => page.data.rows),
    [invoiceListData],
  );

  const allUpdateList = selectedInvoice?.id
    ? [selectedInvoice]
    : flattedUpdateData?.length
    ? [...flattedUpdateData]
    : [];

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currency = currencyType.find((currency) => currency.id === selectedCurrency)?.name;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (!invoicePaymentData?.client_id?.id || !parseFloat(invoicePaymentData?.amount_received)) {
      setIsLoading(false);
      return null;
    }
    try {
      await invoicePaymentService
        .invoicePaymentAdd({
          ...invoicePaymentData,
          client_id: invoicePaymentData?.client_id?.id,
          payment_date: new Date(invoicePaymentData?.payment_date).getTime(),
          files: selectedFiles,
          paidInvoiceList,
        })
        .then(() => {
          setSubmitted(false);
          // afterAdd(data?.data);
        });
      handleClose();
    } catch (error) {
      // setError({
      //   common: error?.message || alertConstants.SERVER_ERROR,
      // });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  // file upload section ,
  const onFileSelect = (files) => {
    let filesArray = [];

    for (let file of files) {
      filesArray.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        isUploading: false,
        isFailed: false,
        isSuccess: false,
      });
    }
    selectedFiles?.length > 0
      ? setSelectedFiles([...selectedFiles, ...filesArray])
      : setSelectedFiles([...filesArray]);
  };
  // remove selected file
  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const handlePriceBlur = (e) => {
    const value = e.target.value.trim();

    // If value is empty or invalid, reset to "0.00"
    if (!/^\d+(\.\d+)?$/.test(value) || value === '') {
      handleChange({ target: { name: e.target.name, value: '0.00' } });
    }
  };

  return (
    <>
      <div className='modal_view_content'>
        <div>
          {/* Customer Info Section */}
          <div className='invoice-form-section'>
            <div className='grid-3-col'>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>{LABEL_OF_CLIENT} Name</label>
                <SearchableInfiniteQuerySelect
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryKey={['client-list-task']}
                  enableCondition={true}
                  isCustomFunction
                  queryFn={(params, page) =>
                    clientService.clientList({
                      ...params,
                      select: ['name', 'id', 'currency'],
                      pageVo: {
                        pageNo: page,
                        noOfItems: 10,
                      },
                    })
                  }
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name', 'currency'],
                    };
                  }}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption, name: 'client_id' },
                    });
                  }}
                  placeholder={`Select ${LABEL_OF_CLIENT}`}
                  styles={CommonDropDownStyle}
                  menuPortalTarget={document.getElementById('MODAL')}
                  value={invoicePaymentData?.client_id}
                  isDisabled={selectedInvoice?.id}
                  // className={'mt-2'}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Amount Received</label>
                <div className=' currency-indicator-input'>
                  <span className='currency-indicator'>{currency}</span>
                  <input
                    type='text'
                    className={`input-box border-none ${
                      !invoicePaymentData?.client_id?.id ? 'cursor-no-drop' : ''
                    }`}
                    name='amount_received'
                    value={invoicePaymentData.amount_received}
                    onChange={(e) => {
                      // Allow only numbers and at most one decimal point
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) || value === '') {
                        handleChange(e);
                      }
                    }}
                    onBlur={handlePriceBlur}
                    disabled={!invoicePaymentData?.client_id?.id}
                  />
                </div>
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Bank Charges</label>
                <input
                  type='text'
                  className={`input-box ${
                    !invoicePaymentData?.client_id?.id ? 'cursor-no-drop' : ''
                  }`}
                  placeholder='Bank charges'
                  name='bank_charges'
                  value={invoicePaymentData.bank_charges}
                  onChange={(e) => {
                    // Allow only numbers and at most one decimal point
                    const value = e.target.value;
                    if (/^\d*\.?\d*$/.test(value) || value === '') {
                      handleChange(e);
                    }
                  }}
                  onBlur={handlePriceBlur}
                  disabled={!invoicePaymentData?.client_id?.id}
                />
              </div>

              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Payment Date</label>
                <input
                  type='date'
                  className={`input-box ${
                    !invoicePaymentData?.client_id?.id ? 'cursor-no-drop' : ''
                  }`}
                  name='payment_date'
                  value={invoicePaymentData.payment_date}
                  onChange={handleChange}
                  disabled={!invoicePaymentData?.client_id?.id}
                />
              </div>

              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Reference No</label>
                <input
                  type='text'
                  className={`input-box ${
                    !invoicePaymentData?.client_id?.id ? 'cursor-no-drop' : ''
                  }`}
                  placeholder='Reference'
                  name='reference_number'
                  value={invoicePaymentData.reference_number}
                  onChange={handleChange}
                  disabled={!invoicePaymentData?.client_id?.id}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Payment Mode</label>
                <ReactSelect
                  id='trigger-type-select'
                  options={paymentType}
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => option?.id}
                  placeholder='Select a condition'
                  styles={CommonDropDownStyle}
                  isDisabled={!invoicePaymentData?.client_id?.id}
                  value={paymentType.find(
                    (option) => option.id === invoicePaymentData?.payment_mode,
                  )}
                  onChange={(selectedOption) => {
                    handleChange({
                      target: { value: selectedOption?.id, name: 'payment_mode' },
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {/* Items Table */}
          <div className='invoice-form-section'>
            <div style={{ marginTop: 15 }}>
              <div className='inv-table-container'>
                <div className='inv-table-header'>
                  {TABLE_COLUMN?.map((column) => (
                    <>
                      <div className='inv-table-column'>{column}</div>
                    </>
                  ))}
                </div>

                {allUpdateList?.length === 0 && Boolean(!invoicePaymentData?.client_id?.id) && (
                  <div className='text-center mt-2'>Please Select client</div>
                )}
                {allUpdateList?.length === 0 && Boolean(invoicePaymentData?.client_id?.id) && (
                  <div className='text-center mt-2'>No Data</div>
                )}

                {allUpdateList?.length > 0 && (
                  <div style={{ maxHeight: '20vh', overflow: 'auto' }}>
                    {allUpdateList?.map((invoice) => (
                      <>
                        <div className='payment-table-row'>
                          <div className='inv-table-column'>{invoice?.invoice_number}</div>
                          <div className='inv-table-column'>
                            {INVOICE_STATUS_TYPE[invoice?.invoice_status]}
                          </div>
                          <div className='inv-table-column'>
                            {moment(parseInt(invoice?.bill_date)).format('MMM DD, YYYY')}
                          </div>
                          <div className='inv-table-column'>
                            {moment(parseInt(invoice?.due_date)).format('MMM DD, YYYY')}
                          </div>

                          <div className='inv-table-column'>{invoice?.total}</div>
                          <div className='inv-table-column'>
                            {parseFloat(invoice?.total) - parseFloat(invoice?.paid_amount)}
                          </div>
                          <div className='inv-table-column'>
                            {invoice?.invoice_status !== 3 && (
                              <input
                                className='payment-input-box'
                                type='text'
                                placeholder='Price'
                                onChange={(event) => {
                                  if (invoicePaymentData.amount_received == 0) {
                                    makeAlert('Please enter amount received');
                                  } else {
                                    handleInvoiceChange(event, invoice);
                                  }
                                }}
                                value={
                                  paidInvoiceList?.find((item) => item?.id === invoice?.id)
                                    ?.new_payment ?? 0.0
                                }
                                disabled={invoice?.invoice_status === 3}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                    <div
                      ref={observerRef}
                      className='d-flex justify-content-center'
                      style={{ minHeight: '2px' }}
                    >
                      {hasNextPage && isFetchingNextPage && isLoading && (
                        <div
                          className='cursor-pointer'
                          onClick={() => hasNextPage && fetchNextPage()}
                          style={{ height: '100px' }}
                        >
                          <LoadingIcon size={60} />
                        </div>
                      )}
                    </div>{' '}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Notes & Total Section */}
          <div className='invoice-form-section'>
            <div className='grid-2-col'>
              <div className='total-section '>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Notes</label>
                  <textarea
                    className={`notes-textarea box-focus ${
                      invoicePaymentData?.client_id?.id ? '' : 'cursor-no-drop'
                    }`}
                    placeholder='Add notes here'
                    name='notes'
                    value={invoicePaymentData.notes}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    disabled={!invoicePaymentData?.client_id?.id}
                  ></textarea>
                </div>
              </div>

              <div className='total-section'>
                <div className='total-row mb-3'>
                  <span>Amount Received</span>
                  <span>
                    {invoicePaymentData?.client_id?.currency
                      ? currencyType.find(
                          (currency) => currency.id === invoicePaymentData?.client_id?.currency,
                        )?.textIcon
                      : currencyTextIcon}
                    {invoicePaymentData?.amount_received}
                  </span>
                </div>
                <div className='total-row mb-3'>
                  <span>Amount used for payments</span>
                  <span>
                    {invoicePaymentData?.client_id?.currency
                      ? currencyType.find(
                          (currency) => currency.id === invoicePaymentData?.client_id?.currency,
                        )?.textIcon
                      : currencyTextIcon}
                    {invoicePaymentData?.amount_for_payment}
                  </span>
                </div>

                <div className='total-row mb-3'>
                  <span>Amount in excess</span>
                  <span>
                    {invoicePaymentData?.client_id?.currency
                      ? currencyType.find(
                          (currency) => currency.id === invoicePaymentData?.client_id?.currency,
                        )?.textIcon
                      : currencyTextIcon}
                    {invoicePaymentData?.amount_excess}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* File Upload */}
          <div className='invoice-form-section'>
            <div>
              <input
                accept={'*/*'}
                multiple
                onChange={(e) => onFileSelect(e.target.files)}
                id='upload'
                type='file'
                style={{ display: 'none' }}
                disabled={!invoicePaymentData?.client_id?.id}
                className={`${!invoicePaymentData?.client_id?.id ? 'cursor-no-drop' : ''}`}
              />
              <div
                className='file-upload-contain'
                style={{
                  padding: '1rem 5px',
                  color: 'var(--second-text-color)',
                  backgroundColor: 'var(--bg-light)',
                }}
              >
                <label className='upload-text text-decoration-none' htmlFor='upload'>
                  <div className='icon-outer-file'>
                    <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
                  </div>
                  {/* Drag and Drop file here or */}
                  Choose file
                </label>
              </div>
              <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
};
