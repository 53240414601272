import React, { useContext } from 'react';
import { MENU } from '../_constants';
import { GlobalContext } from '../Utils';
import { TAB_VALUE_CONST } from './GlobalSearchHelperFunction';

export const GlobalSearchTabs = ({ activeTab, setActiveTab }) => {
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);

  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');
  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const TABS = [
    TAB_VALUE_CONST.All,
    TAB_VALUE_CONST.TASK,
    TAB_VALUE_CONST.UPDATE,
    TAB_VALUE_CONST.USER,
    ...(hasExist(MENU.chat.default_name) ? [TAB_VALUE_CONST.CHAT] : []),
    ...(hasExist(MENU.files.default_name) ? [TAB_VALUE_CONST.FILE] : []),
    ...(hasExist(MENU.project.default_name)
      ? [{ ...TAB_VALUE_CONST.PROJECT, title: LABEL_OF_PROJECT }]
      : []),
  ];

  // Filter out the "Folder" tab if folderId is present
  const isActive = (tabName) => activeTab?.name === tabName;

  return (
    <section className='file_tabs'>
      <div className='file-tab-line' style={{ top: 30, color: '#E5E7EB' }} />

      <div
        className='d-flex gap-3 my-3 flex-wrap'
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
          maxWidth: '100%',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {TABS.map((item, index) => (
          <div
            key={index}
            style={{ zIndex: '2' }}
            className={`${isActive(item.name) ? 'tab_active' : 'tab_inactive'} fz-13px `}
            onClick={() => setActiveTab(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </section>
  );
};
