/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import {
  COMMON_USER_SELECT,
  CommonDropDownStyle,
  LIST_PERMISSION_VALUE,
  MENU,
  USER_LIST_ACTIONS,
} from '../_constants';
import { sharedUserSelect } from '../_constants/filesystem.constant';
import { TimeSheetTypeSelectOptions } from '../_constants/timesheet.constants';
import { clientService, userService } from '../_services';
import { FileSystemImage, fullNameUser, getSettingsByName, GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const TimeSheetAdd = ({ handleClose, handleSubmit, isLoading }) => {
  const { globalSettings, getMenuLabelName, roleBasedPermission } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings = getSettingsByName(globalSettings, 'menu-settings');

  const hasExist = (default_name) => {
    return menuSettings?.labels?.some((label) => label.default_name === default_name);
  };

  const [submitted, setSubmitted] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [timesheetData, setTimesheetData] = useState({
    type: 1,
    type_name: 'Task',
    client_id: null,
    fromDate: null,
    toDate: null,
    userIds: [],
  });

  const usersForShareWithAll = [
    { id: 0, first_name: 'All', last_name: '', image_url: FileSystemImage.GroupIcon },
  ];

  // Fetch user list based on objective type
  const { data: userData, isLoading: assigneeListLoading } = useQuery({
    queryKey: ['user-list', timesheetData?.client_id],
    queryFn: () =>
      userService.getNonRoleBasedUserList({
        searchKey: '',
        action: USER_LIST_ACTIONS.ACTION_EVERY_USER,
        select: COMMON_USER_SELECT,
        client_id: timesheetData?.client_id,
      }),
    // enabled: okrData?.objective_type === objectiveType.team,
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'fromDate' || name == 'toDate') {
      if (name === 'fromDate' && timesheetData.toDate) {
        const fromDate = moment(value);
        const toDate = moment(timesheetData.toDate);
        // Check if the difference is more than 3 months
        if (toDate.diff(fromDate, 'months') > 3) {
          return; // Exit if the difference exceeds 3 months
        }
      }
      if (name === 'toDate' && timesheetData.fromDate) {
        const toDate = moment(value);
        const fromDate = moment(timesheetData.fromDate);
        // Check if the difference is more than 3 months
        if (fromDate.diff(toDate, 'months') > 3) {
          return; // Exit if the difference exceeds 3 months
        }
      }
      setTimesheetData((prev) => ({
        ...prev,
        [name]: moment(value).valueOf(),
      }));
      return;
    }
    if (name === 'type') {
      setTimesheetData((prev) => ({ ...prev, type: value.value, type_name: value.label }));
      return;
    }
    setTimesheetData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeClient = (selectedOption) => {
    setTimesheetData((prev) => ({
      ...prev,
      client_id: selectedOption?.id,
      client_name: selectedOption?.name,
    }));
  };

  const onSelectUsers = (selectedusers) => {
    const ids = selectedusers.map((user) => user?.id).filter(Boolean);
    setTimesheetData((prev) => ({
      ...prev,
      userIds: ids,
    }));
    setSelectedUsers((prev) => [...prev, ...selectedusers]);
    usersForShareWithAll.push(...selectedusers);
  };

  const {
    data: clientList,
    error: clientError,
    isLoading: clientLoading,
  } = useQuery({
    queryKey: ['clientListForTaskSingleView'],
    queryFn: () => clientService.clientList({ select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
    enabled: roleBasedPermission?.ACTION_CLIENT_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS,
  });

  const handleClickNext = () => {
    setSubmitted(true);

    if (timesheetData.type && timesheetData.fromDate && timesheetData.toDate) {
      // nav(`/${RT.TIMESHEET_GENERATE}`, { state: { timesheetData } });
      handleSubmit(timesheetData);
    }
  };

  const handleRemoveUser = (user) => {
    const removedUser = selectedUsers.filter((item) => parseInt(item.id) !== parseInt(user.id));
    setSelectedUsers(removedUser);
    setTimesheetData((prev) => ({
      ...prev,
      userIds: removedUser.filter((item) => item.id),
    }));
  };

  return (
    <>
      <div className='modal_header'>
        <h6 className='menu-item-head'>Create Timesheet</h6>
      </div>
      <div className='modal_view_content modal_view_content_end'>
        <div className='timesheet-formitem mb-3'>
          <label className='mb-1 fz-13px-rem  label_inputtimesheet-add-label '>
            Timesheet Type
          </label>
          <ReactSelect
            options={TimeSheetTypeSelectOptions}
            styles={CommonDropDownStyle}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            openMenuOnClick
            isLoading={isLoading}
            classNames={{
              menuPortal: () => `z-index-100 fz-14px react-select-portal`,
            }}
            value={{ label: timesheetData?.type_name, value: timesheetData?.type }}
            filterOption={null}
            onChange={(e) => {
              handleChange({ target: { name: 'type', value: e } });
            }}
          />
          {submitted && !timesheetData?.type && (
            <div className='help-block'> Timesheet type is required</div>
          )}
        </div>
        {roleBasedPermission?.ACTION_CLIENT_LIST !== LIST_PERMISSION_VALUE.NO_ACCESS &&
          hasExist(MENU.contact.default_name) && (
            <div className='timesheet-formitem mb-3 '>
              <div className='task-single-menu-value underline-hover'>
                <SearchableQuerySelect
                  styles={CommonDropDownStyle}
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  isLoading={clientLoading}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryFn={clientService.clientList}
                  queryKey={['client-list-task', timesheetData?.client_id]}
                  enableCondition={true}
                  isCustomFunction
                  initialValue={clientList}
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name'],
                    };
                  }}
                  onChange={(val) => handleChangeClient(val)}
                  placeholder={`Select ${LABEL_OF_CLIENT}`}
                  className={'timesheet-select w-100 '}
                  value={
                    timesheetData?.client_id
                      ? { name: timesheetData?.client_name, id: timesheetData?.client_id }
                      : null
                  }
                  isClearable
                />
              </div>
            </div>
          )}

        <div className='timesheet-formitem-row  mb-3 flex-wrap '>
          <input
            type='date'
            className={
              'input-box timesheet-date ' +
              (submitted && !timesheetData?.fromDate ? 'has-error' : '')
            }
            id='fromDate'
            autoFocus
            name='fromDate'
            value={moment(timesheetData?.fromDate).format('YYYY-MM-DD')}
            min={moment().subtract(3, 'months').format('YYYY-MM-DD')}
            max={moment().format('YYYY-MM-DD')}
            // value={timesheetData?.name}
            placeholder={`From Date `}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <input
            type='date'
            className={
              'input-box timesheet-date ' + (submitted && !timesheetData?.toDate ? 'has-error' : '')
            }
            id='toDate'
            autoFocus
            name='toDate'
            value={moment(timesheetData?.toDate).format('YYYY-MM-DD')}
            min={moment(timesheetData?.fromDate).format('YYYY-MM-DD')}
            max={moment().format('YYYY-MM-DD')}
            // value={timesheetData?.name}
            placeholder={`To Date `}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </div>
        <div className='timesheet-formitem'>
          <label className='mb-1 fz-13px-rem  label_inputtimesheet-add-label '>Employee</label>
          <div className='shared-with-container'>
            <StackedAvatars
              selectProps={{
                menuPlacement: 'bottom',
                isCustomFunction: true,
                queryKey: 'user-list-file',
                queryFn: userService.getNonRoleBasedUserList,
                getFunctionParams: (searchKey) => ({
                  searchKey,
                  select: sharedUserSelect,
                  action: USER_LIST_ACTIONS.ACTION_EVERY_USER,
                  client_id: timesheetData?.client_id,
                }),
                // itemToAdd: USER_ALL_OPTION,
              }}
              imageList={selectedUsers}
              getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
              getSourceByKey={(user) =>
                user?.compressed_image_url ? user.compressed_image_url : user.image_url
              }
              tooltipPortal
              getGenderByKey={(user) => user.gender}
              size={30}
              dropdownSize={40}
              isClearable={() => true}
              onAvatarRemove={(user) => {
                handleRemoveUser(user, 'shared_with_user');
              }}
              // menuPlacement='top'
              options={userData}
              isAddable
              justifyPosition=''
              limit={5}
              showInitials={false}
              getValueByKey={(user) => user.id}
              onAdd={(selectedUsers) => {
                onSelectUsers(selectedUsers, 'user');
              }}
              zIndex={2}
            />
          </div>
        </div>
      </div>

      <div className={`modal_footer`}>
        <div className='d-flex gap-2'>
          <Button.Container className='cancel-btn' handleOk={handleClose}>
            <Cancel />
            <Button.Title title='Cancel' />
          </Button.Container>
          <Button.Container type='submit' isDisabled={isLoading} handleOk={handleClickNext}>
            <Tick />
            <Button.Title title={'Next'} />
            <Button.Loading isLoading={isLoading} color='#FFF' size={20} />
          </Button.Container>
        </div>
      </div>
    </>
  );
};
