/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import { Eye, EyeOff, Plus, Trash2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import ReactSelect from 'react-select';
import { DepartmentAdd, DesignationAdd } from '../Department';
import { useUser } from '../Hook/useUser';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  compressImageOnly,
  EmployeeState,
  fullNameUser,
  getFileType,
  GlobalContext,
  phoneNumberValidation,
  sanitizeFileName,
} from '../Utils';
import { Cancel, LoadingIcon, Tick } from '../Utils/SvgIcons';
import { Button, Modal, ToggleButton } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ErrorComponent } from '../_components/ErrorComponent';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import UserDropDown from '../_components/UserDropDown';
import {
  apiConstants,
  COMMON_USER_SELECT,
  CommonDropDownStyle,
  USER_LIST_ACTIONS,
} from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { miscService, roleService, userService } from '../_services';
import { departmentService } from '../_services/department.service';
import { teamService } from '../_services/team.service';
import {
  MY_TEAM_ERROR_MSG,
  MY_TEAM_REQUIRED_FIELD_MSG,
  USER_DETAILS_REQUIRED_FIELDS,
} from './MyTeamConstant';
import './MyTeamCss.css';
import { myTeamSchema } from './MyTeamValidation';

export function MyTeamAddModal({ editUserID, handleClose = () => {} }) {
  const { globalSettings } = useContext(GlobalContext);
  const employeeSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'employee-settings')?.labels;

  const imageRef = useRef(null);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [compressed, setCompressed] = useState(null);

  const [file, setFile] = useState();
  const [image, setImage] = useState();
  // eslint-disable-next-line no-prototype-builtins
  const customFields = employeeSettings?.filter((obj) => obj.hasOwnProperty('isCustom'));

  const hasExist = (name) => {
    const setting = employeeSettings?.find((label) => label.label === name);
    return setting ? setting.value : undefined;
  };

  // hooks section
  const id = editUserID ? editUserID : 0; // Access the id parameter from the URL
  const { makeAlert } = useContext(GlobalContext);

  const currentUser = useUser();
  // useState section

  const defaultEmployee = {
    ...EmployeeState,
    custom_fields:
      (customFields ?? []).reduce((acc, curr) => {
        return { ...acc, [curr.label]: '' };
      }, {}) ?? {},
  };

  const [employee, setEmployee] = useState(EmployeeState);
  const [submitted, setSubmitted] = useState(false);
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [userSearch, setUserSearch] = useState(''); // state for  user list organizationSearch
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamError, setTeamError] = useState({ message: '' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [addDesignationModal, setDesignationAddModal] = useState(false);
  const [addDepartmentModal, setDepartmentAddModal] = useState(false);
  // const [isCredentialRequired, setIsCredentialRequired] = useState(null);

  const [query, setQuery] = useState({
    select: USER_DETAILS_REQUIRED_FIELDS,
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getUserDetails(query);
    }
  }, [id]);

  // gender selection list definition

  const genderList = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Others', value: 3 },
  ];

  // today date
  let today = moment().format('YYYY-MM-DD');

  /**
   * Get user
   * */
  const getUserDetails = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const user = await userService.userGet(queryNew);

      let userDob = moment(parseInt(user?.data?.dob)).format('YYYY-MM-DD') ?? null;
      let department_id = user?.data?.department_id ? user?.data?.department : null;
      let designation_id = user?.data?.designation_id ? user?.data?.designation : null;
      let reported_users = user?.data?.reported_users ? user?.data?.reported_users : [];
      const result = user?.data;
      setEmployee((prev) => ({
        ...prev,
        ...result,
        dob: userDob,
        designation_id: designation_id,
        department_id: department_id,
        reported_users: reported_users,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const debounceDepartment = useDebounce(departmentSearch, 500);

  //  list all departments

  const {
    data: departmentData,
    error: departmentError,
    isLoading: departmentLoading,
    refetch: departmentRefetch,
  } = useQuery({
    queryKey: ['departmentList', debounceDepartment],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: departmentSearch,
        select: ['is_department', 'name', 'id'],
        is_department: 1,
      }),
    placeholderData: keepPreviousData,
  });

  const debounceDesignation = useDebounce(designationSearch, 500);

  //  list all designation

  const {
    data: designationData,
    error: designationError,
    isLoading: designationLoading,
    refetch: designationRefetch,
  } = useQuery({
    queryKey: ['designationList', debounceDesignation, employee?.department_id],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: designationSearch,
        select: ['is_department', 'name', 'id'],
        department_id: employee?.department_id?.id,
        is_department: 0,
      }),
    placeholderData: keepPreviousData,
  });

  // role list
  const {
    data: roleData,
    error: roleError,
    isLoading: roleLoading,
  } = useQuery({
    queryKey: ['roleList'],
    queryFn: () => roleService.roleList(),
    select: (data) => data,
  });

  // team list
  const { data: teamData, isLoading: isTeamLoading } = useQuery({
    queryKey: ['emp-add-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name', 'members_details', 'members'],
      }),
    select: (data) => data.data.rows,
  });
  const debounceUser = useDebounce(userSearch, 500);

  // Function to format reporting user fields , pass true for isAdmin if the user is Admin
  const reportingUserFields = (user, isSuperAdmin = false, is_immediate = 0) => {
    if (!user) {
      return null;
    }

    return {
      id: user?.id,
      name: fullNameUser(user?.first_name, user?.last_name),
      is_immediate: is_immediate,
      is_enable_notification: 1,
      is_admin: user?.role_default_name === 'owner' ? true : false,
      is_super_admin: isSuperAdmin,
    };
  };

  const userListQueryFn = async () => {
    const res = await userService.getRoleBasedUserList({
      searchKey: userSearch,
      select: COMMON_USER_SELECT,
      action: USER_LIST_ACTIONS.ACTION_USER_ALLOCATION,
    });
    // const adminDetails = reportingUserFields(res.data.admin, true);

    // const allUsers =
    //   adminDetails && !employee?.reported_users?.some((item) => item.id === adminDetails.id)
    //     ? [adminDetails, ...employee.reported_users]
    //     : employee.reported_users;
    // const allUsers = employee.reported_users;
    let currentUserAsReportedUser;
    if (!id) {
      currentUserAsReportedUser = reportingUserFields(
        currentUser,
        currentUser?.is_sub_admin ? currentUser?.is_sub_admin == false : false,
        1,
      );
    }
    const allUsers =
      currentUserAsReportedUser &&
      !employee?.reported_users?.some((item) => item.id === currentUserAsReportedUser.id)
        ? [currentUserAsReportedUser, ...employee.reported_users]
        : employee.reported_users;
    // const allUsers = employee.reported_users;

    setEmployee((prev) => ({
      ...prev,
      reported_users: allUsers,
    }));
    return res;
  };

  // user list
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useQuery({
    queryKey: ['userList-ReportingUser', debounceUser, employee?.id],
    queryFn: () => userListQueryFn(),
    enabled: !parseInt(id) || Boolean(employee?.id),
  });

  // function for update the user list for reported-to selection field.
  const filteredUserData = useMemo(() => {
    // code for remove selected user from user list

    // if (!userData?.data || !employee?.reported_users) return [];
    if (!userData?.data) return [];

    const reportedUserIds = employee?.reported_users?.map((user) => user.id);

    // Note: Ensure `currentUser` is not listed in the User list
    return userData.data.rows?.filter(
      (option) => !reportedUserIds?.includes(option.id) && option.id !== id,
    );
  }, [userData, employee, id]);

  //function to change url to file
  function dataUrlToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
  }
  const handleImageUpload = async () => {
    setIsPictureLoading(true);
    const data = dataUrlToFile(image?.imageUrl, image?.imageFile?.name);
    const { compressedData, uploadedData } = await onFileSelect(data); // handles the cropped image
    const img = uploadedData.pop();

    setEmployee((prev) => ({
      ...prev,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed ?? null,
    }));
    setCompressed(compressedData?.compressedImageBlobUrl);
    setIsPictureLoading(false);
    setIsPreviewOpen(false);
  };
  const onFileSelect = async (fileData) => {
    let cloneSelectedFiles = [];
    cloneSelectedFiles.push({
      name: fileData.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(fileData.name),
      fileType: getFileType(fileData.name.split('.').pop()),
      size: fileData.size,
      extension: fileData.name.split('.').pop(),
      file: fileData,
    });
    const response = await handleFileUpload(cloneSelectedFiles);
    return response;
  };
  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }
    const uploadedData = [];
    const compressedData = { compressedImageBlobUrl: '', fileNameCompressed: '' };
    for (let fileObj of data) {
      try {
        if (fileObj.fileType === 'image') {
          let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

          let signedDataCompressed = await miscService.createSignedUploadUrl({
            type: fileObj.fileType, //--video,image,audio
            ext: fileObj.extension, //--jpg or mp4
            name: `${fileObj.sanitizeName}_compressed`,
          });

          if (signedDataCompressed?.data?.signedUrl) {
            let signedUrlCompressed = signedDataCompressed.data.signedUrl;
            let fileNameCompressed = signedDataCompressed.data.filename;

            // Upload the compressed file
            await fetch(signedUrlCompressed, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: compressedFile,
            }).then((response) => response.text());

            compressedData.fileNameCompressed = fileNameCompressed;
            compressedData.compressedImageBlobUrl = compressedImageBlobUrl;
          }
        }

        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });
        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;
          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());
          uploadedData.push(fileName);
        }
      } catch (error) {
        console.log({ error });
      }
    }
    return { uploadedData, compressedData };
  };

  ///function to change image
  const changePicture = (event) => {
    const { files } = event.target;
    setFile(URL.createObjectURL(event?.target?.files[0]));
    setImage((prev) => ({
      ...prev,
      imageFile: files[0],
    }));
    setIsPreviewOpen(true);
  };

  //use to crop image choose
  const onCrop = () => {
    const imageElement = imageRef?.current;
    const cropper = imageElement?.cropper;
    setImage((prev) => ({
      ...prev,
      imageUrl: cropper.getCroppedCanvas().toDataURL(),
    }));
  };

  // delete a user from reporting user
  const handleDelete = (index) => {
    const updatedReportedUsers = [...employee.reported_users];
    updatedReportedUsers.splice(index, 1);
    setEmployee((prev) => ({
      ...prev,
      reported_users: updatedReportedUsers,
    }));
  };

  // handle change of checkbox  - reporting user list table
  const handleReportingUserFeatureChange = (option, event) => {
    const { checked, name } = event.target;

    const update = (employee?.reported_users || []).map((itm, idx) => ({
      ...itm,
      [name]:
        name === 'is_immediate'
          ? option === idx
            ? 1
            : 0
          : option === idx
          ? checked
            ? 1
            : 0
          : itm[name],
    }));

    setEmployee((prev) => ({
      ...prev,
      reported_users: update,
    }));
  };

  // handleChange of Reporting user select
  const handleReportingUserSelect = (user) => {
    const reporting_user_options = reportingUserFields(user);
    setEmployee((prev) => ({
      ...prev,
      reported_users: prev.reported_users?.length
        ? [...prev.reported_users, reporting_user_options]
        : [reporting_user_options],
    }));
  };

  // handleChange of Reporting user - team selection
  const handleTeamSelection = (selectedTeam) => {
    const reporting_users_of_team = selectedTeam?.members_details?.map((team) =>
      reportingUserFields(team),
    );

    setEmployee((prev) => {
      const existingUsers = prev.reported_users.map((user) => parseInt(user.id)); // assuming each user has a unique 'id'

      // Filter out users that already exist in the reported_users array
      const newUsers = reporting_users_of_team.filter(
        (user) => !existingUsers.includes(parseInt(user.id)),
      );

      return {
        ...prev,
        reported_users: [...prev.reported_users, ...newUsers],
      };
    });
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === 'department') {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
        designation_id: 0,
      }));
      return;
    }

    if (type === 'organization') {
      setEmployee((prev) => ({
        ...prev,
        [name]: value,
        department_id: 0,
        designation_id: 0,
      }));
      return;
    }

    setEmployee((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeCustomField = (event) => {
    const { name, value } = event.target;
    setEmployee((prev) => ({
      ...prev,
      custom_fields: { ...employee?.custom_fields, [name]: value },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    const { password, ...rest } = employee;
    const isImmediateUserPresent =
      employee?.reported_users?.length > 0
        ? employee?.reported_users.some((user) => user?.is_immediate === 1)
        : true;

    if (!isImmediateUserPresent) {
      makeAlert(MY_TEAM_ERROR_MSG.IMMEDIATE_USER_REQUIRED);

      return;
    }

    const isPhoneValid = employee?.phone_no ? phoneNumberValidation(employee?.phone_no) : true;
    if (!isPhoneValid) {
      makeAlert(MY_TEAM_ERROR_MSG.PHONE_NUMBER);
      setIsLoading(false);
      return;
    }

    const dateOfBirth = new Date(employee?.dob).getTime();
    const reported_users_ids = employee?.reported_users?.map((user) => parseInt(user?.id));
    const emp_code = employee?.employee_code ? employee?.employee_code : null;

    let payload = {
      ...rest,
      dob: dateOfBirth,
      first_name: rest.first_name.toString().trim(),
      last_name: rest.last_name.toString().trim(),
      reported_users_ids: reported_users_ids,
      designation_id: parseInt(rest?.designation_id?.id),
      department_id: parseInt(rest?.department_id?.id),
      employee_code: emp_code,
    };

    // if (isCredentialRequired) {
    payload.username = employee?.username?.toString()?.trim();
    payload.password = password?.toString()?.trim();
    // }
    // if (!isCredentialRequired) {
    //   payload.username = payload.username ? payload.username : '';
    //   payload.password = payload.password ? payload.password : '';
    // }

    // Validate with dynamic schema based on the id
    const validationResult = myTeamSchema(hasExist('Gender'), id).safeParse(payload);
    if (!validationResult.success) {
      makeAlert('Please Complete the form');
      scrollToErrorElement();
      return;
    }

    try {
      setIsLoading(true);

      const result = await userService.userAdd(payload);

      if (result?.data && !result.error_code) {
        // if (result?.data?.is_credential_present === false) {
        //   makeAlert('Please submit credentials');
        //   setIsLoading(false);
        //   setSubmitted(false);
        // setIsCredentialRequired(true);
        // } else {
        id ? makeAlert('User details updated') : makeAlert('New user created');
        setIsLoading(false);
        handleClose();
        // }
      }
    } catch (error) {
      makeAlert(error?.message);
      setIsLoading(false);
    } finally {
      setSubmitted(false);
    }
  };

  /***
   * To scroll to the first found error element. 'help-block' class is considered as the error element. It will scroll to the first found parent of help-block class.
   */

  const scrollToErrorElement = () => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector('.error-block');
      if (!element) {
        return;
      }
      const parentElement = element.parentElement;
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  let imageUrl = compressed
    ? compressed
    : employee?.compressed_image_url
    ? apiConstants.imgUrlS3 + employee.compressed_image_url
    : employee?.image_url
    ? apiConstants.imgUrlS3 + employee?.image_url
    : '';

  return (
    <>
      <ErrorComponent
        error={
          designationError?.message ||
          roleError?.message ||
          departmentError?.message ||
          userError?.message ||
          teamError?.message
        }
      />

      <div className='modal_header'>
        <span className=' '>{id == 0 ? 'Add' : 'Edit'} Employee </span>
      </div>

      <div className='row mb-2 myteam-add-container modal_view_content'>
        <form action='' className='my-team-form' onSubmit={handleSubmit}>
          <div className=' col-md-6 row'>
            <div
              className='col-md-12 '
              style={{ borderRight: '1px solid #DBDBDB ', paddingRight: 20 }}
            >
              {/* component will active when error occur   */}

              {hasExist('Employee Picture') && (
                <div
                  className='mb-3 ms-3 position-relative cursor-pointer'
                  style={{ width: 'fit-content' }}
                >
                  <label
                    className='cursor-pointer'
                    htmlFor='image_upload'
                    onClick={() => {
                      setIsPictureLoading(true);

                      // Set a timeout to hide the loading icon after the file dialog likely opens
                      setTimeout(() => {
                        setIsPictureLoading(false); // Hide loading icon after a brief delay
                      }, 1500); // Adjust this delay as needed
                    }}
                  >
                    <CircularAvatar
                      isImgStacked
                      size={75}
                      source={imageUrl}
                      htmlFor='image_upload'
                    />
                    <div className='add-plus-btn-bg'>
                      <Plus color='var(--bg-primary)' size={17} />
                    </div>
                    <div style={{ position: 'absolute', top: 25, left: 25 }}>
                      {isPictureLoading && <LoadingIcon size={25} />}
                    </div>
                  </label>
                  <input
                    type='file'
                    name='image_url'
                    id='image_upload'
                    hidden
                    onChange={changePicture}
                    accept='image/*'
                  />
                </div>
              )}

              <div className='row mb-2'>
                <div className='col '>
                  <div>
                    <label className='form-input-label'>
                      First Name<span className='field-required'> *</span>
                    </label>
                    <input
                      type='text'
                      className='input-box'
                      name='first_name'
                      value={employee?.first_name}
                      placeholder='Employee name'
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    <ErrorBlock
                      errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.FIRST_NAME}
                      hasError={submitted && employee?.first_name.toString().trim() === ''}
                    />
                  </div>
                </div>
                <div className='col pl-0'>
                  <div>
                    <label className='form-input-label'>
                      Last Name<span className='field-required'> *</span>
                    </label>
                    <input
                      type='text'
                      className='input-box'
                      name='last_name'
                      value={employee?.last_name}
                      placeholder='Last name '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />

                    <ErrorBlock
                      errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.LAST_NAME}
                      hasError={submitted && employee?.last_name.toString().trim() === ''}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col '>
                  <div className='row'>
                    {hasExist('Gender') && (
                      <div className='col-6'>
                        <label className='form-input-label'>
                          Gender<span className='field-required'> *</span>
                        </label>
                        <ReactSelect
                          styles={CommonDropDownStyle}
                          components={{ DropdownIndicator: CustomDropdownIndicator }}
                          options={genderList}
                          getOptionLabel={(option) => {
                            return option?.label;
                          }}
                          getOptionValue={(option) => {
                            return option?.value;
                          }}
                          onChange={(selectedOption) => {
                            handleChange({
                              target: {
                                name: 'gender',
                                value: selectedOption.value,
                              },
                            });
                          }}
                          value={
                            genderList.find((option) => option?.value === employee?.gender) || null
                          }
                        />
                        <ErrorBlock
                          errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.GENDER}
                          hasError={submitted && !employee?.gender}
                        />
                      </div>
                    )}
                    {hasExist('Date of Birth') && (
                      <div className='col-6 ' style={{ paddingLeft: 0 }}>
                        {/* <div> */}
                        <label className='form-input-label dob-text'>Date of Birth</label>
                        <input
                          type='date'
                          className='input-box'
                          max={today}
                          name='dob'
                          min={0}
                          style={{ padding: '4px ' }}
                          value={employee?.dob}
                          placeholder='Enter PIN '
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                      </div>
                      // </div>
                    )}
                  </div>
                </div>
                <div className=' col pl-0 '>
                  <label className='form-input-label'>Employee Code</label>
                  <div className='position-relative mb-3'>
                    <input
                      type='text'
                      className='input-box'
                      name='employee_code'
                      value={employee?.employee_code}
                      placeholder='Employee code'
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                {hasExist('Address') && (
                  <div className='col'>
                    <div className='form-group'>
                      <label htmlFor='address' className='form-input-label'>
                        Address
                      </label>
                      <textarea
                        rows={4}
                        className='form-control box-focus fz-13px'
                        id='address'
                        name='address'
                        value={employee?.address}
                        placeholder='Enter address '
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='row mb-2'>
                <div className='col'>
                  <div>
                    <div className='d-flex justify-content-between'>
                      <div className='form-input-label'>
                        Department
                        {/* <span className='field-required'> *</span> */}
                      </div>
                      <ToolTip tooltipText={'Add Department'} isModern>
                        <div className=' pointer' onClick={() => setDepartmentAddModal(true)}>
                          <Plus size={14} />
                        </div>
                      </ToolTip>
                    </div>
                    <ReactSelect
                      name='department_id'
                      // styles={DropDownStyle}
                      styles={{
                        ...CommonDropDownStyle,
                        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                      }}
                      menuPortalTarget={document.body}
                      components={{ DropdownIndicator: CustomDropdownIndicator }}
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                      getOptionValue={(option) => {
                        return option?.id;
                      }}
                      onInputChange={(inputString) => {
                        setDepartmentSearch(inputString);
                      }}
                      isLoading={departmentLoading}
                      filterOption={null}
                      inputValue={departmentSearch}
                      options={departmentData?.data?.rows}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            type: 'department',
                            name: 'department_id',
                            value: selectedOption,
                          },
                        });
                      }}
                      value={employee?.department_id}
                    />
                  </div>
                </div>
                <div className='col  pl-0'>
                  <div className='d-flex justify-content-between'>
                    <div className='form-input-label'>Designation</div>
                    <ToolTip tooltipText={'Add Designation'} isModern>
                      <div className=' pointer' onClick={() => setDesignationAddModal(true)}>
                        <Plus size={14} />
                      </div>
                    </ToolTip>
                  </div>
                  <ReactSelect
                    name='designation_id'
                    components={{ DropdownIndicator: CustomDropdownIndicator }}
                    styles={{
                      ...CommonDropDownStyle,
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    menuPortalTarget={document.body}
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    onInputChange={(inputString) => {
                      setDesignationSearch(inputString);
                    }}
                    filterOption={null}
                    inputValue={designationSearch}
                    options={designationData?.data?.rows}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: 'designation_id',
                          value: selectedOption,
                        },
                      });
                    }}
                    value={employee?.designation_id}
                    isLoading={designationLoading}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-12 '>
              <div className='row mb-2'>
                <div className='col-md-12'>
                  <div className=''>
                    {/* Role selection section  */}
                    <div className='form-input-label'>
                      Role
                      <span className='field-required'> *</span>
                    </div>

                    <SearchableQuerySelect
                      queryKey={'role-list'}
                      queryFn={roleService.roleList}
                      getFunctionParams={(searchKey) => ({
                        searchKey,
                        select: ['id', 'name'],
                      })}
                      isLoading={roleLoading}
                      isCustomFunction
                      initialValue={roleData?.data?.rows}
                      getOptionLabel={(option) => option?.name}
                      getOptionValue={(option) => option.id}
                      components={{ DropdownIndicator: CustomDropdownIndicator }}
                      value={
                        roleData?.data?.rows?.find(
                          (option) => option.default_name === employee?.role_default_name,
                        ) || null
                      }
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'role_default_name',
                            value: selectedOption?.default_name,
                          },
                        });
                      }}
                      styles={CommonDropDownStyle}
                      menuPlacement='top'
                      placeholder='Choose role'
                      // className={`select-box ${
                      //   submitted && !employee?.role_default_name && 'has-error'
                      // } `}
                    />

                    <ErrorBlock
                      errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.ROLE}
                      hasError={submitted && !employee?.role_default_name}
                    />
                    {/* End of role selection section  */}
                  </div>
                </div>
              </div>
              <div className='row mb-2'>
                {!id && (
                  <div className='col'>
                    <div>
                      <label className='form-input-label'>
                        Email <span className='field-required'> *</span>
                      </label>
                      <input
                        type='email'
                        className='input-box'
                        name='email_id'
                        value={employee?.email_id}
                        placeholder='Enter email address '
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                      <ErrorBlock
                        errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.EMAIL_ID}
                        hasError={submitted && !employee?.email_id}
                      />
                    </div>
                  </div>
                )}
                {hasExist('Contact Number') && (
                  <div className={`col ${!id ? 'pl-0' : ''}  `}>
                    <div>
                      <label className='form-input-label'>Phone Number</label>
                      <input
                        type='text'
                        className='input-box'
                        name='phone_no'
                        value={employee?.phone_no}
                        placeholder='Enter phone number '
                        onChange={(event) => {
                          handleChange(event);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              {console.log('useradd', customFields, employee?.custom_fields)}
              {/* custom fields section  */}
              <div className='row'>
                {customFields?.map((item, index) => {
                  return hasExist(item?.label) ? (
                    <div className='col-md-12 mb-2' key={index}>
                      <div>
                        <label htmlFor={item?.label} className='form-input-label'>
                          {item?.label}
                        </label>
                        <input
                          type={item?.fieldType}
                          className='input-box'
                          id={item?.label}
                          name={item?.label}
                          // value={employee?.custom_fields.?[item?.label] || ''}
                          value={(employee?.custom_fields || {})[item?.label] || ''}
                          placeholder={'Enter ' + item?.label}
                          onChange={(event) => {
                            handleChangeCustomField(event);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>

              {/* end of custom fields section  */}

              {!id && (
                <div className='row mb-2'>
                  <div className='col'>
                    <label className='form-input-label'>
                      Username <span className='field-required'> *</span>
                    </label>
                    <input
                      type='text'
                      className={`input-box  ${
                        !employee?.username?.toString()?.trim() && submitted ? 'username-box' : ''
                      }`}
                      name='username'
                      value={employee?.username}
                      placeholder='Username'
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                    <ErrorBlock
                      errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.USERNAME}
                      hasError={submitted && !employee?.username?.toString()?.trim()}
                    />
                  </div>
                  <div className='col  pl-0'>
                    <label htmlFor='password' className='form-input-label'>
                      Password <span className='field-required'> *</span>
                    </label>
                    <div className='password-block'>
                      <div style={{ position: 'relative' }}>
                        <input
                          type={isPasswordVisible ? 'text' : 'password'}
                          className={`input-box  ${
                            !employee?.password?.toString()?.trim() && submitted
                              ? 'username-box'
                              : ''
                          }`}
                          autoComplete='off'
                          id='password'
                          name='password'
                          placeholder='Password'
                          value={employee?.password}
                          onChange={(event) => {
                            handleChange(event);
                          }}
                        />
                        <a
                          className='password-icon'
                          onClick={() => setIsPasswordVisible((prev) => !prev)}
                        >
                          {isPasswordVisible ? (
                            <Eye width={18} height={18} />
                          ) : (
                            <EyeOff width={18} height={18} />
                          )}
                        </a>
                      </div>
                      <ErrorBlock
                        errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.PASSWORD}
                        hasError={submitted && !employee?.password?.toString()?.trim()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-md-6 my-team-reported-container'>
            <div>
              {/* report to selection section  */}
              <div className='col mb-2'>
                <div className='form-input-label'>
                  Reported To
                  {/* <span style={{ color: 'red' }}> *</span> */}
                </div>
                <div className='row'>
                  <div className='col'>
                    <ReactSelect
                      name='reported to'
                      styles={CommonDropDownStyle}
                      components={{ DropdownIndicator: CustomDropdownIndicator }}
                      formatOptionLabel={(data, metaData) => (
                        <UserDropDown data={data} metaData={metaData} />
                      )}
                      getOptionValue={(option) => {
                        return option?.id;
                      }}
                      onInputChange={(inputString) => {
                        setUserSearch(inputString);
                      }}
                      filterOption={null}
                      inputValue={userSearch}
                      options={filteredUserData}
                      onChange={(selectedOption) => {
                        handleReportingUserSelect(selectedOption);
                        setSelectedUser(null);
                      }}
                      value={selectedUser}
                    />
                  </div>

                  <div className='col pl-0'>
                    {!id && (
                      <SearchableQuerySelect
                        queryKey={'user-add-team-list'}
                        queryFn={teamService.teamList}
                        getFunctionParams={(searchKey) => ({
                          searchKey,
                          select: ['id', 'name', 'members_details', 'members'],
                        })}
                        isCustomFunction
                        isLoading={isTeamLoading}
                        initialValue={teamData}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) => {
                          handleTeamSelection(selectedOption);
                          setSelectedTeam(null);
                        }}
                        styles={CommonDropDownStyle}
                        components={{ DropdownIndicator: CustomDropdownIndicator }}
                        placeholder='Select Team '
                        menuPortalTarget={document.getElementById('MODAL')}
                        className={``}
                        value={selectedTeam}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* end of report to selection section  */}

              {/* Reported user table  */}
              <div className='col-md-12 mt-4  table_outer table-reportinguser'>
                <table className='table table-hover tbl mb-0'>
                  <thead>
                    <tr>
                      <th style={{ width: '250px' }}>Reporting User</th>
                      <th>Notification</th>
                      <th>Make Immediate</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {userLoading && (
                      <tr>
                        <td colSpan={4} className='text-center'>
                          <LoadingIcon size={20} />
                        </td>
                      </tr>
                    )}
                    {!userLoading && !employee?.reported_users?.length && (
                      <tr>
                        <td colSpan={4} className='text-center' style={{ color: '#878787' }}>
                          No Data
                        </td>
                      </tr>
                    )}

                    {employee?.reported_users?.map((item, index) => {
                      return (
                        <SelectedReportingUsers
                          key={item.id}
                          index={index}
                          item={item}
                          handleDelete={handleDelete}
                          handleReportingUserFeatureChange={handleReportingUserFeatureChange}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <ErrorBlock
                errorMsg={MY_TEAM_ERROR_MSG.IMMEDIATE_USER_REQUIRED}
                hasError={
                  submitted &&
                  employee?.reported_users?.length > 0 &&
                  employee?.reported_users?.every((user) => user?.is_immediate === 0)
                }
              />

              {/* end of Reported user table  */}
            </div>

            {isPreviewOpen && (
              <div className='image-preview-container'>
                <div className='image-preview-card'>
                  <div className='image-preview'>
                    <Cropper
                      crop={onCrop}
                      src={file}
                      initialAspectRatio={1}
                      aspectRatio={1}
                      ref={imageRef}
                      style={{ maxHeight: '50vh' }}
                    />
                  </div>
                  <div className='image-preview-button-div'>
                    <a className='btn image-preview-button' onClick={handleImageUpload}>
                      Save {isPictureLoading && <LoadingIcon size={30} />}
                    </a>
                    <a onClick={() => setIsPreviewOpen(false)} className='btn image-preview-button'>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>

      {addDepartmentModal && (
        <Modal.Container
          handleClose={() => {
            setDepartmentAddModal(false);
          }}
        >
          <Modal.View className={'modal_view_label '}>
            <Modal.Head
              Heading={'Add Department'}
              handleClose={() => {
                setDepartmentAddModal(false);
              }}
            />

            <DepartmentAdd
              afterAdd={(data) => {
                if (data?.id) {
                  makeAlert('New Department Added');
                  handleChange({
                    target: {
                      type: 'department',
                      name: 'department_id',
                      value: { id: data?.id, name: data?.name },
                    },
                  });
                }
              }}
              handleClose={() => {
                setDepartmentAddModal(false);
                departmentRefetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {addDesignationModal && (
        <Modal.Container
          handleClose={() => {
            setDesignationAddModal(false);
          }}
        >
          <Modal.View className={'modal_view_label modal_view'}>
            <Modal.Head
              Heading={'Add Designation'}
              handleClose={() => {
                setDesignationAddModal(false);
              }}
            />
            <DesignationAdd
              defaultDepartment={employee?.department_id?.id ? employee?.department_id : null}
              afterAdd={(data) => {
                if (data?.id) {
                  makeAlert('New Designation Added');
                  if (parseInt(data?.department_id) !== parseInt(employee?.department_id?.id)) {
                    handleChange({
                      target: {
                        type: 'department',
                        name: 'department_id',
                        value: null,
                      },
                    });
                  }
                  // if (parseInt(data?.department_id) === parseInt(employee?.department_id?.id)) {
                  handleChange({
                    target: {
                      name: 'designation_id',
                      value: { id: data?.id, name: data?.name },
                    },
                  });
                  // }
                }
              }}
              handleClose={() => {
                setDesignationAddModal(false);
                designationRefetch();
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      <div className='modal_footer'>
        <div className='d-flex top-light-shadow justify-content-between align-items-center apply-btn-container'>
          <div>
            {!id && (
              <a
                className='reset-button cursor-pointer'
                onClick={() => {
                  setSubmitted(false);
                  setIsLoading(false);
                  // setIsCredentialRequired(false);

                  setEmployee({
                    ...defaultEmployee,
                    reported_users: [
                      reportingUserFields(
                        currentUser,
                        currentUser?.is_sub_admin ? currentUser?.is_sub_admin == false : false,
                        1,
                      ),
                    ],
                  });

                  setCompressed(null);
                }}
              >
                Reset All
              </a>
            )}
          </div>
          <div className='d-flex  gap-2'>
            <Button.Container
              type='button'
              className='cancel-btn'
              handleOk={() => {
                handleClose();
              }}
            >
              <Cancel />
              <Button.Title title='Cancel' />
            </Button.Container>
            <Button.Container
              handleOk={(e) => handleSubmit(e)}
              type='submit'
              isDisabled={isLoading}
            >
              <Tick />
              <Button.Title title={'Submit'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </div>
    </>
  );
}

const SelectedReportingUsers = ({
  index,
  item,
  handleReportingUserFeatureChange,
  handleDelete,
}) => {
  return (
    <tr key={index}>
      <td>{item?.name}</td>
      <td>
        <ToggleButton
          id={index}
          isChecked={item?.is_enable_notification}
          size={30}
          handleToggle={(event) => {
            handleReportingUserFeatureChange(index, {
              target: {
                name: 'is_enable_notification',
                checked: event.target.checked,
              },
            });
          }}
        />
      </td>
      <td>
        <input
          className='form-check-input'
          type='radio'
          name='is_immediate'
          id={index}
          checked={item?.is_immediate}
          onChange={(event) => handleReportingUserFeatureChange(index, event)}
        />
      </td>
      <td>
        {/* {!item.is_super_admin && (
          <Trash2
            style={{ width: '15px' }}
            onClick={() => {
              handleDelete(index);
            }}
          />
        )} */}
        <Trash2
          style={{ width: '15px' }}
          onClick={() => {
            handleDelete(index);
          }}
        />
      </td>
    </tr>
  );
};
