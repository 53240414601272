import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';

export const timeSheetService = {
    getTimeSheetEntries,
    generateTimeSheet,
    getTimeSheetList,
    deleteTimeSheet

};

async function getTimeSheetEntries(payload) {
    const apiUrl = `${apiConstants.apiUrl}/get-time-sheet-entries`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(apiUrl, requestOptions).then(handleResponse);
}



async function generateTimeSheet(payload) {
    const apiUrl = `${apiConstants.apiUrl}/generate-time-sheet`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };

    return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getTimeSheetList(payload) {
    const apiUrl = `${apiConstants.apiUrl}/time-sheet-list`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };

    return fetch(apiUrl, requestOptions).then(handleResponse);
}
async function deleteTimeSheet(payload) {
    const apiUrl = `${apiConstants.apiUrl}/remove-time-sheet-by-id`;
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };

    return fetch(apiUrl, requestOptions).then(handleResponse);
}