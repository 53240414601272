import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { CommonDropDownStyle } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { taskAssignUserSelect } from '../Task/taskConstants';

/***
 * @callback FormalLabelCallBack
 * @param data - The option data
 * @param metadata - THe metadata related to the react select
 */
/***
 * @callback GetCallBack
 * @param option - The option data
 */
/***
 * @callback GetFilterCallback
 * @param user - The user object
 */

/***
 * Use this if you need to use multiple react select with same useQuery.
 * Ie. if you need a userList for two select and manage search for each.
 * THis will have separate search function, you don't have to add search key on initial call.
 * This will not have search conflict with other react select data.
 * @param {object} props
 * @param {object[]} props.initialValue - THe initial loaded value of the list
 * @param {string} props.queryKey - The key used for querying the function
 * @param {functionName?} props.queryFn - The function to be called on search
 * @param {(searchKey:string)=>{}?} props.getFunctionParams - A function to generate the function params
 * @param {boolean?} props.isCustomFunction - If true will take the queryFn and params from teh provided function, false by default
 * @param {GetCallBack} props.getOptionLabel - For get the label from the list
 * @param {GetCallBack} props.getOptionValue - FOt getting teh select value from the list
 * @param {FormalLabelCallBack} props.formatOptionLabel - For formatting the select list
 * @param {GetFilterCallback | null} props.getFilteredSearchValues = For filtering the searched user list
 * @returns {React.ReactNode} - returns a react node
 */
function SearchableQuerySelect({
  initialValue = [],
  queryKey = 'unidentifiedKey',
  queryFn = () => {},
  getFunctionParams = () => {},
  getFilteredSearchValues = null,
  querySelect = null,
  isCustomFunction = false,
  itemToAdd = null,
  enableCondition = true,
  ...restProps
}) {
  const [searchKey, setSearchKey] = useState('');
  const debouncedSearchKey = useDebounce(searchKey, 500);
  const params = getFunctionParams(debouncedSearchKey);

  const { data, isLoading } = useQuery({
    queryFn: isCustomFunction
      ? () => queryFn(params)
      : () =>
          userService.userList({
            select: taskAssignUserSelect,
            searchKey: debouncedSearchKey,
          }),
    queryKey: [queryKey[0], debouncedSearchKey],
    select: (data) => (querySelect ? querySelect(data) : data.data.rows),
    enabled: Boolean(debouncedSearchKey) && enableCondition,
  });

  const searchData = data && itemToAdd ? [itemToAdd, ...data] : data;

  const options =
    debouncedSearchKey && data
      ? getFilteredSearchValues
        ? searchData.filter(getFilteredSearchValues)
        : searchData
      : initialValue;

  return (
    <ReactSelect
      options={options}
      //styles={DropDownStyle}
      styles={CommonDropDownStyle}
      openMenuOnClick
      isLoading={isLoading}
      classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
      filterOption={null}
      inputValue={searchKey}
      onInputChange={(inputString) => setSearchKey(inputString)}
      {...restProps}
    />
  );
}

export default SearchableQuerySelect;
