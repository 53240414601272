import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import { alertConstants } from '../_constants';
import { taxService } from '../_services/tax.service';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { TaxState } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import './invoice.css';

export const AddTaxModal = ({ handleClose = () => {}, id = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [taxData, setTaxData] = useState(TaxState);
  const inputRefs = useRef([]);

  const fetchTax = async () => {
    const data = await taxService.taxGet({
      id: id,
      select: ['id', 'name', 'rate'],
    });

    setTaxData({
      ...data?.data,
    });
    return data;
  };

  // get all user list
  const {
    // eslint-disable-next-line no-unused-vars
    data: tax,
  } = useQuery({
    queryKey: ['tax-list'],
    queryFn: () => fetchTax(),
    enabled: Boolean(id),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTaxData({
      ...taxData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (taxData?.name?.toString().trim() === '') {
      setIsLoading(false);
      return null;
    }

    if (!taxData?.rate?.toString().trim() || isNaN(Number(taxData?.rate))) {
      setError({ common: 'Rate must be a valid number' });
      setIsLoading(false);
      return;
    }
    try {
      await taxService.taxAdd({ ...taxData, rate: Number(taxData.rate) });
      setSubmitted(false);
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };
  return (
    <>
      <div className='modal_view_content'>
        <ErrorComponent error={error?.message} />

        <div className='invoice-form-section'>
          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Name</label>
            <input
              type='text'
              className='input-box'
              value={taxData?.name}
              name='name'
              onChange={handleChange}
            />
            <ErrorBlock
              errorMsg={'Name required'}
              hasError={submitted && taxData?.name.toString().trim() === ''}
            />
          </div>

          <div>
            <label className='mb-1 fz-13px-rem  label_input'>Rate</label>

            <div className='currency-indicator-input'>
              <input
                type='text'
                className='input-box border-none p-0'
                value={taxData?.rate}
                name='rate'
                // onChange={handleChange}
                onChange={(e) => {
                  // Allow only numbers and at most one decimal point
                  const value = e.target.value;

                  if (/^\d*\.?\d*$/.test(value) || value === '') {
                    if (value <= 100) handleChange(e);
                  }
                }}
                ref={(el) => (inputRefs.current = el)} // Store reference
              />
              <span
                className='currency-indicator'
                style={{ border: 'none', cursor: 'pointer' }}
                onClick={() => inputRefs.current?.focus()} // Focus input
              >
                %
              </span>
            </div>
            <ErrorBlock
              errorMsg={'Rate must be a valid number'}
              hasError={
                submitted &&
                (taxData?.rate.toString().trim() === '' || isNaN(Number(taxData?.rate)))
              }
            />
          </div>
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
};
