/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AlignJustify, EllipsisVertical, Pen } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext, Images } from '../../Utils';
import { Button, Modal } from '../../_components';
import { statusService } from '../../_services/status.service';
import { HeadWithArrow, StatusCard } from '../UtilsInSettings';
import { StatusAdd } from './StatusAdd';
import { Cancel, CustomTrashIcon, Tick } from '../../Utils/SvgIcons';

export function StatusComponent({ isLoading, StatusData, statusListRefetch }) {
  const [newStatusData, setNewStatusData] = useState(StatusData || []);

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectId, setSelectedId] = useState(null);

  useEffect(() => {
    setNewStatusData(StatusData);
  }, [StatusData]);

  //--hooks
  const { makeAlert } = useContext(GlobalContext);
  const queryClient = useQueryClient();

  //--state
  const [addModal, setAddModal] = useState(false);

  const [selectedData, setSelectedData] = useState({});
  const [arrowDown, setArrowDown] = useState(false);

  //-- status add or update
  const handleAddOrUpdate = async (query) => {
    await statusService.statusAdd(query);
    makeAlert('Success');
    setAddModal(false);
    statusListRefetch();
  };

  //-- react query fot mutation function
  const {
    mutate: handleMutate,
    isError,
    error,
  } = useMutation({
    mutationFn: handleAddOrUpdate,
  });

  //-- react query fot mutation function
  const {
    mutate: handleDelete,
    // isError,
    error: deleteError,
  } = useMutation({
    mutationFn: handleDeleteFunction,
  });

  async function handleDeleteFunction(query) {
    await statusService.statusDelete(query);
    makeAlert('Success');
    setAddModal(false);
    setDeleteModal(false);
    statusListRefetch();
  }

  useEffect(() => {
    if (error?.message || deleteError?.message) {
      makeAlert(error?.message || deleteError?.message);
    }
  }, [error?.message, deleteError?.message]);

  //-- handling edit
  const onHandleEdit = (selectedObj) => {
    setAddModal(true);
    setSelectedData(selectedObj);
  };

  //-- For modal close
  const modalClose = () => {
    setAddModal(false);
    setSelectedData({});
  };

  //-- handling delete
  const onHandleDelete = (deleteData) => {
    // let deleteQuery = {};
    // deleteQuery.id = deleteData.id;
    // handleDelete(deleteQuery);
    setSelectedId(deleteData.id);
    setDeleteModal(true);
    // const radioButton = document.querySelector('input[type="radio"]:checked');
    // radioButton.checked = false;
  };

  const dragItem = useRef();
  const dragEnterItem = useRef();

  const handleDragStart = (index) => {
    dragItem.current = index;
  };

  const handleDragEnter = (index) => {
    dragEnterItem.current = index;
  };

  const handleSettingUpdate = (data) => {
    const extractedData = data.map(({ id, order, name }) => ({ id, order, name }));

    statusService.statusArrange(extractedData);
  };

  // --react query for mutation function
  const { mutate: orderMutate } = useMutation({
    mutationFn: handleSettingUpdate,
    mutationKey: ['status-arrange'],
    onSuccess: (data) => {},
  });

  const handleDragEnd = () => {
    const dragIndex = dragItem.current;
    const enterIndex = dragEnterItem.current;

    if (dragIndex === enterIndex) return;

    const updatedStatusData = [...newStatusData];
    const [draggedItem] = updatedStatusData.splice(dragIndex, 1);
    updatedStatusData.splice(enterIndex, 0, draggedItem);

    // Update order based on new indices
    updatedStatusData.forEach((item, index) => {
      item.order = index + 1;
    });

    setNewStatusData(updatedStatusData);
    orderMutate(updatedStatusData);
  };

  return (
    <section>
      <HeadWithArrow
        head={'Default Status'}
        isDown={arrowDown}
        handler={() => setArrowDown(!arrowDown)}
      />
      {!arrowDown && (
        <>
          {isLoading && <div>Loading...</div>}
          {newStatusData
            ?.sort((a, b) => a.order - b.order)
            ?.map((LABEL, index) => {
              return (
                <div
                  className='status-contain'
                  key={index}
                  draggable
                  onDragOver={(e) => e.preventDefault()}
                  onDragStart={() => handleDragStart(index)}
                  onDragEnter={() => handleDragEnter(index)}
                  onDragEnd={handleDragEnd}
                >
                  <div style={{ display: 'flex', gap: 10 }}>
                    <AlignJustify size={18} opacity={0.7} cursor={'pointer'} />
                    <StatusCard
                      is_default={LABEL?.is_default}
                      LABEL={LABEL?.name}
                      defaultName={LABEL?.default_name}
                      color={LABEL?.color || '#e1e1e1'}
                    />
                  </div>
                  <div className='edit-dele-wrap'>
                    <div role='button' onClick={() => onHandleEdit(LABEL)}>
                      <Pen size={16} color='#87909E' />
                    </div>
                    <div role='button' onClick={() => onHandleDelete(LABEL)}>
                      <CustomTrashIcon size={16} />
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <AddNewView head={'Add New'} handleOK={() => setAddModal(true)} /> */}
        </>
      )}

      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' />
              <div className='del-description'>Are you sure you want to delete.</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setDeleteModal(false)}
                className={'status-del-cancel'}
              >
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDelete({ id: selectId })}>
                <Tick />
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}

      {addModal && (
        <Modal.Container handleClose={modalClose}>
          <Modal.View className={'add-status'}>
            <StatusAdd
              setSelectedData={setSelectedData}
              handleMutate={handleMutate}
              selectedData={selectedData}
              modalClose={modalClose}
              setAddModal={setAddModal}
              length={StatusData?.length}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </section>
  );
}

//-- options component
const OptionsComponent = ({ index, handleEdit, handleDelete, LABEL }) => {
  const handleOutsideClick = (event) => {
    const radioButton = document.querySelector('input[type="radio"]:checked');
    if (radioButton && !radioButton.closest('.edit-dele-wrap').contains(event.target)) {
      radioButton.checked = false;
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleRadioClick = (event) => {
    const radioButton = event.target;
    const editDeleWrap = radioButton.closest('.edit-dele-wrap');
    const optionsDiv = editDeleWrap.querySelector('.options');
    const containerRect = editDeleWrap.getBoundingClientRect();
    const optionsRect = optionsDiv.getBoundingClientRect();

    const bottomOverflow = containerRect.bottom + optionsRect.height > window.innerHeight;
    const topOverflow = containerRect.top - optionsRect.height < 0;

    if (bottomOverflow) {
      optionsDiv.style.top = 'auto';
      optionsDiv.style.bottom = '100%';
    } else if (topOverflow) {
      optionsDiv.style.top = '100%';
      optionsDiv.style.bottom = 'auto';
    } else {
      optionsDiv.style.top = '100%';
      optionsDiv.style.bottom = 'auto';
    }
  };

  return (
    <div className='edit-dele-wrap' style={{ position: 'relative' }}>
      <input
        style={{ display: 'none' }}
        onClick={handleRadioClick}
        type='radio'
        id={`bb-${index}`}
        name='status'
      />
      <label htmlFor={`bb-${index}`}>
        <EllipsisVertical cursor={'pointer'} size={17} />
      </label>
      <div className='options'>
        <div onClick={() => handleEdit(LABEL)} className='option'>
          Edit
        </div>
        {!LABEL.is_default && (
          <>
            <div className='option-line'></div>
            <div className='option' onClick={() => handleDelete(LABEL)}>
              Delete
            </div>
          </>
        )}
      </div>
    </div>
  );
};
