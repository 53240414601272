/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { X } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Button } from '../_components';
import { roleService } from '../_services';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import { GlobalContext } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';

export const RoleAdd = ({ id = null, handleClose = () => {} }) => {
  const [roleData, setRoleData] = useState({ name: '' });
  const [submitted, setSubmitted] = useState(false);
  const { makeAlert } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleRoleSave = async () => {
    await roleService.roleAdd(roleData);
    // return response;
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleRoleSave,
    mutationKey: ['role-add'],
    onSuccess: (data) => {
      handleClose();
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  const fetchRoleData = async (id) => {
    return roleService.roleGet({ id: id, select: ['id', 'name'] }).then((response) => {
      setRoleData({ name: response?.data[0]?.name, id: id });
      return response?.data[0];
    });
  };

  const { data: roleDetails, isLoading: roleLoading } = useQuery({
    queryKey: ['role-data', id],
    queryFn: () => fetchRoleData(id),
    select: (data) => {
      return data;
    },
    enabled: Boolean(id),
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    return setRoleData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setSubmitted(true);
    setIsLoading(true);

    if (roleData?.name?.toString()?.trim() === '' || !roleData?.name) {
      setIsLoading(false);
      return null;
    }
    handleMutate();
  };
  return (
    <div className='d-flex flex-column' style={{ height: '100vh' }}>
      <div className='modal_header'>
        <div className='invite-head fz-16px'>{id ? 'Edit' : 'Add'} Role</div>
        <div
          className='pointer'
          onClick={() => {
            handleClose();
          }}
        >
          <X size={16} color='#87909E' />
        </div>
      </div>

      <div className='invite-form-body'>
        {/* name section  */}
        <div>
          <label className='form-input-label'>Role Name</label>
          <span className='field-required'> *</span>
          <input
            type='text'
            className='input-box'
            style={{ height: '34px' }}
            name='name'
            value={roleData?.name}
            placeholder={`Enter name `}
            onChange={(event) => {
              handleChange(event);
            }}
          />

          <ErrorBlock
            errorMsg={'Name required'}
            hasError={submitted && (roleData?.name?.toString()?.trim() === '' || !roleData?.name)}
          />
        </div>

        {roleLoading && (
          <div className='text-center'>
            <LoadingIcon size={40} />
          </div>
        )}
      </div>

      <div className='modal_footer'>
        <Button.Container
          className={'outline-cancel-button'}
          type='button'
          handleOk={() => {
            setRoleData({ name: '' });
            handleClose();
          }}
        >
          <Button.Title title={'Cancel'} />
        </Button.Container>
        <Button.Container
          type='submit'
          className='blue-btn'
          isDisabled={isLoading}
          handleOk={handleSubmit}
        >
          <Button.Title title='Save' />
          <Button.Loading isLoading={isLoading} />
        </Button.Container>
      </div>
    </div>
  );
};
