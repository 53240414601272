import React, { useContext } from 'react';
import Select from 'react-select';
import { GlobalContext } from '../../Utils';
import { Popover } from '../../_components/Popover';
import { MENU } from '../../_constants';
import {
  EMPLOYEE_FIELDS,
  REPORTING_FIELDS,
  TASK_OWNER_OPTIONS,
  userTypeList,
} from '../SettingsLabels';
import { customStyles } from '../UtilsInSettings';

export default function TaskEditSettings({
  taskSettingData,
  loadingItemName,
  handleEditTaskChange,
  modalRef = null,
}) {
  const loadOptionFields = (inputValue, callback) => {
    // Filter options based on the inputValue
    const filteredOptions = TASK_OWNER_OPTIONS.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
    callback(filteredOptions);
  };

  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const UPDATED_EMPLOYEE_FIELDS = [...EMPLOYEE_FIELDS, LABEL_OF_CLIENT, LABEL_OF_PROJECT];
  const UPDATED_REPORTING_FIELDS = [...REPORTING_FIELDS, LABEL_OF_CLIENT, LABEL_OF_PROJECT];

  const loadOptionForFields = (inputValue, callback, userType) => {
    // Filter options based on the userType and inputValue
    const filteredOptions =
      userType === 'Task Owner'
        ? UPDATED_EMPLOYEE_FIELDS.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase()),
          )
        : UPDATED_REPORTING_FIELDS.filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase()),
          );
    // Convert the filteredOptions to an array of objects with 'value' and 'label' properties
    const options = filteredOptions.map((option) => ({
      value: option,
      label: option,
    }));
    // Call the callback function with the options
    callback(options);
  };

  return (
    <>
      {userTypeList?.map((user, index) => {
        const userTypeData = taskSettingData?.[user.name];
        return (
          <div key={index}>
            <div className='owner-contain'>
              <div className='sub-head'>{user.label}</div>
              <div>
                <Popover.PopoverItem
                  scrollRef={modalRef}
                  positionLeft={(width) => width - 150}
                  positionTop={() => '0px'}
                  isAdjustable
                >
                  <Select
                    className='task-select-style'
                    styles={customStyles}
                    options={TASK_OWNER_OPTIONS}
                    loadOptions={loadOptionFields}
                    id={`ReportingUser_${user.name}`} // Use a unique ID for each user
                    name={user.name}
                    value={
                      userTypeData
                        ? TASK_OWNER_OPTIONS?.find(
                            (option) => option.value === userTypeData?.fields,
                          )
                        : null
                    }
                    onChange={(selectedOption) => {
                      handleEditTaskChange(user.name, selectedOption?.value);
                    }}
                    menuPortalTarget={document.getElementById('MODAL')}
                  />
                </Popover.PopoverItem>
              </div>
            </div>
            {userTypeData?.fields === 3 && (
              <>
                <div className='desc-txt'>
                  Set task edit selected field permission for {user.label}.
                </div>
                <div className='owner-select-contain'>
                  <Select
                    className='full-select-style'
                    styles={{
                      indicatorsContainer: () => {
                        return {
                          display: 'flex',
                          justifyContent: 'center',
                        };
                      },
                      control: (style) => ({
                        ...style,
                        fontSize: 13,
                      }),
                      option: (style) => ({
                        ...style,
                        fontSize: 13,
                      }),
                      // menuList: (style) => ({
                      //   ...style,
                      //   fontSize: 13,
                      // }),
                    }}
                    options={
                      user.label === 'Task Owner'
                        ? UPDATED_EMPLOYEE_FIELDS.map((field) => ({
                            value: field,
                            label: field,
                          }))
                        : UPDATED_REPORTING_FIELDS.map((field) => ({
                            value: field,
                            label: field,
                          }))
                    }
                    value={userTypeData?.selectedFields?.map((field) => ({
                      value: field,
                      label: field,
                    }))}
                    onChange={(selectedOption) => {
                      const selectedFields = selectedOption?.map((option) => option?.value);
                      handleEditTaskChange(user.name, selectedFields, 'selectedFields');
                    }}
                    isMulti
                    loadOptions={(inputValue, callback) =>
                      loadOptionForFields(inputValue, callback, user.label)
                    }
                    isLoading={user.name === loadingItemName}
                    menuPortalTarget={document.getElementById('MODAL')}
                  />
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
}
