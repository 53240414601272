/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';
import { ArrowDownToLine, FileText, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFileUploadMutation } from '../../Hook/useFileUploadMutation';
import useObserver from '../../Hook/useObserver';
import { getFileType, getSize, Images, sanitizeFileName } from '../../Utils';
import { CircleLoader, CircularProgressBar } from '../../Utils/SvgIcons';
import { Button, Modal } from '../../_components';
import { apiConstants } from '../../_constants';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import { fileService } from '../../_services';
import { documentTypes, documentTypesWithColor, imageTypes, videoTypes } from '../taskConstants';

export function TaskAttachmentsWithAdd({
  taskId,
  CustomView,
  handleOK,
  accept,
  ownerID,
  name,
  isMulti = false,
  openOnStart = false,
  onClose = () => {},
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({ modal: false, file: null });
  // const [isUploading, setIsUploading] = useState(false);
  const [isDelete, setIsDelete] = useState({ modal: false, fileId: null });
  // Use useRef to store the latest taskId
  const latestTaskIdRef = React.useRef(taskId);

  // Update the ref whenever taskId changes
  useEffect(() => {
    latestTaskIdRef.current = taskId;
  }, [taskId]);
  //fetch files
  const {
    data: fileList,
    refetch: fileRefetch,
    fetchNextPage,
    isLoading: isFileLoading,
    hasNextPage,
    // isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['file', taskId],
    queryFn: ({ pageParam = 1 }) =>
      fileService.fileList({
        task_id: latestTaskIdRef.current,
        select: ['id', 'name', 'task_id', 'files_data', 'size'],
        status: 1,
        pageVo: { pageNo: pageParam, noOfItems: 10 },
      }),
    select: (data) => data?.pages,
    enabled: Boolean(taskId),
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });

  //formats to accept
  // const acceptFormat = '.pdf,.doc,.docx,.xml,.html,.txt,image/*';

  //to selected file data handling
  const onFileSelect = (files) => {
    let cloneSelectedFiles = [...selectedFiles];
    for (let file of files) {
      cloneSelectedFiles.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        document_type: 'task-files',
        file,
      });
    }
    handleFileUpload([...cloneSelectedFiles]);
    setSelectedFiles([...cloneSelectedFiles]);
  };

  //file upload service call
  const onUpload = async (fileData) => {
    try {
      await fileService.fileAdd(fileData);
      fileRefetch();
    } catch (e) {
      console.log({ e });
    }
  };
  const { createSignedUrlAndUploadFile, progressMap } = useFileUploadMutation();
  //upload handler
  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }
    // setIsUploading(true);
    for (let fileObj of data) {
      try {
        const fileName = await createSignedUrlAndUploadFile(fileObj.file, 'uploadFile');
        const uploadedData = {
          name: fileObj.sanitizeName,
          task_id: latestTaskIdRef.current,
          description: name,
          files_data: {
            url: fileName,
            name: fileObj.sanitizeName,
            size: fileObj.size,
            extension: fileObj.extension,
          },
          owner_id: ownerID,
          size: fileObj.size,
          file_type: 1,
          issued_date: moment().valueOf(),
        };
        onUpload(uploadedData);
      } catch (error) {
        console.log({ error });
      }
    }
    // setIsUploading(false);
    setSelectedFiles([]);
  };

  //temporarily removed

  //remove file by deactivate
  const fileDeactivate = async (fileId) => {
    try {
      await fileService.fileDeactivate({ id: fileId });
      fileRefetch();
    } catch (e) {
      console.log(e);
    }
    setIsDelete({ modal: false, fileId: null });
  };
  const handleDelete = (fileId) => {
    setIsDelete({ modal: true, fileId: fileId });
  };
  // for fetch next page if it has next page and the observer ref is visible
  const { observerRef } = useObserver({
    onIntersection: () => hasNextPage && fetchNextPage(),
    dependency: [hasNextPage],
  });

  //uploaded files view
  const UploadedFiles = ({ data = [] }) => {
    const mappedData = data?.map((file, index) => {
      const size = getSize(parseInt(file?.size)); //function for get file size
      const fileType = imageTypes.includes(file?.files_data?.extension);
      return (
        <div className='file-container' key={index}>
          <div
            className={`file-view ${fileType ? 'pointer' : ''} `}
            onClick={() => {
              fileType &&
                setSelectedFile({
                  modal: true,
                  file: apiConstants?.imgUrlS3 + file?.files_data?.url,
                });
            }}
          >
            <GetFileWithTypes file={file} key={index} />
          </div>
          <div className='row m-0 file-text-container'>
            <text className='file-text'>
              {file?.name?.length > 15 ? file?.name.substring(0, 15) + '...' : file?.name}.
              {file?.files_data?.extension}
            </text>
            <text className='file-size'>{size}</text>
          </div>
          <div
            className='file-option-delete'
            onClick={() => {
              handleDelete(file.id);
            }}
          >
            <Trash color='#707070' size={12} />
          </div>
          <div
            className='file-option-download'
            onClick={() => {
              window.location.href = apiConstants?.imgUrlS3 + file?.files_data?.url;
            }}
          >
            <ArrowDownToLine color='#707070' size={12} />
          </div>
        </div>
      );
    });
    return mappedData;
  };

  //drop files handler
  const onDrop = useCallback((acceptedFiles) => {
    // Handle the files here
    onFileSelect(acceptedFiles);
  }, []);
  // drop hook initialization
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <section className='attachment-container'>
      <div className='py-2'>
        <text className='attachment-heading'>Attachments</text>
      </div>
      <input
        accept={'all'}
        multiple={isMulti}
        onChange={(e) => onFileSelect(e.target.files)}
        id='upload'
        type='file'
        style={{ display: 'none' }}
      />
      <div className='attachment-upload-container'>
        <div {...getRootProps({ className: 'd-flex align-items-center fz-13px' })}>
          <input {...getInputProps()} id='upload' />
          <div className='ptr'>
            {isDragActive ? (
              <> Drop the files here ...</>
            ) : (
              <>
                Drop your files here to
                <span style={{ textDecoration: 'underline' }}> upload</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='upload-files-container mt-3'>
        {selectedFiles?.length > 0 && (
          <div className='file-container'>
            <div className='file-view'>
              <CircularProgressBar
                unit=''
                percentage={progressMap?.uploadFile ?? 0}
                size='35'
                downloadedMB={progressMap?.uploadFile ?? 0}
              />
            </div>
            <div className='row m-0 file-text-container'>
              <text className='file-text'>
                {selectedFiles?.[0]?.name?.length > 15
                  ? selectedFiles?.[0]?.name.substring(0, 15) + '...'
                  : selectedFiles?.[0]?.name}
                .{selectedFiles?.[0]?.files_data?.extension}
              </text>
              <text className='file-size'>{selectedFiles?.[0]?.size}</text>
            </div>
          </div>
        )}
        {isFileLoading ? (
          <div className='d-flex w-100 justify-content-center align-items-center p-2'>
            <CircleLoader />
          </div>
        ) : (
          <>
            {fileList?.map((file, index) => {
              return <UploadedFiles key={index} data={file?.data?.rows} />;
            })}
            <div ref={observerRef} style={{ height: 5, width: 5 }}></div>
          </>
        )}
      </div>
      {isDelete.modal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head
              handleClose={() => setIsDelete({ modal: false, fileId: null })}
              Heading={'Delete Confirmation'}
            />
            <Modal.Description>
              <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
              <div className='del-description'>{DELETE_NOTE.FILE}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                // isDisabled={isDeleting}
                handleOk={() => setIsDelete({ modal: false, fileId: null })}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                // isDisabled={isDeleting}
                handleOk={() => fileDeactivate(isDelete.fileId)}
              >
                <Button.Title title={'Delete'} />
                <Button.Loading
                  // isLoading={isDeleting}
                  color='#FFF'
                />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}

      {selectedFile.modal && (
        <Modal.Container>
          <Modal.View>
            <Modal.Head
              handleClose={() => setSelectedFile({ modal: false, file: null })}
              Heading={'Image view'}
            />
            <Modal.Description>
              <img
                src={selectedFile.file}
                className=''
                alt=''
                style={{ objectFit: 'contain', width: '100%', height: 350 }}
              />
            </Modal.Description>
          </Modal.View>
        </Modal.Container>
      )}
    </section>
  );
}
// to get file (document) icon view
const GetFileWithTypes = ({ file }) => {
  if (imageTypes.includes(file?.files_data?.extension)) {
    return (
      <img
        src={apiConstants?.imgUrlS3 + file?.files_data?.url}
        height={'100%'}
        width={'100%'}
        style={{ padding: '9px 0px', objectFit: 'contain' }}
        key={file?.files_data?.url}
        alt={file?.name}
      />
    );
  }
  if (documentTypes.includes(file?.files_data?.extension)) {
    const color = documentTypesWithColor[file?.files_data?.extension] ?? '#394056';
    return (
      <>
        <div className='row doc-item' style={{ background: color }}>
          <FileText size={20} color={'#fff'} />
          <text style={{ color: '#fff', textAlign: 'center' }}>{file?.files_data?.extension}</text>
        </div>
      </>
    );
  }
  if (videoTypes.includes(file?.files_data?.extension)) {
    return (
      <video
        src={apiConstants?.imgUrlS3 + file?.files_data?.url}
        height={'100%'}
        width={'100%'}
        style={{ padding: '9px 0px' }}
        key={file?.files_data?.url}
      />
    );
  }
  return null; // or some default element
};
