/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { RT } from '../_constants';
import { Images } from '../Utils';
import { TruncatedText } from './TruncatedText';

export const UpdateItem = ({
  index,
  queryPayload = {},
  taskOwnerProfile,
  taskName,
  ltMsgCreatorProfile,
  ltMsgCreatorName,
  lastMessage,
  updatesCount,

  formattedDate,
  taskId,
  pathName = '/',
}) => {
  const navigate = useNavigate();
  let navLink = `/${RT.TASK}/${taskId}?tab=description&${queryPayload}&&nextAndPreviousFromChatUpdate=true&currentIndex=${index}&&prevpath=${pathName}`;

  let nameAndMessage = `${ltMsgCreatorName}: ${lastMessage}`;

  return (
    <div className='status-card pointer' onClick={() => navigate(navLink)}>
      <div>
        <CircularAvatar isMale source={taskOwnerProfile} size={35} />
        {/* <img src={taskOwnerProfile} alt='Document Icon' className='document-icon' /> */}
      </div>

      <div className='status-content'>
        <TruncatedText className={'main-text'} text={taskName} />

        <div className='nt-message-content'>
          <div className='nt-icon-container'>
            <div className='nt-chat-icon'>
              <img src={Images.chatGreen} height={14} width={14} />
            </div>
            <span className='nt-message-text'>
              <span className='message-text'>
                <TruncatedText className={'update-message'} text={nameAndMessage} />
              </span>
            </span>
          </div>
          <div className={`nt-timestamp ${Boolean(updatesCount) > 0 ? 'text-bold' : ''}`}>
            {formattedDate}
          </div>
        </div>

        
      </div>
    </div>
  );
};
