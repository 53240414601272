import { useNavigate, useSearchParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import { Table, Tbody, Th, Thead, Tr } from 'react-super-responsive-table';

import { Calendar, Home, User } from 'lucide-react';
import moment from 'moment';
import { PaginationComponent, TableRow } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { workflowService } from '../_services';
import useErrorAlert from '../Hook/useErrorAlert';
import { MyTeamIcon } from '../Utils';
import { LoadingIcon, ProgressIcon, ProjectIcon, SINoIcon } from '../Utils/SvgIcons';
import './workflow.css';
const TABLE_COLUMN = ['Sl.No', 'Name', 'No.of tasks', 'No.of templates', 'Status', 'Created At'];

export function WorkflowProcess() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);

  const { makeAlert } = useErrorAlert();
  const PROJECT_TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <ProgressIcon /> Trigger Type
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User size={20} /> Wf.Name
        </div>
      ),
      minWidth: 50,
    },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          {' '}
          <Calendar height={14} width={14} /> Created At
        </div>
      ),
      minWidth: 50,
    },
    {
      name: <div></div>,
    },
  ];
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const queryClient = useQueryClient();

  const tableContainerRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [addModal, setAddModal] = useState(false);
  const [view, setView] = useState('add');
  // useState section
  const [query, setQuery] = useState({
    select: ['id', 'name', 'createdAt', 'updatedAt'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    // status: 1,
  });

  // Form state
  const [workflow, setWorkflow] = useState({ name: '', description: '' });

  // organization list API call section
  const {
    data: workflowData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['workflow-list', query?.pageVo?.pageNo],
    queryFn: () => workflowService.getWorkflowProcessList(query),
  });

  const tableData = workflowData?.data?.rows?.map((process, index) => {
    const slNo = (workflowData?.data?.page - 1) * workflowData?.data?.limit + index + 1;

    const createdAtInString = moment(process.createdAt).format('MMM DD, YYYY');
    return (
      <tr key={index}>
        <td>{slNo}</td>
        <td>{process?.trigger_type}</td>
        <td>{process?.workflow_name}</td>
        <td>{process?.current_status}</td>
        <td>{createdAtInString}</td>
      </tr>
    );
  });

  const listItemCount = workflowData?.data?.rows.length;

  return (
    <>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            // onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Process'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<ProjectIcon height={12} width={12} color='#2A2E34' />}
            // onClick={() => navigate(`/${RT.WORK_FLOW_LIST}`)}
          />
        </div>
      </div>

      <div className='project-table-container' style={{ maxWidth: '100%' }}>
        <section className='mt-2'>
          <div
            className='project-table table-container no-padding'
            ref={tableContainerRef}
            style={{ maxHeight: '65vh' }}
          >
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr className='table-head'>
                  {PROJECT_TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody style={{ verticalAlign: 'middle' }}>
                {isLoading && (
                  <TableRow noOfCols={8}>
                    <LoadingIcon size={60} />
                  </TableRow>
                )}
                {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>}
                {listItemCount > 0 && isSuccess && tableData}
              </Tbody>
            </Table>
          </div>
        </section>
        {listItemCount > 0 && (
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={workflowData?.data}
            />
          </section>
        )}
      </div>

      <div style={{ padding: '5px 25px', display: 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            style={{
              background: '#d2d2d2',
              padding: '3px 9px',
              borderRadius: 4,
              marginTop: 15,
              cursor: 'pointer',
            }}
            onClick={() => setAddModal(true)}
          >
            Create
          </div>
        </div>

        <section className='mt-2'>
          <div className='table_outer'>
            <table className='table table-hover tbl mb-0'>
              <thead>
                <tr>
                  {TABLE_COLUMN.map((heading, index) => (
                    <th key={index} scope='col'>
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* {isLoading && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    Loading...
                  </td>
                </tr>
              )}
              {isError && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    Error fetching data
                  </td>
                </tr>
              )} */}
                {/* {!isLoading && workflows?.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No data found
                  </td>
                </tr>
              )} */}

                {/* {orgRows} */}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </>
  );
}
