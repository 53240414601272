import { useQuery } from '@tanstack/react-query';
import { Bell } from 'lucide-react';
import React from 'react';
import { notificationService } from '../_services/notification.service';
import { useUser } from '../Hook/useUser';

export default function NotificationBell({
  isNotificationVisible,
  setIsNotificationVisible,
  children,
}) {
  const user = useUser();
  const { data: notificationCount } = useQuery({
    queryKey: ['notificationCount'],
    refetchInterval: 20000,
    queryFn: () => notificationService.getNotificationCount(),
    enabled: user?.id ? true : false,
  });

  let NotificationCount =
    notificationCount?.data?.NotificationCount > 999
      ? '999+'
      : notificationCount?.data?.NotificationCount?.toString();

  NotificationCount = NotificationCount?.toString();

  const marginRightValue =
    NotificationCount?.length === 4
      ? 13
      : NotificationCount?.length === 3
      ? 10
      : NotificationCount?.length === 2
      ? 0
      : NotificationCount?.length === 1
      ? 0
      : 0;

  return (
    <div
      className='notification-header-wrap'
      style={{
        marginRight: marginRightValue,
      }}
    >
      {Boolean(parseInt(NotificationCount)) && (
        <div
          className='total_notification_count'
          style={{ position: 'absolute', marginLeft: '9px', marginTop: '-6px' }}
        >
          {NotificationCount}
        </div>
      )}
      <div className='d-flex pointer bell-clickable  header-menu-icon'>
        {/* <img className='tab-img' src={Images.Dashboard} /> */}
        <Bell size={19} onClick={() => setIsNotificationVisible((prev) => !prev)} />
        {isNotificationVisible && children}
      </div>
    </div>
  );
}
