import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const invoicePaymentService = {
  invoicePaymentAdd,
  invoicePaymentList,
  invoicePaymentGet,
  invoicePaymentDeactivate,
  invoicePaymentDetailsGet,
  invoicePaymentDownload,
};

async function invoicePaymentList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/invoice_payment_list', requestOptions).then(handleResponse);
}

function invoicePaymentGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_invoice_payment?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function invoicePaymentAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invoice_payment_add', requestOptions).then(handleResponse);
}

async function invoicePaymentDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/invoice_payment_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function invoicePaymentDetailsGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_invoice_payment_details?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function invoicePaymentDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invoice-payment-download', requestOptions).then(
    handleResponse,
  );
}
