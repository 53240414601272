import { useQuery } from '@tanstack/react-query';
import { ArrowLeftIcon, Home } from 'lucide-react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconLabelSet from '../_components/IconLabelSet';
import { RT } from '../_constants';
import { invoiceService } from '../_services/invoice.service';
import { MyTeamIcon } from '../Utils';
import { InvoiceIcon } from '../Utils/SvgIcons';
import { InvoiceListSinglePage } from './InvoiceListSinglePage';

export const InvoiceEstimate = () => {
  const navigate = useNavigate();
  const invoiceId = useParams()?.id;

  const { data: invoiceData } = useQuery({
    queryKey: ['invoice-get', invoiceId],
    queryFn: () =>
      invoiceService.invoiceGet({
        select: ['id', 'invoice_number'],
        id: invoiceId,
      }),
    select: (data) => data?.data,
  });

  return (
    <div className='h-75'>
      <div className='d-flex  breadcrumbs url-inner-navigation'>
        <div className='back-button-container' onClick={() => navigate(-1)}>
          <ArrowLeftIcon strokeWidth={1.5} size={26} color='#87909E' />
        </div>{' '}
        <div className='d-flex align-items-center '>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} stroke='var(--second-text-color)' />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Invoice'}
            fontWeight={400}
            icon={<InvoiceIcon primeColor='var(--second-text-color)' width={13} height={13} />}
            onClick={() => navigate(`/${RT.INVOICE_LIST}`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <div className='value-font-13 pointer'>{invoiceData?.invoice_number}</div>
        </div>
      </div>

      <div className='d-flex gap-3 h-100 p-3'>
        <div className='h-100' style={{ backgroundColor: 'white', borderRadius: 8, width: '35%' }}>
          <InvoiceListSinglePage />
        </div>
        {/* <div className='h-100' style={{ backgroundColor: 'white', borderRadius: 8, width: '60%' }}>
          <InvoiceSingleView id={invoiceId} />
        </div> */}
      </div>
    </div>
  );
};
