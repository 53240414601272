import moment from 'moment';
import React from 'react';

const DeleteMenuOption = ({
  deleteFromUseQuery,
  makeActionAlert,
  onDelete,
  selectedPosition,
  recoverMessageFromUseQuery,
  onClose,
}) => {
  const loggedUser = JSON.parse(localStorage.getItem('user'));

  const isUserSame = loggedUser.id === selectedPosition.item.creator_details.id;

  // TODO: After the time limit set in settings, adjust the time limit based on that. If no time limit delete option will always visible
  const isLimitPassed =
    new Date().getTime() - moment(selectedPosition.item.createdAt).valueOf() > 60000;

  if (isLimitPassed || !isUserSame) {
    return null;
  }

  return (
    <div
      className='btn-wrapper w-100 text-center'
      onClick={() => {
        const isTimeLimitPassed =
          new Date().getTime() - moment(selectedPosition.item.createdAt).valueOf() > 60000;
        if (isTimeLimitPassed) {
          makeActionAlert('Message deletion time limit exceeded.');
          return;
        }

        const deletedIndexObj = deleteFromUseQuery(selectedPosition.item.id);

        makeActionAlert({
          message: 'Message Deleted',
          isSuccess: false,
          time: 5,
          showButton: true,
          onTimesUp: () => {
            onDelete(selectedPosition.item, deletedIndexObj, selectedPosition?.index);
          },
          onButtonClick: ({ timeout }) => {
            clearTimeout(timeout);

            recoverMessageFromUseQuery(deletedIndexObj, selectedPosition.item);
          },
        });
        onClose();
      }}
    >
      Delete
    </div>
  );
};

const ReplyMenuOption = ({ selectedPosition, onReplySelect, onClose }) => {
  return (
    <div
      className='btn-wrapper w-100 text-center'
      onClick={() => {
        onReplySelect(selectedPosition.item);
        onClose();
        // setSelectedPosition({ x: 0, y: 0, item: null });
      }}
    >
      Reply
    </div>
  );
};

const ReactionMenuOption = ({ onClose, setReactionVisibleIndex, selectedPosition }) => {
  return (
    <div
      className='btn-wrapper w-100 text-center mt-1'
      onClick={() => {
        setReactionVisibleIndex(selectedPosition.index);
        onClose();
        // setSelectedPosition({ x: 0, y: 0, item: null });
      }}
    >
      React
    </div>
  );
};

const CopyMenuOption = ({ onClose, makeAlert, selectedPosition }) => {
  return (
    <div
      className='btn-wrapper w-100 text-center mt-1'
      onClick={() => {
        // Check whether the website is https or localhost, mic is only accessible on these sites
        const isLocalhost = window.location.hostname === 'localhost';
        const isHttps = window.location.protocol === 'https:';
        if (!isLocalhost && !isHttps) {
          onClose();
          makeAlert('This feature is restricted to secure (HTTPS) connections only.', 'error');
          // setSelectedPosition({ x: 0, y: 0, item: null });
          return;
        }
        navigator.clipboard?.writeText(selectedPosition.item.message);
        onClose();
        makeAlert('Message Copied');
        // setSelectedPosition({ x: 0, y: 0, item: null });
      }}
    >
      Copy
    </div>
  );
};

export const ChatMenu = {
  CopyMenuOption,
  DeleteMenuOption,
  ReplyMenuOption,
  ReactionMenuOption,
};
