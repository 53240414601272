import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pen, Plus, SquarePen, Trash } from 'lucide-react';
import { default as React, useContext, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import useDebounce from '../_helpers/useDebounce';

import StackedAvatars from '../_components/Avatar/StackedAvatars';
import { ErrorComponent } from '../_components/ErrorComponent';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { DELETE_NOTE } from '../_constants/deletenote.constants';
import { teamService } from '../_services/team.service';
import { HeadWithArrow } from '../Settings';
import TeamAdd from '../Team/TeamAdd';
import { GlobalContext, Images, fullNameUser } from '../Utils';
import { Cancel, CompanyIcon, LoadingIcon, Tick } from '../Utils/SvgIcons';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';

const TeamList = () => {
  // reference section
  const reference = useRef(null);
  const { roleBasedPermission } = useContext(GlobalContext);

  const queryClient = useQueryClient();
  const [addModal, setAddModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteModal, setDeleteModal] = useState(false);
  // const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editTeamID, setEditTeamID] = useState(null);

  const [query, setQuery] = useState({
    select: ['id', 'name', 'company_id', 'members', 'members_details', 'Action'],
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });
  const TABLE_COLUMN = [
    { name: <img src={Images.SerialNo} height={14} width={14} /> },
    {
      name: (
        <section className='d-flex align-items-center gap-1' style={{ width: 'max-content' }}>
          <SquarePen height={14} width={14} color='#67677F' />
          Team Name
        </section>
      ),
    },
    {
      name: (
        <section className='d-flex align-items-center gap-1'>
          <CompanyIcon height={14} width={14} color='#67677F' />
          Organization
        </section>
      ),
    },
    {
      name: (
        <section className='d-flex align-items-center gap-1'>
          <CompanyIcon height={14} width={14} color='#67677F' />
          Members
        </section>
      ),
    },
    { name: '' },
  ];

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const {
    data: TeamData,
    refetch,
    error,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['teamList', query?.pageVo?.pageNo, debouncedSearch],
    queryFn: () => teamService.teamList(query),
    placeholderData: keepPreviousData,
  });

  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let search = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: search,
    }));
  };

  const modalClose = () => {
    setDeleteModal(false);
    setEditTeamID(null);
  };
  const handleDeactivate = async (id) => {
    try {
      await teamService.teamDeactivate({ id });
      await queryClient.invalidateQueries('branch');
      modalClose();
    } catch (error) {
      console.error('Error deleting branch:', error);
    }
  };

  const listItemCount = TeamData?.data?.rows.length;
  const teamRows = TeamData?.data?.rows.map((team, index) => {
    const slNo = (TeamData?.data?.page - 1) * TeamData?.data?.limit + index + 1;
    return (
      <Tr key={index}>
        <Td>{slNo}</Td>
        <Td>{team?.name} </Td>
        <Td>{team?.['Company.name']} </Td>
        <Td className='d-flex justify-content-start'>
          <StackedAvatars
            getSourceByKey={(user) => user.image_url}
            getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
            getGenderByKey={(user) => user.gender === 1}
            imageList={team?.members_details}
            showInitials={false}
            size={25}
          />
        </Td>

        <Td style={{ verticalAlign: 'middle', cursor: 'pointer' }}>
          <div className='d-flex gap-1'>
            {(roleBasedPermission?.ACTION_USER_DELETE || roleBasedPermission?.ACTION_USER_EDIT) && (
              <div
                className='d-flex gap-2 justify-content-end icon-div invisible'
                style={{ width: 'fit-content' }}
              >
                {roleBasedPermission?.ACTION_USER_EDIT && (
                  <ToolTip tooltipText={'Edit'} isModern scrollRef={reference}>
                    <div
                      className='subtask-icon-bg'
                      onClick={() => {
                        setEditTeamID(team?.id);
                        setAddModal(true);
                      }}
                    >
                      <Pen size={15} />
                    </div>
                  </ToolTip>
                )}
                {roleBasedPermission?.ACTION_USER_DELETE && (
                  <ToolTip tooltipText={'Delete'} isModern scrollRef={reference}>
                    <div
                      className='subtask-icon-bg'
                      onClick={() => {
                        setEditTeamID(team?.id);
                        setDeleteModal(true);
                      }}
                    >
                      <Trash size={15} />
                    </div>
                  </ToolTip>
                )}
              </div>
            )}
            <div className='icon-div invisible' style={{ width: 'fit-content' }}></div>
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <div>
      <div className='organization_list sub_padding team-list-table '>
        <ErrorComponent error={error?.message} />

        <section>
          <HeadWithArrow
            head={'User Group'}
            isDown={false}
            handler={() => {}}
            note={`Manage user access and roles with ease. Customize settings to fit your team's needs.`}
          />
          <div className='tab_contain mt-2'>
            <SearchBar handleSearch={handleSearch} searchKey={query.searchKey} />
            <div className='d-flex gap-1 align-items-center justify-content-end'>
              {roleBasedPermission?.ACTION_USER_ADD && (
                <ToolTip tooltipText={'Add user group'} isModern>
                  <div
                    className={'menu-item-outline'}
                    ref={reference}
                    onClick={() => setAddModal(true)}
                  >
                    <Plus size={16} color={'#87909E'} />
                  </div>
                </ToolTip>
              )}
            </div>
          </div>

          <section className='mt-2'>
            <div className='table_outer_team '>
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr>
                    {TABLE_COLUMN.map((heading, index) => {
                      return (
                        <Th key={index} scope='col'>
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>

                <Tbody>
                  {isLoading && (
                    <TableRow noOfCols={7}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={7}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && teamRows}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={TeamData?.data}
              />
            </section>
          )}
        </section>

        {addModal && (
          <Modal.Container handleClose={() => setAddModal(false)}>
            <Modal.View className={'modal_view_branch'}>
              <Modal.Head
                Heading={editTeamID ? 'Edit user group' : 'Add user group'}
                handleClose={() => {
                  setAddModal(false);
                  setEditTeamID(null);
                }}
              />
              <TeamAdd
                id={editTeamID}
                handleClose={() => {
                  setAddModal(false);
                  setEditTeamID(null);
                  refetch();
                }}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
              <div className='del-description'>{DELETE_NOTE.TEAM}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={() => handleDeactivate(editTeamID)}>
                <Tick />
                <Button.Title title={'Delete'} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};

export default TeamList;
