/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import countryRegionData from 'country-region-data/dist/data-umd';
import { CircleCheckBig, Plus } from 'lucide-react';
import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import { Button, CustomFileUpload } from '../_components';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { apiConstants, CommonDropDownStyle, MENU, USER_LIST_ACTIONS } from '../_constants';
import { sharedUserSelect } from '../_constants/filesystem.constant';
import { clientService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import { SelectedFiles } from '../FileSystem/SelectedFiles';
import { SharedWith } from '../FileSystem/SharedWith';
import { currencyType } from '../Settings/InvoiceSettings';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  ClientInitialState,
  FileSystemImage,
  getFileType,
  GlobalContext,
  Images,
  sanitizeFileName,
} from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { CLIENT_TYPE, PRIMARY_CONTACT, REGION_BASED_TAX_TREATMENT_LIST } from './ClientConstant';

export const ClientAddModal = ({ handleClose = () => {}, afterAdd = () => {}, id = null }) => {
  const { getMenuLabelName, makeAlert } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const [selectedTab, setSelectedTab] = useState('details');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [client, setClient] = useState(ClientInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [compressed, setCompressed] = useState(null);
  const [gstLoading, setGstLoading] = useState(false);
  const [gstVerification, setGstVerification] = useState({
    isVerified: false,
    data: '',
  });

  // Convert country data to React Select format
  const countryOptions = countryRegionData?.map((country) => ({
    value: country?.countryShortCode,
    label: country?.countryName,
  }));

  // get client details for edit if id >0
  const getClient = async () => {
    const res = await clientService.clientGet({
      select: [
        'id',
        'name',
        'phone',
        'name_prefix',
        'first_name',
        'last_name',
        'currency',
        'email',
        'phone',
        'shared_with_user',
        'shared_with_team',
        'compressed_logo',
        'pan_number',
        'tax_treatment',
        'tax_number',
        'attention',
        'address',
        'city',
        'pin_code',
        'files',
        'country',
        'state',
        'type',
      ],
      id: id,
    });

    if (res?.data?.length) {
      const result = res?.data[0];

      let sharedTeam =
        result?.shared_with_team?.length > 0 && result?.shared_with_team[0] !== 0
          ? result.sharedTeams
          : result?.shared_with_team?.length > 0
          ? [SHARED_WITH_ALL_OPTION.TEAM]
          : [];
      let sharedUser =
        result?.shared_with_user?.length > 0 && result?.shared_with_user[0] !== 0
          ? result.sharedUsers
          : result?.shared_with_user?.length > 0
          ? [SHARED_WITH_ALL_OPTION.USER]
          : [];

      let country = countryOptions?.find((country) => country?.value === result?.country) ?? null;
      if (result?.tax_number) {
        setGstVerification({ isVerified: true, data: '' });
      }

      setClient({
        ...result,
        shared_with_team: sharedTeam,
        shared_with_user: sharedUser,
        country: country,
      });
      return res?.data[0];
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: clients } = useQuery({
    queryKey: ['client-data', id],
    queryFn: () => getClient(),
    enabled: Boolean(id),
  });

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (name === 'country') {
      setClient((prev) => ({
        ...prev,
        [name]: value,
        tax_treatment: null,
        tax_number: '',
      }));
      return;
    }
    if (type === 'shared_with') {
      const lastItem = value.length > 0 ? value[value.length - 1] : [];
      const isSelectedAll = lastItem && lastItem.id === 0;

      let filteredValue;
      if (isSelectedAll) {
        // If 'All' is selected last, show only 'All'
        filteredValue = [lastItem];
      } else {
        // Filter out 'All' if other items are selected
        filteredValue = value.filter((item) => item.id !== 0);
      }

      setClient((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));

      return;
    }
    setClient((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // save api call
  const handleSave = async () => {
    const shareWithUserIds = client?.shared_with_user?.map((item) => item.id);
    const shareWithTeamIds = client?.shared_with_team?.map((item) => item.id);
    const country = client?.country?.value;

    await clientService
      .clientAdd({
        ...client,
        shared_with_team: shareWithTeamIds,
        shared_with_user: shareWithUserIds,
        files: selectedFiles,
        country: country,
      })
      .then(() => {
        setIsSubmitted(false);
        afterAdd();
      });
    handleClose();
  };

  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['client-add'],
    onSuccess: (data) => {
      setIsSubmitted(false);
      handleClose();
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsSubmitted(true);

    if (
      !client?.type ||
      !client?.last_name ||
      !client?.last_name.toString().trim() ||
      !client?.first_name ||
      !client?.first_name.toString().trim() ||
      (client?.type === CLIENT_TYPE.BUSINESS &&
        (!client?.name || client?.name.toString().trim() === '')) ||
      (client?.tax_treatment?.is_tax_number_required &&
        (!client?.tax_number || client?.tax_number.toString().trim() === ''))
    ) {
      setIsLoading(false);
      return null;
    }

    try {
      if (gstVerification?.isVerified || client?.country?.value !== 'IN') {
        handleMutate();
      } else {
        if (
          client?.tax_treatment?.is_tax_number_required &&
          !gstVerification?.isVerified &&
          client?.country?.value === 'IN'
        ) {
          makeAlert('Please verify GST before submission');
          setIsLoading(false);
          return null;
        }
        handleMutate();
      }
    } catch (error) {
      makeAlert('Client add failed');
    } finally {
      setIsLoading(false);
      setIsSubmitted(false);
    }
  };

  // team list
  const { data: teamData } = useQuery({
    queryKey: ['shared-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
  });

  const { data: usersForShare } = useQuery({
    queryKey: ['user-list-client'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: sharedUserSelect,
        action: USER_LIST_ACTIONS.ACTION_CLIENT_ALLOCATION,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  // file upload section ,
  const onFileSelect = (files) => {
    let filesArray = [];

    for (let file of files) {
      filesArray.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        isUploading: false,
        isFailed: false,
        isSuccess: false,
      });
    }
    selectedFiles?.length > 0
      ? setSelectedFiles([...selectedFiles, ...filesArray])
      : setSelectedFiles([...filesArray]);
  };
  // remove selected file
  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };
  // handleChange image upload
  const handleImageUpload = (file, name, compressed, compressedBlob) => {
    setClient((prev) => ({
      ...prev,
      [name]: file.pop(),
      compressed_logo: compressed[0],
    }));
    setCompressed(compressedBlob);
  };
  let imageUrl = compressed
    ? compressed
    : client?.compressed_logo
    ? apiConstants.imgUrlS3 + client.compressed_logo
    : client?.logo
    ? apiConstants.imgUrlS3 + client?.logo
    : Images.ImagePreview;

  const handleGstVerification = async () => {
    setGstLoading(true);
    if (
      client?.tax_treatment?.is_tax_number_required &&
      client?.tax_number?.toString().trim() !== ''
    ) {
      try {
        const res = await clientService.verifyGst({ gstin: client?.tax_number });
        if (res?.data?.status_cd === '0') {
          setGstVerification({ isVerified: false, data: { message: res?.data?.error?.message } });
          setGstLoading(false);
        } else {
          setGstVerification({ isVerified: true, data: res?.data });
          setGstLoading(false);
        }
      } catch (error) {
        console.log('error on');
        setGstVerification({ isVerified: false, data: { message: 'Verification Failed' } });
        setGstLoading(false);
      }
    } else {
      console.log('error on');

      return;
    }
  };

  return (
    <>
      <div className='modal_view_content'>
        <div className='invoice-form-section d-flex flex-column gap-2'>
          <div className='position-relative mb-3 mt-2' style={{ width: 'fit-content' }}>
            <img src={imageUrl} alt='project-logo' className='project-image-outer' />
            <CustomFileUpload
              name='logo'
              accept='image'
              isMulti={false}
              isCompressed={true}
              handleOK={handleImageUpload}
              CustomView={
                <div className='logo-add-plus-btn-bg cursor-pointer'>
                  <Plus color='var(--bg-primary)' size={17} />
                </div>
              }
            />
          </div>
          <div className='d-flex gap-4'>
            <div>
              <input
                className='form-check-input'
                type='radio'
                name='type'
                checked={client?.type === 1}
                onChange={() => {
                  handleChange({
                    target: {
                      name: 'type',
                      value: 1,
                    },
                  });
                }}
              />
              <label className='form-check-label ms-2' htmlFor='milestone'>
                Business
              </label>
            </div>

            <div>
              <input
                className='form-check-input'
                type='radio'
                name='type'
                checked={client?.type === 2}
                onChange={() => {
                  handleChange({
                    target: {
                      name: 'type',
                      value: 2,
                    },
                  });
                }}
              />
              <label className='form-check-label ms-2' htmlFor='milestone'>
                Individual
              </label>
            </div>
          </div>
          <ErrorBlock errorMsg={`Please select type`} hasError={isSubmitted && !client?.type} />
          {client?.type === 1 && (
            <div>
              <label className='mb-1 fz-13px-rem  label_input'>{LABEL_OF_CLIENT} Name</label>
              <input
                type='text'
                className='input-box'
                name='name'
                value={client?.name}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              <ErrorBlock
                errorMsg={`${LABEL_OF_CLIENT} name required`}
                hasError={isSubmitted && (!client?.name || !client?.name.toString().trim())}
              />
            </div>
          )}

          <div className='grid-3-col'>
            <div>
              <label className='mb-1 fz-13px-rem  label_input'>Prefix</label>
              <ReactSelect
                isCustomFunction
                options={PRIMARY_CONTACT}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) => {
                  handleChange({
                    target: { value: parseInt(selectedOption?.id), name: 'name_prefix' },
                  });
                }}
                styles={CommonDropDownStyle}
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                }}
                placeholder='select'
                value={PRIMARY_CONTACT?.find((item) => item.id === client?.name_prefix)}
              />
            </div>

            <div>
              <label className='mb-1 fz-13px-rem  label_input'>First Name</label>
              <input
                type='text'
                className='input-box'
                name='first_name'
                value={client?.first_name}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              <ErrorBlock
                errorMsg={'first name required'}
                hasError={
                  isSubmitted && (!client?.first_name || !client?.first_name.toString().trim())
                }
              />
            </div>

            <div>
              <label className='mb-1 fz-13px-rem  label_input'>Last Name</label>
              <input
                type='text'
                className='input-box'
                name='last_name'
                value={client?.last_name}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
              <ErrorBlock
                errorMsg={'last name required'}
                hasError={
                  isSubmitted && (!client?.last_name || !client?.last_name.toString().trim())
                }
              />
            </div>
          </div>

          <div className='w-50'>
            <label className='mb-1 fz-13px-rem  label_input'>Currency</label>
            <ReactSelect
              id='trigger-type-select'
              styles={CommonDropDownStyle}
              components={{
                DropdownIndicator: CustomDropdownIndicator,
              }}
              options={currencyType}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              placeholder='Select a currency'
              value={currencyType.find((option) => option.id === client.currency) || null}
              onChange={(selectedOption) => {
                handleChange({
                  target: { value: parseInt(selectedOption?.id), name: 'currency' },
                });
              }}
            />
          </div>

          <div className='grid-3-col'>
            <div>
              <label className='mb-1 fz-13px-rem  label_input'>Email Address</label>
              <input
                type='email'
                className='input-box'
                name='email'
                value={client?.email}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>

            <div>
              <label className='mb-1 fz-13px-rem  label_input'>Phone</label>
              <input
                type='text'
                className='input-box'
                name='phone'
                value={client?.phone}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
        </div>

        <div className='invoice-form-section'>
          <ClientInfoTabs
            selectedTab={selectedTab}
            setSelectedTab={(tab) => {
              setSelectedTab(tab);
            }}
          />

          {selectedTab === 'details' && (
            <>
              <div className='w-50   d-flex flex-column gap-2'>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Pan Number</label>
                  <input
                    type='text'
                    className='input-box'
                    name='pan_number'
                    value={client?.pan_number}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor='country' className='mb-1 fz-13px'>
                    Country
                  </label>
                  <ReactSelect
                    id='country'
                    name='country'
                    options={countryOptions}
                    value={client?.country}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: { name: 'country', value: selectedOption },
                      });
                    }}
                    placeholder='Select Country'
                    styles={{
                      ...CommonDropDownStyle,
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    menuPortalTarget={document.body}
                    components={{ DropdownIndicator: CustomDropdownIndicator }} // Use the custom dropdown indicator here
                  />
                </div>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Tax Treatment</label>
                  <ReactSelect
                    isCustomFunction
                    options={
                      REGION_BASED_TAX_TREATMENT_LIST[client?.country?.value] ||
                      REGION_BASED_TAX_TREATMENT_LIST['OTHER'] ||
                      []
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(selectedOption) => {
                      handleChange({
                        target: { value: selectedOption, name: 'tax_treatment' },
                      });
                    }}
                    styles={CommonDropDownStyle}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    value={client?.tax_treatment}
                    placeholder='select'
                  />
                </div>

                {/* File Upload */}
              </div>
              {client?.tax_treatment?.is_tax_number_required && (
                // <div className='d-flex align-items-center gap-3'>
                <div className='mt-1'>
                  <label className='mb-1 fz-13px-rem  label_input'>
                    {client?.country?.value === 'IN' ? 'GST' : 'Tax Registration'} Number
                  </label>

                  <div className='d-flex align-items-center gap-1'>
                    <input
                      type='text'
                      className='input-box w-50'
                      name='tax_number'
                      value={client?.tax_number}
                      onChange={(event) => {
                        setGstVerification({ isVerified: false, data: '' });
                        handleChange(event);
                      }}
                    />
                    {client?.tax_treatment?.is_tax_number_required &&
                      client?.country?.value === 'IN' &&
                      !gstVerification?.isVerified && (
                        <span
                          style={{ color: 'var(--Base-Color)', fontWeight: 600, fontSize: 11 }}
                          className='d-flex cursor-pointer'
                          onClick={() => {
                            if (!gstLoading) {
                              handleGstVerification();
                            }
                          }}
                        >
                          <Tick color='var(--Base-Color)' />
                          {gstLoading ? 'Verifying...' : 'Verify'}
                        </span>
                      )}
                    {client?.tax_treatment?.is_tax_number_required &&
                      gstVerification?.isVerified && (
                        <span
                          style={{ color: 'green', fontWeight: 600, fontSize: 11 }}
                          className='d-flex cursor-pointer align-items-center '
                        >
                          Verified
                          <CircleCheckBig color='green' size={12} />
                        </span>
                      )}
                  </div>
                  <ErrorBlock
                    errorMsg={gstVerification?.data?.message}
                    hasError={
                      client?.tax_treatment?.is_tax_number_required && !gstVerification?.isVerified
                    }
                  />

                  <ErrorBlock
                    errorMsg={`${client?.country?.value === 'IN' ? 'GST' : 'Tax'} number required`}
                    hasError={
                      client?.tax_treatment?.is_tax_number_required &&
                      isSubmitted &&
                      (!client?.tax_number || !client?.tax_number.toString().trim())
                    }
                  />
                </div>
              )}
              <div>
                <input
                  accept={'*/*'}
                  multiple
                  onChange={(e) => onFileSelect(e.target.files)}
                  id='upload'
                  type='file'
                  style={{ display: 'none' }}
                />
                <div
                  className='file-upload-contain'
                  style={{
                    padding: '1rem 5px',
                    color: 'var(--second-text-color)',
                    backgroundColor: 'var(--bg-light)',
                  }}
                >
                  <label className='upload-text text-decoration-none' htmlFor='upload'>
                    <div className='icon-outer-file'>
                      <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
                    </div>
                    {/* Drag and Drop file here or */}
                    Choose file
                  </label>
                </div>

                <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                  <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
                </div>
              </div>
            </>
          )}
          {selectedTab === 'address' && (
            <div className='grid-2-col'>
              {/* Billing Address  */}
              <div>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Attention</label>
                  <input
                    type='text'
                    className='input-box'
                    name='attention'
                    value={client?.attention}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>

                <div className=' mb-16'>
                  <div className='form-group'>
                    <label className='mb-1 fz-13px-rem  label_input'>Address</label>
                    <textarea
                      className='form-control fz-13px'
                      name='address'
                      style={{ height: 80 }}
                      value={client?.address}
                      placeholder='Enter Address '
                      onChange={(event) => {
                        handleChange(event);
                      }}
                    />
                  </div>
                </div>
                <div className=' mb-16'>
                  <label className='mb-1 fz-13px-rem  label_input'>City</label>
                  <input
                    type='text'
                    className='input-box'
                    name='city'
                    value={client?.city}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>

                <div className=' mb-16'>
                  <label className='mb-1 fz-13px-rem  label_input'>Postal/Zip Code</label>
                  <input
                    type='number'
                    className='input-box '
                    name='pin_code'
                    value={client?.pin_code}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {selectedTab === 'visibility' && (
            <div>
              <SharedWith
                onAdd={(userOrTeam, type) => {
                  const name = type === 'user' ? 'shared_with_user' : 'shared_with_team';
                  handleChange({
                    target: {
                      value: [...(client[name] ?? []), ...userOrTeam],
                      name: name,
                      type: 'shared_with',
                    },
                  });
                }}
                onAvatarRemove={(userOrTeam, name) => {
                  setClient((prev) => ({
                    ...prev,
                    [name]: prev[name].filter(
                      (item) => parseInt(item.id) !== parseInt(userOrTeam.id),
                    ),
                  }));
                }}
                userImageList={client?.shared_with_user ? client?.shared_with_user : []}
                sharedUserSelect={sharedUserSelect}
                usersForShare={usersForShare}
                teamForShare={teamData}
                teamImageList={client?.shared_with_team ? client?.shared_with_team : []}
                userListAction={USER_LIST_ACTIONS.ACTION_CLIENT_ALLOCATION}
              />
            </div>
          )}
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>
    </>
  );
};

const ClientInfoTabs = ({ selectedTab, setSelectedTab }) => {
  const TABS = [
    { name: 'Other Details', value: 'details' },
    { name: 'Address', value: 'address' },
    { name: 'Visibility', value: 'visibility' },
  ];

  return (
    <section className='file_tabs '>
      <div className='file-tab-line' style={{ top: '35px' }} />
      <div className='file-tab-contain' style={{ padding: '0px 15px 15px 0px' }}>
        {TABS.map((item, index) => (
          <div
            key={index}
            style={{ zIndex: '2', padding: '1px 1px 12px 1px' }}
            className={selectedTab === item?.value ? 'tab_active' : 'tab_inactive'}
            onClick={() => setSelectedTab(item?.value)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </section>
  );
};
