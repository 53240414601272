/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import React, { useMemo, useState } from 'react';
import { useUser } from '../../Hook/useUser';
import { taskState, validateTaskWithTemplateTest } from '../../Utils';
import useDebounce from '../../_helpers/useDebounce';
import { taskService, userService } from '../../_services';
import { labelService } from '../../_services/label.service';
import { templateService } from '../../_services/template.service';
import { taskAssignUserSelect } from '../taskConstants';
import { getHours, getLabelIsActive, validateHours } from '../taskValidation';
import { RelatedInlineAdd } from './RelatedInlineAdd';
import { USER_LIST_ACTIONS } from '../../_constants';

function TaskSingleRelatedTaskAdd({
  taskData,
  refetch = () => {},
  setIsTyping = () => {},
  referenceTable,
  isList = true,
}) {
  const [relatedTask, setRelatedTask] = useState(taskState);

  const [labelSearch, setLabelSearch] = useState('');
  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const debouncedLabel = useDebounce(labelSearch, 500);
  const debouncedUser = useDebounce(userSearch, 500);

  const isMenuLabelOn = getLabelIsActive(); // for label settings is on or not
  //
  // get template validation fields
  const { data: templateData } = useQuery({
    queryKey: ['templateData', taskData?.template_id],
    queryFn: () =>
      templateService.getSingleTemplate({
        id: taskData?.template_id,
        select: ['id', 'validation_fields', 'verifying_users'],
        expanded_list: false,
      }),
    select: (data) => {
      return data?.data?.[0];
    },
    enabled: Boolean(taskData?.template_id),
  });

  // to get label/tag list for change label/tag
  const {
    data: labelData,
    isLoading: isLabelLoading,
    // eslint-disable-next-line no-unused-vars
    error: labelLoadError,
  } = useQuery({
    queryKey: ['labelData', taskData?.template_id, debouncedLabel],
    queryFn: () =>
      labelService.labelList({
        searchKey: debouncedLabel,
        select: ['id', 'name', 'label_color', 'text_color', 'status'],
      }),
    select: (data) => {
      return data?.data?.rows;
      // to get already selected labels
      // const uniqueElements =
      //   filteredIds?.length > 0
      //     ? data?.data?.rows?.filter((element) => !filteredIds.includes(parseInt(element.id)))
      //     : data?.data?.rows; //filtered selection unselected labels
      // return uniqueElements;
    },
    enabled: isMenuLabelOn,
  });
  // to get user list for change the owner
  const { data: userList } = useQuery({
    queryKey: ['userData', taskData?.template_id, taskData?.project_id, debouncedUser],
    queryFn: () =>
      userService.getRoleBasedUserList({
        searchKey: debouncedUser,
        action: USER_LIST_ACTIONS.ACTION_TASK_ALLOCATION,
        select: taskAssignUserSelect,
        ...(taskData?.project_id ? { project_id: taskData?.project_id } : {}),
        ...(taskData?.template_id ? { template_id: taskData?.template_id } : {}),
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      return userData;
    },
  });

  const user = useUser();
  useMemo(() => {
    setRelatedTask({ ...relatedTask, assignee_id: user?.id, assigned_user: user });
  }, [user]);

  // submission having validation and add task  /  error display if any
  const handleRelatedTaskAdd = (setIsAddRowVisible, setIsAddRowActive) => {
    const finalTaskData = relatedTask?.project_id
      ? { ...relatedTask, project_id: taskData?.project_id }
      : { ...relatedTask };
    if (relatedTask?.expected_hours || relatedTask?.expected_minutes) {
      finalTaskData.expected_hours = getHours(relatedTask); // get hours by hours and minutes
    }
    if (typeof relatedTask?.note === 'object') {
      finalTaskData.note = draftToHtml(relatedTask?.note);
    }
    const validationError = validateTaskWithTemplateTest(templateData?.validation_fields, [
      finalTaskData,
    ]); // checking which fields are required for task and shows error - template

    finalTaskData.parent_id = taskData?.parent_id != 0 ? taskData?.parent_id : taskData.id;

    const isValidHour = validateHours(
      relatedTask?.task_start_date,
      relatedTask?.task_end_date,
      finalTaskData.expected_hours,
    ); // to validate the hours according to the start - end dates
    if (isValidHour) {
      let time = parseFloat(finalTaskData.expected_hours);
      finalTaskData.expected_hours = time;
    } else {
      //   makeAlert(taskValidationConstant.INVALID_EXPECTED_TIME);
      //   errorTimeout();
      return;
    }
    delete finalTaskData.expected_minutes;
    // setIsSubmitted(true);
    finalTaskData.template_id = taskData?.template_id;
    try {
      taskService.taskAdd(finalTaskData).then((res) => {
        if (res?.statusCode == 200) {
          //   setIsSubmitted(false);
          setIsAddRowVisible(false);
          setIsAddRowActive(false);
        }
        // setTaskData({ ...taskState, template_id: taskData.template_id });
        // handleClose();
      });
    } catch (e) {
      //   makeAlert(e?.message ?? 'Server is busy');
      console.log(e);
      //   setIsSubmitted(false);
    } finally {
      //   errorTimeout();
      onCancel();
      setTimeout(() => {
        refetch();
      }, 1000);
    }
  };

  // on cancel the return related task state as initial and typing false
  const onCancel = () => {
    setRelatedTask(() => ({
      ...taskState,
      assigned_user: user,
      assignee_id: user?.id,
    }));
    setIsTyping(false);
  };
  return (
    <section className='task-single-related-task-add-container'>
      <RelatedInlineAdd
        key={1}
        taskData={relatedTask}
        setTaskData={setRelatedTask}
        templateData={templateData}
        onAdd={(setIsAddRowVisible, setIsAddRowActive) =>
          handleRelatedTaskAdd(setIsAddRowVisible, setIsAddRowActive)
        }
        setLabelSearch={setLabelSearch}
        userList={userList}
        labelList={labelData}
        onCancel={onCancel}
        isRelatedTask={true}
        setSearch={setUserSearch}
        setUserSearch={setUserSearch}
        labelSearch={labelSearch}
        userSearch={userSearch}
        isUpdateButtonVisible
        isMenuLabelOn={isMenuLabelOn}
        tableRef={referenceTable}
        isList={isList}
      />
    </section>
  );
}

export default TaskSingleRelatedTaskAdd;
