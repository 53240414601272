import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Home, ListFilter, Plus, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { invoiceService } from '../_services/invoice.service';
import { getArrayParam } from '../Calander/TimeBoxHelperFunctions';
import '../common.css';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, MyTeamIcon } from '../Utils';
import {
  Calendar,
  InvoiceIcon,
  LoadingIcon,
  OrderNumberIcon,
  PaymentIcon,
  PaymentIcon2,
  SINoIcon,
  StatusIcon,
  User,
} from '../Utils/SvgIcons';
import { AddInvoice } from './AddInvoice';
import { AddPaymentReceived } from './AddPaymentReceived';
import './invoice.css';
import { INVOICE_STATUS_TYPE, TRANSACTION_TYPE } from './InvoiceConstant';
import { InvoiceDeleteConfirmation } from './InvoiceDeleteConfirmation';
import { InvoiceFilter } from './InvoiceFilter';
import InvoiceSingle from './InvoiceSingle';
export function InvoiceList() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  //   const [isFilterVisible, setIsFilterVisible] = useState(false);
  // const [addModal, setAddModal] = useState(false);
  const [addInvoiceModal, setAddInvoiceModal] = useState(false);
  const [paymentAddModal, setPaymentAddModal] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [invoiceId, setInvoiceId] = useState();
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editInvoiceId, setEditInvoiceId] = useState(null);

  const { getMenuLabelName, roleBasedPermission, globalSettings, makeAlert } =
    useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <InvoiceIcon height={15} width={16} primeColor='var(--second-text-color)' /> Invoices
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <OrderNumberIcon /> Order Number
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User color='var(--second-text-color)' /> {LABEL_OF_CLIENT} Name
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' /> Amount Due
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' /> Amount
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <StatusIcon /> Status
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Created On
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          {' '}
          <Calendar height={14} width={14} /> Due Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef(null);
  // filter popup reference
  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  // filter ---
  const due_date = getParam('due_date');
  const invoice_status = getParam('payment_status');
  const client_id = getArrayParam(searchParams, 'client_id');

  const filterToUrl = {};
  if (due_date) filterToUrl.due_date = due_date;
  if (client_id) filterToUrl.client_id = client_id;
  if (invoice_status) filterToUrl.invoice_status = invoice_status;

  const filterCount = Object.keys(filterToUrl)?.length;

  // useState section
  const [query, setQuery] = useState({
    select: [
      'id',
      'invoice_number',
      'order_number',
      'createdAt',
      'due_date',
      'invoice_status',
      'bill_date',
      'total',
      'paid_amount',
      'currency',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
    transaction_type: TRANSACTION_TYPE.INVOICE,
  });

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // code to redirect page 1 after adding filter
  useEffect(() => {
    if (searchParams.get('pageNo')) {
      const page = parseInt(searchParams.get('pageNo'));
      if (query.pageVo.pageNo !== page) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          pageVo: { ...prevQuery.pageVo, pageNo: page },
        }));
      }
    }
  }, [searchParams, query.pageVo.pageNo]);

  const {
    data: invoiceData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ['invoice-list', query?.pageVo?.pageNo, debouncedSearch, filterToUrl],
    queryFn: () => invoiceService.invoiceList({ ...query, ...filterToUrl }),
    // enabled: roleBasedPermission?.ACTION_MANAGE_INVOICE,
    placeholderData: keepPreviousData,
  });

  // NO ACCESS MANAGE
  if (!roleBasedPermission?.ACTION_MANAGE_INVOICE) {
    return <NoPermission />;
  }

  const modalClose = () => {
    setDeleteModal(false);
    setEditInvoiceId(null);
  };
  const handleDeactivate = async (id, isPaymentNeedToDelete = false) => {
    try {
      console.log(isPaymentNeedToDelete, 'oyyyy');

      await invoiceService.invoiceDeactivate({ id, is_payment_delete: isPaymentNeedToDelete });
      let isNeedToBack = invoiceData?.data?.rows.length === 1 && invoiceData?.data?.pages > 1;

      if (isNeedToBack) {
        const page = searchParams.get('pageNo');
        searchParams.set('pageNo', page - 1);
        setSearchParams(searchParams);
        setQuery((prev) => ({
          ...prev,
          pageVo: {
            ...prev.pageVo,
            pageNo: prev.pageVo.pageNo - 1,
          },
        }));
      } else {
        refetch();
      }
      modalClose();

      makeAlert('Deleted Successfully');
    } catch (error) {
      makeAlert(error?.message || 'Failed to delete');

      console.error('Error deleting project :', error);
    }
  };
  // get the count of project
  const listItemCount = invoiceData?.data?.rows.length;

  const tableData = invoiceData?.data?.rows?.map((invoice, index) => {
    const slNo = (invoiceData?.data?.page - 1) * invoiceData?.data?.limit + index + 1;
    const currencyTextIconForInvoice = invoice?.client?.currency
      ? currencyType.find((currency) => currency.id === invoice?.client?.currency)?.textIcon
      : currencyTextIcon;
    return (
      <Tr
        key={invoice?.id}
        onClick={() => {
          setInvoiceModal(true);
          setInvoiceId(invoice?.id);
        }}
        className='cursor-pointer'
      >
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className='table-responsive-td' style={{ fontWeight: 500 }}>
          {invoice?.invoice_number}
        </Td>
        <Td> {invoice?.order_number}</Td>
        <Td> {invoice?.client?.name}</Td>
        <Td>
          {currencyTextIconForInvoice}
          {parseFloat(invoice?.total) - parseFloat(invoice?.paid_amount)}
        </Td>
        <Td>
          {currencyTextIconForInvoice} {parseFloat(invoice?.total)}
        </Td>
        <Td>
          <span
            style={{
              color:
                invoice?.invoice_status === 1
                  ? 'var(--color-red)'
                  : invoice?.invoice_status === 2
                  ? 'orange'
                  : 'green',
            }}
          >
            {INVOICE_STATUS_TYPE[invoice?.invoice_status]}
          </span>
        </Td>
        <Td>
          {new Date(invoice?.createdAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Td>
        <Td>
          <span>
            {invoice?.due_date ? moment(parseInt(invoice?.due_date)).format('MMM DD, YYYY') : '--'}
          </span>
        </Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          // ref={reference}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            {invoice?.invoice_status !== 3 && (
              <ToolTip
                tooltipText={`Record Payment`}
                isModern
                popOver
                scrollRef={tableContainerRef}
              >
                <div
                  className='icon-bg'
                  onClick={() => {
                    setPaymentAddModal(invoice);
                  }}
                >
                  <PaymentIcon2 />
                </div>
              </ToolTip>
            )}

            <ToolTip tooltipText={`Delete Invoice`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditInvoiceId(invoice);
                  setDeleteModal(true);
                }}
              >
                <Trash size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Invoice'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<InvoiceIcon height={12} width={12} />}
            onClick={() => nav(`/${RT.INVOICE_LIST}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='invoice-table-container'>
          {/* header input section */}
          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* <ToolTip tooltipText={`Generate task bill`} isModern>
                  <div className='menu-item-outline' onClick={() => nav(`/${RT.BILL_LIST}`)}>
                    <InvoiceIcon primeColor='var(--second-text-color)' height={15} width={16} />
                  </div>
                </ToolTip> */}
                <ToolTip tooltipText={`Add Invoice`} isModern>
                  <div className='menu-item-outline' onClick={() => setAddInvoiceModal(true)}>
                    <Plus size={16} color='var(--second-text-color)' />
                  </div>
                </ToolTip>

                <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                    }}
                    ref={filterRef}
                    onClick={() => {
                      setIsFilterVisible(true);
                    }}
                  >
                    <ListFilter
                      size={16}
                      color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                    />
                    {isFilterVisible && (
                      <InvoiceFilter
                        setSearchParams={setSearchParams}
                        handleClose={(event) => {
                          event.stopPropagation();
                          setIsFilterVisible(false);
                        }}
                      />
                    )}

                    {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                  </div>
                </ToolTip>
                {/* <ToolTip tooltipText={`Add Invoice`} isModern>
                  <div className='menu-item-outline' onClick={() => setAddModal(true)}>
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip> */}

                {/* <div className='generate-bill-icon' onClick={() => nav(`/${RT.BILL_LIST}`)}>
                  <span>
                    <InvoiceIcon primeColor='var(--second-text-color)' height={15} width={16} />
                  </span>
                  Generate Bill
                </div> */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={invoiceData?.data}
              />
            </section>
          )}
        </div>
        {/* {deleteModal && (
          <Modal.Container>
            <Modal.View className={'status-delete'}>
              <Modal.Head handleClose={() => modalClose()} Heading={'Delete Confirmation'} />
              <Modal.Description>
                <img src={Images.DeleteBin} width={35} height={50} alt='' className='mb-3' />
                <div className='del-description'>
                  {editInvoiceId?.invoice_status === 1
                    ? DELETE_NOTE.Invoice
                    : 'You are already paid on this payment. Are you certain you wish to delete this user? This action is irreversible.'}
                </div>
              </Modal.Description>
              <div className='modal_footer'>
                <Button.Container handleOk={() => modalClose()} className={'status-del-cancel'}>
                  <Button.Title title={'Cancel'} />
                </Button.Container>
                <Button.Container handleOk={() => handleDeactivate(editInvoiceId?.id)}>
                  <Button.Title title={'Delete'} />
                </Button.Container>
              </div>
            </Modal.View>
          </Modal.Container>
        )} */}

        {deleteModal && (
          <InvoiceDeleteConfirmation
            setDeleteModal={setDeleteModal}
            handleMutate={handleDeactivate}
            makeAlert={makeAlert}
            // isPending={isPending}
            editInvoiceId={editInvoiceId}
          />
        )}
        {addInvoiceModal && (
          <Modal.Container
            handleClose={() => {
              setAddInvoiceModal(false);
            }}
          >
            <Modal.View className={'w-75'}>
              <Modal.Head
                Heading={'Create Invoice'}
                handleClose={() => {
                  setAddInvoiceModal(false);
                }}
              />
              <AddInvoice
                transactionType={TRANSACTION_TYPE.INVOICE}
                handleClose={() => {
                  setAddInvoiceModal(false);
                }}
                afterAdd={() => refetch()}
              />
            </Modal.View>
          </Modal.Container>
        )}

        {invoiceModal && (
          <Modal.Container
            handleClose={() => {
              setInvoiceModal(false);
            }}
          >
            <Modal.View
              modelViewInnerClass={'payment-inner-modal-view'}
              containerClass='payment_received_view'
              style={{ marginTop: '5px', width: '45%', marginRight: '8px', marginBottom: '5px' }}
            >
              <Modal.Head
                Heading={'Invoice'}
                handleClose={() => {
                  setInvoiceModal(false);
                }}
              />
              <InvoiceSingle invoice_id={invoiceId} />
            </Modal.View>
          </Modal.Container>
        )}

        {paymentAddModal?.id && (
          <Modal.Container
            handleClose={() => {
              setPaymentAddModal(null);
              //   setEditOrganizationID(null);
            }}
          >
            <Modal.View className={'w-75'}>
              <Modal.Head
                // Heading={editOrganizationID ? 'Edit Organization' : 'Add Organization'}
                Heading={'Record Payment'}
                handleClose={() => {
                  setPaymentAddModal(null);
                  //   setEditOrganizationID(null);
                }}
              />
              <AddPaymentReceived
                handleClose={() => {
                  setPaymentAddModal(null);
                  refetch();
                }}
                selectedInvoice={paymentAddModal}
              />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
