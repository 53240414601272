/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ReactSelect from 'react-select';
import { userFilter } from '../Utils';
import { Button } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import UserDropDown from '../_components/UserDropDown';
import { CommonDropDownStyle } from '../_constants';
import { POSITION } from '../_constants/contextMenu.constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { departmentService } from '../_services/department.service';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const MyTeamFilter = ({
  position = POSITION.RIGHT,
  handleClose = () => {},
  setSearchParams,
  searchParams,
  isVisible,
}) => {
  const positionClassName = position === POSITION.RIGHT ? 'right-30px' : 'left-30px';
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [designationSearch, setDesignationSearch] = useState('');
  const [userSearch, setUserSearch] = useState(''); // state for  user list search
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState(userFilter);

  //  list all departments
  const debounceDepartment = useDebounce(departmentSearch, 500);
  const debounceUser = useDebounce(userSearch, 500);

  const {
    data: departmentData,
    error: departmentError,
    isLoading: departmentLoading,
  } = useQuery({
    queryKey: ['departmentList', debounceDepartment],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: debounceDepartment,
        select: ['is_department', 'name', 'id'],
        is_department: 1,
      }),
    select: (data) => data?.data?.rows,
    placeholderData: keepPreviousData,
  });

  const debounceDesignation = useDebounce(designationSearch, 500);

  //  list all designation
  const {
    data: designationData,
    error: designationError,
    isLoading: designationLoading,
  } = useQuery({
    queryKey: ['designationList', debounceDesignation, filterData?.department_id],
    queryFn: () =>
      departmentService.departmentList({
        searchKey: debounceDesignation,
        select: ['is_department', 'name', 'id'],
        is_department: 0,
        ...(filterData?.department_id && {
          department_id: filterData.department_id?.id,
        }),
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  // get all user list
  const {
    data: userData,
    error: userError,
    isLoading: userLoading,
  } = useQuery({
    queryKey: ['userList', debounceUser],
    queryFn: () =>
      userService.userList({
        searchKey: debounceUser,
        select: ['id', 'first_name', 'last_name', 'image_url', 'gender', 'employee_code'],
      }),
    placeholderData: keepPreviousData,
  });

  // Fetch user, department, and designation details based on IDs in URL
  // useEffect(() => {
  //   const newFilterData = structuredClone(filterData);

  //   const fetchDetails = async () => {
  //     const promises = [];

  //     searchParams.forEach((value, key) => {
  //       if (key !== 'status') {
  //         newFilterData[key] = value;

  //         if (key === 'reported_users_ids' && value) {
  //           promises.push(
  //             userService
  //               .userGet({ id: value, select: ['first_name', 'last_name', 'id'] })
  //               .then((user) => {
  //                 newFilterData['reported_users_ids'] = user?.data;
  //               }),
  //           );
  //         }

  //         if (key === 'department_id' && value) {
  //           promises.push(
  //             departmentService
  //               .departmentGet({ id: value, select: ['name', 'id'] })
  //               .then((dept) => {
  //                 newFilterData['department_id'] = dept?.data;
  //               }),
  //           );
  //         }

  //         if (key === 'designation_id' && value) {
  //           promises.push(
  //             departmentService
  //               .departmentGet({ id: value, select: ['name', 'id'] })
  //               .then((desig) => {
  //                 newFilterData['designation_id'] = desig?.data;
  //               }),
  //           );
  //         }
  //       }
  //     });

  //     await Promise.all(promises);

  //     setFilterData(newFilterData);
  //   };

  //   fetchDetails();
  // }, [searchParams]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'department_id') {
      setFilterData((prev) => ({
        ...prev,
        designation_id: 0,
        [name]: value,
      }));
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const payload = {
      department_id: filterData.department_id?.id || 0,
      designation_id: filterData.designation_id?.id || 0,
      reported_users_ids: filterData.reported_users_ids?.id || 0,
    };

    const statusParam = searchParams.get('status');

    searchParams.delete('pageNo');
    const newParams = { ...searchParams };

    Object.keys(payload).forEach((key) => {
      if (payload[key] && payload[key] !== 0) {
        newParams[key] = payload[key];
      } else {
        delete newParams[key];
      }
    });

    if (statusParam) {
      newParams.status = statusParam;
    }

    newParams.pageNo = 1;
    setSearchParams(newParams);
    setIsLoading(false);
    handleClose(event);
  };

  const handleClear = (event) => {
    const statusParam = searchParams.get('status');
    const newParams = { ...searchParams };

    Object.keys(userFilter).forEach((key) => {
      delete newParams[key];
    });

    if (statusParam) {
      newParams.status = statusParam;
    }

    setSearchParams(newParams);
    setFilterData(userFilter);
    handleClose(event);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={`menu_popup filter-container ${positionClassName}`}
      style={{ width: 260, padding: 20 }}
    >
      <ErrorComponent
        error={departmentError?.message || designationError?.message || userError?.message}
      />

      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Filter</h6>
          <div className='menu-item-clear' onClick={handleClear}>
            Clear
          </div>
        </div>
        <div className='row mb-2'>
          <div className='filter-item-name'>Reported To</div>

          <div className='form-group'>
            <ReactSelect
              placeholder='Choose reporting user'
              name='reported_users_ids'
              styles={CommonDropDownStyle}
              formatOptionLabel={(data, metaData) => (
                <UserDropDown data={data} metaData={metaData} />
              )}
              getOptionValue={(option) => option?.id}
              onInputChange={(inputString) => {
                setUserSearch(inputString);
              }}
              filterOption={null}
              isLoading={userLoading}
              inputValue={userSearch}
              options={userData?.data?.rows}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'reported_users_ids',
                    value: selectedOption,
                  },
                });
              }}
              isClearable
              value={filterData?.reported_users_ids}
              // value={
              //   userData?.data?.rows.find(
              //     (option) => option.id === filterData?.reported_users_ids,
              //   ) || null
              // }
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div>
            <div className='filter-item-name'>Department</div>

            <ReactSelect
              name='department_id'
              styles={CommonDropDownStyle}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              onInputChange={(inputString) => {
                setDepartmentSearch(inputString);
              }}
              isLoading={departmentLoading}
              filterOption={null}
              inputValue={departmentSearch}
              options={departmentData}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'department_id',
                    value: selectedOption,
                  },
                });
              }}
              isClearable
              value={filterData?.department_id}
              // value={
              //   departmentData?.find((option) => option.id === filterData?.department_id) || null
              // }
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className=''>
            <div className='filter-item-name'>Designation</div>
            <ReactSelect
              name='designation_id'
              styles={CommonDropDownStyle}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              onInputChange={(inputString) => {
                setDesignationSearch(inputString);
              }}
              filterOption={null}
              inputValue={designationSearch}
              options={designationData}
              onChange={(selectedOption) => {
                handleChange({
                  target: {
                    name: 'designation_id',
                    value: selectedOption,
                  },
                });
              }}
              isClearable
              isLoading={designationLoading}
              // value={
              //   designationData?.find((option) => option.id === filterData?.designation_id) || null
              // }
              value={filterData?.designation_id}
            />
          </div>
        </div>
        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container className={'cancel-btn'} type='button' handleOk={handleClose}>
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isLoading}>
              <Tick />
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
