/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Download } from 'lucide-react';
import moment from 'moment';
import React, { useContext } from 'react';
import { apiConstants } from '../_constants';
import { invoicePaymentService } from '../_services/invoicePayment.service';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext } from '../Utils';
import './invoice.css';

export default function PaymentSingle({ payment_id }) {
  const { globalSettings } = useContext(GlobalContext);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  //   const selectedCurrency = invoiceSettings?.labels?.currency;

  //   const currencyTextIcon = currencyType.find(
  //     (currency) => currency.id === selectedCurrency,
  //   )?.textIcon;

  const getInvoiceDetails = async () => {
    const response = await invoicePaymentService.invoicePaymentDetailsGet({ id: payment_id });
    console.log('response', response);

    return response.data;
  };

  const { isLoading, isSuccess, error, refetch, data } = useQuery({
    queryKey: ['invoice-details'],
    queryFn: () => getInvoiceDetails(),
    enabled: payment_id ? true : false,
  });

  const invoicePaymentData = data?.invoicePaymentData;
  const invoicePaymentItems = data?.invoicePaymentItems || [];
  const settingsData = data?.settingsData;

  function getCurrencyById(id) {
    return currencyType.find((currency) => currency.id === id);
  }

  const formattedCompanyAddress = invoicePaymentData?.company_address
    ?.split(',')
    .map((line, index) => <div key={index}>{line.trim()}</div>);

  const formattedClientAddress = invoicePaymentData?.company_address
    ?.split(',')
    .map((line, index) => <div key={index}>{line.trim()}</div>);

  let issuedDate = moment(parseInt(invoicePaymentData?.payment_date)).format('D MMM YYYY');

  // general settings update
  const handleSettingUpdate = async (data) => {
    let response = await invoicePaymentService.invoicePaymentDownload(data);
    window.open(apiConstants.imgUrlS3 + response.data, '_blank', 'noopener,noreferrer');
  };

  // --react query fot mutation function
  const {
    mutate: handleMutate,
    isError,
    isPending,
  } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  const downloadInvoice = () => {
    handleMutate({ payment_id: payment_id });
  };

  const currentCurrency = getCurrencyById(parseInt(invoicePaymentData?.currency));

  console.log('currentCurrency', currentCurrency);

  const selectedCurrency = settingsData?.labels?.currency;

  const clientCurrency = getCurrencyById(parseInt(invoicePaymentData?.client_currency))?.textIcon;

  const settingsCurrency = getCurrencyById(parseInt(selectedCurrency))?.textIcon;

  const mainCurrency = clientCurrency || settingsCurrency || '';

  return (
    <section style={{ overflow: 'auto', padding: '10px 10px 10px 10px' }}>
      {/* start */}
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'flex-end',
          marginBottom: 8,
          marginRight: 8,
        }}
      >
        {isPending ? (
          <div>Downloading..</div>
        ) : (
          <Download onClick={() => downloadInvoice()} size={16} style={{ cursor: 'pointer' }} />
        )}
      </div>

      {/* <div className='invoice-line'></div> */}
      {/* start */}
      {/* head */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '8px 5px 13px 0px',
        }}
      >
        <div style={{ fontSize: 17, fontWeight: 600 }}>Payment Receipt</div>
      </div>

      {/* head */}
      <div className='invoice-line'></div>
      <div
        style={{
          margin: '25px 0px 30px 0px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Bill from,</div>
          <div className='invoice-txt'>{invoicePaymentData?.company_name || '--'},</div>
          <div className='invoice-txt'>{formattedCompanyAddress || '--'}</div>
          {invoicePaymentData?.company_email && (
            <div className='invoice-txt'>Email: {invoicePaymentData?.company_email}</div>
          )}
          {invoicePaymentData?.company_phone && (
            <div className='invoice-txt'>Phone: {invoicePaymentData?.company_phone}</div>
          )}
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Bill to,</div>
          <div className='invoice-txt'>{invoicePaymentData?.client_name || '--'},</div>
          <div className='invoice-txt'>{formattedClientAddress || '--'}</div>
          {invoicePaymentData?.company_phone && (
            <div className='invoice-txt'>Email: {invoicePaymentData?.company_phone}</div>
          )}
          {invoicePaymentData?.client_phone && (
            <div className='invoice-txt'>Phone: {invoicePaymentData?.client_phone}</div>
          )}
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'>Issued On,</div>
          <div>{issuedDate}</div>
        </div>
        {/* section 1 */}
        <div>
          <div className='head-bold mb-1'></div>
          <div></div>
        </div>
        {/* section 1 */}
      </div>

      <div className='invoice-line mb-4'></div>

      <div className='invoice-sub-table-container'>
        <table className='invoice-table'>
          {/* Table Header */}
          <thead>
            <tr className='invoice-table-header'>
              <th style={{ width: '30%' }}>Invoice Number</th>
              <th>Invoice Date</th>
              <th>Invoice Amount</th>
              <th>Paid Amount</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {invoicePaymentItems &&
              invoicePaymentItems.length &&
              invoicePaymentItems?.map((item, index) => {
                let issuedDate = moment(parseInt(item?.bill_date)).format('D MMM YYYY');

                return (
                  <>
                    <tr key={index} className='invoice-table-row'>
                      <td>{item?.invoice_number}</td>
                      <td>{issuedDate}</td>

                      <td>
                        {mainCurrency}
                        {item?.amount_total}
                      </td>
                      <td>
                        {mainCurrency}
                        {item?.amount_paid}
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>

        <div className='invoice-table-footer '>
          <div className='invoice-footer-label'>Amount Received</div>
          <div className='invoice-footer-value'>
            {mainCurrency}
            {invoicePaymentData?.amount_received}
          </div>
        </div>
        <div className='invoice-table-footer '>
          <div className='invoice-footer-label'>Used Amount</div>
          <div className='invoice-footer-value'>
            {mainCurrency}
            {invoicePaymentData?.amount_for_payment}
          </div>
        </div>
        <div className='invoice-table-footer '>
          <div className='invoice-footer-label'>Amount in excess</div>
          <div className='invoice-footer-value'>
            {mainCurrency}
            {invoicePaymentData?.amount_excess}
          </div>
        </div>
      </div>
    </section>
  );
}
