import React, { useEffect, useState } from 'react';
import { Button } from '../_components';
import { FROM_VALUE, LEDGER_MENUS } from '../Invoice/InvoiceConstant';
import { filename, Images } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export function Export({ handleClose, from, handleOk, isPending }) {
  const [selectedFormat, setSelectedFormat] = useState('pdf');
  const [fileName, setFileName] = useState(`ledger_${filename}`);
  const [menuItems, setMenuItems] = useState([]);

  const returnMenus = (value) => {
    if (value === FROM_VALUE.LEDGER) {
      return LEDGER_MENUS;
    }
  };

  useEffect(() => {
    let menus = returnMenus(from);
    setMenuItems(menus);
  }, [from]);

  // Function to update custom_name or checkbox value
  const handleChange = (index, field, newValue) => {
    setMenuItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? { ...item, [field]: newValue } : item)),
    );
  };

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleExport = (e) => {
    e.preventDefault();
    // Handle export logic here
    console.log('Exporting as', selectedFormat);
    console.log('File name:', fileName);
    console.log('Selected menu items:', menuItems);

    const filteredLedgerMenus = menuItems?.filter((item) => item.value === true);

    handleOk({ selectedFormat, menuItems: filteredLedgerMenus, fileName });
  };

  console.log('na');
  return (
    <div className='scroll-container'>
      <section style={{ marginTop: '8px', padding: '8px' }}>
        {/* pdf and excel */}
        <div style={{ display: 'flex', gap: 8, marginBottom: 10 }}>
          <div
            className={`sml-crd ${selectedFormat === 'pdf' ? 'selected' : ''}`}
            onClick={() => handleFormatChange('pdf')}
            style={{
              border: `1px solid var(--border-color)`,
              borderColor: selectedFormat === 'pdf' ? 'red' : 'var(--border-color)',
            }}
          >
            <img
              src={Images.pdf}
              alt='PDF'
              style={{ height: 18, width: 18, objectFit: 'contain' }}
            />
            <span style={{ fontSize: 13, fontWeight: '500', marginLeft: 5 }}>PDF</span>
          </div>

          <div
            className={`sml-crd ${selectedFormat === 'excel' ? 'selected' : ''}`}
            onClick={() => handleFormatChange('excel')}
            style={{
              border: `1px solid var(--border-color)`,
              borderColor: selectedFormat === 'excel' ? 'green' : 'var(--border-color)',
            }}
          >
            <img
              src={Images.excel}
              alt='Excel'
              style={{ height: 18, width: 18, objectFit: 'contain' }}
            />
            <span style={{ fontSize: 13, fontWeight: '500', marginLeft: 5 }}>Excel</span>
          </div>
        </div>
        {/* pdf and excel */}

        <label style={{ marginBottom: 10, marginTop: 10, fontWeight: '500', fontSize: 13 }}>
          File name
        </label>
        <div>
          <input
            type='text'
            className='inp-head'
            value={fileName}
            style={{ fontSize: 13 }}
            onChange={(e) => setFileName(e.target.value)}
          />
        </div>
        {/* <div className='inp-head'>Report_15-5-2010</div> */}
        <label style={{ marginBottom: 20, fontWeight: '500', fontSize: 13 }}>Select fields</label>
        {menuItems?.map((menu, index) => (
          <section className='export-inp-container' key={index}>
            <div style={{ width: '100%', position: 'relative' }}>
              <div className='label-input'>{menu?.label_name}</div>
              <input
                type='text'
                className='export_text'
                value={menu.custom_name}
                style={{ fontSize: 13 }}
                onChange={(e) => handleChange(index, 'custom_name', e.target.value)}
              />
            </div>
            <input
              className='menu_check_box'
              type='checkbox'
              checked={menu.value}
              onChange={(e) => handleChange(index, 'value', e.target.checked)}
            />
          </section>
        ))}
      </section>
      <div className='modal_footer'>
        <div className=' d-flex justify-content-end gap-2'>
          <Button.Container className={'cancel-btn'} type='reset' handleOk={() => handleClose()}>
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleExport(e)} type='submit' isDisabled={isPending}>
            <Tick /> <Button.Title title={'Export'} />
            <Button.Loading isLoading={isPending} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
}
